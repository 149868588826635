import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Servicio para gestionar la conexión con node.
 */
@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient) { }

  // Define la ruta de origen para las llamadas.
  rootURL = '/api';

  //! GETS
  //#region GETS
  /**
   * Saca toda la lista de markets.
   * @returns
   */
  getMarketsList() {
    return this.http.get<any>(this.rootURL + '/marketsList');
  }

  /**
   * Recibe el Id de la tabla externalplatform y lo envía a node para ejecutar la query.
   * @param id
   * @returns
   */
  getMarket(id: number) {
    return this.http.get<any>(this.rootURL + '/market/' + id);
  }

  /**
   * Recibe el id_externalplatform de la tabla ruleset y lo envía a node para ejecutar la query.
   * @param id
   * @returns
   */
  getProductRules(id: number, type: number) {
    return this.http.get<any>(this.rootURL + '/market/' + id + '/' + type + '/rules')
  }

  /**
   * Recibe el id_externalplatform de la tabla ruleset y lo envía a node para ejecutar la query.
   * @param id
   * @returns
   */
  getCategoryRules(id: number) {
    return this.http.get<any>(this.rootURL + '/market/' + id + '/categories')
  }

  /**
   * Saca la lista de reglas con campo enum para listar en las opciones.
   * @returns
   */
  getRulesEnum() {
    return this.http.get<any>(this.rootURL + '/market/rules/columnEnum/')
  }

  /**
   * Saca la lista de valores de discount_type para Shop&GO
   * @returns lista de valores posibles para discount_type
   */
  getDiscountsSet() {
    return this.http.get<any>(this.rootURL + '/market/shopgo/getDiscountsSet')
  }

  /**
   * Selecciona los distintos tipos de discount reasons
   * @returns 
   */
  getDiscountReasonIds() {
    return this.http.get<any>(this.rootURL + '/market/shopgo/getDiscountReasonIds')
  }

  /**
   * Recibe la tabla de la que se quiere saber los nombres de las columnas y se envía la petición a node.
   * @param table
   * @returns
   */
  getItemFieldColumnNames() {
    return this.http.get<any>(this.rootURL + '/markets/ruleTable/itemField')
  }

  /**
   * Recibe los campos existentes en la columna PropertyName de la tabla de customproperties.
   * @returns
   */
  getCustomProperties() {
    return this.http.get<any>(this.rootURL + '/markets/ruleTable/customProperties')
  }

  /**
   * Recibe los campos disponibles de customproperties
   * @param property
   * @returns
   */
  getCustomPropertiesValues(property: string) {
    return this.http.get<any>(this.rootURL + '/markets/ruleTable/customPropertiesValues/' + property)
  }

  /**
   * Recibe los campos de fieldsList
   * @param column
   * @returns
   */
  getItemFieldsList(column: string) {
    return this.http.get<any>(this.rootURL + '/markets/ruleTable/itemFieldsList/' + column)
  }

  /**
   * Recibe los campos de fieldslist y la tabla
   * @param column
   * @param table
   * @returns
   */
  getItemFieldsListTable(column: string, table: string) {
    return this.http.get<any>(this.rootURL + '/markets/ruleTable/itemFieldsList/' + column + '/' + table)
  }

  /**
   * Recibe los campos de fieldslist y la tabla para tablas sin group_names
   * @param column
   * @param table
   * @returns
   */
  getItemFieldsListSpecial(column: string, table: string) {
    return this.http.get<any>(this.rootURL + '/markets/ruleTable/itemFieldsListSpecial/' + column + '/' + table)
  }

  /**
   * Recibe el id para pedir el json con los datos requeridos.
   * @param id
   * @returns
   */
  getMarketJson(id: number) {
    return this.http.get<any>(this.rootURL + '/market/' + id + '/marketJson')
  }

  /**
   * Recibe el id para pedir el json con los campos activos para ser mapeados
   * @param id
   * @returns
   */
  getMarketJsonActives(id: number) {
    return this.http.get<any>(this.rootURL + '/market/' + id + '/actives')
  }

  /**
   * Recibe la lista de alias para los itemfields.
   * @returns
   */
  getItemFieldAlias() {
    return this.http.get<any>(this.rootURL + '/markets/itemFieldAlias')
  }

  /**
   * Recibe los datos del mapeo para comprobar si ya existen o no
   * @param id
   * @param attType
   * @returns
   */
  checkMapping(id: number, attType: string) {
    return this.http.get<any>(this.rootURL + '/market/' + id + '/map/' + attType)
  }

  /**
   * Recibe los datos del mapeo para comprobar si ya existen o no
   * @param id
   * @param attType
   * @returns
   */
  checkDynamicMapping(id: number, attType: string, mappingTabId: number) {
    return this.http.post(this.rootURL + '/checkDynamicMapping', {id, attType, mappingTabId})
  }

  checkMappingCaracteristicas() {
    return this.http.get(this.rootURL + '/checkMappingCaracteristicas')
  }

  /**
   * Obtiene los mapeos de talla
   * @returns 
   */
  getSizeMappings(){
    return this.http.get<any>(this.rootURL +`/markets/getSizeMappings`)
  }

  /**
   * Obtiene las marcas
   * @returns 
   */
  getBrands(){
    return this.http.get<any>(this.rootURL +`/markets/getBrands`)
  }

  /**
   * Obtiene las tallas externas para el market seleccionado
   * @param id de la plataforma externa
   * @returns 
   */
  getExternalSizes(id:any){
    return this.http.get<any>(this.rootURL +`/markets/getExternalSizes/`+id)
  }
  
  /**
   * Obtiene la lista de plataformas externas
   * @returns 
   */
  getPlatforms(){
    return this.http.get<any>(this.rootURL +`/markets/getPlatforms`)
  }

  /**
   * Obtiene la lista de dominios de talla
   * @returns 
   */
  getDomains(){
    return this.http.get<any>(this.rootURL +`/markets/getDomains`)
  }

  /**
   * Obtiene la información del mapeo que va a ser editado
   * @param id del mapeo
   * @returns 
   */
  getMappingToEdit(id:any){
    return this.http.get<any>(this.rootURL +`/markets/getMappingToEdit/`+id)
  }

  /**
   * Recibe los datos de los internalgroups
   * @returns
   */
  getInternalGroups() {
    return this.http.get<any>(this.rootURL + '/markets/internalgroups')
  }

  /**
   * Obtiene los valores de grupos internos correspondientes con el internalgroup02
   * @returns 
   */
  getInternalGroupsNameGroup2(){
    return this.http.get<any>(this.rootURL + '/markets/internalGroupsName2')
  }

  /**
   * Obtiene los valores de grupos internos correspondientes con el internalgroup13
   * @returns 
   */
  getInternalGroupsNameGroup13(){
    return this.http.get<any>(this.rootURL + '/markets/internalGroupsName13')
  }

  /**
   * Recibe la lista de errores de la tabla item_error
   */
  getItemErrors() {
    return this.http.get<any>(this.rootURL + '/markets/getItemErrors');
  }

  /**
   * Recibe la lista de errores de la tabla item_error
   */
   getItemErrorsOpt() {
    return this.http.get<any>(this.rootURL + `/markets/getItemErrorsOpt/paged`);
  }

  /**
   * Llamada con where para la consulta de la tabla de errores
   * @param where 
   * @returns 
   */
  getItemErrorsFiltered(where:string) {
    return this.http.get<any>(this.rootURL + `/markets/getItemErrorsOpt/filtered/${where}`);
  }

  /**
   * Recibe los datos de talla del artículo requerido
   * @param id referencia del artículo
   * @param ean código ean del artículo
   * @returns {any}
   */
  getTalla(id: number, ean: string) {
    return this.http.get<any>(this.rootURL + '/getTalla/'+id+"/"+ean);
  }

  /**
   * Recibe los datos 
   * @param id referencia del artículo
   * @param id_externalplatform id de plataforma externa
   * @returns {any} 
   */
  getTablaTalla(id: number, id_externalplatform: number) {
    return this.http.get<any>(this.rootURL + '/getTablaTalla/'+id+"/"+id_externalplatform);
  }

  /**
   * Recibe la lista de errores y warnings de la tabla logs con los parámetros de server side
   * @returns 
   */
  getLogErrors() {
    return this.http.get<any>(this.rootURL + '/markets/getLogErrors');
  }

  /**
   * Recibe la lista de errores y warnings de la tabla de logs
   * @returns 
   */
  getAllLogErrors() {
    return this.http.get<any>(this.rootURL + '/markets/getAllLogErrors')
  }

  /**
   * Obtiene la información de origen de la tabla log
   * @returns 
   */
  getOrigen(){
    return this.http.get<any>(this.rootURL+'/markets/getOrigen')
  }

  /**
   * Recibe la lista de ids que comparten un market_group
   * @param id 
   * @returns 
   */
  getMarketGroups(id: number) {
    return this.http.get<any>(this.rootURL + '/markets/getMarketGroups/' + id)
  }

  /**
   * Saca la información de los pagos
   * @returns 
   */
  getPaymentsInfo() {
    return this.http.get<any>(this.rootURL + '/markets/getPaymentsInfo')
  }

  /**
   * Saca el usuario requerido
   * @param email 
   * @returns 
   */
  getUserByEmail(email: string) {
    return this.http.get<any>(this.rootURL + '/getUserByEmail/' + email);
  }

  /**
   * Saca el usuario a través de su authToken
   * @param authToken 
   * @returns 
   */
  getUserByAuthToken(authToken: string) {
    return this.http.get<any>(this.rootURL + '/getUserByAuthToken/' + authToken);
  }

  /**
   * Saca la lista de usuarios existentes
   * @returns 
   */
  getAllUsers() {
    return this.http.get<any>(this.rootURL + '/allUsers');
  }

  /**
   * Saca la lista de todos los permisos
   * @returns 
   */
  getPermisos() {
    return this.http.get<any>(this.rootURL + '/allPermisos');
  }

  /**
   * Saca la lista de todos los roles
   * @returns 
   */
  getRoles() {
    return this.http.get<any>(this.rootURL + '/allRoles');
  }

  /**
   * Saca la lista de los roles con los nombres de los permisos
   * @returns 
   */
  getRolesWithNames() {
    return this.http.get<any>(this.rootURL + '/getRolesWithNames');
  }

  /**
   * Saca el rol requerido por id
   * @param id 
   * @returns 
   */
  getRole(id: number) {
    return this.http.get<any>(this.rootURL + '/getRole/' + id);
  }

  /**
   * Obtiene los códigos de error de la configuración
   * @returns 
   */
  getErrorCodes(){
    return this.http.get<any>(this.rootURL + '/markets/errorCodes');
  }

  /**
   * Saca los item_states
   * @returns 
   */
  getItemStates() {
    return this.http.get<any>(this.rootURL + '/getItemStates')
  }

  /**
   * Saca las orders
   * @returns 
   */
  getOrderStates() {
    return this.http.get<any>(this.rootURL + '/getOrderStates')
  }

  /**
   * Obtiene la información de productos para el market seleccionado
   * @param id de la plataforma externa
   * @returns 
   */
  getItemInfo(id: number) {
    return this.http.get<any>(this.rootURL + '/getItemInfo/' + id);
  }
  /**
   * Recibe los datos de temporadas
   * @returns
   */
  getInternalSeasons() {
    return this.http.get<any>(this.rootURL + '/getinternalDataSeasons')
  }

  /**
   * Obtiene la información de rules y rulesets
   * @param id 
   * @returns 
   */
  getRulesAndRuleset(id:number) {
    return this.http.get<any>(this.rootURL + '/getRulesAndRuleset/'+id)
  }

  /**
   * Recibe los datos de marcas internas
   * @returns 
   */
  getInternalBrands() {
    return this.http.get<any>(this.rootURL + '/getInternalBrands')
  }

  /**
   * Recibe los datos de marcas internas
   * @returns 
   */
  getManufacturers() {
    return this.http.get<any>(this.rootURL + '/getManufacturers')
  }

  /**
   * getMappingOrderState
   * @returns mapeo de estados de pedidos del market seleccionado
   */
   getMappingOrderStates(id: number) {
    return this.http.get<any>(this.rootURL + '/getMappingOrderStates/' + id)
  }

  /**
   * Saca la lista de todos las tiendas y sus respectivas 
   * inclusiones o exclusiones a la hora de reponer stock
   * @returns 
   */
  getInclusionsExclusions() {
    return this.http.get<any>(this.rootURL + '/allInclusionsExclusions');
  }

  /**
   * Saca la lista de todas las tiendas y sus respectivas 
   * inclusiones o exclusiones a la hora de reponer stock
   * con nombres en lugar de ids
   * @returns 
   */
  getInclusionsExclusionsWithNames() {
    return this.http.get<any>(this.rootURL + '/allInclusionsExclusionsWithNames');
  }

  /**
   * Saca la lista de todas las tarifas
   * @returns 
   */
  getTarifas() {
    return this.http.get<any>(this.rootURL + '/getTarifas');
  }

  /**
   * Saca la lista del control de exposición
   * @returns 
   */
  getControlExposicion() {
    return this.http.get<any>(this.rootURL + '/getControlExposicion');
  }

  /**
   * Saca la lista de descuentos para Shop & Go
   * @returns lista de descuentos para Shop & GO
   */
  getSGDiscounts() {
    return this.http.get<any>(this.rootURL + '/getSGDiscounts');
  }

  /**
   * Saca la lista de descuentos para Shop & Go
   * @returns lista de descuentos para Shop & GO
   */
  getSeurUsers() {
    return this.http.get<any>(this.rootURL + '/getSeurUsers');
  }

  /**
   * Saca la lista de descuentos para Shop & Go
   * @returns lista de descuentos para Shop & GO
   */
  getSeurUser(id: number) {
    return this.http.get<any>(this.rootURL + '/getSeurUser/' + id);
  }

  /**
   * Saca los datos del descuento requerido de Shop&GO
   * @returns datos del descuento
   */
  getSGDiscount(id: number) {
    return this.http.get<any>(this.rootURL + '/getSGDiscount/' + id);
  }

  /**
   * Saca la lista de todas las tiendas, ids y nombre
   * @returns 
   */
  getAllTiendas() {
    return this.http.get<any>(this.rootURL + '/allTiendas');
  }
  
  /**
   * Saca la lista de todas las tiendas, ids y nombre
   * @returns 
   */
  getAllTiendasPedidos() {
    return this.http.get<any>(this.rootURL + '/getAllTiendasPedidos');
  }

  /**
   * Saca la lista de tiendas para el editor múltiple
   * @returns 
   */
  getTiendasEdit() {
    return this.http.get<any>(this.rootURL + '/getTiendasEdit')
  }

  /**
   * Saca la lista de todas las tiendas, ids y nombre, que no estén ya en la tabla
   * @returns 
   */
  getTiendasNotIncExc() {
    return this.http.get<any>(this.rootURL + '/tiendasNotIncExc');
  }

  /**
   * Saca la lista de todas las tiendas, ids y nombre, que no estén ya en la tabla
   * @returns 
   */
  getTiendaIncExc(id: number) {
    return this.http.get<any>(this.rootURL + '/tiendaIncExc/'+id);
  }

  /**
   * Saca la lista de todas las reglas de logística
   * @returns 
   */
  getLogisticRules() {
    return this.http.get<any>(this.rootURL + '/getLogisticRules')
  }

  /**
   * Obtiene la regla logística seleccionada
   * @param id de la regla logística
   * @returns 
   */
  getLogisticRule(id: any) {
    return this.http.get<any>(this.rootURL + '/getLogisticRule/'+id)
  }

  /**
   * Obtiene los países a los que se hacen envíos
   * @returns 
   */
  getShippingCountries() {
    return this.http.get<any>(this.rootURL + '/getShippingCountries')
  }

  /**
   * Obtiene las provincias a las que se hacen envíos
   * @returns 
   */
  getShippingProvinces() {
    return this.http.get<any>(this.rootURL + '/getShippingProvinces')
  }

  /**
   * Obtiene las tienas con stock
   * @returns 
   */
  getStockStores() {
    return this.http.get<any>(this.rootURL + '/getStockStores')
  }

  /**
   * Obtiene los datos de las plataformas externas
   * @returns 
   */
  getExternalPlatforms() {
    return this.http.get<any>(this.rootURL + '/getExternalPlatforms')
  }

  /**
   * Obtiene los operadores logísticos
   * @returns 
   */
  getLogisticOperators() {
    return this.http.get<any>(this.rootURL + '/getLogisticOperators')
  }

  /**
   * Obtiene los valores de los operadores logísticos
   * @returns 
   */
  getLogisticOperatorsValues() {
    return this.http.get<any>(this.rootURL + '/getLogisticOperatorsValues')
  }

  /**
   * Obtiene las reglas logísticas a partir de una prioridad seleccionada
   * @param priority desde la que se quiere consultar
   * @returns 
   */
  getFromPriority(priority:number) {
    return this.http.get<any>(this.rootURL + '/getFromPriority/' + priority)
  }
  
  /**
  * getMiraklCategories
  * @returns categorías de Mirakl
  */
  getMiraklCategories(id: number) {
    return this.http.get<any>(this.rootURL + '/getMiraklCategories/' + id)
  }

  /**
   * Obtiene la información de deportes para el market de MIRAKL seleccionado
   * @param id de la plataforma externa
   * @returns 
   */
  getMiraklSportCategories(id: number) {
    return this.http.get<any>(this.rootURL + '/getMiraklSportCategories/' + id)
  }
  
  /**
   * Obtiene la configuración de tienda
   * @returns 
   */
  getShopConfData() {
    return this.http.get<any>(this.rootURL + '/getShopConfData')
  }

  /**
   * Recibe los datos de configuración de la tienda requerida
   * @param id 
   * @returns 
   */
  getShopData(id:number) {
    return this.http.get<any>(this.rootURL + '/getShopData/'+id)
  }

  /**
   * Obtiene los id y los números de pedido
   * @returns 
   */
  getNumOrders() {
    return this.http.get<any>(this.rootURL + '/getNumOrders')
  }
  
  /**
   * Obtiene los datos de pedido de la plataforma seleccionada y dentro del límite propuesto
   * @param id de la plataforma externa
   * @param limit límite de resultados
   * @returns 
   */
  getNumOrdersPlatform(id: number, limit: number) {
    return this.http.get<any>(this.rootURL + '/getNumOrdersPlatform/'+ id + '/' + limit)
  }

  /**
   * Obtiene los datos de pedido del pedido seleccionado
   * @param idOrder id del pedido
   * @returns 
   */
  getOrder(idOrder: string) {
    return this.http.get<any>(this.rootURL + '/getOrder/' + idOrder)
  }

  /**
   * Obtiene las lines del pedido seleccionado
   * @param idOrder id del pedido
   * @returns 
   */
  getOrderLines(idOrder: number) {
    return this.http.get<any>(this.rootURL + '/getOrderLines/' + idOrder)
  }

  /**
   * Obtiene los descuentos del pedido seleccionado
   * @param idOrder id del pedido
   * @returns 
   */
  getOrderDiscounts(idOrder: number) {
    return this.http.get<any>(this.rootURL + '/getOrderDiscounts/' + idOrder)
  }

  /**
   * Obtiene los charges del pedido seleccionado
   * @param idOrder id del pedido
   * @returns 
   */
  getOrderCharges(idOrder: number) {
    return this.http.get<any>(this.rootURL + '/getOrderCharges/' + idOrder)
  }

  /**
   * Devuelve lo que se le envíe y se utiliza como si fuesen líneas de pedido
   * @param orderLines 
   * @returns 
   */
  returnOrderLines(orderLines: string) {
    return this.http.get<any>(this.rootURL + '/returnOrderLines/' + orderLines)
  }

  /**
   * Obtiene todos los productos que están asociados a una plataforma externa
   * @param idExternalPlat 
   * @returns 
   */
  getArticlesExternalPlatform(idExternalPlat: any) {
    return this.http.get<any>(this.rootURL + '/getArticlesExternalPlatform/' + idExternalPlat)
  }

  /**
   * Obtiene las tallas de  un artículo en función de su Id y su plataforma externa
   * @param marketId id de la plataforma externa
   * @param itemId id del artículo
   * @returns 
   */
  getItemSizes(marketId: number, itemId: number) {
    return this.http.get<any>(this.rootURL + '/getItemSizes/' + marketId + "/" + itemId)
  }

  getSizeDomains() {
    return this.http.get<any>(this.rootURL + '/getSizeDomains')
  }

  getSizeLabelsPlantillas() {
    return this.http.get<any>(this.rootURL + '/getSizeLabelsPlantillas')
  }

  /**
   * Gestiona el guardado de una nueva línea de pedido
   * @param marketId 
   * @returns 
   */
  handleNewLineExternalId(marketId: number) {
    return this.http.get<any>(this.rootURL + '/handleNewLineExternalId/' + marketId)
  }
  
  /**
   * Recibe los datos de las categorías de Miravia
   * @returns 
   */
  getCategoriesMiravia() {
    return this.http.get<any>(this.rootURL + '/getCategoriesMiravia')
  }

  /**
   * Obtiene los parámtros de configuración del market
   * @param id 
   * @returns 
   */
  getConfigMarket(id:number){
    return this.http.get<any>(this.rootURL+'/markets/' + id + '/getConfigMarket')
  }

  /**
   * Obtiene los parámetros de configuración de las tiendas
   * @returns 
   */
  getConfigShops(){
    return this.http.get<any>(this.rootURL+'/markets/getConfigShops')
  }

  /**
   * OBtiene la configuración de todas las tiendas
   * @returns 
   */
  getAllConfigShops(){
    return this.http.get<any>(this.rootURL+'/markets/getAllConfigShops')
  }

  /**
   * Obtiene el valor de los operadores logísticos
   * @returns 
   */
  getOpLogisticValue(){
    return this.http.get<any>(this.rootURL+'/getOpLogisticValue')
  }

  /**
   * Obtiene los valores de estados de operadores logísticos
   * @returns 
   */
  getOpLogisticState(){
    return this.http.get<any>(this.rootURL+'/getOpLogisticState')
  }

  /**
   * Obtiene los datos logísticos de Avelon
   * @returns 
   */
  getAvelonLogisticValue(){
    return this.http.get<any>(this.rootURL+'/getAvelonLogisticValue')
  }

  /**
   * Obtiene los datos logístcos no mapeados
   * @returns 
   */
  getOpLogisticStateNoMap(){
    return this.http.get<any>(this.rootURL+'/getOpLogisticStateNoMap')
  }

  /**
   * Obtiene lo datos de mapeos de estados logísticos
   * @returns 
   */
  getMappingOpLogisticStates(){
    return this.http.get<any>(this.rootURL+'/getMappingOpLogisticStates')
  }

  /**
   * Obtiene la lista de dispositivos de Shop & Go
   * @returns 
   */
  getSgDevices(){
    return this.http.get<any>(this.rootURL+'/getSgDevices')
  }

  /**
   * Obtiene la lista de tiendas
   * @returns 
   */
  getTiendas(){
    return this.http.get<any>(this.rootURL+'/getTiendas')
  }

  /**
   * Recibe la lista de códigos iso de los países internos
   * @returns {any} lista de isos internos
   */
  getCountryIsos(){
    return this.http.get<any>(this.rootURL+'/getCountryIsos')
  }

  /**
   * Recibe la lista de códigos iso de Mirakl
   * @returns {any} lista de isos externos
   */
  getMiraklIsoCodes(){
    return this.http.get<any>(this.rootURL+'/getMiraklIsoCodes')
  }

  /**
   * Llamada a la API de SGA para realizar las operaciones de ItemMovementDocuments
   * @returns 
   */
  itemMovementDocuments() {
    return this.http.get<any>(this.rootURL+'/itemMovementDocuments')
  }

  /**
   * Llamada a la API de SGA para realizar las operaciones de RefreshB2C
   * @returns 
   */
  refreshB2C() {
    return this.http.get<any>(this.rootURL+'/refreshB2C')
  }

  /**
   * Obtiene los parámetros de configuración
   * @returns 
   */
  getConfigParams() {
    return this.http.get<any>(this.rootURL+'/getConfigParams')
  }

  /**
   * Recibe el JSON con los valores distintos de tipo y origen de los logs
   */
  getLogTypeOrigin() {
    return this.http.get<any>(this.rootURL+'/getLogTypeOrigin')
  }

  getShopPatterns() {
    return this.http.get<any>(this.rootURL+'/getShopPatterns')
  }
  
  getTemplatePatterns() {
    return this.http.get<any>(this.rootURL+'/getTemplatePatterns')
  }

  getTemplateShop() {
    return this.http.get<any>(this.rootURL+'/getTemplateShop')
  }

  getTemplates() {
    return this.http.get<any>(this.rootURL+'/getTemplates')
  }

  getTemplateItemShops() {
    return this.http.get<any>(this.rootURL+'/getTemplateItemShops')
  }

  getPatternIndexes() {
    return this.http.get<any>(this.rootURL+'/getPatternIndexes')
  }

  getPurchaseOrders(supplierId: number) {
    return this.http.get<any>(this.rootURL+'/getPurchaseOrders/' + supplierId)
  }

  getProductTotalCounts() {
    return this.http.get<any>(this.rootURL+'/getProductTotalCounts')
  }

  getTemplateTotalCounts(templateId: number) {
    return this.http.get<any>(this.rootURL+'/getTemplateTotalCounts/' + templateId)
  }

  getCaracteristicas() {
    return this.http.get<any>(this.rootURL+'/getCaracteristicas')
  }
  //#endregion GETS

  //! SEMI-GETS
  //#region SEMI-GETS
  /**
   * Recibe el id_externalplatform de la tabla ruleset y lo envía a node para ejecutar la query.
   * @param id
   * @returns
   */
  getRulesOnProducts(id: number, id_ruleset: number) {
    return this.http.post(this.rootURL + '/market/' + id + '/rules', {id_ruleset})
  }

  /**
   * Recibe la tabla y el dato que quiere consultar.
   * @param id
   * @param data
   * @param table
   * @returns
   */
  getMarketTableData(id: number, table: String, order: String , fields: String = '*',id_atributo:number = 0) {
    return this.http.post(this.rootURL + '/market/' + id + '/data', {table, order, fields, id_atributo})
  }

  /**
   * ? Llamada para mientras no se cambia el componente de Mapeos
   * Obtiene los datos de marcas para miravia
   * @param id 
   * @param table 
   * @param order 
   * @param fields 
   * @returns 
   */
  getMiraviaBrands(id: number, table: String, order: String , fields: String = '*') {
    return this.http.post(this.rootURL + '/getMiraviaBrands/' + id, {table, order, fields})
  }

  /**
   * Recibe el grupo por el que quiere filtrar
   * @param id
   * @param group
   * @returns
   */
  getMarketTableDataGroups(group: string) {
    return this.http.post(this.rootURL + '/getMarketTableDataGroups', {group})
  }

  /**
   * Recibe la tabla y el dato que quiere consultar.
   * @param id
   * @param field
   * @returns
   */
  getMarketTableDataCompositionSpartoo(field: String) {
    return this.http.post(this.rootURL + '/getMarketTableDataCompositionSpartoo', {field})
  }

  /**
   * Llamada para sacar datos de internalgroups
   * @param group 
   * @returns 
   */
  getFromInternal(group: string) {
    return this.http.post(this.rootURL + '/getFromInternal', {group})
  }

  /**
   * Llamada para crear una rule cuando se activa un market
   * @param externalPlatform 
   * @returns 
   */
  productRuleCreate(externalPlatform: number) {
    return this.http.post(this.rootURL + '/productRuleCreate', {externalPlatform})
  }

  /**
   * getAllFromTable
   * @param table 
   * @returns 
   */
  getAllFromTable(table: string) {
    return this.http.post(this.rootURL + '/getAllFromTable', {table})
  }

  /**
   * Llamada para sacar todas las conversiones
   * @param id 
   * @returns 
   */
  getConversions(id:any){
    return this.http.post(this.rootURL +`/markets/getConversions`,{id})
  }

  /**
   * Recibe los campos para crear una categoría de Mirakl.
   * @param name
   * @param marketId
   * @param externalCat
   * @param orden
   * @param type
   * @returns
   */
  createMiraklCategory(marketId: number, name: string ) {
    return this.http.post(this.rootURL + '/createMiraklCategory', {marketId, name})
  }

  getOpLogisticStateNoMapFiltro(idOpLogistico: number){
    return this.http.post(this.rootURL+'/getOpLogisticStateNoMapFiltro', {idOpLogistico})
  }

  getSelectionSimulate(itemList: string) {
    return this.http.post<any>(this.rootURL+'/getSelectionSimulate/', {itemList})
  }
  //#endregion SEMI-GETS

  //! POSTS
  //#region POSTS
  /**
   * Recibe los campos para crear una regla de producto junto con el id de market.
   * @param name
   * @param marketId
   * @param externalCat
   * @param orden
   * @param type
   * @returns
   */
  createProductRule(name: string, marketId: number, externalCat: string, orden: number, type: number) {
    return this.http.post(this.rootURL + '/market/ruleset/create', {name, marketId, externalCat, orden, type})
  }

  /**
   * Recibe los campos para crear una regla de categoría junto con el id de market.
   * @param id_ruleset
   * @param rule_type
   * @param attribute_type
   * @param attribute
   * @param operation
   * @param value
   * @returns
   */
   createRule(id_ruleset: number, rule_type: string, attribute_type: string, attribute: string, operation: string, value: string, name: string = '') {
    return this.http.post(this.rootURL + '/market/rule/create', {id_ruleset, rule_type, attribute_type, attribute, operation, value, name})
  }

  /**
   * Recibe una string con los valores a utilizar en el mapeo dinámico
   * @param values
   * @returns
   */
  createDynamicMapping(values: string, market_id: number, name: string, attribute_type: string) {
    return this.http.post(this.rootURL + '/market/dynamicMap/save', {values, market_id, name, attribute_type})
  }

  /**
   * Comprueba si hay tab para el mapeo, si no, lo crea
   * @param market_id 
   * @param mapping_attributes 
   * @returns 
   */
  checkMappingTab(market_id: number, mapping_attributes: string) {
    return this.http.post(this.rootURL + '/market/dynamicMap/check', {market_id, mapping_attributes})
  }

  /**
   * Saca el id del tab para el mapeo correspondiente
   * @param market_id 
   * @param name 
   * @param attribute_type 
   * @returns 
   */
  getMappingTabId(market_id: number, name: string, attribute_type: string) {
    return this.http.post(this.rootURL + '/getMappingTabId', {market_id, name, attribute_type})
  }

  /**
   * Se guarda el mapeo de tallas
   * @param marca 
   * @param domain 
   * @param conversion 
   * @param platform 
   * @param external_size 
   * @returns 
   */
  setSizeMapping(marca:any, domain:any, conversion:any, platform:any, external_size:any){
    return this.http.post(this.rootURL + '/market/map/setSizeMapping', {marca, domain, conversion, platform, external_size})
  }

  /**
   * Se guarda el mapeo de discount
   * @param external_discount 
   * @param avelon_discount_reason 
   * @returns 
   */
  setDiscount(external_discount:any,avelon_discount_reason:any, external_platform:any){
    return this.http.post(this.rootURL + '/market/map/setDiscount', {external_discount,avelon_discount_reason,external_platform})
  }

  /**
   * Duplica las reglas recimiebo el id de la plataforma y de la regla que quiere copiar
   * @param idPlatform 
   * @param idRuleset 
   * @returns 
   */
  duplicateRuleset(idPlatform: number, idRuleset: number) {
    return this.http.post(this.rootURL + '/market/duplicateRuleset', {idPlatform, idRuleset})
  }

  /**
   * Crea un nuevo usuario
   * @param nombre 
   * @param apellidos 
   * @param email 
   * @param password 
   * @param role 
   * @returns 
   */
  saveNewUser(nombre: string, apellidos: string, email: string, password: string, role: number) {
    return this.http.post(this.rootURL + '/createUser' , {nombre, apellidos, email, password, role})
  }

  /**
   * Crea un nuevo rol
   * @param name 
   * @param permisos 
   * @returns 
   */
  saveNewRole(name: string, permisos: string) {
    return this.http.post(this.rootURL + '/createRole' , {name, permisos})
  }

  /**
   * Guarda el mapeo de tallas
   * @param id 
   * @param marca 
   * @param domain 
   * @param conversion 
   * @param platform 
   * @param external_size 
   * @returns 
   */
  saveMappingEdit(id:any, marca:any, domain:any, conversion:any, platform:any, external_size:any){
    return this.http.post<any>(this.rootURL + `/market/map/saveMappingEdit`, {id, marca, domain, conversion, platform, external_size})
  }

  /**
   * Crea un mapeo de estado de pedido
   * @param externalPlatform_id 
   * @param avelon_order_state 
   * @param avelon_order_logitic_state 
   * @param market_state 
   * @returns 
   */
  createOrderStateMap(externalPlatform_id: number, avelon_order_state: string, avelon_order_logistic_state: string, market_state: number, avelon_state_list_lang: string, avelon_logistic_state_list_lang: string) {
    return this.http.post(this.rootURL + '/createOrderStateMap' , {externalPlatform_id, avelon_order_state, avelon_order_logistic_state, market_state, avelon_state_list_lang, avelon_logistic_state_list_lang})
  }

  /**
   * Guarda una nueva tienda
   * @param id 
   * @param inc 
   * @param exc 
   * @returns 
   */
  saveNewTienda(values: string) {
    return this.http.post(this.rootURL + '/saveNewTienda', {values})
  }

  /**
   * Guarda un nuevo descuento
   * @param values 
   * @returns 
   */
  saveDiscount(values: string) {
    return this.http.post(this.rootURL + '/saveDiscount', {values})
  }

  /**
   * Guarda un nuevo usuario de SEUR
   * @param values 
   * @returns 
   */
  saveSeurUser(values: string) {
    return this.http.post(this.rootURL + '/saveSeurUser', {values})
  }

  /**
   * Guarda la regla logística
   * @param values 
   * @returns 
   */
  saveLogisticRule(values: string) {
    return this.http.post(this.rootURL + '/saveLogisticRule', {values})
  }

  /**
   * Guarda el estado de regla logística
   * @param values 
   * @returns 
   */
  saveLogisticState(values: string) {
    return this.http.post(this.rootURL + '/saveLogisticState', {values})
  }

  /**
   * Guarda el estado de la regla logística mapeable
   * @param values 
   * @returns 
   */
  saveLogisticStateMapeable(values: string) {
    return this.http.post(this.rootURL + '/saveLogisticStateMapeable', {values})
  }

  /**
   * Guarda un nuevo dispositivo de Shop & Go
   * @param values 
   * @returns 
   */
  saveSgDevice(values: string) {
    return this.http.post(this.rootURL + '/saveSgDevice', {values})
  }
  
  /**
   * Inserta el pedido editado
   * @param orderData 
   */
  insertEditedOrder(orderData: string) {
    return this.http.post(this.rootURL + '/insertEditedOrder', {orderData})
  }

  /**
   * Gestiona el cambio de transportista de cara a Avelon
   * @param handlerInfo 
   * @param serviceInfo 
   * @returns 
   */
  handleShipping(handlerInfo: string, serviceInfo: string) {
    return this.http.post(this.rootURL + '/handleShipping', {handlerInfo, serviceInfo})
  }

  /**
   * Añade un mensaje al log
   * @param message 
   * @returns 
   */
  logData(message: string) {
    return this.http.post(this.rootURL + '/logData', {message})
  }

  updateTemplateItemShops(id_item: number, id_template: number,  shops: string) {
    return this.http.post(this.rootURL + '/updateTemplateItemShops', {id_item, id_template, shops})
  }
  updateTemplateItemShopsValues(values: string) {
    return this.http.post(this.rootURL + '/updateTemplateItemShopsValues', {values})
  }

  addTemplateShop(values: string) {
    return this.http.post(this.rootURL + '/addTemplateShop', {values})
  }

  createTemplatePattern(values: string) {
    return this.http.post(this.rootURL + '/createTemplatePattern', {values})
  }

  createTemplate(name: string, sizeDomain: string) {
    return this.http.post(this.rootURL + '/createTemplate', {name, sizeDomain})
  }
  /**
   * Envía la lista de referencias a las que aplicar los mínimos internos
   * @returns 
   */
  minimosInternosRefresh(data: string) {
    return this.http.post<any>(this.rootURL+'/minimosInternosRefresh', {data})
  }

  saveNotes(reference:number, notes: string) {
    return this.http.post<any>(this.rootURL+'/saveNotes', {reference, notes})
  }
  
  bindShops(filters: number[], shops: any[], template: number = 0, type: string = "common") {
    return this.http.post<any>(this.rootURL+'/bindShops', {filters, shops, template, type})
  }
  
  createCaracteristica(caracteristica: string) {
    return this.http.post<any>(this.rootURL+'/createCaracteristica', {caracteristica})
  }

  createFeatureMapping(values: string) {
    return this.http.post<any>(this.rootURL+'/createFeatureMapping', {values})
  }
  //#endregion POSTS

  //! PUTS
  //#region PUTS
  /**
   * Recibe los campos para editar junto con el id_ruleset para actualizar el registro.
   * @param name
   * @param externalCat
   * @param order
   * @param type
   * @param id
   * @returns
   */
  updateProductRule(name: string, category: string, id: number) {
    return this.http.put(this.rootURL + '/market/ruleset/edit', {name, category, id})
  }

  /**
   * Actualiza a borrada la regla de producto
   * @param id 
   * @returns 
   */
  updateDeleteProductRule(id: number) {
    return this.http.put(this.rootURL + '/market/ruleset/editDelete', {id})
  }
  
  /**
   * Recibe los campos para editar junto con el id_rule para actualizar el registro.
   * @param type
   * @param attType
   * @param attribute
   * @param operacion
   * @param valor
   * @param id
   * @returns
   */
  updateRule(type: string, attType: string, attribute: string, operacion: string, valor: string, id: number, name: string = '') {
    return this.http.put(this.rootURL + '/market/rule/edit', {type, attType, attribute, operacion, valor, id, name })
  }

  /**
   * Recibe los campos para editar junto con el id_rule para actualizar el registro.
   * @param type
   * @param attType
   * @param attribute
   * @param operacion
   * @param valor
   * @param id
   * @returns
   */
  updateMiraklCategory(new_value: string, valor: string, marketId: number) {
    return this.http.put(this.rootURL + '/updateMiraklCategory', {new_value, valor, marketId })
  }

  /**
   * Recibe el campo name y el id_rule para actualizar el name del registro.
   * @param name
   * @param id
   * @returns
   */
  updateRuleName(name: string, id: number) {
    return this.http.put(this.rootURL + '/market/rules/edit/name', {name, id})
  }

  /**
   * Recibe los campos para actualizar discounts
   * @param avelon_discount_reason 
   * @param external_discount 
   * @param id 
   * @returns 
   */
  updateDiscounts(avelon_discount_reason:any,external_discount:any,id:any,external_platform:any) {
    return this.http.put(this.rootURL + '/update/updateDiscounts', {avelon_discount_reason,external_discount,id,external_platform})
  }

  /**
   * Recibe los strings utilizados para lanzar una actualización múltiple en el market deseado
   * @param valuesWhen
   * @param valuesIn
   * @returns
   */
  updateDynamicMapping(valuesWhen: string, valuesIn: string, marketId: number, mappingTabId: number) {
    return this.http.put(this.rootURL + '/market/map/dynamicEdit', {valuesWhen, valuesIn, marketId, mappingTabId})
  }

  /**
   * Recibe el estado al que debe ser actualizado un market.
   * @param state 
   * @param id 
   * @returns 
   */
  updateMarketState(state: number, id: number) {
    return this.http.put(this.rootURL + '/market/updateState', {state, id})
  }

  /**
   * Recibe el id de error que debe ser actualizado
   * @param idLine 
   * @returns 
   */
  marcarResuelto(idLine: string) {
    return this.http.put(this.rootURL + '/market/marcarResuelto', {idLine})
  }

  /**
   * Actualiza a resuelto el error de producto
   * @param idLine 
   * @returns 
   */
  marcarResueltoTipo(idLine: string) {
    return this.http.put(this.rootURL + '/market/marcarResueltoTipo/tipo', {idLine})
  }

  /**
   * Edita al usuario requerido
   * @param id 
   * @param nombre 
   * @param apellidos 
   * @param email 
   * @param password 
   * @param role 
   * @returns 
   */
  saveUserEdit(id: number, nombre: string, apellidos: string, email: string, password: string, role: number) {
    return this.http.put(this.rootURL + '/saveUserEdit', {id, nombre, apellidos, email, password, role})
  }

  /**
   * Edita el role requerido
   * @param id 
   * @param nombre 
   * @param permisos 
   * @returns 
   */
  saveRoleEdit(id: number, nombre: string, permisos: string) {
    return this.http.put(this.rootURL + '/saveRoleEdit', {id, nombre, permisos})
  }

  /**
   * Actualiza los datos del mapeo
   * @param id_platform 
   * @param avelonState 
   * @param avelonLogistics 
   * @param marketState 
   * @param id_mapping 
   * @returns 
   */
  // updateStatesMapping(id_platform: number, avelonLogistics: string, marketState: string, id_mapping: number) {
  //   return this.http.put(this.rootURL + '/updateStatesMapping', {id_platform, avelonLogistics, marketState, id_mapping})
  // }
  updateStatesMapping(id_platform: number, avelonState: string, avelonLogistics: string, marketState: number, avelon_state_list_lang: string, avelon_logistic_state_list_lang: string, id_mapping: number) {
    return this.http.put(this.rootURL + '/updateStatesMapping', {id_platform, avelonState, avelonLogistics, marketState, avelon_state_list_lang, avelon_logistic_state_list_lang, id_mapping})
  }

  /**
   * Edita la tienda requerida
   * @param id 
   * @param inc 
   * @param exc 
   * @returns 
   */
  saveTiendaEdit(id: number, inc: string, exc: string) {
    return this.http.put(this.rootURL + '/saveTiendaEdit', {id, inc, exc})
  }

  /**
   * Edita la tarifa requerida
   * @param id 
   * @param inc 
   * @param exc 
   * @returns 
   */
  saveTarifaEdit(id: number, value: number) {
    return this.http.put(this.rootURL + '/saveTarifaEdit', {id, value})
  }

  /**
   * Edita las reglas seleccionadas
   * @param id 
   * @param inc 
   * @param exc 
   * @returns 
   */
  editTiendas(idList: string, inc: string, exc: string) {
    return this.http.put(this.rootURL + '/editTiendas', {idList, inc, exc})
  }

  /**
   * Edita la regla logística seleccionada
   * @param shippingType 
   * @param shippingCountry 
   * @param shippingProvince 
   * @param stockShop 
   * @param opLogistic 
   * @param opLogisticReturn 
   * @param destinyShop 
   * @param platform 
   * @param priority 
   * @param instructions 
   * @param id 
   * @returns 
   */
  editLogisticRule(shippingType:string, shippingCountry:string, shippingProvince:string, stockShop:string, opLogistic:number, opLogisticReturn:number, destinyShop:string, platform:string, priority:number, instructions:string,  shopngo: number, id:number) {
    return this.http.put(this.rootURL + '/editLogisticRule', {shippingType, shippingCountry, shippingProvince, stockShop, opLogistic, opLogisticReturn, destinyShop, platform, priority, instructions, shopngo, id})
  }

  /**
   * Actualiza la prioridad en caso de
   * @param id 
   * @returns 
   */
  updatePriority(id:number) {
    return this.http.put(this.rootURL + '/updatePriority', {id})
  }

  /**
   * Actualiza en cascada las prioridades
   * @param values 
   * @returns 
   */
  updatePriorityCascade(values:string) {
    return this.http.post(this.rootURL + '/updatePriorityCascade', {values})
  }

  /**
   * Actualiza el body de una order
   * @param body 
   * @param idOrder 
   * @returns 
   */
  updateOrderBody(body:string, idOrder: number) {
    return this.http.put(this.rootURL + '/updateOrderBody', {body, idOrder})
  }
  
  /**
   * Actualiza los valores editables de configuración de una tienda
   * @param retail 
   * @param active 
   * @param master 
   * @param id 
   * @returns 
   */
  editShopConfig(retail:number, active: number, master: number, id:number) {
    return this.http.put(this.rootURL + '/editShopConfig', {retail, active, master, id})
  }

  /**
   * Activa la ruleset seleccionada
   * @param activate 
   * @param id 
   * @returns 
   */
  activateRule(activate: number, id:number) {
    return this.http.put(this.rootURL + '/activateRule', {activate, id})
  }

  /**
   * Actualiza los valores del descuento de Shop&GO requerido
   * @param discount_reason_id 
   * @param concepto 
   * @param discount_type 
   * @param amount 
   * @param activo 
   * @param fecha_inicio 
   * @param fecha_fin 
   * @param id 
   * @returns 
   */
  editDiscount(discount_reason_id: number, concepto: string, discount_type: string, amount: number, activo: number, fecha_inicio: string, fecha_fin: string, id: number) {
    return this.http.put(this.rootURL + '/editDiscount', {discount_reason_id, concepto, discount_type, amount, activo, fecha_inicio, fecha_fin, id})
  }

  /**
   * Actualiza los valores de usuario
   * @param discount_reason_id 
   * @param concepto 
   * @param discount_type 
   * @param amount 
   * @param activo 
   * @param fecha_inicio 
   * @param fecha_fin 
   * @param id 
   * @returns 
   */
  editSeurUser(id: number, account_number: string) {
    return this.http.put(this.rootURL + '/editSeurUser', {id, account_number})
  }

  /**
   * Guarda la configuración del market
   * @param binded_store 
   * @param active_photo_index 
   * @param order_prefix 
   * @param creates_new_customer 
   * @param default_payment_method 
   * @param default_payment_portal 
   * @param table_schema 
   * @param id 
   * @returns 
   */
  saveConfigMarket(binded_store:number, active_photo_index:string, order_prefix:string, creates_new_customer:boolean, default_payment_method:string, default_payment_portal:string, table_schema:string, id:number, usesMinimosInternos: number, minimosInternosList?: string) {
    return this.http.put(this.rootURL + '/saveConfigMarket', {binded_store, active_photo_index, order_prefix, creates_new_customer, default_payment_method, default_payment_portal, table_schema, id, usesMinimosInternos, minimosInternosList})
  }

  /**
   * Actualiza a 0 el hash de los items vinculados con el market seleccionado
   * @param id 
   * @returns 
   */
  updateItemHash(id: number){
    return this.http.put(this.rootURL + '/updateItemHash', {id})
  }

  /**
   * Guarda un estado Logistico nuevo en la BD
   * @param id 
   * @returns 
   */
  updateLogisticState(operador: any, estado: any, estadoAvelon: any, incidencia: any, final: any, id: Number){
    return this.http.put(this.rootURL + '/updateLogisticState', {operador, estado, estadoAvelon, incidencia, final, id})
  }

  /**
   * Actualiza la información de un dispositivo de Shop & Go
   * @param tienda 
   * @param activo 
   * @param alias 
   * @param beta 
   * @param macImpresora 
   * @param terminal 
   * @param dispositivo 
   * @param ipTransportista 
   * @param ipA4 
   * @param ipTicket 
   * @param id 
   * @returns 
   */
  updateSgDevice(tienda: any, activo: any, alias: any, beta: any, macImpresora: any, terminal: any, dispositivo: any, ipTransportista: any, ipA4: any, ipTicket: any, id: any){
    return this.http.put(this.rootURL + '/updateSgDevice', {tienda, activo, alias, beta, macImpresora, terminal, dispositivo, ipTransportista, ipA4, ipTicket, id})
  }

  /**
   * Actualiza a mapeable el estado de operador logístico seleccionado
   * @param mapeable 
   * @param id 
   * @returns 
   */
  updateMapeable(mapeable: number, id: any){
    return this.http.put(this.rootURL + '/updateMapeable', {mapeable, id})
  }

  /**
   * Actualiza el ISO de mirakl
   * @param internal 
   * @param external 
   * @returns 
   */
  updateMiraklIso(internal: string, external: string){
    return this.http.put(this.rootURL + '/updateMiraklIso', {internal, external})
  }

  /**
   * Actualiza el valor requerido de config
   * @param data 
   * @returns 
   */
  updateConfig(data: any){
    return this.http.put(this.rootURL + '/updateConfig', {data})
  }

  /**
   * Actualiza el valor de forceCheck en config
   * @param valueCheck 
   * @returns 
   */
  updateForceCheck(valueCheck: string){
    return this.http.put(this.rootURL + '/updateForceCheck', {valueCheck})
  }

  /**
   * Actualiza los datos del forzado
   * @param type cadena de texto que identifica el tipo de forzado que se quiere realizar
   * @param values lista de referencias a las que se le quiere forzar algún dato
   * @returns 
   */
  updateForceData(type: string, values: string) {
    return this.http.put(this.rootURL + '/updateForceData', {type, values})
  }
  updateForceDataMulti(values: string) {
    return this.http.put(this.rootURL + '/updateForceDataMulti', {values})
  }

  updateTemplatePattern(values: any) {
    return this.http.post(this.rootURL + '/updateTemplatePattern', {values})
  }

  updateFeature(idFeature: number, updatedCaracteristica: string) {
    return this.http.put(this.rootURL + '/updateFeature', {idFeature, updatedCaracteristica})
  }
  //#endregion PUTS

  //! DELETES
  //#region DELETES
  /**
   * Recibe el id_ruleset para apuntar a la regla requerida para eliminar.
   * @param id
   * @returns
   */
  deleteProductRule(id: number) {
    return this.http.delete(this.rootURL + '/market/ruleset/delete/' + id)
  }

  /**
   * Recibe el id de la plataforma externa y los valores para localizar los mapeos que se quieren eliminar
   * @param externalPlatform 
   * @param valuesDelete 
   * @returns 
   */
  deleteMapping(externalPlatform: string, valuesDelete: String) {
    return this.http.delete(this.rootURL + '/deleteMapping/' + externalPlatform + '/' + valuesDelete)
  }

  /**
   * Elimina el mapeo de talla
   * @param id_mapping_size 
   * @returns 
   */
  deleteSizeMapping(id_mapping_size: number) {
    return this.http.delete(this.rootURL + '/market/map/deleteSizeMapping/' + id_mapping_size)
  }

  /**
   * Elimina el mapeo de descuento
   * @param id 
   * @returns 
   */
  deleteDiscounts(id:number){
    return this.http.delete(this.rootURL + '/market/map/deleteDiscounts/discounts/'+id)
  }

  /**
   * Recibe el id_rule para apuntar a la regla requerida para eliminar.
   * @param id
   * @returns
   */
  deleteRule(id: number) {
    return this.http.delete(this.rootURL + '/market/rules/delete/' + id)
  }

  /**
   * Envía el id del usuario que se quiere eliminar
   * @param id 
   * @returns 
   */
  deleteUser(id: number) {
    return this.http.delete(this.rootURL + '/deleteUser/' + id)
  }
  
  /**
   * Envía el id del role que se quiere eliminar
   * @param id 
   * @returns 
   */
  deleteRole(id: number) {
    return this.http.delete(this.rootURL + '/deleteRole/' + id)
  }

  /**
   * Envía el id del mapeo de estado de pedido que se quiere eliminar
   * @param id 
   * @returns 
   */
  deleteOrderStateMap(externalPlat: number, rule: number) {
    return this.http.delete(this.rootURL + '/deleteOrderStateMap/' + externalPlat + "/" + rule)
  }

  /**
   * Borra una tienda
   * @param id 
   * @returns 
   */
  deleteTienda(id: number) {
    return this.http.delete(this.rootURL + '/deleteTienda/' + id )
  }

  /**
   * Borra una regla de descuento de Shop&GO
   * @param id 
   * @returns 
   */
  deleteDiscount(id: number) {
    return this.http.delete(this.rootURL + '/deleteDiscount/' + id )
  }

  /**
   * Borra un usuario de SEUR
   * @param id 
   * @returns 
   */
  deleteSeurUser(id: number) {
    return this.http.delete(this.rootURL + '/deleteSeurUser/' + id )
  }

  /**
   * Elimina la regla logística seleccionada
   * @param id 
   * @param priority 
   * @returns 
   */
  deleteLogisticRule(id: number, priority: number) {
    return this.http.delete(this.rootURL + '/deleteLogisticRule/' + id + "/" + priority )
  }

  /**
   * Borra un estado logístico mapeado
   * @param id 
   * @returns 
   */
  deleteLogisticState(id: any) {
    return this.http.delete(this.rootURL + '/deleteLogisticState/' + id )
  }

/**
 * Borra un dispositivo mapeado de Shop & Go
 * @param id 
 * @returns 
 */
  deleteSgDevice(id: any) {
    return this.http.delete(this.rootURL + '/deleteSgDevice/' + id )
  }

  deleteFeaturesMapping(id: number, values: string) {
    return this.http.delete(this.rootURL + '/deleteFeaturesMapping/' + id + "/" + values )
  }

  deleteFeature(idFeature: number) {
    return this.http.delete(this.rootURL + '/deleteFeature/' + idFeature )
  }
  //#endregion DELETES
}