<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
<div class="card card-custom">
    <div class="card-body">
        <div class="row">
            <div class="col-sm">
                <div class="accordion" id="accordionExample" style="margin-bottom: 20px;">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Búsqueda avanzada
                            </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <form class="form-group">
                                    <div class="row mb-6">

                                        <div class="col-lg-3 mb-6">
                                            <label>Id artículo:</label>
                                            <input class="form-control" type="text" id="item">
                                        </div>
                                        <div class="col-lg-3 mb-6">
                                            <label>Market:</label>
                                            <ng-multiselect-dropdown (onSelect)="search()" name="market"
                                                class="multi-select" [settings]="dropdownSingleSettings"
                                                [data]="markets" [(ngModel)]="selectedMarket">
                                            </ng-multiselect-dropdown>
                                        </div>

                                        <div class="col-lg-3 mb-6">
                                            <label>Marca:</label>
                                            <ng-multiselect-dropdown (onSelect)="search()" name="marca"
                                                [placeholder]="'Sin selección'" class="multi-select"
                                                [settings]="dropdownSingleSettingsMulti" [data]="marcas"
                                                [(ngModel)]="selectedMarca">
                                            </ng-multiselect-dropdown>
                                        </div>
                                        <div class="col-lg-3 mb-6">
                                            <label>Descripción del producto:</label>
                                            <ng-multiselect-dropdown (onSelect)="search()" name="descriptionProduct"
                                                [placeholder]="'Sin selección'" class="multi-select"
                                                [settings]="dropdownSingleSettingsMulti" [data]="descriptionProducts"
                                                [(ngModel)]="selectedDescriptionProduct">
                                            </ng-multiselect-dropdown>
                                        </div>

                                        <div class="col-lg-3 mb-6">
                                            <label>Comercial:</label>
                                            <ng-multiselect-dropdown (onSelect)="search()" name="comercial"
                                                [placeholder]="'Sin selección'" class="multi-select"
                                                [settings]="dropdownSingleSettingsMulti" [data]="comerciales"
                                                [(ngModel)]="selectedComercial">
                                            </ng-multiselect-dropdown>
                                        </div>

                                        <div class="col-lg-3 mb-6">
                                            <label>Familia:</label>

                                            <ng-multiselect-dropdown id="familia" name="familia"
                                                [placeholder]="'Sin selección'" class="multi-select"
                                                [settings]="dropdownSingleSettingsMulti" [data]="familiares"
                                                [(ngModel)]="selectedFamilia" (onSelect)="search()">
                                            </ng-multiselect-dropdown>

                                        </div>
                                        <div class="col-lg-3 mb-6">
                                            <label>Temporada:</label>
                                            <ng-multiselect-dropdown
                                                (onSelect)="search()" name="temporada"
                                                [placeholder]="'Sin selección'" class="multi-select"
                                                [settings]="dropdownSingleSettingsSeasons" [data]="temporadas"
                                                [(ngModel)]="selectedTemporada">
                                            </ng-multiselect-dropdown>
                                        </div>

                                    </div>
                                    <input type="submit"
                                        style="margin-top: 10px; margin-bottom: 10px; margin-right: 20px;"
                                        class="btn btn-primary" id="search" value="Buscar">
                                    <button class="btn btn-primary font-weight-bolder" id="anadir" (click)="limpiarFiltros()">
                                        <i class="las la-broom"></i>Limpiar filtros
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="kt_datatable_info_products_wrapper" class="dataTables_wrapper dt-bootstrap4">
                <div style="width: 100%; padding-left: -10px;">
                    <div class="table-responsive">
                        <table id="kt_datatable_info_products"
                            class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom"
                            role="grid" aria-describedby="kt_datatable_info_products_info" style="width:100%">

                            <tfoot id="footer">
                                <tr role="row">
                                    <th width="10"></th>
                                    <th width="10"></th>
                                    <th width="10"></th>
                                    <th width="10"></th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>