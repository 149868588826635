export class OrderLinesDiscounts {
    Amount: number;
    DiscountReasonId: number;

    constructor(Amount: number, DiscountReasonId: number) {
        {
          this.Amount = Amount;
          this.DiscountReasonId = DiscountReasonId;
        }
    }
  }