<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
<div class="card card-custom">
  <div class="card-header" class="my-5 mx-5">
    <div>
      <button (click)="limpiarSelected(); getExternalSizes(0)" class="btn btn-primary font-weight-bolder" id="anadir" data-bs-toggle="modal" data-bs-target="#kt_modal_nuevo"><i class="la la-plus" ></i>Nuevo mapeo</button>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <button class="btn btn-primary font-weight-bolder" id="anadir" (click)="limpiarFiltros()"><i class="las la-broom"></i>Limpiar filtros</button>
    </div>
  </div>
  <div class="card-body">
    <div id="kt_datatable_wrapper" class="dataTables_wrapper dt-bootstrap4">
      <div style="width: 100%; padding-left: -10px;">
        <div class="table-responsive">
          <table id="kt_datatable_tallajes_new" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_info" style="width:100%">
            
            <thead>
                <tr role="row">
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes_new" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes_new" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes_new" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes_new" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes_new" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes_new" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                </tr>
            </thead>

            <tfoot id="footer">
              <tr role="row">
                <th width="10"></th>
                <th width="10"></th>
                <th width="10"></th>
                <th width="10"></th>
                <th width="10"></th>
                <th width="10"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <!-- <div id="kt_datatable_tallajes_wrapper" class="dataTables_wrapper dt-bootstrap4">
      <div style="width: 100%; padding-left: -10px;">
        <div class="table-responsive">
          <table id="kt_datatable_tallajes_tallajes" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_tallajes_info" style="width:100%">
              <thead>
                  <tr role="row">
                      <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Marca</th>
                      <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Tallaje Avelon</th>
                      <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Conversión</th>
                      <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Market</th>
                      <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_tallajes" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Tallaje Market</th>
                      <th class="sorting_disabled" tabindex="0" aria-controls="kt_datatable_tallajes" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                  </tr>
              </thead>
              <tr id="filters">

              </tr>
              <tbody>
                  <tr *ngFor="let item of mappings">
                    <td>{{item["id_brand"]}}</td>
                    <td>{{item["id_sizeDomain"]}}</td>
                    <td>{{item["id_conversion_destino"]}}</td>
                    <td>{{item["id_externalplatform"]}}</td>
                    <td>{{item["external_sizGroup"]}}</td>
                    <td *ngIf='item["is_default"]==0' style="font-size:16px; color: red;">&times;</td>
                    <td *ngIf='item["is_default"]==1' style="font-size:16px; color: green;">&check;</td>
                    <td nowrap="nowrap" class="datatable-actions-td">
                        <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action" data-bs-target="#kt_modal_duplicar" data-bs-toggle="modal" (click)="poblarModalDuplicar(item['id_mapping_size'])" title="Duplicar">
                          <span class="svg-icon svg-icon-md">
                            <svg xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"/>
                                  <path 
                                    d="M6,9 L6,15 C6,16.6568542 7.34314575,18 9,18 L15,18 L15,18.8181818 C15,20.2324881 14.2324881,21 12.8181818,21 L5.18181818,21 C3.76751186,21 3,20.2324881 3,18.8181818 L3,11.1818182 C3,9.76751186 3.76751186,9 5.18181818,9 L6,9 Z" fill="#000000" fill-rule="nonzero"/>
                                  <path 
                                    d="M10.1818182,4 L17.8181818,4 C19.2324881,4 20,4.76751186 20,6.18181818 L20,13.8181818 C20,15.2324881 19.2324881,16 17.8181818,16 L10.1818182,16 C8.76751186,16 8,15.2324881 8,13.8181818 L8,6.18181818 C8,4.76751186 8.76751186,4 10.1818182,4 Z" fill="#000000" opacity="0.3"/>¡
                              </g>
                            </svg>
                          </span>
                        </a>
                        <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action" data-bs-target="#kt_modal_dani" data-bs-toggle="modal" (click)="poblarModal(item['id_mapping_size'])" title="Editar">
                          <span class="svg-icon svg-icon-md"> 
                            <svg xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                  fill="#000000" fill-rule="nonzero"
                                  transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                                <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                              </g>
                            </svg> 
                          </span>
                        </a>
                        <a href="javascript:;" class="btn btn-sm btn-clean btn-icon datatable-action" (click)="deleteSizeMapping(item['id_mapping_size'])" title="Eliminar"> 
                          <span class="svg-icon svg-icon-md"> 
                            <svg
                                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                viewBox="0 0 24 24" version="1.1">
                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                  <rect x="0" y="0" width="24" height="24"></rect>
                                  <path
                                    d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                    fill="#000000" fill-rule="nonzero"></path>
                                  <path
                                    d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                    fill="#000000" opacity="0.3"></path>
                              </g>
                            </svg> 
                          </span> 
                        </a>
                    </td>
                  </tr>
              </tbody>
              <tfoot>
                  <tr>
                      <th>Marca</th>
                      <th>Tallaje Avelon</th>
                      <th>Conversión</th>
                      <th>Market</th>
                      <th>Tallaje Market</th>
                      <th></th>
                  </tr>
              </tfoot>
          </table>
        </div>
      </div>
    </div> -->
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_duplicar">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Duplicar regla</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
              <label for="marcaMODAL" class="my-5"><b>¿A qué markets quieres aplicar esta misma regla?</b></label>
              <ng-multiselect-dropdown
                #duplicateMarketSelected
                [placeholder]="'Market'"
                [settings]="dropdownMultiple"
                [data]="platforms"
                [(ngModel)]="selectedDuplicateMarket"
              > 
              </ng-multiselect-dropdown>
          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="duplicateRule()" class="btn btn-primary">Duplicar</button>
          </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_dani">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Editar regla</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
              <label for="marcaMODAL" class="my-5"><b>Marca</b></label>
              <ng-multiselect-dropdown
                #marcaEdit
                [placeholder]="'Marca'"
                [settings]="dropdownSingle"
                [data]="brands"
                [(ngModel)]="selectedMarca"
              > 
              </ng-multiselect-dropdown>
              <label for="marca" class="my-5"><b>Tallaje</b></label>
              <ng-multiselect-dropdown
                #tallajeEdit
                [placeholder]="'Tallaje'"
                [settings]="dropdownSingle"
                [data]="domains"
                [(ngModel)]="selectedTallaje"
                (onSelect)="getConversions(selectedTallaje[0].Id)"
              > 
              </ng-multiselect-dropdown>
              <label for="conversionMODAL" class="my-5"><b>Conversión</b></label>
              <ng-multiselect-dropdown
                #conversionEdit
                [placeholder]="'Conversión'"
                [settings]="dropdownSingle"
                [data]="conversions"
                [(ngModel)]="selectedConversion"
              > 
              </ng-multiselect-dropdown>
              <ng-container *ngIf="externalSizes">
                <label for="external_sizeMODAL" class="my-5"><b>Tallaje Market</b></label>
                <ng-multiselect-dropdown
                  #tallajeMarketEdit
                  [placeholder]="'Tallaje Market'"
                  [settings]="dropdownSingle"
                  [data]="externalSizes"
                  [(ngModel)]="selectedTallajeMarket"
                > 
                </ng-multiselect-dropdown>
              </ng-container>
              <input type="hidden" id="id_platform_edit"/>
              <input type="hidden" id="id_mappingEDIT"/>
          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="saveMappingEdit()" class="btn btn-primary">Guardar</button>
          </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_nuevo">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Nueva regla</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <label for="marca" class="my-5"><b>Marca</b></label>
            <ng-multiselect-dropdown
              [placeholder]="'Marcas'"
              [settings]="dropdownMultiple"
              [data]="brands"
              [(ngModel)]="selectedMarca"
            >
            </ng-multiselect-dropdown>
            <label for="domain" class="my-5"><b>Tallaje</b></label>
            <ng-multiselect-dropdown
              #tallajeEdit
              [placeholder]="'Tallaje'"
              [settings]="dropdownSingle"
              [data]="domains"
              [(ngModel)]="selectedTallaje"
              (onSelect)="getConversions(selectedTallaje[0].Id)"
            > 
            </ng-multiselect-dropdown>
            <label for="conversion" class="my-5"><b>Conversión</b></label>
            <ng-multiselect-dropdown
              #conversionEdit
              [placeholder]="'Conversión'"
              [settings]="dropdownSingle"
              [data]="conversions"
              [(ngModel)]="selectedConversion"
            > 
            </ng-multiselect-dropdown>
            <label for="platform" class="my-5"><b>Market</b></label>
            <ng-multiselect-dropdown
              [placeholder]="'Market'"
              [settings]="dropdownMultiple"
              [data]="platforms"
              [(ngModel)]="selectedItemsMarket"
              (onSelect)="getExternalSizes(selectedItemsMarket, true)"
              (onDeSelect)="getExternalSizes(selectedItemsMarket, true)"
            > 
            </ng-multiselect-dropdown>
      
            <ng-container *ngIf="externalSizes">
              <label for="external_size" class="my-5"><b>Tallaje Market</b></label>
              <ng-multiselect-dropdown
                #tallajeMarketEdit
                [placeholder]="'Tallaje Market'"
                [settings]="dropdownSingle"
                [data]="externalSizes"
                [(ngModel)]="selectedTallajeMarket"
              > 
              </ng-multiselect-dropdown>
            </ng-container>
            <br/>
            <input type="hidden" id="id_mappingEDIT"/>
        </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="saveMarketSizeMap()" class="btn btn-primary">Guardar</button>
          </div>
      </div>
  </div>
</div>
