<!-- Hacer a mano el select, no funciona la función de jquery -->
<div class="card ">
    <div class="card-header card-header-stretch">
        <h3 class="card-title">Mapeos</h3>
        <div class="card-toolbar">

            <!-- BEGIN::Navegador -->
            <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                <ng-container *ngFor="let map of mappingAttributes; let i = index">
                    <li class="nav-item">
                        <a *ngIf="conditionMiravia(map.name)" [attr.id]="i == 0 ? 'mustClick' : null" class="nav-link" (click)="gestorFunciones(map.name, map.attribute_source); clearLists();" data-bs-toggle="tab" href="#{{map.name.toLowerCase()}}">
                            {{map.name}}
                        </a>
                    </li>
                </ng-container>
            </ul>
            <!-- END::Navegador -->
        </div>
    </div>
    
    <div class="card-body">
        <div class="tab-content" id="myTabContent">
            <ng-container *ngIf="isItActive('color'); else elseBlock">
                <div class="tab-pane fade" id="color" role="tabpanel">
                    <div class="pb-10">
                        <button class="btn btn-primary saveSelection" id="kt_datatable_mapping_example_2_submit"
                            (click)="saveOrUpdate()">Guardar selección</button>
                    </div>
                    <table id="kt_datatable_mapping"
                        class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom"
                        role="grid" aria-describedby="kt_datatable_mapping_info" style="width:100%">

                        <tfoot id="footer">
                            <tr role="row">
                                <th width="10"></th>
                                <th width="10"></th>
                                <th width="10"></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ng-container>
            <ng-template #elseBlock>
                <div class="tab-pane fade" id="genero" role="tabpanel">
                    <div class="pb-10">
                        <button class="btn btn-primary saveSelection" id="kt_datatable_mapping_example_2_submit"
                            (click)="saveOrUpdate()">Guardar selección</button>
                    </div>
                    <table id="kt_datatable_mapping"
                        class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom"
                        role="grid" aria-describedby="kt_datatable_mapping_info" style="width:100%">

                        <tfoot id="footer">
                            <tr role="row">
                                <th width="10"></th>
                                <th width="10"></th>
                                <th width="10"></th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </ng-template>
        </div>
    </div>
</div>