// var $  = require( 'jquery' );
var dt = require( 'datatables.net' );

import { NgModule, APP_INITIALIZER,LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { ReglasComponent } from './pages/reglas/reglas.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
// #fake-end#
import { TallajesComponent } from './pages/tallajes/tallajes.component';
import {DataTablesModule} from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularDualListBoxModule } from 'angular-dual-listbox';
import {MatTooltipModule} from '@angular/material/tooltip'
import {MatButtonModule } from '@angular/material/button'; 
import {NgxPaginationModule} from 'ngx-pagination';

import { PanelUsuariosComponent } from './pages/panel-usuarios/panel-usuarios.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { DiscountsComponent } from './pages/discounts/discounts.component';
import { ReglasPedidosComponent } from './pages/reglas-pedidos/reglas-pedidos.component';

import { InfoProductosComponent } from './pages/info-productos/info-productos.component';
import { DynamicMappingComponent } from './pages/dynamic-mapping/dynamic-mapping.component';
import { ReglasLogisticaComponent } from './pages/reglas-logistica/reglas-logistica.component';
import { RehacerPedidosComponent } from './pages/rehacer-pedidos/rehacer-pedidos.component';
import { ShopConfigComponent } from './pages/shop-config/shop-config.component';
import { EstadosLogisticosComponent } from './pages/estados-logisticos/estados-logisticos.component';
import { SgaToolComponent } from './pages/sga-tool/sga-tool.component';
import { SgaConfigurationComponent } from './pages/sga-configuration/sga-configuration.component';
import { PlantillasPredistribucionComponent } from './pages/plantillas-predistribucion/plantillas-predistribucion.component';
import { ScrollToTopComponent } from './pages/shared/scroll-to-top/scroll-to-top.component';
import { DynamicModalComponent } from './pages/shared/dynamic-modal/dynamic-modal.component';
import { OperadoresLogisticosComponent } from './pages/operadores-logisticos/operadores-logisticos.component';
import { AdvancedSearchSimularComponent } from './pages/shared/advanced-search-simular/advanced-search-simular.component';
import { Angular2CsvModule } from 'angular2-csv';
import { CaracteristicasComponent } from './pages/caracteristicas/caracteristicas.component';

registerLocaleData(localeEs);

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ReglasComponent,
    ReglasPedidosComponent,
    DynamicMappingComponent,
    TallajesComponent, 
    PanelUsuariosComponent, 
    DiscountsComponent, 
    InfoProductosComponent,
    ReglasLogisticaComponent,
    RehacerPedidosComponent,
    ShopConfigComponent,
    EstadosLogisticosComponent,
    SgaConfigurationComponent,
    SgaToolComponent,
    PlantillasPredistribucionComponent,
    ScrollToTopComponent,
    DynamicModalComponent,
    OperadoresLogisticosComponent,
    AdvancedSearchSimularComponent,
    CaracteristicasComponent,
  ],
  imports: [
    FormsModule,
    AngularDualListBoxModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    SweetAlert2Module.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    ClipboardModule,
    DataTablesModule,
    MatTooltipModule,
    MatButtonModule,
    NgMultiSelectDropDownModule.forRoot(),
    
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
          passThruUnknownUrl: true,
          dataEncapsulation: false,
        })
      : [],
    // #fake-end#
    AppRoutingModule,
    Angular2CsvModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    NgxPaginationModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
