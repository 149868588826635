<div class="accordion" id="accordionSimulate">
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                Búsqueda avanzada
            </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionSimulate">
            <div class="accordion-body">
                <form class="form-group">
                    <div class="row mb-6">
                        <div class="col-lg-3 mb-6">
                            <label>Id artículo:</label>
                            <input class="form-control" type="text" id="items" [(ngModel)]="inputData" name="items"
                                placeholder="¿Más de uno?, separa con comas">
                        </div>
                        <div class="col-lg-3 mb-6">
                            <label>Marca:</label>
                            <ng-multiselect-dropdown name="marca" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="marcas"
                                [(ngModel)]="selectedMarca">
                            </ng-multiselect-dropdown>
                            <input hidden id="selectedMarcaSimulate" value="{{selectedMarca}}">
                        </div>
                        <div class="col-lg-3 mb-6">
                            <label>Temporada:</label>
                            <ng-multiselect-dropdown name="temporada" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="temporadas"
                                [(ngModel)]="selectedTemporada">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="col-lg-3 mb-6">
                            <label>Familia:</label>
                            <ng-multiselect-dropdown id="familia" name="familia" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="familias"
                                [(ngModel)]="selectedFamilia">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="col-lg-3 mb-6">
                            <label>Comercial:</label>
                            <ng-multiselect-dropdown id="comercial" name="comercial" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="comercial"
                                [(ngModel)]="selectedComercial">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="col-lg-3 mb-6">
                            <label>Proveedor:</label>
                            <ng-multiselect-dropdown id="manufacturer" name="manufacturer"
                                [placeholder]="'Sin selección'" class="multi-select" [settings]="dropdownSettings"
                                [data]="manufacturer" [(ngModel)]="selectedManufacturer">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="col-lg-3 mb-6" *ngIf="templates.length > 0">
                            <label>Plantilla:</label>
                            <ng-multiselect-dropdown id="plantilla" name="plantilla" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="templates"
                                [(ngModel)]="selectedTemplate">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <input type="submit" style="margin-top: 10px; margin-bottom: 10px; margin-right: 20px;"
                        class="btn btn-primary" [id]="buscarButtonId" value="Buscar">
                    <button class="btn btn-primary font-weight-bolder" (click)="limpiar()">
                        <i class="las la-broom"></i>Limpiar filtros
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>