<link
  href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css"
  rel="stylesheet"
/>
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>

<div class="card">
  <div style="padding-bottom: 0" class="card-body">
    <div class="row">
      <div class="col-sm-8 col-md-6 col-lg-10">
        <div class="search-bar">
          <ng-multiselect-dropdown
            #platformSelection
            id="searchMarket"
            [placeholder]="'Plataformas externas'"
            [settings]="dropdownSingle"
            [data]="externalPlatforms"
            [(ngModel)]="selectedExternalPlatformFirst"
            (click) = "selectedOrder = []"
            (ngModelChange)="handleMarketSelection('update')"
          >
          </ng-multiselect-dropdown>
        </div>
        <div class="search-bar">
          <ng-multiselect-dropdown
            #orderSelection
            id="search"
            [placeholder]="'Pedidos'"
            [settings]="dropdownOrder"
            [data]="numOrdersPlatform"
            [(ngModel)]="selectedOrder"
            [disabled]="selectedUpdatePlatform"
          >
          </ng-multiselect-dropdown>
        </div>
      </div>

      <div class="col-sm-4 col-md-6 col-lg-2">
        <button (click)="getOrder()" style="width: 100%" class="btn btn-primary float-right ms-auto">
          Cargar pedido
        </button>
        <button (click)="cleanInputs()" style="width: 100%; margin-top: 3px;" class="btn btn-primary float-right ms-auto">
          Limpiar tabla
        </button>
        <!-- <button (click)="getOrder()" *ngIf="selectedOrder.length != 0" style="width: 100%" class="btn btn-primary float-right ms-auto">
          Cargar pedido
        </button>
        <button (click)="cleanInputs(); nuevoPedido=true" *ngIf="selectedOrder.length === 0" style="width: 100%" class="btn btn-primary float-right ms-auto">
          Crear pedido
        </button> -->
      </div>
      <!-- <div *ngIf="nuevoPedido" class="col-sm-8 col-md-6 col-lg-10">
        <label for="searchMarket">Selecciona un market</label>
        <div class="search-bar">
          <ng-multiselect-dropdown
            #platformSelection
            id="searchMarket"
            [placeholder]="'Plataformas externas'"
            [settings]="dropdownSingle"
            [data]="externalPlatforms"
            [(ngModel)]="selectedExternalPlatformFirst"
            (click)="handleMarketSelection('create')"
          >
          </ng-multiselect-dropdown>
        </div>
      </div> -->
    </div>
  </div>
  <div class="card-header card-header-stretch">
    <h3 class="card-title">Rehacer pedidos</h3>
    <div class="card-toolbar">
      <!-- Navegador -->
      <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
        <ng-container>
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#tab_cabecera">
              Cabecera
            </a>
          </li>
        </ng-container>
        <ng-container>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#tab_direccion">
              Dirección
            </a>
          </li>
        </ng-container>
        <ng-container>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#tab_lineas">
              Líneas
            </a>
          </li>
        </ng-container>
        <ng-container>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#tab_descuentos">
              Descuentos
            </a>
          </li>
        </ng-container>
        <ng-container>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#tab_charges">
              Charges
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="card-body">
    <div class="tab-content" id="myTabContent">
      <!--* BEGIN::Cabecera -->
      <ng-container>
        <div class="tab-pane fade active show" id="tab_cabecera" role="tabpanel">
          <!--* BEGIN::Form -->
          <div class="card-body d-flex flex-row justify-content-center">
            <div class="form-group w-100">
              <label for="externalId">External ID</label>
              <input id="externalId" type="text" class="form-control">
              <label for="origin">Origen</label>
              <input id="origin" type="text" class="form-control">
              <label for="orderDateTime">Fecha pedido</label>
              <input id="orderDateTime" type="date" class="form-control">
              <label for="shippingMode">Modo de envio</label>
              <input id="shippingMode" type="text" class="form-control" disabled>
              <label for="shippingService">Tipo de envío</label>
              <!-- <input id="shippingService" type="text" class="form-control"> -->
              <select name="shippingService" id="shippingService" class="form-control" (change)="changeShipping()" disabled>
                <option disabled selected="true">Selecciona una opción</option>
                <option value="1">HOME</option>
                <option value="3">STORE</option>
              </select>
              <label for="shippingServiceAux">Shipping Service Aux</label>
              <input id="shippingServiceAux" type="text" class="form-control" disabled>
            </div>
            <div class="form-group p-10"></div>
            <div class="form-group w-100">
              <label for="marketId">Market ID</label>
              <input id="marketId" type="text" class="form-control">
              <label for="customerEmail">Dirección email</label>
              <input type="email"  id="customerEmail" class="form-control"/>
              <!-- <label for="idClient">ID Cliente</label>
              <input id="idClient" type="text"  class="form-control"/>
              <label for="clusterLogistico">Cluster logístico</label>
              <input id="clusterLogistico" type="text"  class="form-control"/> -->
              <!-- <label for="paymentPortal">Payment portal</label>
              <input id="paymentPortal" type="text"  class="form-control"/> -->
              <label for="externalPaymentMethod">Payment method</label>
              <input id="externalPaymentMethod" type="text"  class="form-control"/>
              <label for="shippingShop">Tienda recogida</label>
              <ng-multiselect-dropdown
                #shippingShop
                [disabled]="isDropdownDisabled"
                [placeholder]="'Tiendas'"
                [data]="listShops"
                [(ngModel)]="selectedShop"
                [settings]="dropdownSingle"
              >
              </ng-multiselect-dropdown>
              <!-- <input id="shippingShop" type="text"  class="form-control"/> -->
              <label for="phone">Teléfono</label>
              <input id="phone" type="text"  class="form-control"/>
            </div>
          </div>
          <!--* END::Form-->
        </div>
      </ng-container>
      <!--* END::Cabecera -->
      <!--* BEGIN::Dirección -->
      <ng-container>
        <div class="tab-pane fade" id="tab_direccion" role="tabpanel">
          <!--* BEGIN::Form -->
          <div class="card-body d-flex flex-row justify-content-center">
            <div class="form-group w-100">
              <div>
                <p>Envío</p>
              </div>
              <div class="card p-5">
                <label for="shippingName">Nombre</label>
                <input id="shippingName" type="text" class="form-control">
                <label for="shippingSurname">Apellidos</label>
                <input id="shippingSurname" type="text" class="form-control">
                <label for="shippingPhone">Teléfono</label>
                <input id="shippingPhone" type="text" class="form-control">
                <label for="shippingAddress1">Dirección 1</label>
                <input id="shippingAddress1" type="text" class="form-control">
                <label for="shippingAddress2">Dirección 2</label>
                <input id="shippingAddress2" type="text" class="form-control">
                <label for="shippingCity">Ciudad</label>
                <input id="shippingCity" type="text" class="form-control">
                <label for="shippingZipCode">Código postal</label>
                <input id="shippingZipCode" type="text" class="form-control">
                <label for="shippingProvince">Provincia</label>
                <input id="shippingProvince" type="text" class="form-control">
                <label for="shippingCountryId">País</label>
                <input id="shippingCountryId" type="text" class="form-control">
              </div>
            </div>
            <div class="form-group p-10"></div>
            <div class="form-group w-100">
              <div>
                <p>Facturación</p>
              </div>
              <div class="card p-5">
                <!-- <p>Facturación</p> -->
                <label for="invoiceName">Nombre</label>
                <input id="invoiceName" type="text" class="form-control">
                <label for="invoiceSurname">Apellidos</label>
                <input id="invoiceSurname" type="text" class="form-control">
                <!-- <label for="invoicePhone">Teléfono</label>
                <input id="invoicePhone" type="text" class="form-control"> -->
                <label for="invoiceAddress1">Dirección 1</label>
                <input id="invoiceAddress1" type="text" class="form-control">
                <label for="invoiceAddress2">Dirección 2</label>
                <input id="invoiceAddress2" type="text" class="form-control">
                <label for="invoiceCity">Ciudad</label>
                <input id="invoiceCity" type="text" class="form-control">
                <label for="invoiceZipCode">Código postal</label>
                <input id="invoiceZipCode" type="text" class="form-control">
                <label for="invoiceProvince">Provincia</label>
                <input id="invoiceProvince" type="text" class="form-control">
                <label for="invoiceCountryId">País</label>
                <input id="invoiceCountryId" type="text" class="form-control">
              </div>
            </div>
          </div>
          <!--* END::Form -->
        </div>
      </ng-container>
      <!--* END::Dirección -->
      <!--* BEGIN::Líneas -->
      <ng-container>
        <div class="tab-pane fade" id="tab_lineas" role="tabpanel">
          <div id="kt_datatable_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div class="card-header" class="my-5 mx-5">
              <div>
                <button  class="btn btn-primary font-weight-bolder" id="anadir" data-bs-toggle="modal" data-bs-target="#kt_modal_add"><i class="la la-plus" ></i>Añadir línea</button>
              </div>
            </div>
            <div style="width: 100%; padding-left: -10px;">
              <!-- <div class="table-responsive"> -->
              <div>
                <table id="kt_datatable_orders_redo" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_info"
                  style="width: 100%;">
                  <thead>
                    <tr>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                    </tr>
                  </thead>
                  <tfoot id="footer">
                    <tr>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_orders_redo" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!--* END::Líneas -->
      <!--* BEGIN::Descuentos -->
      <ng-container>
        <div class="tab-pane fade" id="tab_descuentos" role="tabpanel">
          <div id="kt_datatable_discounts_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div class="card-header" class="my-5 mx-5">
              <div>
                <button  class="btn btn-primary font-weight-bolder" id="discount" data-bs-toggle="modal" data-bs-target="#kt_modal_add_discount"><i class="la la-plus" ></i>Añadir descuento</button>
              </div>
            </div>
            <div style="width: 100%; padding-left: -10px;">
              <div class="table-responsive">
              <!-- <div> -->
                <table id="kt_datatable_discounts" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_info"
                  style="width: 100%;">
                  <thead>
                    <tr>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                    </tr>
                  </thead>
                  <tfoot id="footer">
                    <tr>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_discounts" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!--* END::Descuentos -->
      <!--* BEGIN::Charges -->
      <ng-container>
        <div class="tab-pane fade" id="tab_charges" role="tabpanel">
          <div id="kt_datatable_charges_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div class="card-header" class="my-5 mx-5">
              <div>
                <button  class="btn btn-primary font-weight-bolder" id="anadir" data-bs-toggle="modal" data-bs-target="#kt_modal_add_charge"><i class="la la-plus" ></i>Añadir charge</button>
              </div>
            </div>
            <div style="width: 100%; padding-left: -10px;">
              <div class="table-responsive">
              <!-- <div> -->
                <table id="kt_datatable_charges" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_info"
                  style="width: 100%;">
                  <thead>
                    <tr>
                      <th tabindex="0" aria-controls="kt_datatable_charges" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_charges" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_charges" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                    </tr>
                  </thead>
                  <tfoot id="footer">
                    <tr>
                      <th tabindex="0" aria-controls="kt_datatable_charges" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_charges" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                      <th tabindex="0" aria-controls="kt_datatable_charges" rowspan="1" colspan="1"
                        aria-label="Record ID: activate to sort column descending"></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!--* END::Charges -->
    </div>
    <div class="my-5 mx-5">
        <div>
          <button *ngIf="nuevoPedido"
            class="btn btn-primary font-weight-bolder"
            id="anadir"
            (click)="insertOrder()"
          >
            Insertar pedido
          </button>
          <button *ngIf="!nuevoPedido"
            class="btn btn-primary font-weight-bolder"
            id="editar"
            (click)="editOrder()"
          >
            Editar pedido / GUARDAR  
          </button>
          <!-- <button class="btn btn-primary font-weight-bolder" id="anadir" (click)="limpiarFiltros()"><i class="las la-broom"></i>Limpiar filtros</button> -->
        </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_edit">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Editar línea de pedido</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body d-flex flex-row justify-content-center">
              <div class="form-group w-100">
                <label for="externalIdEdit">ExternalId</label>
                <input id="externalIdEdit" type="text" class="form-control">
                <label for="itemIdEdit">ItemId*</label>
                <form [formGroup]="myForm">
                  <ng-multiselect-dropdown
                    #extPlatItemSelectionEdit
                    [placeholder]="'Artículos de la plataforma'"
                    [data]="articlesPlatform"
                    formControlName="article"
                    [(ngModel)]="selectedExtPlatItemEdit"
                    [settings]="dropdownSingle"
                    (click)="checkSizes('edit')"
                  >
                  </ng-multiselect-dropdown>
                </form>
                <label for="sizeIndexAdd">SizeIndex*</label>
                <ng-multiselect-dropdown
                  #sizeIndexSelectionEdit
                  [placeholder]="'Talla'"
                  [settings]="dropdownSingle"
                  [data]="itemSizes"
                  [(ngModel)]="selectedItemSizeEdit"
                  (click)="selectedItemSizeEdit.length > 0 ? loadPrice('edit') : ''"
                >
                </ng-multiselect-dropdown>
                <label for="activePriceEdit">Active price*</label>
                <input id="activePriceEdit" type="number" class="form-control">
                <label for="originalPriceEdit">Original price*</label>
                <input id="originalPriceEdit" type="number" class="form-control">
                <label for="discountEdit">Discount</label>
                <input id="discountEdit" type="number" class="form-control">
              </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" (click)="editLine()" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
        </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_edit_discount">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Editar línea de descuento</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body d-flex flex-row justify-content-center">
              <div class="form-group w-100">
                <label for="discountTypeEdit">Tipo</label>
                <input id="discountTypeEdit" type="text" class="form-control">
                <label for="discountAmountEdit">Cantidad</label>
                <input id="discountAmountEdit" type="number" class="form-control">
                <label for="discountCodeEdit">Código*</label>
                <input id="discountCodeEdit" type="text" class="form-control">
                <label for="discountReasonEdit">Discount Reason</label>
                <input id="discountReasonEdit" type="number" class="form-control">
              </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" (click)="editLine()" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
        </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_edit_charge">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Editar línea de charge</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body d-flex flex-row justify-content-center">
              <div class="form-group w-100">
                <label for="chargeDescriptionEdit">Descripción</label>
                <input id="chargeDescriptionEdit" type="text" class="form-control">
                <label for="chargeAmountEdit">Cantidad</label>
                <input id="chargeAmountEdit" type="number" class="form-control">
              </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" (click)="editLine()" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
        </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_add">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Añadir línea de pedido</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body d-flex flex-row justify-content-center">
              <div class="form-group w-100">
                <label for="externalPlarform">Plataforma externa</label>
                <ng-multiselect-dropdown
                  #externalPlatformSelection
                  id="search"
                  [placeholder]="'Plataforma externa'"
                  [settings]="dropdownSingle"
                  [data]="externalPlatforms"
                  [(ngModel)]="selectedExternalPlatform"
                  [disabled]="orderLoaded"
                  (click)="getArticlesExternalPlatform(selectedExternalPlatform.length > 0 ? selectedExternalPlatform[0].id : 0);handleNewLineExternalId()"
                >
                </ng-multiselect-dropdown>
                <label for="externalIdAdd">ExternalId</label>
                <input id="externalIdAdd" type="text" class="form-control">
                <label for="itemIdAdd">ItemId*</label>
                <form [formGroup]="myForm">
                  <ng-multiselect-dropdown
                    #extPlatItemSelection
                    [placeholder]="'Artículos de la plataforma'"
                    [data]="articlesPlatform"
                    formControlName="article"
                    [(ngModel)]="selectedExtPlatItem"
                    (click)="checkSizes()"
                    [settings]="dropdownSingle"
                  >
                  </ng-multiselect-dropdown>
                </form>

                <!-- <input id="itemIdAdd" type="text" class="form-control"> -->
                <fieldset *ngIf="selectedExtPlatItem.length > 0" >
                  <label for="sizeIndexAdd">SizeIndex*</label>
                  <ng-multiselect-dropdown
                    #sizeIndexSelection
                    [placeholder]="'Talla'"
                    [settings]="dropdownSingle"
                    [data]="itemSizes"
                    [(ngModel)]="selectedItemSize"
                    (click)="selectedItemSize.length > 0 ? loadPrice() : ''"
                  >
                  </ng-multiselect-dropdown>
                </fieldset>
                <label for="activePriceAdd">Active price*</label>
                <input id="activePriceAdd" type="number" class="form-control">
                <label for="originalPriceAdd">Original price*</label>
                <input id="originalPriceAdd" type="number" class="form-control">
                <label for="discountAdd">Descuento</label>
                <input id="discountAdd" type="number" class="form-control">
                <!-- <label for="discountsAdd">Discounts</label>
                <input id="discountsAdd" type="text" class="form-control"> -->
              </div>
            </div>
        </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="orderLoaded ? addLine('update') : addLine('create')" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
          </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_add_discount">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Añadir descuento</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body d-flex flex-row justify-content-center">
              <div class="form-group w-100">
                <label for="discountType">Tipo</label>
                <input id="discountType" type="text" class="form-control">
                <label for="discountAmount">Cantidad</label>
                <input id="discountAmount" type="number" class="form-control">
                <label for="discountCode">Código*</label>
                <input id="discountCode" type="text" class="form-control">
                <label for="discountReason">Discount Reason</label>
                <input id="discountReason" type="number" class="form-control">
              </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" (click)="orderLoaded ? addDiscount('update') : addDiscount('create')" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
        </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_add_charge">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Añadir charge</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <div class="card-body d-flex flex-row justify-content-center">
              <div class="form-group w-100">
                <label for="chargeDescription">Descripción</label>
                <input id="chargeDescription" type="text" class="form-control">
                <label for="chargeAmount">Cantidad</label>
                <input id="chargeAmount" type="number" class="form-control">
              </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
            <button type="button" (click)="orderLoaded ? addCharges('update') : addCharges('create')" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
        </div>
      </div>
  </div>
</div>