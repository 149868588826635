export class OrderTotalDiscounts {
  Type: string;
  Amount: number;
  Code: string;
  DiscountReasonId: number;
  
  constructor(Type: string, Amount: number, Code: string, DiscountReasonId: number) {
      {
        this.Type = Type;
        this.Amount = Amount;
        this.Code = Code;
        this.DiscountReasonId = DiscountReasonId;
      }
  }
}