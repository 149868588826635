import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.scss']
})
export class DiscountsComponent implements OnInit {
  dtOptions: DataTables.Settings = {}

  idDiscounts: number
  cerrar = false
  
  markets: any = []
  selectedMarket: any[] = []
  @ViewChild('multi') selectTienda: MultiSelectComponent;

  selectedMarketEdit: any[] = []
  @ViewChild('multiEdit') selectTiendaEdit: MultiSelectComponent;

  couponNames: any = []

  dropdownSingleSettings: IDropdownSettings = {}

  public cont = 0

  private timeoutId: any
  table: DataTables.Api

  componentDestroyed$: Subject<boolean> = new Subject()

  private unsubscribe: Subscription[] = [];

  constructor(private appService: AppService, private renderer: Renderer2) { }

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region  Metodos de la clase
  /**
   * Guarda el mapeo de descuento
   */
  saveMap() {
    let cupon = $('#cupon').val()
    let discount_reason = $('#discount_reason').val()
    let market = this.selectedMarket

    if (!this.couponNames.find((item: any) => item === cupon)) {
      if (cupon != "" && discount_reason != "") {
        var markets = ""; 
        market.forEach(element => {
          markets+= element["Id"]+=","
        });
        
        const appServiceSubscription = this.appService.setDiscount(cupon, discount_reason, markets).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          this.table.ajax.reload()
          }, err => {
            console.log(err)
          })

        this.unsubscribe.push(appServiceSubscription);
      } else {
        alert("Hay algún campo vacío.")
      }
    } else {
      Swal.fire('No se guardaron los cambios. El nombre del cupón ya existe.', '', 'info')
    }

    this.selectedMarket = []
  }

  /**
   * Edita el mapeo de descuento
   */
  editMap() {
    let cuponEdit = $('#cuponEdit').val()
    let discount_reasonEdit = $('#discount_reasonEdit').val()
    let market = this.selectedMarketEdit;

    if (cuponEdit != "" && discount_reasonEdit != "") {
      var markets = ""; 
      market.forEach(element => {
        markets+= element["Id"]+=","
      });

      const appServiceSubscription = this.appService.updateDiscounts(this.idDiscounts, cuponEdit, discount_reasonEdit, markets).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          this.cerrar = true;
          this.table.ajax.reload()
        }, err => {
          console.log(err)
        })
      this.unsubscribe.push(appServiceSubscription);
    } else {
      alert("Hay algún campo vacío.")
    }
  }

  /**
   * Se encarga de eliminar el descuento escogido
   * @param id id del descuento que se quiere borrar
   */
  deleteDiscounts(id:any) {
    Swal.fire({
      title: '¿Resolver?',
      text: '¿Está seguro de que desea eliminar este elemento?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('¡Guardado!', '', 'success'
        )
        const appServiceSubscription = this.appService.deleteDiscounts(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
            this.table.ajax.reload()
          }, err => {
            console.log(err)
          })
        this.unsubscribe.push(appServiceSubscription);
      } else if (result.isDenied) {
        Swal.fire('No se guardaron los cambios', '', 'info')
      }
    })
  }

  /**
   * Accede a la información de plataformas externas
   */
  getPlatforms() {
    const appServiceSubscription = this.appService.getPlatforms().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.markets = res;
        this.markets.push({ "Id": 0, "Name": "TODOS" })

        this.timeoutId = setTimeout(() => {
          this.processTable()
        }, 0);
      }, err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Obtiene la información de la tabla iniciada
   */
  getTableData() {
    var originalData = this.table.ajax.json()

    var dataArray = Object.values(originalData)
    
    dataArray.forEach(e => {
      this.couponNames.push(e.external_discount)
    });
  }
  //#endregion Métodos de la clase

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Selector de reglas en edición
   */
  discountSelect(idDiscount: any) {
    this.clearSelected()
    // this.idDiscount = idDiscount

    let discounts = Array.from(this.table.rows().data())

    discounts.forEach(j => {
      if (j['id'] == idDiscount) {
        let discountMarket = JSON.parse(j['id_externalplatform_group'])
        discountMarket.forEach((e: { Id: any; Name: any; }) => {
          this.selectTiendaEdit.addSelected({ id: e.Id, text: e.Name }); 
        });

        this.idDiscounts = j['id']
        $('#cuponEdit').val(j['external_discount'])
        $('#discount_reasonEdit').val(j['avelon_discount_reason'])
      }
    })
  }
  /**
   * Vacía de contenido los selectores
   */
  clearSelected () {
    this.selectedMarketEdit = []
    this.selectTiendaEdit.selectedItems = []
  }
  //#endregion UTILS

  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Procesa la información para generar la datatable
   */
  processTable() {
    this.table = $("#kt_datatable_discounts").DataTable({
      dom: 'lrtip',
      order: [[1, "desc"]],
      scrollX: false,
      pageLength: 50,
      processing: true,
      responsive: true,
      language: {
        "processing": `<span>Cargando...
        </span>`,
        "lengthMenu": "Mostrar _MENU_ registros",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "search": "Buscar:",
        "loadingRecords": " ",
        "paginate": {
          "first": "Primero",
          "last": "Último",
          "next": ">",
          "previous": "<"
        },
        "aria": {
          "sortAscending": ": Activar para ordenar la columna de manera ascendente",
          "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      },

      ajax: {
        url: environment.portalHost + 'api/getDiscounts',
        //url: 'http://localhost:4200/api/getDiscounts',
        dataSrc: "",
      },
      columns: [
        { title: 'CUPÓN', data: 'external_discount' },
        { title: 'DISCOUNT REASON', data: 'avelon_discount_reason' },
        { title: 'MARKET', data: 'id_externalplatform' },
        {
          title: '',
          data: '',
          render: function (data: any, type: any, full: any) {
            return `<i type="button" class="la la-edit editDiscount" edit=` + full.id + ` data-bs-target="#kt_modal_edit" data-bs-toggle="modal" title="` + full.id + `"></i>
            <i type="button" drop=`+full.id+` class="la la-trash deleteDiscount"></i>`
          },
          className: 'dt-body-center border-right-0'
        },
        {
          title: '__', data: 'id_externalplatform_group',
        },
      ],
      columnDefs:[
        {targets: 4,visible:false}
      ],
      drawCallback: () => {
        const editDiscount = document.getElementsByClassName('editDiscount');
        
        for (let i = 0; i < editDiscount.length; i++) {
          const e = editDiscount[i];
          let id = e.getAttribute("edit")
          
          e.addEventListener('click', () => {
            this.discountSelect(id)
          });
        }

        const deleteDiscount = document.getElementsByClassName('deleteDiscount');

        for (let i = 0; i < deleteDiscount.length; i++) {
          const e = deleteDiscount[i];
          let idDiscount = e.getAttribute("drop")

          e.addEventListener('click', () => {
            this.deleteDiscounts(idDiscount)
          });
        }
      },

      initComplete: () => {
        const editDiscount = document.getElementsByClassName('editDiscount');

        for (let i = 0; i < editDiscount.length; i++) {
          const e = editDiscount[i];
          let id = e.getAttribute("edit")

          e.addEventListener('click', () => {
            this.discountSelect(id)
          });
        }

        const deleteDiscount = document.getElementsByClassName('deleteDiscount');

        for (let i = 0; i < deleteDiscount.length; i++) {
          const e = deleteDiscount[i];
          let idDiscount = e.getAttribute("drop")

          e.addEventListener('click', () => {
            this.deleteDiscounts(idDiscount)
          });
        }
      },
    })
  }

  /**
   * Inicia el componente
   */
  ngOnInit(): void {
    this.getPlatforms()

    this.dropdownSingleSettings = {
      idField: 'Id',
      textField: 'Name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.timeoutId = setTimeout(() => {
      this.getTableData()
    }, 3000);
  }
  
  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy(): void {
    clearTimeout(this.timeoutId)

    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.table) {
      this.table.destroy()
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN
}