<div class="card">
    <!-- <div class="card-header card-header-stretch">
        Header
    </div> -->
    <div class="card-body">
      <div class="card-body justify-content-center">
        <div class="form-group w-100">
          <label for="avelonUser">USUARIO_AVELON_ELOGISTIK</label>
          <input id="avelonUser" type="text" class="form-control">
          <label for="repoUser">USUARIO_REPO_TRASPASOS</label>
          <input id="repoUser" type="text" class="form-control">
          <label for="warehouses">WAREHOUSES</label>
          <ng-multiselect-dropdown
            #configShopSelection
            [placeholder]="'Config shops'"
            [data]="configShops"
            [(ngModel)]="selectedConfigShop"
            [settings]="dropdownMulti"
            >
          </ng-multiselect-dropdown>
          <label for="prioridad">PRIORIDAD TIENDAS</label>
          <input id="prioridad" type="text" class="form-control">
          <!-- <input id="warehouses" type="text" class="form-control"> -->
      </div>
      <div class="my-5 mx-5">
        <div>
          <button 
            class="btn btn-primary font-weight-bolder"
            id="anadir"
            (click)="updateConfig()"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>