<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
<script type="text/javascript" charset="utf8" src="https://cdn.datatables.net/1.10.18/js/jquery.dataTables.js"></script>

<div class="card card-custom">
    <div class="card-header" class="my-5 mx-5">
        <div>
          <button  class="btn btn-primary font-weight-bolder" id="anadir" (click)="clearSelected();loadPriority()" data-bs-toggle="modal" data-bs-target="#kt_modal_nuevo"><i class="la la-plus" ></i>Nueva regla</button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <button class="btn btn-primary font-weight-bolder" id="anadir" (click)="limpiarFiltros()"><i class="las la-broom"></i>Limpiar filtros</button>
          <!-- <button class="btn btn-primary font-weight-bolder" id="anadir" (click)="limpiarFiltros()"><i class="las la-broom"></i>Limpiar filtros</button> -->
        </div>
      </div>
  <div class="card-body" id="card-body">
    <div id="kt_datatable_wrapper" class="dataTables_wrapper dt-bootstrap4">
      <div style="width: 100%; padding-left: -10px;">
        <div class="table-responsive">
          <table id="kt_datatable_logistic_rules" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_info"
            style="width: 100%;">
            <thead>
              <tr>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <!-- <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending" class="sorting_disabled"></th> -->
              </tr>
            </thead>
            <tfoot id="footer">
              <tr>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th>
                <!-- <th tabindex="0" aria-controls="kt_datatable_logistic_rules" rowspan="1" colspan="1"
                  aria-label="Record ID: activate to sort column descending"></th> -->
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Descripción</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <!-- <p [innerHtml]="one"></p> -->
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
    </div>
  </ng-template>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_nuevo">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Nueva regla</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <label for="priority" class="my-5"><b>Prioridad</b></label>
            <input name="priority" #priority id="priority" type="number" min="0" class="form-control form-control-sm form-filter datatable-input">
            <label for="shopNGo" class="my-5"><b>Shop & GO</b></label>
            <div class="form-check form-switch form-check-custom form-check-solid" >
              <input class="form-check-input" name="shopNGo" type="checkbox" value="" id="shopNGo" (click)="isShopNGo('create')"/>
            </div>
            <label for="shippingType" class="my-5"><b>Tipo de envío*</b></label>
            <ng-multiselect-dropdown
                [placeholder]="'Tipo de envío'"
                [settings]="dropdownSettingsSingle"
                [data]="shippingType"
                [(ngModel)]="selectedShippingType"
            >
            </ng-multiselect-dropdown>
            <ng-container *ngIf="checkShippingHome()">
              <label for="shippingCountry" class="my-5"><b>País de destino</b></label>
              <ng-multiselect-dropdown
                  [placeholder]="'País de destino'"
                  [settings]="dropdownSettings"
                  [data]="shippingCountries"
                  [(ngModel)]="selectedCountries"
                >
                </ng-multiselect-dropdown>
              <ng-container *ngIf="checkIfSpain()">
                <label for="shippingProvince" class="my-5"><b>Provincia de destino</b></label>
                <ng-multiselect-dropdown
                    [placeholder]="'Provincia de destino'"
                    [settings]="dropdownSettings"
                    [data]="shippingProvinces"
                    [(ngModel)]="selectedProvinces"
                  >
                  </ng-multiselect-dropdown>
              </ng-container>
            </ng-container>
            <label for="stockStore" class="my-5"><b>Tienda con stock</b></label>
            <ng-multiselect-dropdown
                [placeholder]="'Tiendas con stock'"
                [settings]="dropdownSettingsStockStore"
                [data]="stockStores"
                [(ngModel)]="selectedStockStores"
              >
              </ng-multiselect-dropdown>
            <ng-container *ngIf="!createShopNGo">
              <label for="externalPlatformOrigin" class="my-5"><b>Plataforma externa de origen</b></label>
              <ng-multiselect-dropdown
                  [placeholder]="'Plataforma externa de origen'"
                  [settings]="dropdownSettings"
                  [data]="externalPlatforms"
                  [(ngModel)]="selectedExternalPlatforms"
                >
              </ng-multiselect-dropdown>
            </ng-container>
            <ng-container *ngIf="checkShippingStore()">
              <label for="storeDestiny" class="my-5"><b>Tienda de destino</b></label>
              <ng-multiselect-dropdown
                  [placeholder]="'Tienda destino'"
                  [settings]="dropdownSettingsStockStore"
                  [data]="stockStores"
                  [(ngModel)]="selectedStoreDestiny"
              >
              </ng-multiselect-dropdown>
            </ng-container>
            <label for="operator" class="my-5"><b>Operador logístico*</b></label>
            <ng-multiselect-dropdown
                [placeholder]="'Operador logístico'"
                [settings]="dropdownSettingsSingle"
                [data]="logisticOperators"
                [(ngModel)]="selectedLogisticOperator"
            >
            </ng-multiselect-dropdown>
            <label for="operatorReturn" class="my-5"><b>Operador logístico de devolución</b></label>
            <ng-multiselect-dropdown
                [placeholder]="'Operador logístico de devolución'"
                [settings]="dropdownSettingsSingle"
                [data]="logisticOperators"
                [(ngModel)]="selectedLogisticOperatorReturn"
            >
            </ng-multiselect-dropdown>
            <label for="instructions" class="my-5"><b>Instrucciones</b></label>
            <textarea name="instructions" #instructions id="instructions" cols="30" rows="5" class="form-control form-control-sm form-filter"></textarea>
            <input type="hidden" id="id_mappingEDIT"/>
        </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="saveLogisticRule()" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
          </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_edit">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Editar regla</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <label for="priority" class="my-5"><b>Prioridad</b></label>
            <input name="priority" #editPriority id="priority" type="number" min="0" class="form-control form-control-sm form-filter datatable-input">
            <label for="shopNGo" class="my-5"><b>Shop & GO</b></label>
            <div class="form-check form-switch form-check-custom form-check-solid" >
              <input class="form-check-input" name="shopNGo" type="checkbox" value="" id="shopNGoEdit" (click)="isShopNGo('update')"/>
            </div>
            <label for="shippingType" class="my-5"><b>Tipo de envío</b></label>
            <ng-multiselect-dropdown
                #editShipping
                (click)="checkChange()"
                [placeholder]="'Tipo de envío'"
                [settings]="dropdownSettingsSingle"
                [data]="shippingType"
                [(ngModel)]="selectedShippingType"
            >
            </ng-multiselect-dropdown>
            <div #domicilio>
              <label for="shippingCountry" class="my-5"><b>País de destino</b></label>
              <ng-multiselect-dropdown
                  #editCountry
                  [placeholder]="'País de destino'"
                  [settings]="dropdownSettings"
                  [data]="shippingCountries"
                  [(ngModel)]="selectedCountries"
                >
                </ng-multiselect-dropdown>
              <div #spain>
                <label for="shippingProvince" class="my-5"><b>Provincia de destino</b></label>
                <ng-multiselect-dropdown
                    #editProvince
                    [placeholder]="'Provincia de destino'"
                    [settings]="dropdownSettings"
                    [data]="shippingProvinces"
                    [(ngModel)]="selectedProvinces"
                  >
                  </ng-multiselect-dropdown>
              </div>
            </div>
            <label for="stockStore" class="my-5"><b>Tienda con stock</b></label>
            <ng-multiselect-dropdown
                #editStockStore
                [placeholder]="'Tiendas con stock'"
                [settings]="dropdownSettingsStockStore"
                [data]="stockStores"
                [(ngModel)]="selectedStockStores"
              >
              </ng-multiselect-dropdown>
            <ng-container *ngIf="!updateShopNGo">
              <label for="externalPlatformOrigin" class="my-5"><b>Plataforma externa de origen</b></label>
              <ng-multiselect-dropdown
                  #editPlatformOrigin
                  [placeholder]="'Plataforma externa de origen'"
                  [settings]="dropdownSettings"
                  [data]="externalPlatforms"
                  [(ngModel)]="selectedExternalPlatforms"
                >
              </ng-multiselect-dropdown>
            </ng-container>
            <div #tienda>
              <label for="storeDestiny" class="my-5"><b>Tienda de destino</b></label>
              <ng-multiselect-dropdown
                  #editStoreDestiny
                  [placeholder]="'Tienda destino'"
                  [settings]="dropdownSettingsStockStore"
                  [data]="stockStores"
                  [(ngModel)]="selectedStoreDestiny"
              >
              </ng-multiselect-dropdown>
            </div>
            <label for="operator" class="my-5"><b>Operador logístico</b></label>
            <ng-multiselect-dropdown
                #editOperator
                [placeholder]="'Operador logístico'"
                [settings]="dropdownSettingsSingle"
                [data]="logisticOperators"
                [(ngModel)]="selectedLogisticOperator"
            >
            </ng-multiselect-dropdown>

            <label for="operatorReturn" class="my-5"><b>Operador logístico de devolución</b></label>
            <ng-multiselect-dropdown
                #editOperatorReturn
                [placeholder]="'Operador logístico de devolución'"
                [settings]="dropdownSettingsSingle"
                [data]="logisticOperators"
                [(ngModel)]="selectedLogisticOperatorReturn"
            >
            </ng-multiselect-dropdown>
            <label for="instructions" class="my-5"><b>Instrucciones</b></label>
            <textarea name="instructions" #editInstructions id="instructions" cols="30" rows="5" class="form-control form-control-sm form-filter"></textarea>
            <input type="hidden" id="id_mappingEDIT"/>
        </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="editLogisticRule()" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
          </div>
      </div>
  </div>
</div>
<!-- TODO: Implementar scroll to top en todos los componentes que tengan mucho scroll: app-scroll-to-top -->