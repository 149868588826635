import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-estados-logisticos',
  templateUrl: './estados-logisticos.component.html',
  styleUrls: ['./estados-logisticos.component.scss'],
})
export class EstadosLogisticosComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtOptionsMapeados: DataTables.Settings = {};

  mappingOpLogisticStates: any;
  dropdownSingleSettings: IDropdownSettings = {};
  closeResult2: string;
  @ViewChild('content2') myModal2: any;
  public listMapped: any;
  logisticList: any[];
  mappIdList: any[];
  logisticStateList: any[];
  logisticStateListNoMap: any;

  avelonStateList: any[];
  operadorSeleccionado: any[] = [];
  estadoSeleccionado: any[] = [];
  estadoAvelonSeleccionado: any[] = [];
  mappingSeleccionado: any;
  incidenciaSeleccionada: Number;
  finalSeleccionado: Number;
  @ViewChild('operador_seleccionado') operador_seleccionado: any;
  @ViewChild('estado_seleccionado') estado_seleccionado: any;
  @ViewChild('estado_avelon_seleccionado') estado_avelon_seleccionado: any;

  closeResult: string;
  @ViewChild('content') myModal: any;
  renderer: any;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings2: IDropdownSettings = {};

  mappingId: string;
  selectedOperator: any;
  selectedState: any;
  stateId: any;

  private timeoutId!: any;

  private unsubscribe: Subscription[] = [];

  componentDestroyed$: Subject<boolean> = new Subject()

  table: DataTables.Api
  tableMapeados: DataTables.Api

  constructor(
    private appService: AppService,
    private ref: ChangeDetectorRef
  ) {}

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE

  //! GETS
  //#region GETS
    /**
   * Accede a los datos de estados logísticos
   */
  getOpLogisticState() {
    const appServiceSubscription = this.appService
      .getOpLogisticState()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        this.logisticStateList = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      });
    this.unsubscribe.push(appServiceSubscription);

  }

  /**
   * Accede a los datos no mapeados de estados de operadores logísticos
   */
  getOpLogisticStateNoMapFiltro() {
    let idOpLogistico = this.operadorSeleccionado[0].id
    const appServiceSubscription = this.appService
      .getOpLogisticStateNoMapFiltro(idOpLogistico)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        this.logisticStateListNoMap = res
      }, err => {
        console.log(err)
      });
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a los datos no mapeados de estados logísticos
   */
  getOpLogisticStateNoMap() {
    const appServiceSubscription = this.appService
      .getOpLogisticStateNoMap()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        this.logisticStateListNoMap = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      });
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a los datos de operadores logísticos
   */
  getOpLogisticValue() {
    const appServiceSubscription = this.appService
      .getOpLogisticValue()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        this.logisticList = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      });
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a los datos logísticos de Avelon
   */
  getAvelonLogisticValue() {
    const appServiceSubscription = this.appService
      .getAvelonLogisticValue()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        this.avelonStateList = res
        this.processTable();
        this.processTableMapeados();
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      });
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a los datos de estados de operadores logísticos
   */
  getMappingOpLogisticStates() {
    const appServiceSubscription = this.appService
      .getMappingOpLogisticStates()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        this.mappingOpLogisticStates = res;
        this.ref.detectChanges()
      }, err => {
        console.log(err);
      });
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a la información de datos específicos de mapeo de operadores logísticos
   * @param id 
   */
  getMappingOpLogisticStatesSpecific(id: number) {
    this.tableMapeados.ajax.url(environment.portalHost + 'api/getMappingOpLogisticStatesSpecific/'
    + id)
    this.tableMapeados.ajax.reload();
  }
  //#endregion GETS
  
  //! POSTS
  //#region POSTS
    /**
   * Guarda el estado logístico mapeable
   */
  saveLogisticStateMapeable() {
    let operador =
      this.operadorSeleccionado.length > 0
        ? this.operadorSeleccionado[0].id
        : null;

    let estado =
      this.estadoSeleccionado.length > 0 ? this.estadoSeleccionado[0].id : null;

    let estadoAvelon =
      this.estadoAvelonSeleccionado.length > 0
        ? this.estadoAvelonSeleccionado[0].id
        : null;

    let incidencia = $('#is_incidence_map').prop('checked') ? 1 : 0;
    let final = $('#is_final_map').prop('checked') ? 1 : 0;

    let mapeable = $('#check-' + estado + '').prop('checked') ? 1 : 0;

    let values = "('" + operador + "','" + estado + "','" + estadoAvelon + "','" + incidencia + "','" + final + "')";

    if (mapeable == 0) {
      Swal.fire('Este estado logistico no se puede mapear', '', 'info');
    } else {
      const appServiceSubscription = this.appService
        .saveLogisticStateMapeable(values)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(res => {
          this.clearSelected();
          this.reloadAll();
        }, err => {
          console.log(err);
        });
      this.unsubscribe.push(appServiceSubscription);
    }
  }

  /**
   * Guarda el nuevo estado logístico
   */
  saveLogisticState() {
    let operador =
      this.operadorSeleccionado.length > 0
        ? this.operadorSeleccionado[0].id
        : null;

    let estado =
      this.estadoSeleccionado.length > 0 ? this.estadoSeleccionado[0].id : null;
    let estadoAvelon =
      this.estadoAvelonSeleccionado.length > 0
        ? this.estadoAvelonSeleccionado[0].id
        : null;
    let incidencia = $('#is_incidence_save').val('checked') ? 1 : 0;
    let final = $('#is_final_save').prop('checked') ? 1 : 0;

    let values = "('" + operador + "','" + estado + "','" + estadoAvelon + "','" + incidencia + "','" + final + "')";

    if (operador == null || estado == null || estadoAvelon == null) {
      Swal.fire(
        'Es necesario rellenar todos los campos con asterisco',
        '',
        'info'
      );
    } else {
      const appServiceSubscription = this.appService
        .saveLogisticState(values)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(res => {
          this.clearSelected();
          this.reloadAll();
        }, err => {
          console.log(err);
        });
      this.unsubscribe.push(appServiceSubscription);
    }
  }
  //#endregion POSTS
  
  //! PUTS
  //#region PUTS
  /**
   * Actualiza el estado logístico
   */
  updateLogisticState() {
    let operador = this.operadorSeleccionado[0].id;
    let estado = this.estadoSeleccionado[0].id;
    let estadoAvelon = this.estadoAvelonSeleccionado[0].id;
    let incidencia = $('#is_incidence').prop('checked') ? 1 : 0;
    let final = $('#is_final').prop('checked') ? 1 : 0;

    let id = this.mappingSeleccionado;
    const appServiceSubscription = this.appService
      .updateLogisticState(
        operador,
        estado,
        estadoAvelon,
        incidencia,
        final,
        id
      )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res) => {
        this.reloadAll();
      }, err => {
        console.log(err);
      });
    this.unsubscribe.push(appServiceSubscription);
  }

  updateMapeable(id:any) {
    let mapeable = $("#check-"+id).prop("checked") ? 1 : 0

    const appServiceSubscription = this.appService.updateMapeable(mapeable, id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.reloadAll();
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion PUTS
  
  //! DELETES
  //#region DELETES
  /**
   * Borra el estado logístico
   * @param id 
   */
  deleteLogisticState(id: any) {
    this.stateId = this.mappingOpLogisticStates.find(
      (a: { id: any }) => a.id == id
    );
    Swal.fire({
      title: '¿Borrar?',
      text: '¿Está seguro de que desea eliminar este elemento?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('¡Guardado!', '', 'success');
        const appServiceSubscription = this.appService
          .deleteLogisticState(id)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(res => {
            this.reloadAll()
          }, err => {
            console.log(err);
          });
        this.unsubscribe.push(appServiceSubscription);
      } else if (result.isDenied) {
        Swal.fire('No se guardaron los cambios', '', 'info');
      }
    });
  }
  //#endregion DELETES
  
  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Limpia los elementos seleccionados
   */
  clearSelected() {
    this.operadorSeleccionado = [];
    this.estadoSeleccionado = [];
    this.estadoAvelonSeleccionado = [];
    $('#is_incidence').prop('checked', false);
    $('#is_final').prop('checked', false);
  }

  private initiateParameters() {
    this.getAvelonLogisticValue();
    this.getOpLogisticStateNoMap();
    this.getOpLogisticState();
    this.getOpLogisticValue();
    this.getMappingOpLogisticStates();
  }

  /**
   * Recarga las tablas y la ventana
   */
  private reloadAll() {
    this.table.ajax.reload();
    this.tableMapeados.ajax.reload();
    // window.location.reload();
  }

    /**
   * Comprueba los datos para marcar las casillas correspondientes
   * @param id 
   * @returns 
   */
  checkDataMap(id: any) {
    if(id != undefined && id != null){
      var mapping = this.logisticStateListNoMap?.find(
        (i: { id: any }) => i.id == id
      );
    }else{
      return;
    }

    if(mapping.op_logistic_id != undefined && mapping.op_logistic_id != null){
      var operador = this.logisticList?.find(
        (i: { id: any }) => i.id == mapping.op_logistic_id
      );
    }else{
      return;
    }

    let estado = this.logisticStateList?.find(
      (i: { id: any }) => i.id == mapping.id
    );

    let estadoAvelon = this.avelonStateList?.find(
      (i: { id: any }) => i.id == mapping.avelon_logistic_state
    );

    this.mappingSeleccionado = id;
    this.operador_seleccionado.addSelected({
      id: operador?.id,
      text: operador?.value,
    });

    if(estado.id != undefined && estado.value != undefined){
      this.estado_seleccionado.addSelected({ id: estado.id, text: estado.text });
    }else{
      return;
    }

    if(estadoAvelon?.id != undefined && estadoAvelon?.id != null && estadoAvelon?.value != null && estadoAvelon?.value != undefined){
      this.estado_avelon_seleccionado.addSelected({
        id: estadoAvelon.id,
        text: estadoAvelon.value,
      });
    }else{
      return;
    }
  }

  /**
   * Comprueba los datos para marcar las casillas correspondientes
   * @param id 
   */
  checkData(id: any) {
    if(id != undefined && id != null){
    var mapping = this.mappingOpLogisticStates.find(
      (i: { mapping_op_logistic_state_id: any }) =>
        i.mapping_op_logistic_state_id == id
    );
    }
    if(mapping.op_logistic_id != null && mapping.op_logistic_id != undefined ){
      var operador = this.logisticList.find(
        (i: { id: any }) => i.id == mapping.op_logistic_id
      );

    }

    let estado = this.logisticStateList.find(
      (i: { id: any }) => i.id == mapping.op_logistic_state_id
    );
    let estadoAvelon = this.avelonStateList.find(
      (i: { id: any }) => i.id == mapping.avelon_logistic_state
    );

    this.mappingSeleccionado = id;
    this.operador_seleccionado.addSelected({
      id: operador.id,
      text: operador.value,
    });

    this.estado_seleccionado.addSelected({ id: estado.id, text: estado.text });
    this.estado_avelon_seleccionado.addSelected({
      id: estadoAvelon.id,
      text: estadoAvelon.value,
    });

    $('#is_incidence')
      .prop('checked', mapping.is_incidence != 0 ? true : false)
      .attr('value', mapping.is_incidence);
    $('#is_final')
      .prop('checked', mapping.is_final != 0 ? true : false)
      .attr('value', mapping.is_final);
  }
  //#endregion UTILS

  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Procesa la tabla de mapeados
   */
  processTableMapeados() {
    this.tableMapeados = $('#kt_datatable_logistic_states_mapeados').DataTable({
      processing: true,
      responsive: true,
      language: {
        processing: `<span>Cargando...
          </span>`,
        lengthMenu: 'Mostrar _MENU_ registros',
        zeroRecords: 'No se encontraron resultados',
        emptyTable: 'Ningún dato disponible en esta tabla',
        infoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        infoFiltered: '(filtrado de un total de _MAX_ registros)',
        search: 'Buscar:',
        loadingRecords: ' ',
        paginate: {
          first: 'Primero',
          last: 'Último',
          next: '>',
          previous: '<',
        },
        aria: {
          sortAscending:
            ': Activar para ordenar la columna de manera ascendente',
          sortDescending:
            ': Activar para ordenar la columna de manera descendente',
        },
        decimal: ',',
        thousands: '.',
        info: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
      },

      ajax: {
        url: environment.portalHost + 'api/getMappingOpLogisticStates',
        // url: 'http://localhost:4200/api/getMappingOpLogisticStates',
        dataSrc: '',
      },
      
      columns: [
        {
          title: 'Estado Logístico',
          data: 'op_logistic_state_id',
          render: (d) => {
            let estado_logistico = this.logisticStateList?.find(
              (i: { id: any }) => i.id == d
            );
          
            return `<td>` + estado_logistico?.value + `</td>`;        
        },
        },
        {
          title: 'Estado Logístico Avelon',
          data: 'avelon_logistic_state',
          render: (d) => {
            let estado_avelon = this.avelonStateList?.find(
              (i: { id: any }) => i.id == d
            );
            return `<td>` + estado_avelon?.value + `</td>`;
          },
        },
        {
          title: 'INCIDENCIA',
          data: 'is_incidence',
          render: (d) => {
            return d == 0
              ? `<td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg></td>`
              : `<td><span style="font-size:16px; color: green;">&check;</span></td>`;
          },
        },
        {
          title: 'FINAL',
          data: 'is_final',
          render: (d) => {
            return d == 0
              ? `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>`
              : `<span style="font-size:16px; color: green;">&check;</span>`;
          },
        },
        {
          title: '',
          data: '',
          render: function (data: any, type: any, full: any) {
            return (
              `
              <td nowrap="nowrap" class="datatable-actions-td">
                <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action editLogistic" data-bs-target="#kt_modal_edit" data-bs-toggle="modal" id="` +
              full.mapping_op_logistic_state_id +
              `"  title="Editar">
                  <span class="svg-icon svg-icon-md">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                          fill="#000000" fill-rule="nonzero"
                          transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                        <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                      </g>
                    </svg>
                  </span>
                </a>
                </td>
                <td nowrap="nowrap" class="datatable-actions-td">
                <a class="btn btn-sm btn-clean btn-icon datatable-action eliminarEstado" id="` +
              full.mapping_op_logistic_state_id +
              `" title="Eliminar">
                  <span class="svg-icon svg-icon-md">
                    <svg
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                        viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                            fill="#000000" fill-rule="nonzero"></path>
                          <path
                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                            fill="#000000" opacity="0.3"></path>
                      </g>
                    </svg>
                  </span>
                </a>
              </td>`
            );
          },
          className: 'dt-body-center border-right-0',
        },
      ],
      drawCallback: () => {
        const editRulesModal = document.getElementsByClassName('editLogistic');

        for (let i = 0; i < editRulesModal.length; i++) {
          const e = editRulesModal[i];

          e.addEventListener('click', () => {
            this.checkData(e.id);
            this.mappingId = e.id;
          });
        }

        const deleteRule = document.getElementsByClassName('eliminarEstado');

        for (let i = 0; i < deleteRule.length; i++) {
          const e = deleteRule[i];

          e.addEventListener('click', () => {
            this.deleteLogisticState(e.id);
            this.stateId = e.id;
          });
        }
      },

      initComplete: () => {
        const editRulesModal = document.getElementsByClassName('editLogistic');

        for (let i = 0; i < editRulesModal.length; i++) {
          const e = editRulesModal[i];

          e.addEventListener('click', () => {
            this.checkData(e.id);
            this.mappingId = e.id;
          });
        }

        const deleteRule = document.getElementsByClassName('eliminarEstado');

        for (let i = 0; i < deleteRule.length; i++) {
          const e = deleteRule[i];

          e.addEventListener('click', () => {
            this.deleteLogisticState(e.id);
            this.stateId = e.id;
          });
        }
      },
    })
  }

  /**
   * Procesa la tabla e no mapeados
   */
  processTable() {
    this.table = $('#kt_datatable_logistic_states').DataTable({
      processing: true,
      responsive: true,
      autoWidth: false,
      language: {
        processing: `<span>Cargando...
        </span>`,
        lengthMenu: 'Mostrar _MENU_ registros',
        zeroRecords: 'No se encontraron resultados',
        emptyTable: 'Ningún dato disponible en esta tabla',
        infoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        infoFiltered: '(filtrado de un total de _MAX_ registros)',
        search: 'Buscar:',
        loadingRecords: ' ',
        paginate: {
          first: 'Primero',
          last: 'Último',
          next: '>',
          previous: '<',
        },
        aria: {
          sortAscending:
            ': Activar para ordenar la columna de manera ascendente',
          sortDescending:
            ': Activar para ordenar la columna de manera descendente',
        },
        decimal: ',',
        thousands: '.',
        info: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
      },

      ajax: {
        url: environment.portalHost + 'api/getOpLogisticStateNoMap',
        // url: 'http://localhost:4200/api/getOpLogisticStateNoMap',
        dataSrc: '',
      },

      columns: [
        {
          title: 'id',
          data: 'id',
          render: (d) => {
            let estado_logistico = this.logisticStateListNoMap?.find(
              (i: { id: any }) => i.id == d
            );
            return `<td>` + estado_logistico?.id + `</td>`;
          },
        },
        {
          title: 'Estado Logístico',
          data: 'id',
          render: (d) => {
                let estado_logistico = this.logisticStateListNoMap?.find(
              (i: { id: any }) => i.id == d
            );
              return `<td>` + estado_logistico?.value + `</td>`
          },
        },
        {
          title: 'Notificar',
          data: 'id',

          render: (d) => {
                let map = this.logisticStateListNoMap?.find(
              (i: { id: any }) => i.id == d
            );
            return map?.mappeable == 1
              ? `<td><input class="form-check-input" type="checkbox" id="check-` +
                  map?.id +
                  `" checked="checked"" />
            <label class="form-check-label" for="checkMap">
                Mapeable
            </label></td>`
              : `<td><input class="form-check-input" type="checkbox" id="check-` +
                  map?.id +
                  `""/>
            <label class="form-check-label" for="checkMap">
                Mapear
            </label></td>`;
          },
        },

        {
          title: 'Mapear',
          data: '',
          render: function (data: any, type: any, full: any) {
            return (
              `
            <td nowrap="nowrap" class="datatable-actions-td">

            <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action mapLogistic" data-bs-target="#kt_modal_mapping" data-bs-toggle="modal" id="` +
            full.id +
            `"  title="Editar">
              <span class="svg-icon svg-icon-md">
                <svg xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"></rect>
                    <path
                      d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                      fill="#000000" fill-rule="nonzero"
                      transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                  </g>
                </svg>
              </span>
            </a>
            </td>
            `
            );
          },
        },
      ],

      columnDefs: [
        {
          targets: 0,
        },
        {
          targets: 1,
        },
        {
          targets: 2,
          width: '100px',
        },
        {
          targets: 3,
          width: '20px',
        },
      ],

      drawCallback: () => {
        const editRulesModal = document.getElementsByClassName('mapLogistic');

        for (let i = 0; i < editRulesModal.length; i++) {
          const e = editRulesModal[i];
          e.addEventListener('click', () => {
              this.mappingId = e.id;
              if(e.id != undefined && e.id != null){
                this.checkDataMap(e.id);

              }else{
                return;
              }
          });
        }
      },

      initComplete: () => {
        const editRulesModal = document.getElementsByClassName('mapLogistic');

        for (let i = 0; i < editRulesModal.length; i++) {
          const e = editRulesModal[i];

          e.addEventListener('click', () => {
            this.mappingId = e.id;
            if(e.id != undefined && e.id != null){
              this.checkDataMap(e.id);
            }else{
              return;
            }
          });
        }

        this.logisticStateListNoMap?.forEach((e: any) => {
          let element = document.getElementById('check-' + e.id)
          if (element != null) {
            element.addEventListener('click', () => {
              if(e.id != undefined && e.id != null){
                this.updateMapeable(e.id)
              }else{
                return;
              }
            })
          }
        });
      },
    })

    this.timeoutId = setTimeout(() => {
      $('#fakeClick').click();
    }, 1000);
  }

  /**
   * Inicia el componente
   */
  ngOnInit(): void {
    this.initiateParameters();
    
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    
    this.dropdownSettings2 = {
      singleSelection: true,
      idField: 'id',
      textField: 'text',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    
    this.timeoutId = setTimeout(() => {
      (<HTMLElement>$('#mustClick').get(0)).click();
    }, 500);

    this.timeoutId= setTimeout(() => {
      this.table.ajax.reload();
      this.tableMapeados.ajax.reload();
    }, 1000);
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy() {
    clearTimeout(this.timeoutId)

    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.table) {
      this.table.destroy();
    }
    
    if (this.tableMapeados) {
      this.tableMapeados.destroy()
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN
}
