import { ChangeDetectorRef, Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sga-tool',
  templateUrl: './sga-tool.component.html',
  styleUrls: ['./sga-tool.component.scss']
})
export class SgaToolComponent implements OnInit {
  dropdownSettings: IDropdownSettings = {};
  
  operadores: any = []
  
  configParams: any = []
  
  loading = false
  success = false
  error = false

  loadingTransport = false
  successTransport = false
  errorTransport = false
  
  expeditionChecked = false;
  expeditionNumChecked = false;
  returnChecked = false;
  
  selectedOperator: any[] = []
  @ViewChild('operatorSelector') operatorSelector:MultiSelectComponent;
  
  userData: any = {}

  handleShippingReturn: string = ""

  type: string = ""

  private unsubscribe: Subscription[] = [];

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(private appService: AppService, private ref: ChangeDetectorRef ) { }

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE

  //! GETS
  //#region GETS
  /**
   * Accede a los datos de operadores logísticos
   */
  getLogisticOperatorsValues(): void {
    const appServiceSubscription = this.appService.getLogisticOperatorsValues().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.operadores = res;
    }, err => {
      console.log(err);
    });
    this.unsubscribe.push(appServiceSubscription);
  }

  getUserData(): void {
    let key = localStorage.getItem(`${environment.appVersion}-${environment.USERDATA_KEY}`)
    if (key != null) {
      const appServiceSubscription = this.appService.getUserByAuthToken(JSON.parse(key).authToken).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.userData = res
      }, err => {
        console.log(err);
      });
      this.unsubscribe.push(appServiceSubscription);
    }
  }

  /**
   * Accede a los parámetros de config
   */
  getConfigParams(): void {
    const appServiceSubscription = this.appService.getConfigParams().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.configParams = res;
    }, err => {
      console.log(err);
    });
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Actualiza el campo de traspasos (itemMovementDocuments)
   */
  itemMovementDocuments(): void {
    this.resetLoad();
    const appServiceSubscription = this.appService.itemMovementDocuments().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      if (res.response_code == 'SUCCESS') {
        $(document).ready(function() {
          // Cambiar el contenido HTML del span
          $("#success").html("<strong>" + res.response_code +"</strong>")
        });
        this.loading = false
        this.success = true
        this.logData()
      }
      else {
        $(document).ready(function() {
          $("#error").html("<strong>" + res.description +"</strong>")
        });
        this.loading = false
        this.error = true

      }
      this.ref.detectChanges();
    }, err => {
      $(document).ready(function() {
        $("#error").html("<strong>" + err.message +"</strong>")
      });
      this.loading = false
      this.error = true
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Actualiza el campo de pedidos B2C
   */
  refreshB2C() {
    this.resetLoad();
    const appServiceSubscription = this.appService.refreshB2C().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      if (res == 'OK') {
        $(document).ready(function() {
          $("#success").html("<strong>" + res +"</strong>")
        });
        this.loading = false
        this.success = true
        this.logData()
      }
      else {
        $(document).ready(function() {
          $("#error").html("<strong>" + res.description +"</strong>")
        });
        this.loading = false
        this.error = true
      }
      this.ref.detectChanges();
    }, err => {
      $(document).ready(function() {
        $("#error").html("<strong>" + err.message +"</strong>")
      });
      this.loading = false
      this.error = true
    })
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion GETS

  //! POSTS
  //#region POSTS
  /**
   * Gestiona los datos de envíos
   */
  handleShipping() {
    let externalIds_array = $("#externalIds").val().split(',')
    let externalIds_arrayTrim = externalIds_array.map((item: string) => item.trim());

    let counterRef = $("#expeditionNum").val()

    let operator = this.operadores.find((o: { id: any; }) => o.id == this.selectedOperator[0].id)
    // let shippingServiceInfo = this.expeditionNumChecked ? (this.returnChecked ? `${operator.shippingService_info};COUNTER_REF:(${counterRef})` : `COUNTER_REF:(${counterRef})`) : operator.shippingService_info
    let shippingServiceInfo = ""

    if (this.expeditionNumChecked && this.returnChecked) {
      shippingServiceInfo = `${operator.shippingService_info};COUNTER_REF:(${counterRef})`
    }
    if (!this.expeditionNumChecked && this.returnChecked) {
      shippingServiceInfo = `${operator.shippingService_info}`
    }
    if (this.expeditionNumChecked && !this.returnChecked) {
      shippingServiceInfo = `COUNTER_REF:(${counterRef})`
    }

    let avelonUser = this.configParams.find((o: { key: string; }) => o.key == 'USUARIO_AVELON_ELOGISTIK').value
    let avelonName = operator.avelon_name

    let handlerInfo = {
      ExternalIds: externalIds_arrayTrim,
      ShippingHandler: avelonName,
      EmployeeId: avelonUser
    }

    let serviceInfo = {
      ExternalIds: externalIds_arrayTrim,
      ShippingService: shippingServiceInfo,
      EmployeeId: avelonUser
    }

    const appServiceSubscription = this.appService.handleShipping(JSON.stringify(handlerInfo), JSON.stringify(serviceInfo)).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.resetLoadTransport();
      this.handleShippingReturn = res.toString()
      this.logData()
      if (res == "OK") {
        this.loadingTransport = false
        this.successTransport = true
        $(document).ready(function() {
          $("#successTransport").html("<strong><p style='color:green;'>" + res + "</p></strong>")
        });
        Swal.fire('Cambiado transportista', '', 'success');
      }
      else {
        this.loadingTransport = false
        this.successTransport = true
        $(document).ready(function() {
          $("#successTransport").html("<strong><p style='color:red;'>" + res + "</p></strong>")
        });
        Swal.fire(`Error tratando de cambiar el transportista:<hr>${res}`, '', 'error');
      }
      this.ref.detectChanges();
    }, err => {
      console.log(err);
    });
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion POSTS

  //! PUTS
  //#region PUTS
  /**
   * Gestiona los mensajes que deben aparecer en pantalla
   * @param option cadena de texto que identifica opción a actualizar
   */
  handleOption(option: string) {
    const resultForceData = this.updateForceData(option);
  
    if (option === "tariffCheck") {
      Swal.fire('Actualizado forzado', '', 'success');
    } 
    else {
      if ($("#priceRefs").val() !== "") {
        if (resultForceData) {
          Swal.fire('Todo actualizado', '', 'success');
        } else {
          if (resultForceData === null) {
            Swal.fire('Actualizado forzado. La lista no está en el formato correcto, no se pudo actualizar', '', 'warning');
          } else {
            Swal.fire('Actualizado forzado. Ha habido un problema actualizando la lista de referencias', '', 'warning');
          }
        }
      } else {
        Swal.fire('Actualizado forzado', '', 'success');
      }
    }
  }

  /**
   * Actualiza la información de las referencias para los distintos forzados
   * @param type cadena de texto que identifica el tipo de actualización que se requiere
   * @returns 
   */
  updateForceData(type: string){
    let forceValues = null
    switch (type) {
      case "tariffCheck":
        return false
      case "priceCheck":
        forceValues = $("#priceRefs").val()
        break;
      default:
        break;
    }

    if (!this.esListaDeNumeros(forceValues)) {
      return null
    }

    const appServiceSubscription = this.appService.updateForceData(type, forceValues).pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.logData()
    }, err => {
      console.log(err);
      return false
    });

    this.unsubscribe.push(appServiceSubscription);

    return true
  }
  //#endregion PUTS

  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Resetea la carga
   */
  private resetLoad() {
    this.loading = true;
    this.success = false;
    this.error = false;
  }

  /**
   * Resetea la carga de transportistas
   */
  private resetLoadTransport() {
    this.loadingTransport = true;
    this.successTransport = false;
    this.errorTransport = false;
  }

  /**
   * Actualiza a 1 el valor del forzado de los diferentes tipos
   * @param option cadena de texto que determina el valor enviado a forzar
   */
  forceCheck(option: string): void {
    const data = { [option]: 1 };
    const appServiceSubscription = this.appService.updateConfig(data).pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.handleOption(option)
    }, err => {
      console.log(err);
    });
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Gestiona los mensajes de información que serán enviados a la tabla log
   * @param type 
   */
  logData() {
    let button = ""
    let options = ""
    switch (this.type) {
      case 'itemMovementDocuments':
        button = $("#btnTraspasos").text().trim()
        break;
      case 'refreshB2C':
        button = $("#btnB2C").text().trim()
        break;
      case 'priceCheck':
        button = $("#btnPrices").text().trim()
        options = `, con las siguientes referencias: ${$("#priceRefs").val()}.`
        break;
      case 'tariffCheck':
        button = $("#btnTariff").text().trim()
        break;
      case 'shippings':
        button = "Cambiar transportistas"
        options = `, con las siguientes referencias: ${$("#externalIds").val()} ; para el operador: ${this.selectedOperator[0].value}.`
        break;
      default:
        break;
    }

    let checks = ""

    let labelExpedition = $("#labelCheckExpedition").text().toUpperCase()
    let labelReturn = $("#labelCheckReturn").text().toUpperCase()
    
    if (this.expeditionNumChecked && this.returnChecked) {
      checks = ` ${labelExpedition} con valor: ${$("#expeditionNum").val()}. También ${labelReturn}`
    }
    if (!this.expeditionNumChecked && this.returnChecked) {
      checks = ` Se marca la opción de ${labelReturn}`
    }
    if (this.expeditionNumChecked && !this.returnChecked) {
      checks = ` ${labelExpedition} con valor: ${$("#expeditionNum").val()}` 
    }

    let user = `${this.userData.username} ${this.userData.lastname}` 

    let message = `El usuario ${user.toUpperCase()} ha realizado una petición para ${button?.toUpperCase()}${options}${checks}`
    
    if (this.handleShippingReturn != "") {
      message += ` Con este resultado: ${this.handleShippingReturn}`
    }

    const appServiceSubscription = this.appService.logData(message).pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
    }, err => {
      console.log(err);
    });
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Comprueba con una expresión regular si el string está compuesto por números, que no termina en coma y que, de ser varios, estén separados por comas
   * @param input cadena de texto con las referencias que escriben en el portal que deben ser actualizadas
   * @returns booleano
   */
  esListaDeNumeros(input: string): boolean {
    const pattern: RegExp = /^(?:\d+,)*\d+$/;
    return pattern.test(input);
  }

  /**
   * Habilita o deshabilita los checks a la hora de enviar los datos de transportistas
   * @param type cadena de texto que identifica la operación que se quiere realizar
   */
  check(type: string) {
    switch (type) {
      case "expedition":
        let valuesCheck = ["SEUR", "DPD"]
        if (valuesCheck.includes(this.selectedOperator[0].value)) {
          this.expeditionChecked = true
        }
        else {
          this.expeditionChecked = false
          this.expeditionNumChecked = false
        }
        break;
      case "return":
        this.returnChecked = !this.returnChecked
        break;
      case "expeditionNum":
        this.expeditionNumChecked = !this.expeditionNumChecked
        break;
      default:
        break;
    }
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Inicia el componente
   */
  ngOnInit(): void {
    this.getLogisticOperatorsValues()
    this.getConfigParams()

    this.getUserData()
    
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN

}