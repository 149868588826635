import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shop-config',
  templateUrl: './shop-config.component.html',
  styleUrls: ['./shop-config.component.scss']
})
export class ShopConfigComponent implements OnInit {
  table: DataTables.Api

  componentDestroyed$: Subject<boolean> = new Subject()
  
  private unsubscribe: Subscription[] = [];

  constructor(private appService: AppService, private ref: ChangeDetectorRef) { }

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE

  //! GETS
  //#region GETS
  checkData(id:any) {
    const appServiceSubscription = this.appService.getShopData(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      $("#marketInput").val(res[0].Name + " - " + res[0].id)
      $("#shopId").val(res[0].id)
      $("#retailSwitch").prop("checked", res[0].retail != 0 ? true : false).attr("value",res[0].retail)
      $("#activeSwitch").prop("checked", res[0].active != 0 ? true : false).attr("value",res[0].active)
      $("#masterSwitch").prop("checked", res[0].is_master_retail_group != 0 ? true : false).attr("value",res[0].is_master_retail_group)
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion GETS

  //! PUTS
  //#region PUTS
  editShopConfig() {
    var table = $('#kt_datatable_shop_config').DataTable();

    let retailEdit = $("#retailSwitch").prop("checked") ? 1 : 0
    let activeEdit = $("#activeSwitch").prop("checked") ? 1 : 0
    let masterEdit = $("#masterSwitch").prop("checked") ? 1 : 0
    let shopId = $("#shopId").val()

    const appServiceSubscription = this.appService.editShopConfig(retailEdit,activeEdit,masterEdit,shopId).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      table.ajax.reload()
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion PUTS

  //#endregion MÉTODOS DE LA CLASE
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Se procesa la datatable
   */
  processTable(){
    this.table = $("#kt_datatable_shop_config").DataTable({
      processing: true,
      responsive: true,
      searching: true,
      language: {
        "processing": `<span>Cargando...
        </span>`,
        "lengthMenu": "Mostrar _MENU_ registros",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "search": "Buscar:",
        "loadingRecords": " ",
        "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": ">",
            "previous": "<"
        },
        "aria": {
            "sortAscending": ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
    },

      deferRender: true,

      ajax: {
        url: environment.portalHost+'api/getShopConfData',
        // url: 'http://localhost:4200/api/getShopConfData',
        dataSrc: "",
      },

      columns: [
        { title: 'MARKET', data: 'Name' },
        { title: 'ID',data: 'id'},
        {
          title: 'RETAIL',
          data: 'retail',
          render: (d) => {
            return d === 0 ? `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>` : `<span style="font-size:16px; color: green;">&check;</span>`
          }
        },
        {
          title: 'ACTIVO',
          data: 'active',
          render: (d) => {
            return d === 0 ? `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>` : `<span style="font-size:16px; color: green;">&check;</span>`
          }
        },
        { title: 'RETAIL GROUP',data: 'retail_group'},
        {
          title: 'MASTER',
          data: 'is_master_retail_group',
          render: (d) => {
            return d === 0 ? `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg" viewBox="0 0 16 16">
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
          </svg>` : `<span style="font-size:16px; color: green;">&check;</span>`
          }
        },
        {
          title: '',
          render: function (data: any, type: any, full: any) {
            return `
            <td nowrap="nowrap" class="datatable-actions-td">
              <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action editShop" data-bs-target="#kt_modal_edit" data-bs-toggle="modal" id="` + full.id + `"  title="Editar">
                <span class="svg-icon svg-icon-md"> 
                  <svg xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                        fill="#000000" fill-rule="nonzero"
                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                      <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                    </g>
                  </svg> 
                </span>
              </a>
              </td>`
          },
          className: 'dt-body-center border-right-0'
        },
      ],

      drawCallback: () => {
        const editShop = document.getElementsByClassName("editShop")

        for (let i = 0; i < editShop.length; i++) {
          const e = editShop[i];

          e.addEventListener('click', () => {
            this.checkData(e.id)
          })
        }
      },
      
      initComplete: () => {
        var table = $('#kt_datatable_shop_config').DataTable();

        const editShop = document.getElementsByClassName("editShop")

        for (let i = 0; i < editShop.length; i++) {
          const e = editShop[i];

          e.addEventListener('click', () => {
            this.checkData(e.id)
          })
        }

        table.columns([0,4]).every( function () {
            var column = this;
            var select = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
                .appendTo( $(column.footer()).empty() )
                .on( 'change',  () => {
                    var val = $.fn.dataTable.util.escapeRegex(
                        select.val()
                    );
    
                    column
                        .search( val ? '^'+val+'$' : '', true, false )
                        .draw();
                } );
    
            column.data().unique().sort().each( function ( d: string, j: any ) {
                select.append( '<option value="'+d+'">'+d+'</option>' )
            } );
        });

        $('tfoot tr:not(#newrule)').appendTo('thead')

        $('select[name="kt_datatable_length"]').addClass('custom-select custom-select-sm form-control form-control-sm');
        $('#kt_datatable_filter label input').addClass("form-control form-control-sm")
      }
    });
  }

  /**
   * Inicia el componente
   */
  ngOnInit(): void {
      this.processTable()
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.table) {
      this.table.destroy();
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN
}