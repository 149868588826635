<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>

<div class="card ">
  <div class="card-header card-header-stretch">
    <h3 class="card-title">Plantillas de predistribución</h3>
    <div class="card-toolbar">
      <!-- Navegador -->
      <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
        <ng-container>
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#tab_productos" (click)="drawTable('productos')">Productos</a>
          </li>
        </ng-container>
        <ng-container>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#tab_plantillas" (click)="drawTable('plantillas')">Plantillas</a>
          </li>
        </ng-container>
        <ng-container>
          <li class="nav-item">
            <!-- <a class="nav-link" data-bs-toggle="tab" href="#tab_simular" (click)="drawTable('simular')">Simular</a> -->
            <!-- <a class="nav-link" data-bs-toggle="tab" href="#tab_simular" (click)="processTableSimular()">Simular</a> -->
            <!-- <a class="nav-link" data-bs-toggle="tab" href="#tab_simular" (click)="processSimulateTables()">Simular</a> -->
            <a class="nav-link" data-bs-toggle="tab" href="#tab_simular" (click)="processTableSimular('kt_datatable_simular_mujer', 'S')">Simular</a>
          </li>
        </ng-container>
        <ng-container *ngIf="false">
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#tab_patrones">Patrones</a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div class="card-body">
    <div class="tab-content" id="myTabContent">
      <div class="modal fade" tabindex="-1" id="kt_modal_image">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <!--begin::Close-->
                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                        <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
                    </div>
                    <!--end::Close-->
                </div>
                <div class="modal-body">
                  <div class="d-flex justify-content-center" id="imageModal">
                    <img id="modalImage" src="" alt="Image" style="width: 100%;">
                  </div>
                </div>
            </div>
        </div>
      </div>
      <ng-container>
        <!-- Tabla de productos -->
        <div class="tab-pane fade active show" id="tab_productos" role="tabpanel">
          <div class="col-sm">
            <!-- <app-advanced-search-simular
              [buscarButtonId] = "'search'"

              [inputData]="inputData"
              [selectedMarca]="selectedMarca"
              [selectedTemporada]="selectedTemporada"
              [selectedFamilia]="selectedFamilia"
              [selectedComercial]="selectedComercial"
              [selectedManufacturer]="selectedManufacturer"
              [selectedTemplate]="selectedTemplateEdit"

              [marcas]="marcas"
              [temporadas]="temporadas"
              [familias]="familias"
              [comercial]="comercial"
              [manufacturer]="manufacturer"

              [dropdownSettings]="dropdownSettings"

              (onLimpiarFiltros)="limpiarFiltros()"
            ></app-advanced-search-simular> -->
            <div class="accordion" id="accordionSimulate">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                        Búsqueda avanzada
                    </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionSimulate">
                  <div class="accordion-body">
                    <form class="form-group">
                      <div class="row mb-6">
                        <div class="col-lg-3 mb-6">
                          <label>Id artículo:</label>
                          <input class="form-control" type="text" id="items" [(ngModel)]="inputData" name="items"
                              placeholder="¿Más de uno?, separa con comas">
                        </div>
                        <div class="col-lg-3 mb-6">
                          <label>Marca:</label>
                          <ng-multiselect-dropdown name="marca" [placeholder]="'Sin selección'"
                              class="multi-select" [settings]="dropdownSettings" [data]="marcas"
                              [(ngModel)]="selectedMarca">
                          </ng-multiselect-dropdown>
                          <!-- <input hidden id="selectedMarcaSimulate" value="{{selectedMarca}}"> -->
                        </div>
                        <div class="col-lg-3 mb-6">
                          <label>Temporada:</label>
                          <ng-multiselect-dropdown name="temporada" [placeholder]="'Sin selección'"
                              class="multi-select" [settings]="dropdownSettings" [data]="temporadas"
                              [(ngModel)]="selectedTemporada">
                          </ng-multiselect-dropdown>
                        </div>
                        <div class="col-lg-3 mb-6">
                          <label>Familia:</label>
                          <ng-multiselect-dropdown id="familia" name="familia" [placeholder]="'Sin selección'"
                              class="multi-select" [settings]="dropdownSettings" [data]="familias"
                              [(ngModel)]="selectedFamilia">
                          </ng-multiselect-dropdown>
                        </div>
                        <div class="col-lg-3 mb-6">
                          <label>Comercial:</label>
                          <ng-multiselect-dropdown id="comercial" name="comercial" [placeholder]="'Sin selección'"
                              class="multi-select" [settings]="dropdownSettings" [data]="comercial"
                              [(ngModel)]="selectedComercial">
                          </ng-multiselect-dropdown>
                        </div>
                        <div class="col-lg-3 mb-6">
                          <label>Proveedor:</label>
                          <ng-multiselect-dropdown id="manufacturer" name="manufacturer"
                              [placeholder]="'Sin selección'" class="multi-select" [settings]="dropdownSettings"
                              [data]="manufacturer" [(ngModel)]="selectedManufacturer">
                          </ng-multiselect-dropdown>
                        </div>
                      </div>
                      <input type="submit" style="margin-top: 10px; margin-bottom: 10px; margin-right: 20px;"
                          class="btn btn-primary" id="search" value="Buscar">
                      <button class="btn btn-primary font-weight-bolder" (click)="limpiarFiltros()">
                          <i class="las la-broom"></i>Limpiar filtros
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion" id="accordionTiendas">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTiendas">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTiendas"
                    aria-expanded="false" aria-controls="collapseTiendas">
                    Asociar tiendas y plantillas
                  </button>
                </h2>
                <div id="collapseTiendas" class="accordion-collapse collapse" aria-labelledby="headingTiendas"
                  data-bs-parent="#accordionTiendas">
                  <div class="accordion-body">
                    <form class="form-group">
                      <div class="row mb-6">
                        <div class="col-lg-6 mb-6">
                          <label>Tiendas:</label>
                          <ng-multiselect-dropdown 
                            id="tiendas" 
                            name="tiendas" 
                            [placeholder]="'Sin selección'"
                            class="multi-select" 
                            [settings]="dropdownSettings" 
                            [data]="stores"
                            [(ngModel)]="selectedTiendas">
                          </ng-multiselect-dropdown>
                          <br>
                          <div class="form-check form-switch form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox" id="bindShopsCheck"/>
                            <label class="form-check-label">
                              Asociar tiendas
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6 mb-6">
                          <label>Plantillas:</label>
                          <ng-multiselect-dropdown
                            #templateSelectorProducts
                            name="plantilla"
                            [placeholder]="'Plantilla'"
                            [settings]="dropdownTemplateSettings"
                            [data]="templates"
                            [(ngModel)]="selectedTemplateProducts"
                          >
                          </ng-multiselect-dropdown>
                          <br>
                          <div class="form-check form-switch form-check-custom form-check-solid">
                            <input class="form-check-input" type="checkbox" id="bindTemplateCheck"/>
                            <label class="form-check-label">
                              Asociar plantillas
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-6">
                        <div class="col-lg-6 mb-6">
                          <button class="btn btn-primary font-weight-bolder" (click)="handleBindShops('filtered')">
                            <i class="las la-paste"></i>Asociar filtrados
                          </button>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <button class="btn btn-primary font-weight-bolder" (click)="handleBindShops('selected')">
                            <i class="las la-paste"></i>Asociar seleccionados
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion" id="accordionProductsSelected">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingSelectedProducts">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseProductsSelected"
                    aria-expanded="false" aria-controls="collapseProductsSelected">
                    Selección
                  </button>
                </h2>
                <div id="collapseProductsSelected" class="accordion-collapse collapse" aria-labelledby="headingProductsSelected"
                  data-bs-parent="#accordionProductsSelected">
                  <div class="accordion-body">
                    <form class="form-group">
                      <p>Artículos seleccionados: {{ selectedProductRows.size }}</p>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <div>
                        <!-- Queda pendiente la opción de seleccionar todos los filtrados, en caso de que la quieran utilizar para algo -->
                        <button hidden class="btn btn-primary font-weight-bolder" (click)="handleSelected('selectFiltered')">
                          SELECCIONAR TODOS LOS FILTRADOS
                        </button>
                        <span hidden>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <button class="btn btn-primary font-weight-bolder" style="margin-top: 10px; margin-bottom: 10px; margin-right: 20px;" (click)="handleSelected('deselect')">
                          Deseleccionar todo
                        </button>
                        <button class="btn btn-primary font-weight-bolder" (click)="handleSelected('filter')">
                          Filtrar por selección
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <!-- <div class="form-check form-switch form-check-custom form-check-solid" style="display: flex; justify-content: space-between;"> -->
            <div class="form-check form-switch form-check-custom form-check-solid"  style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px;">
              <div>
                <input class="form-check-input" type="checkbox" id="lineSelectCheck"/>
                <label class="form-check-label">
                  Seleccionar líneas
                </label>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <button *ngIf="!showAssignedProducts" class="btn btn-primary font-weight-bolder" (click)="showAssigned(true)">
                  <i class="las la-eye"></i>Mostrar asignados
                </button>
                <button *ngIf="showAssignedProducts" class="btn btn-primary font-weight-bolder" (click)="showAssigned(false)">
                  <i class="las la-eye"></i>Mostrar todo
                </button>
              </div>
              <!-- <div style="display: flex; align-items: center;"> -->
              <div style="display: grid; grid-template-columns: auto 1fr 1fr; align-items: center; gap: 20px;">
                <label>
                  Asignación copiada
                </label>
                <div class="col-lg-3 mb-6" style="width: 100%;">
                  <label>Plantilla:</label>
                  <ng-multiselect-dropdown 
                    #plantillaCopied 
                    id="plantillaCopied" 
                    name="plantillaCopied" 
                    [placeholder]="'Sin selección'"
                    class="multi-select" 
                    [settings]="dropdownSingleSettings"
                    [data]="templates"
                    [(ngModel)]="selectedTemplateCopied"
                    (onSelect)="handleCopiedChanges()">
                  </ng-multiselect-dropdown>
                </div>
                <div class="col-lg-3 mb-6" style="width: 100%;">
                  <label>Tiendas:</label>
                  <ng-multiselect-dropdown 
                    #shopsCopied 
                    id="shopsCopied" 
                    name="shopsCopied" 
                    [placeholder]="'Sin selección'"
                    class="multi-select" 
                    [settings]="dropdownSettingsLimit1"
                    [data]="stores"
                    [(ngModel)]="selectedShopsCopied"
                    (onSelect)="handleCopiedChanges()">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
            <br>
            <button hidden class="btn btn-primary font-weight-bolder" style="margin-top: 10px; margin-bottom: 10px; margin-right: 20px;" (click)="saveProductChanges()">
              <i class="las la-save"></i>Guardar cambios
            </button>
          </div>
          <div class="card-header" class="my-5 mx-5">
          </div>
          <div id="kt_datatable_productos_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div style="width: 100%; padding-left: -10px">
              <!-- <div class="table-responsive"> -->
              <div>
                <table id="kt_datatable_productos" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_productos_info" style="width:100%">
                  <thead>
                    <tr role="row">
                    </tr>
                  </thead>
                  <tbody>
                    <th tabindex="0" aria-controls="kt_datatable_productos" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th *ngFor="let store of stores" tabindex="0" aria-controls="kt_datatable_productos" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <!-- Tabla de plantillas -->
        <div class="tab-pane fade" id="tab_plantillas" role="tabpanel">
          <div class="card-header" class="my-5 mx-5">
            <div>
              <ng-multiselect-dropdown
                #templateSelector
                [placeholder]="'Plantilla'"
                [settings]="dropdownTemplateSettings"
                [data]="templates"
                [(ngModel)]="selectedTemplate"
                (onSelect)="changeTemplate()"
              >
              </ng-multiselect-dropdown>
              <button class="btn btn-primary font-weight-bolder" style="margin-top: 10px; margin-bottom: 10px; margin-right: 20px;" (click)="createPlantilla()">
                <i class="las la-plus"></i>Nueva plantilla
              </button>
              <app-dynamic-modal #dynamicModal></app-dynamic-modal>
              <button class="btn btn-primary font-weight-bolder" (click)="handleSaveTemplates()">
                <i class="las la-save"></i>Guardar distribución
              </button>
            </div>
          </div>
          <div id="kt_datatable_plantillas_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div style="width: 100%; padding-left: -10px;">
              <!-- <div class="table-responsive"> -->
              <div>
                <table id="kt_datatable_plantillas" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_plantillas_info" style="width:100%">
                  <thead>
                    <tr role="row">
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <th tabindex="0" aria-controls="kt_datatable_plantillas" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                        <th *ngFor="let index of indexes" tabindex="0" aria-controls="kt_datatable_plantillas" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <!-- Tabla de simular -->
        <div class="tab-pane fade" id="tab_simular" role="tabpanel">
          <div class="card-header" class="my-5 mx-5">
            <div class="col-sm">
              <!-- <app-advanced-search-simular
                [buscarButtonId] = "'searchSimulate'"
  
                [inputData]="inputData"
                [selectedMarca]="selectedMarca"
                [selectedTemporada]="selectedTemporada"
                [selectedFamilia]="selectedFamilia"
                [selectedComercial]="selectedComercial"
                [selectedManufacturer]="selectedManufacturer"
                [selectedTemplate]="selectedTemplateEdit"
  
                [marcas]="marcas"
                [temporadas]="temporadas"
                [familias]="familias"
                [comercial]="comercial"
                [manufacturer]="manufacturer"
                [templates]="templates"
  
                [dropdownSettings]="dropdownSettings"
  
                (onLimpiarFiltros)="limpiarFiltros()"
              ></app-advanced-search-simular> -->
              <div class="accordion" id="accordionSimulate">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                          aria-expanded="true" aria-controls="collapseOne">
                          Búsqueda avanzada
                      </button>
                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                      data-bs-parent="#accordionSimulate">
                    <div class="accordion-body">
                      <form class="form-group">
                        <div class="row mb-6">
                          <div class="col-lg-3 mb-6">
                            <label>Id artículo:</label>
                            <input class="form-control secondInput" type="text" id="items" [(ngModel)]="inputData" name="items"
                                placeholder="¿Más de uno?, separa con comas">
                          </div>
                          <div class="col-lg-3 mb-6">
                            <label>Marca:</label>
                            <ng-multiselect-dropdown name="marca" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="marcas"
                                [(ngModel)]="selectedMarca">
                            </ng-multiselect-dropdown>
                            <!-- <input hidden id="selectedMarcaSimulate" value="{{selectedMarca}}"> -->
                          </div>
                          <div class="col-lg-3 mb-6">
                            <label>Temporada:</label>
                            <ng-multiselect-dropdown name="temporada" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="temporadas"
                                [(ngModel)]="selectedTemporada">
                            </ng-multiselect-dropdown>
                          </div>
                          <div class="col-lg-3 mb-6">
                            <label>Familia:</label>
                            <ng-multiselect-dropdown id="familia" name="familia" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="familias"
                                [(ngModel)]="selectedFamilia">
                            </ng-multiselect-dropdown>
                          </div>
                          <div class="col-lg-3 mb-6">
                            <label>Comercial:</label>
                            <ng-multiselect-dropdown id="comercial" name="comercial" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="comercial"
                                [(ngModel)]="selectedComercial">
                            </ng-multiselect-dropdown>
                          </div>
                          <div class="col-lg-3 mb-6">
                            <label>Proveedor:</label>
                            <ng-multiselect-dropdown id="manufacturer" name="manufacturer"
                                [placeholder]="'Sin selección'" class="multi-select" [settings]="dropdownSettings"
                                [data]="manufacturer" [(ngModel)]="selectedManufacturer">
                            </ng-multiselect-dropdown>
                          </div>
                          <div class="col-lg-3 mb-6" *ngIf="templates.length > 0">
                            <label>Plantilla:</label>
                            <ng-multiselect-dropdown id="plantilla" name="plantilla" [placeholder]="'Sin selección'"
                                class="multi-select" [settings]="dropdownSettings" [data]="templates"
                                [(ngModel)]="selectedTemplateEdit">
                            </ng-multiselect-dropdown>
                        </div>
                        </div>
                        <input type="submit" style="margin-top: 10px; margin-bottom: 10px; margin-right: 20px;"
                            class="btn btn-primary" id="searchSimulate" value="Buscar">
                        <button class="btn btn-primary font-weight-bolder" (click)="limpiarFiltros()">
                            <i class="las la-broom"></i>Limpiar filtros
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionSimulateSelected">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSelectedSimulate">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSimulateSelected"
                      aria-expanded="false" aria-controls="collapseSimulateSelected">
                      Selección
                    </button>
                  </h2>
                  <div id="collapseSimulateSelected" class="accordion-collapse collapse" aria-labelledby="headingSimulateSelected"
                    data-bs-parent="#accordionSimulateSelected">
                    <div class="accordion-body">
                      <form class="form-group">
                        <p>Artículos seleccionados: {{ selectedSimulateRows.size }}</p>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <div>
                          <!-- Queda pendiente la opción de seleccionar todos los filtrados, en caso de que la quieran utilizar para algo -->
                          <button hidden class="btn btn-primary font-weight-bolder" (click)="handleSelected('selectFiltered')">
                            SELECCIONAR TODOS LOS FILTRADOS
                          </button>
                          <span hidden>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <button class="btn btn-primary font-weight-bolder" style="margin-top: 10px; margin-bottom: 10px; margin-right: 20px;" (click)="handleSelected('deselectSimulate')">
                            Deseleccionar todo
                          </button>
                          <button class="btn btn-primary font-weight-bolder" (click)="handleSelected('filterSimulate')">
                            Filtrar por selección
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion" id="accordionOperationsSelected">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSelectedOperations">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOperationsSelected"
                      aria-expanded="false" aria-controls="collapseOperationsSelected">
                      Operaciones
                    </button>
                  </h2>
                  <div id="collapseOperationsSelected" class="accordion-collapse collapse" aria-labelledby="headingOperationsSelected"
                    data-bs-parent="#accordionOperationsSelected">
                    <div class="accordion-body">
                      <form class="form-group">
                        <div>
                          <!-- Queda pendiente la opción de seleccionar todos los filtrados, en caso de que la quieran utilizar para algo -->
                          <button class="btn btn-primary font-weight-bolder" hidden (click)="predistribute('new')">
                            Nueva predistribución
                          </button>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <button class="btn btn-primary font-weight-bolder" (click)="predistribute('selected')">
                            Simular selección
                          </button>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <button class="btn btn-primary font-weight-bolder" (click)="predistribute('filtered')">
                            Simular filtrados
                          </button>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <button class="btn btn-primary font-weight-bolder" (click)="predistribute('selectedDesglose')">
                            Desglosar seleccionados
                          </button>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <button class="btn btn-primary font-weight-bolder" (click)="predistribute('filteredDesglose')">
                            Desglosar filtrados
                          </button>
                          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                          <button class="btn btn-primary font-weight-bolder" hidden (click)="prueba()">
                            OTRA OPERACIÓN
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <br>
              <div class="form-check form-switch form-check-custom form-check-solid"  style="display: grid; grid-template-columns: 1fr 1fr; gap: 20px;">
                <div>
                  <input class="form-check-input" type="checkbox" id="lineSelectCheckSimulate"/>
                  <label class="form-check-label">
                    Seleccionar líneas
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div id="kt_datatable_simular_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div style="width: 100%; padding-left: -10px;">
              <!-- <div class="table-responsive"> -->
              <div class="card-toolbar">
                <!-- Navegador -->
                <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                  <ng-container>
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#tab_mujer" (click)="drawTable('simular_mujer')">Mujer</a>
                    </li>
                  </ng-container>
                  <ng-container>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#tab_hombre" (click)="drawTable('simular_hombre')">Hombre</a>
                    </li>
                  </ng-container>
                  <ng-container>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#tab_kids" (click)="drawTable('simular_kids')">Kids</a>
                    </li>
                  </ng-container>
                  <ng-container>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#tab_unisex" (click)="drawTable('simular_unisex')">Unisex</a>
                    </li>
                  </ng-container>
                </ul>
              </div>
              <div class="tab-content" id="mySecondTabContent">
                <!-- Tabla de mujer -->
                <div class="tab-pane fade active show" id="tab_mujer" role="tabpanel">
                  <div>
                    <table id="kt_datatable_simular_mujer" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_simular_mujer_info" style="width:100%">
                      <thead>
                        <tr role="row">
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <th tabindex="0" aria-controls="kt_datatable_simular" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th *ngFor="let index of indexes" tabindex="0" aria-controls="kt_datatable_simular" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Tabla de hombre -->
                <div class="tab-pane fade" id="tab_hombre" role="tabpanel">
                  <div>
                    <table id="kt_datatable_simular_hombre" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_simular_hombre_info" style="width:100%">
                      <thead>
                        <tr role="row">
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <th tabindex="0" aria-controls="kt_datatable_simular_hombre" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular_hombre" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular_hombre" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th *ngFor="let index of indexes" tabindex="0" aria-controls="kt_datatable_simular_hombre" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular_hombre" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Tabla de kids -->
                <div class="tab-pane fade" id="tab_kids" role="tabpanel">
                  <div>
                    <table id="kt_datatable_simular_kids" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_simular_kids_info" style="width:100%">
                      <thead>
                        <tr role="row">
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <th tabindex="0" aria-controls="kt_datatable_simular_kids" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular_kids" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular_kids" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th *ngFor="let index of indexes" tabindex="0" aria-controls="kt_datatable_simular_kids" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular_kids" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- Tabla de unisex -->
                <div class="tab-pane fade" id="tab_unisex" role="tabpanel">
                  <div>
                    <table id="kt_datatable_simular_unisex" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_simular_unisex_info" style="width:100%">
                      <thead>
                        <tr role="row">
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                            <th tabindex="0" aria-controls="kt_datatable_simular_unisex" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular_unisex" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular_unisex" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th *ngFor="let index of indexes" tabindex="0" aria-controls="kt_datatable_simular_unisex" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_simular_unisex" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<app-scroll-to-top></app-scroll-to-top>

<ng-template #simularSelectedModal let-modal>
  <div class="modal-header">
      <h4 id="modal-title" class="modal-title">Predistribución</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div id="modal-body" class="modal-body">
  <!-- TODO: Añadir una datatable aquí para desplegar la información de la selección que se quiere simular. Además, añadir los botones de descargar como csv, con los datos del pedido y tal -->
    <div id="kt_datatable_selections_simulate_wrapper" class="dataTables_wrapper dt-bootstrap4">
      <div style="width: 100%; padding-left: -10px;">
        <!-- <div class="table-responsive"> -->
        <div>
          <table id="kt_datatable_selections_simulate" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_selections_simulate_info" style="width:100%">
            <thead>
              <tr role="row">
              </tr>
            </thead>
            <tbody>
                <tr>
                  <th tabindex="0" aria-controls="kt_datatable_selections_simulate" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                  <th *ngFor="let index of indexes" tabindex="0" aria-controls="kt_datatable_selections_simulate" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
      <button (click)="getInfoCsv()" id="download-csv" type="button" class="btn btn-primary">Descargar CSV</button>
  </div>
</ng-template>
<ng-template #simularFilteredModal let-modal>
  <div class="modal-header">
      <h4 id="modal-title" class="modal-title">Predistribución</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div id="modal-body" class="modal-body">
  <!-- TODO: Añadir una datatable aquí para desplegar la información de la selección que se quiere simular. Además, añadir los botones de descargar como csv, con los datos del pedido y tal -->
    <div id="kt_datatable_filtered_simulate_wrapper" class="dataTables_wrapper dt-bootstrap4">
      <div style="width: 100%; padding-left: -10px;">
        <!-- <div class="table-responsive"> -->
        <div>
          <table id="kt_datatable_filtered_simulate" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_filtered_simulate_info" style="width:100%">
            <thead>
              <tr role="row">
              </tr>
            </thead>
            <tbody>
                <tr>
                  <th tabindex="0" aria-controls="kt_datatable_filtered_simulate" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                  <th *ngFor="let index of indexes" tabindex="0" aria-controls="kt_datatable_filtered_simulate" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Cerrar</button>
      <button (click)="getInfoCsv()" id="download-csv" type="button" class="btn btn-primary">Descargar CSV</button>
  </div>
</ng-template>
<angular2csv hidden [options]="optionsCsv" class="dropdown-item" id="angular2Csv" [data]="linesForCsv"
filename="predistribucion_{{now.toISOString()}}">
</angular2csv>
<angular2csv hidden [options]="optionsCsvTotals" class="dropdown-item" id="angular2CsvTotals" [data]="linesForCsvTotals"
filename="predistribucion_total_{{now.toISOString()}}">
</angular2csv>