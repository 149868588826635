export class ListItem {
    id: String | number;
    text: String | number;

    constructor(id: string | number, text: string | number) {
        {
          this.id = id;
          this.text = text;
        }
    }
}