import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { Order } from './Order.model';
import { OrderLines } from './OrderLines.model';
import { OrderLinesDiscounts } from './OrderLinesDiscounts.model.';
import { OrderTotalDiscounts } from './OrderTotalDiscunts.model';
import { OrderCharges } from './OrderCharges.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

//! Importante 
@Component({
  selector: 'app-rehacer-pedidos',
  templateUrl: './rehacer-pedidos.component.html',
  styleUrls: ['./rehacer-pedidos.component.scss']
})
export class RehacerPedidosComponent implements OnInit {
  public numOrders: any[] = []
  public numOrdersPlatform: any[] = []
  public order: any[] = []

  public orderBody: Order

  public orderLinesUpdate: OrderLines[] = []
  public orderLinesCreate: OrderLines[] = []

  public totalDiscounts: OrderTotalDiscounts[] = []
  public charges: OrderCharges[] = []

  public orderDiscountCreate: OrderTotalDiscounts[] = []
  public orderChargeCreate: OrderCharges[] = []

  public row: any

  public externalPlatformId: number

  public externalPlatforms: any[] = []
  public articlesPlatform: any[] = []

  public itemSizes: any[] = []

  public orderLoaded = false

  public oldItem = ''

  public nuevoPedido = true

  public listShops: any[] = []
  public listShippingService: any[] = []

  isDropdownDisabled = false;

  selectedUpdatePlatform = true;

  myForm:FormGroup;

  selectedOrder: any[] = []
  @ViewChild('orderSelection') orderselection:MultiSelectComponent;

  selectedExternalPlatformFirst: any[] = []
  @ViewChild('externalPlatformSelection') externalPlatformSelection:MultiSelectComponent;

  selectedExternalPlatform: any[] = []
  @ViewChild('platformSelection') platformSelection:MultiSelectComponent;

  selectedExtPlatItem: any[] = []
  @ViewChild('extPlatItemSelection') extPlatItemSelection:MultiSelectComponent;

  selectedExtPlatItemEdit: any[] = []
  @ViewChild('extPlatItemSelectionEdit') extPlatItemSelectionEdit:MultiSelectComponent;

  selectedItemSize: any[] = []
  @ViewChild('sizeIndexSelection') sizeIndexSelection:MultiSelectComponent;

  selectedItemSizeEdit: any[] = []
  @ViewChild('sizeIndexSelectionEdit') sizeIndexSelectionEdit:MultiSelectComponent;

  selectedShop: any[] = []
  @ViewChild('shippingShop') shopSelection:MultiSelectComponent;

  dropdownOrder:IDropdownSettings = {};
  dropdownSingle:IDropdownSettings = {};

  dtOptions: DataTables.Settings = {};
  dtOptionsDiscounts: DataTables.Settings = {};
  dtOptionsCharges: DataTables.Settings = {};

  private timeoutId!: any
  componentDestroyed$: Subject<boolean> = new Subject()

  private unsubscribe: Subscription[] = [];

  tableLines: DataTables.Api
  tableDiscounts: DataTables.Api
  tableCharges: DataTables.Api

  constructor(private appService: AppService, private ref: ChangeDetectorRef, private fb: FormBuilder) { }

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE

  //! GETS
  //#region GETS
  /**
   * Comprueba las tallas para el tipo de operación requerida
   * @param type 
   */
  checkSizes(type: string = '') {
    switch (type) {
      case "edit":
        if (this.selectedExtPlatItemEdit.length > 0) {
          let itemId = this.selectedExtPlatItemEdit[0].id

          const appServiceSubscription = this.appService.getItemSizes(this.selectedExternalPlatform[0].id, itemId).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
            this.itemSizes = res

            if (this.oldItem != itemId) {
              this.selectedItemSizeEdit = []
              $("#activePriceEdit").val("")
              $("#originalPriceEdit").val("")
            }
    
            this.ref.detectChanges()
          }, err => {
            console.log(err)
          })

          this.unsubscribe.push(appServiceSubscription);
        }
        break;
      default:
        if (this.selectedExtPlatItem.length > 0) {
          let itemId = this.selectedExtPlatItem[0].id
    
          const appServiceSubscription = this.appService.getItemSizes(this.selectedExternalPlatform[0].id, itemId).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
            this.itemSizes = res
            this.selectedItemSize = []
    
            this.ref.detectChanges()
          }, err => {
            console.log(err)
          })

          this.unsubscribe.push(appServiceSubscription);
        }
        break;
    }
  }

  /**
   * Accede a la información de número de pedidoss
   */
  getNumOrders() {
    const appServiceSubscription = this.appService.getNumOrders().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.numOrders = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })

      this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a la información de números de pedidos por plataforma externa
   * @param externalPlat 
   * @param limit 
   */
  getNumOrdersPlatform(externalPlat: number, limit: number = 0) {
    const appServiceSubscription = this.appService.getNumOrdersPlatform(externalPlat, limit).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.numOrdersPlatform = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a la información del pedido requerido
   * @param orderId 
   */
  getOrder(orderId: string = this.selectedOrder.length > 0 ? this.selectedOrder[0].value: "") {
    if (orderId == "") {
      Swal.fire('Debes seleccionar un pedido', '', 'info')
    }
    else {
      this.nuevoPedido = false
      const appServiceSubscription = this.appService.getOrder(orderId).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          this.order = res
          
          this.loadOrder(res)
  
          this.ref.detectChanges()
        }, err => {
          console.log(err)
        })

      this.unsubscribe.push(appServiceSubscription);
    }
  }

  /**
   * Accede a la información de tiendas
   */
  getAllTiendasPedidos() {
    const appServiceSubscription = this.appService.getAllTiendasPedidos().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.listShops = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
 * Obtiene las plataformas externas
 */
  getExternalPlatforms() {
    const appServiceSubscription = this.appService.getExternalPlatforms().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.externalPlatforms = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Obtiene los artículos para la plataforma seleccionada
   * @param idExternalPlat 
   */
  getArticlesExternalPlatform(idExternalPlat: any = null) {
    const appServiceSubscription = this.appService.getArticlesExternalPlatform(idExternalPlat).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.articlesPlatform = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  //#endregion GETS

  //! POSTS
  //#region POSTS
  /**
   * Añade o actualiza las lines
   * @param operation 
   */
  addLine(operation:string) {
    let externalId = $("#externalIdAdd").val()
    let externalPlatItem = this.selectedExtPlatItem.length > 0 ? this.selectedExtPlatItem[0].id : null
    let sizeIndex = this.selectedItemSize.length > 0 ? this.selectedItemSize[0].id : null
    let originalPrice = Math.round($("#originalPriceAdd").val() * 100)
    let activePrice = Math.round($("#activePriceAdd").val() * 100)
    let discountAdd = $("#discountAdd").val() ? Math.round($("#discountAdd").val() * 100) : 0

    if (!externalPlatItem && !sizeIndex && originalPrice == 0 && activePrice == 0) {
      Swal.fire({
        title: 'Ooops',
        text: 'Debes completar todos los campos con asterisco.',
        icon: 'warning',
      })
    } else {
      let discounts: OrderLinesDiscounts[] = []

      discounts.push(new OrderLinesDiscounts(
        discountAdd,
        0
      ))
  
      this.orderLinesCreate.push(new OrderLines(
        externalId,
        externalPlatItem,
        sizeIndex,
        originalPrice,
        activePrice,
        discounts
      ))
  
      this.orderLinesUpdate.push(new OrderLines(
        externalId,
        externalPlatItem,
        sizeIndex,
        originalPrice,
        activePrice,
        discounts
      ))

      switch (operation) {
        case "create":
          // this.cleanInputs()
          
          this.tableLines.ajax.url(`${environment.portalHost}api/returnOrderLines/` + JSON.stringify(this.orderLinesCreate)).load();
          // this.tableLines.ajax.url(`https://ekonnect2.krack.es/api/returnOrderLines/` + JSON.stringify(this.orderLinesCreate)).load();
          // this.tableLines.ajax.url(`http://localhost:4200/api/returnOrderLines/` + JSON.stringify(this.orderLinesCreate)).load();
  
          this.timeoutId = setTimeout(() => {
            this.activateEditDelete()
          }, 500);
          break;
        case "update":
          this.orderBody.Lines = this.orderLinesUpdate

          const appServiceSubscription = this.appService.updateOrderBody(JSON.stringify(this.orderBody), this.selectedOrder[0].id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
              //? ¿Por qué estaba esto aquí?
              // this.cleanInputs()
  
              this.getOrder()
  
              this.ref.detectChanges()
            }, err => {
              console.log(err)
            })

          this.unsubscribe.push(appServiceSubscription);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Añade o actualiza los descuentos
   * @param operation 
   */
  addDiscount(operation: string) {
    let discountType = $("#discountType").val()
    let discountAmount = Number($("#discountAmount").val())
    let discountCode = $("#discountCode").val()
    let discountReason = $("#discountReason").val()

    if (!discountType || discountAmount == 0 || discountAmount == null || discountCode == null) {
      Swal.fire({
        title: 'Ooops',
        text: 'Debes completar correctamente todos los campos.',
        icon: 'warning',
      })
    } else {
      this.totalDiscounts.push(new OrderTotalDiscounts(
        discountType,
        discountAmount,
        discountCode,
        discountReason
      ))

      switch (operation) {
        case "create":
          // this.cleanInputs()
          
          this.tableLines.ajax.url(`${environment.portalHost}api/returnOrderLines/` + JSON.stringify(this.orderLinesCreate)).load();
          // this.tableLines.ajax.url(`https://ekonnect2.krack.es/api/returnOrderLines/` + JSON.stringify(this.orderLinesCreate)).load();
          // this.tableLines.ajax.url(`http://localhost:4200/api/returnOrderLines/` + JSON.stringify(this.orderLinesCreate)).load();

          this.timeoutId = setTimeout(() => {
            this.activateEditDelete()
          }, 500);
          break;
        case "update":
          this.orderBody.TotalDiscounts = this.totalDiscounts

          const appServiceSubscription = this.appService.updateOrderBody(JSON.stringify(this.orderBody), this.selectedOrder[0].id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
              //? ¿Por qué estaba esto aquí?
              // this.cleanInputs()
          
              this.getOrder()
              this.totalDiscounts = []

              this.ref.detectChanges()
            }, err => {
              console.log(err)
            })
          
          this.unsubscribe.push(appServiceSubscription);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Añade o actualiza los charges
   * @param operation 
   */
  addCharges(operation: string) {
    let chargeDescription = $("#chargeDescription").val()
    let chargeAmount = Number($("#chargeAmount").val())

    if (!chargeDescription || chargeAmount == 0 || chargeAmount == null) {
      Swal.fire({
        title: 'Ooops',
        text: 'Debes completar correctamente todos los campos.',
        icon: 'warning',
      })
    } else {
      this.charges.push(new OrderCharges(
        chargeDescription,
        chargeAmount
      ))

      switch (operation) {
        case "create":
          // this.cleanInputs()
          
          this.tableLines.ajax.url(`${environment.portalHost}api/returnOrderLines/` + JSON.stringify(this.orderLinesCreate)).load();
          // this.tableLines.ajax.url(`https://ekonnect2.krack.es/api/returnOrderLines/` + JSON.stringify(this.orderLinesCreate)).load();
          // this.tableLines.ajax.url(`http://localhost:4200/api/returnOrderLines/` + JSON.stringify(this.orderLinesCreate)).load();

          this.timeoutId = setTimeout(() => {
            this.activateEditDelete()
          }, 500);
          break;
        case "update":
          this.orderBody.Charges = this.charges

          const appServiceSubscription = this.appService.updateOrderBody(JSON.stringify(this.orderBody), this.selectedOrder[0].id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
              //? ¿Por qué estaba esto aquí?
              // this.cleanInputs()

              this.getOrder()

              this.ref.detectChanges()
            }, err => {
              console.log(err)
            })
          this.unsubscribe.push(appServiceSubscription);
          break;
        default:
          break;
      }
    }
  }

  /**
   * !Todavía no está en uso
   * Inserta el nuevo pedido en la tabla
   */
  insertOrder() {
    Swal.fire('Esta función no está habilitada todavía', '', 'info')
    //! PANTALLA DE CABECERA
    let externalId = $("#externalId").val()
    let marketId = $("#marketId").val()
    let origin = $("#origin").val()
    let orderDateTime = $("#orderDateTime").val()
    let eMailAddress = $("#customerEmail").val()
    let phone = $("#phone").val()
    let shippingMode = $("#shippingMode").val()
    let shippingService = $("#shippingService").val()
    let shippingShop = $("#shippingShop").val()
    let shippingServiceAux = $("#shippingServiceAux").val()

    //! PANTALLA DE DIRECCIÓN DE ENVÍO Y FACTURACIÓN
    let invoiceAddressee1 = $("#invoiceName").val()
    let invoiceAddressee2 = $("#invoiceSurname").val()
    let invoiceAddress1 = $("#invoiceAddress1").val()
    let invoiceAddress2 = $("#invoiceAddress2").val()
    let invoiceCity = $("#invoiceCity").val()
    let invoiceZipCode = $("#invoiceZipCode").val()
    let invoiceProvince = $("#invoiceProvince").val()
    let invoiceCountryId = $("#invoiceCountryId").val()

    let shippingAddressee1 = $("#shippingName").val()
    let shippingAddressee2 = $("#shippingSurname").val()
    let shippingAddress1 = $("#shippingAddress1").val()
    let shippingAddress2 = $("#shippingAddress2").val()
    let shippingCity = $("#shippingCity").val()
    let shippingZipCode = $("#shippingZipCode").val()
    let shippingProvince = $("#shippingProvince").val()
    let shippingCountryId = $("#shippingCountryId").val()
    let shippingPhone = $("#shippingPhone").val()

    let externalPaymentMethod = $("#externalPaymentMethod").val()
    let shippingCharge;

    console.log(this.orderBody)

    // this.orderBody = new Order(
    //   externalId,
    //   marketId,
    //   origin,
    //   orderDateTime,
    //   eMailAddress,
    //   phone,
    //   shippingMode,
    //   "",
    //   shippingService,
    //   shippingShop,
    //   shippingServiceAux,
    //   orderDateTime,
    //   "",
    //   "",
    //   invoiceAddressee1,
    //   invoiceAddressee2,
    //   "",
    //   "",
    //   "",
    //   "",
    //   invoiceAddress1,
    //   invoiceAddress2,
    //   invoiceZipCode,
    //   invoiceCity,
    //   invoiceProvince,
    //   invoiceCountryId,
    //   "",
    //   shippingAddressee1,
    //   shippingAddressee2,
    //   shippingAddress1,
    //   shippingAddress2,
    //   "",
    //   "",
    //   "",
    //   shippingZipCode,
    //   shippingCity,
    //   shippingProvince,
    //   shippingCountryId,
    //   shippingPhone,
    //   "",
    //   externalPaymentMethod,
    //   shippingCharge,
    //   orderLines,
    //   totalDiscounts,
    //   charges,
    //   isGuest,
    //   sgCustomerEmail,
    //   sgCustomerPhone,
    //   sgCustomerName,
    //   sgCustomerLastname,
    //   checkPrivacy
    // )

    if (this.nuevoPedido) {
      console.log("Holis")
    }
  }

  /**
   * Actualiza el pedido en la tabla
   */
  editOrder() {
    //! PANTALLA DE CABECERA
    let externalId = $("#externalId").val()
    externalId = externalId.includes("R1") ? externalId : externalId + "-R1"
    let marketId = $("#marketId").val()
    marketId = marketId.includes("R1") ? marketId :  marketId + "-R1"
    let origin = $("#origin").val()
    let orderDateTime = $("#orderDateTime").val()
    let eMailAddress = $("#customerEmail").val()
    let phone = $("#phone").val()
    let shippingMode = $("#shippingMode").val()
    let shippingHandler = this.orderBody.ShippingHandler
    let shippingService = $("#shippingService").val()
    let shippingShop = this.selectedShop.length > 0 ? this.selectedShop[0].id : -1
    let shippingServiceAux = this.orderBody.ShippingServiceAux
    let requestedDeliveryDate = this.orderBody.RequestedDeliveryDate
    let paymentRef = this.orderBody.PaymentRef
    let invoiceCompanyName = this.orderBody.InvoiceCompanyName

    //! PANTALLA DE DIRECCIÓN DE ENVÍO Y FACTURACIÓN
    let invoiceAddressee1 = $("#invoiceName").val()
    let invoiceAddressee2 = $("#invoiceSurname").val()
    let invoiceAddressComplement1 = this.orderBody.InvoiceAddressComplement1
    let invoiceAddressComplement2 = this.orderBody.InvoiceAddressComplement2
    let invoiceAddressComplement3 = this.orderBody.InvoiceAddressComplement3
    let invoiceFiscalNumber = this.orderBody.InvoiceFiscalNumber
    let invoiceAddress1 = $("#invoiceAddress1").val()
    let invoiceAddress2 = $("#invoiceAddress2").val()
    let invoiceCity = $("#invoiceCity").val()
    let invoiceZipCode = $("#invoiceZipCode").val()
    let invoiceProvince = $("#invoiceProvince").val()
    let invoiceCountryId = $("#invoiceCountryId").val()

    let shippingCompanyName = this.orderBody.ShippingCompanyName
    let shippingAddressee1 = $("#shippingName").val()
    let shippingAddressee2 = $("#shippingSurname").val()
    let shippingAddress1 = $("#shippingAddress1").val()
    let shippingAddress2 = $("#shippingAddress2").val()
    let shippingAddressComplement1 = this.orderBody.ShippingAddressComplement1
    let shippingAddressComplement2 = this.orderBody.ShippingAddressComplement2
    let shippingAddressComplement3 = this.orderBody.ShippingAddressComplement3
    let shippingCity = $("#shippingCity").val()
    let shippingZipCode = $("#shippingZipCode").val()
    let shippingProvince = $("#shippingProvince").val()
    let shippingCountryId = $("#shippingCountryId").val()
    let shippingPhone = $("#shippingPhone").val()
    let shippingNote = this.orderBody.ShippingNote

    let externalPaymentMethod = $("#externalPaymentMethod").val()
    let shippingCharge = this.orderBody.ShippingCharge
    let orderLines = this.orderBody.Lines
    let totalDiscounts = this.orderBody.TotalDiscounts
    let charges = this.orderBody.Charges
    let isGuest = this.orderBody.Is_guest
    let sgCustomerEmail = this.orderBody.Sg_customer_mail
    let sgCustomerPhone = this.orderBody.Sg_customer_phone
    let sgCustomerName = this.orderBody.Sg_customer_name
    let sgCustomerLastname = this.orderBody.Sg_customer_lastname
    let checkPrivacy = this.orderBody.check_privacy

    this.orderBody = new Order(
      externalId,
      marketId,
      origin,
      orderDateTime,
      eMailAddress,
      phone,
      shippingMode,
      shippingHandler,
      shippingService,
      shippingShop,
      shippingServiceAux,
      requestedDeliveryDate,
      paymentRef,
      invoiceCompanyName,
      invoiceAddressee1,
      invoiceAddressee2,
      invoiceAddressComplement1,
      invoiceAddressComplement2,
      invoiceAddressComplement3,
      invoiceFiscalNumber,
      invoiceAddress1,
      invoiceAddress2,
      invoiceZipCode,
      invoiceCity,
      invoiceProvince,
      invoiceCountryId,
      shippingCompanyName,
      shippingAddressee1,
      shippingAddressee2,
      shippingAddress1,
      shippingAddress2,
      shippingAddressComplement1,
      shippingAddressComplement2,
      shippingAddressComplement3,
      shippingZipCode,
      shippingCity,
      shippingProvince,
      shippingCountryId,
      shippingPhone,
      shippingNote,
      externalPaymentMethod,
      shippingCharge,
      orderLines,
      totalDiscounts,
      charges,
      isGuest,
      sgCustomerEmail,
      sgCustomerPhone,
      sgCustomerName,
      sgCustomerLastname,
      checkPrivacy
    )

    const requiredFieldString = [
      externalId, marketId, origin, eMailAddress, orderDateTime,
      externalPaymentMethod, shippingService, phone,
      shippingAddressee1, shippingAddressee2, shippingAddress1,
      shippingZipCode, shippingCity, shippingProvince, shippingCountryId,
      shippingPhone, invoiceAddressee1, invoiceAddressee2, invoiceAddress1,
      invoiceZipCode, invoiceCity, invoiceProvince, invoiceCountryId
    ];

    const requiredFieldNumber = [
      shippingShop
    ]
  
    const allStringFieldsFilled = requiredFieldString.every(field => field !== "");
    const allNumberFieldsFilled = requiredFieldNumber.every(field => field >= 0);

    const orderData = "('" + marketId + "', " + this.externalPlatformId + ", " + this.order[0].entregado + ", " + this.order[0].gestionado + ", " + this.order[0].estado + ", " + this.order[0].estado_avelon + ", " + this.order[0].estado_market + ", '" +  JSON.stringify(this.orderBody) + "')";

    if (allStringFieldsFilled && allNumberFieldsFilled) {
      const appServiceSubscription = this.appService.insertEditedOrder(orderData).pipe(takeUntil(this.componentDestroyed$)).subscribe((res: any) => {
        this.getOrder(marketId)
        this.ref.detectChanges()
      }, (err: any) => {
        console.log(err)
      })

      this.unsubscribe.push(appServiceSubscription);
    } else {
      Swal.fire({
        title: 'Ooops',
        text: 'Debes completar todos los campos con asterisco.',
        icon: 'warning',
      })
    }
  }
  //#endregion POSTS

  //! PUTS
  //#region PUTS
  /**
   * Edita los datos de la line
   */
  editLine() {
    let externalId = $("#externalIdEdit").val()
    let externalPlatItem = this.selectedExtPlatItemEdit.length > 0 ? this.selectedExtPlatItemEdit[0].id : null
    let sizeIndex = this.selectedItemSizeEdit.length > 0 ? this.selectedItemSizeEdit[0].id : null
    let activePrice = Math.round($("#activePriceEdit").val() * 100)
    let originalPrice = Math.round($("#originalPriceEdit").val() * 100)
    let discountAdd = $("#discountEdit").val() ? Math.round($("#discountEdit").val() * 100) : 0

    if (!externalPlatItem || !sizeIndex || !originalPrice || !activePrice) {
      Swal.fire({
        title: 'Ooops',
        text: 'Debes completar todos los campos con asterisco.',
        icon: 'warning',
      })
    } else {
      let index = this.orderBody.Lines.map((e: { ExternalId: any; }) => e.ExternalId).indexOf(externalId)
      this.orderBody.Lines.splice(index,1)

      let discounts: OrderLinesDiscounts[] = []

      discounts.push(new OrderLinesDiscounts(
        discountAdd,
        0
      ))

      this.orderLinesCreate.push(new OrderLines(
        externalId,
        externalPlatItem,
        sizeIndex,
        originalPrice,
        activePrice,
        discounts
      ))

      this.orderLinesUpdate.push(new OrderLines(
        externalId,
        externalPlatItem,
        sizeIndex,
        originalPrice,
        activePrice,
        discounts
      ))

      this.orderBody.Lines = this.orderLinesUpdate
  
      const appServiceSubscription = this.appService.updateOrderBody(JSON.stringify(this.orderBody), this.selectedOrder[0].id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          //? ¿Por qué estaba esto aquí?
          // this.cleanInputs()

          this.getOrder()

          this.ref.detectChanges()
        }, err => {
          console.log(err)
        })
      
      this.unsubscribe.push(appServiceSubscription);
    }
  }
  //#endregion PUTS
  
  //! DELETES
  //#region DELETES
  /**
   * Se encarga del borrado de lines
   * @param id 
   */
  deleteLine(id: string) {
    Swal.fire({
      title: '¿Borrar?',
      text: '¿Quieres borrar el artículo del pedido?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.orderLoaded) {
          let index = this.orderBody.Lines.findIndex((e: { ExternalId: any; }) => e.ExternalId === id);
          this.orderBody.Lines.splice(index, 1)
  
          const orderBodyString = JSON.stringify(this.orderBody);
          const appServiceSubscription = this.appService.updateOrderBody(orderBodyString, this.selectedOrder[0].id).pipe(takeUntil(this.componentDestroyed$)).subscribe((res: any) => {
              this.getOrder()
              this.ref.detectChanges()
            }, (err: any) => {
              console.log(err);
            });
          this.unsubscribe.push(appServiceSubscription);
        } else {
          let index = this.orderLinesCreate.findIndex((e: { ExternalId: any; }) => e.ExternalId === id);
          this.orderLinesCreate.splice(index, 1)
          
          const orderLinesCreateString = JSON.stringify(this.orderLinesCreate);
          this.tableLines.ajax.url(`${environment.portalHost}api/returnOrderLines/` + orderLinesCreateString).load()
          // this.tableLines.ajax.url(`https://ekonnect2.krack.es/api/returnOrderLines/` + orderLinesCreateString).load()
          // this.tableLines.ajax.url(`http://localhost:4200/api/returnOrderLines/` + orderLinesCreateString).load()
        }
        Swal.fire('¡Borrado!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('No se borró el artículo', '', 'info')
      }
    })
  }

  /**
   * Se encarga del borrado de descuentos
   * @param code 
   */
  deleteDiscount(code: string) {
    Swal.fire({
      title: '¿Borrar?',
      text: '¿Quieres borrar el descuento del pedido?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.orderLoaded) {
          let index = this.orderBody.TotalDiscounts.findIndex((e: { Code: any; }) => e.Code === code);
          this.orderBody.TotalDiscounts.splice(index, 1)
  
          const orderBodyString = JSON.stringify(this.orderBody);

          const appServiceSubscription = this.appService.updateOrderBody(orderBodyString, this.selectedOrder[0].id).pipe(takeUntil(this.componentDestroyed$)).subscribe((res: any) => {
              this.getOrder()
              this.ref.detectChanges()
            }, (err: any) => {
              console.log(err);
            });
            this.unsubscribe.push(appServiceSubscription);
        } else {
          let index = this.orderDiscountCreate.findIndex((e: { Code: any; }) => e.Code === code);
          this.orderDiscountCreate.splice(index, 1)
          
          const orderDiscountCreateString = JSON.stringify(this.orderDiscountCreate);
          this.tableLines.ajax.url(`${environment.portalHost}api/returnOrderDiscounts/` + orderDiscountCreateString).load()
          // this.tableLines.ajax.url(`https://ekonnect2.krack.es/api/returnOrderDiscounts/` + orderDiscountCreateString).load()
          // this.tableLines.ajax.url(`http://localhost:4200/api/returnOrderDiscounts/` + orderDiscountCreateString).load()
        }
        Swal.fire('¡Borrado!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('No se borró el descuento', '', 'info')
      }
    })
  }

  /**
   * Se encarga del borrado de charges
   * @param amount 
   */
  deleteCharge(amount: string) {
    Swal.fire({
      title: '¿Borrar?',
      text: '¿Quieres borrar el descuento del pedido?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.orderLoaded) {
          let index = this.orderBody.Charges.findIndex((e: { Amount: any; }) => e.Amount === amount);
          this.orderBody.Charges.splice(index, 1)
  
          const orderBodyString = JSON.stringify(this.orderBody);

          const appServiceSubscription = this.appService.updateOrderBody(orderBodyString, this.selectedOrder[0].id).pipe(takeUntil(this.componentDestroyed$)).subscribe((res: any) => {
              this.getOrder()
              this.ref.detectChanges()
            }, (err: any) => {
              console.log(err);
            });
            this.unsubscribe.push(appServiceSubscription);
        } else {
          let index = this.orderChargeCreate.findIndex((e: { Amount: any; }) => e.Amount === amount);
          this.orderChargeCreate.splice(index, 1)
          
          const orderChargeCreateString = JSON.stringify(this.orderChargeCreate);
          this.tableLines.ajax.url(`${environment.portalHost}api/returnOrderCharges/` + orderChargeCreateString).load()
          // this.tableLines.ajax.url(`https://ekonnect2.krack.es/api/returnOrderCharges/` + orderChargeCreateString).load()
          // this.tableLines.ajax.url(`http://localhost:4200/api/returnOrderCharges/` + orderChargeCreateString).load()
        }
        Swal.fire('¡Borrado!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('No se borró el descuento', '', 'info')
      }
    })
  }
  //#endregion DELETES

  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Carga la información de precios
   * @param type 
   */
  loadPrice(type: string = '') {
    switch (type) {
      case 'edit':
        let sizeIndexEdit = this.selectedItemSizeEdit[0].id
    
        let lineEdit = this.itemSizes.find((i: { id: any; }) => i.id == sizeIndexEdit)
    
        $("#activePriceEdit").val(lineEdit.active_price)
        $("#originalPriceEdit").val(lineEdit.original_price)
        break;
    
      default:
        let sizeIndex = this.selectedItemSize[0].id
    
        let line = this.itemSizes.find((i: { id: any; }) => i.id == sizeIndex)
    
        $("#activePriceAdd").val(line.active_price)
        $("#originalPriceAdd").val(line.original_price)
        break;
    }
  }

  /**
   * Carga en el modal los datos de las lines
   * @param id 
   */
  loadEdit(id: string) {
    let elem = JSON.parse(JSON.stringify(this.orderBody))

    let line = elem.Lines.find((i: { ExternalId: any; }) => i.ExternalId == id)

    let articleData = this.articlesPlatform.find((i: { id: any; }) => i.id == line.ItemId)
    if (articleData) {
      this.extPlatItemSelectionEdit.addSelected({id: articleData.id, text: articleData.value})
    }

    this.oldItem = line.ItemId

    this.checkSizes("edit")

    this.timeoutId = setTimeout(() => {
      let sizeData = this.itemSizes.find((i: { id: any; }) => i.id == line.SizeIndex)
      if (sizeData) {
        this.sizeIndexSelectionEdit.addSelected({id: sizeData.id, text: sizeData.value})
      }
      this.loadPrice('edit')
    }, 700);


    $("#externalIdEdit").val(line.ExternalId)
    $("#externalIdEdit").prop("disabled", true)
    // $("#activePriceEdit").val(line.ActivePrice / 100)
    // $("#originalPriceEdit").val(line.OriginalPrice / 100)
    $("#discountEdit").val(line.Discounts[0].Amount / 100)
  }

  /**
   * Carga en el modal los datos de descuentos
   * @param id 
   */
  loadEditDiscount(id: string) {
    let elem = JSON.parse(JSON.stringify(this.orderBody))

    let discount = elem.TotalDiscounts.find((i: { Code: any; }) => i.Code == id)

    $("#discountTypeEdit").val(discount.Type)
    $("#discountAmountEdit").val(discount.Amount)
    $("#discountCodeEdit").val(discount.Code)
    $("#discountReasonEdit").val(discount.DiscountReasonId)
  }
  
  /**
   * Carga en el modal los datos de charges
   * @param id 
   */
  loadEditCharge(id: string) {
    let elem = JSON.parse(JSON.stringify(this.orderBody))
    
    let charges = elem.Charges.find((i: { Amount: any; }) => i.Amount == id)
    
    $("#chargeDescriptionEdit").val(charges.ChargeDescription)
    $("#chargeAmountEdit").val(charges.Amount)
  }

  /**
   * Se encarga de gestionar las nuevas líneas de los pedidos
   */
  handleNewLineExternalId() {
    if (!this.orderLoaded) {
      const appServiceSubscription = this.appService.handleNewLineExternalId(this.selectedExternalPlatform[0].id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        $("#externalIdAdd").val(res[0].order_prefix+"-"+res[0].lastOrder+"-")
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
      this.unsubscribe.push(appServiceSubscription);
    }
  }

  /**
   * Gestiona la obtención de información de los pedidos
   * @param type 
   */
  handleMarketSelection(type: string) {
    if (this.selectedExternalPlatformFirst.length > 0) {
      let data = this.selectedExternalPlatformFirst[0].value.split(" ")

      switch (type) {
        case "create":
          $("#externalId").val(data[0]+"-")
          $("#origin").val(data[2])
          break;
        case "update":
          this.selectedUpdatePlatform = false
          if (this.selectedExternalPlatformFirst[0].id == 2) {
            Swal.fire({
              title: '¿Cuántos quieres filtrar?',
              text: 'Este market tiene muchos pedidos, puedes filtrar por un número concreto de pedidos para aligerar la carga. Se ordenan de más recientes a más antiguos. Si lo dejas vacío, cargará todos, pero puede tardar hasta 2 minutos en cargar todos los datos en el selector.',
              icon: 'warning',
              showDenyButton: true,
              input: 'number',
              inputAttributes: {
                id: "fakeClick"
              },
              confirmButtonText: 'Cargar',
              denyButtonText: `Cancelar`,
            }).then((result) => {
              if (result.isConfirmed) {
                if (result.value == "") {
                  this.getNumOrdersPlatform(this.selectedExternalPlatformFirst[0].id, -1)
                }
                else {
                  this.getNumOrdersPlatform(this.selectedExternalPlatformFirst[0].id, result.value)
                }
                this.ref.detectChanges()
              }
            })
            $("#fakeClick").trigger("click")
          } else {
            this.getNumOrdersPlatform(this.selectedExternalPlatformFirst[0].id)
          }
          break;
        default:
          break;
      }
    }
  }

  /**
   * Habilita o deshabilita el dropdown dependiendo del tipo de envío
   */
  changeShipping() {
    $("#shippingMode").val($("#shippingService").val())

    switch ($("#shippingMode").val()) {
      case "3":
        this.isDropdownDisabled = false
        break;
      case "1":
        this.selectedShop = []
        this.isDropdownDisabled = true
        break;
      default:
        break;
    }
  }

  /**
   * Activa la opción de editar y borrar
   */
  activateEditDelete() {
    const deleteLine = document.getElementsByClassName("deleteLine")
    const editLine = document.getElementsByClassName("editLine")

    for (let i = 0; i < deleteLine.length; i++) {
      const e = deleteLine[i];

      e.addEventListener('click', () => {
        this.deleteLine(e.id)
      })
    }
    for (let i = 0; i < editLine.length; i++) {
      const e = editLine[i];

      e.addEventListener('click', () => {
        this.loadEdit(e.id)
      })
    }

    const deleteDiscount = document.getElementsByClassName("deleteDiscount")
    const editDiscount = document.getElementsByClassName("editDiscount")

    for (let i = 0; i < deleteDiscount.length; i++) {
      const e = deleteDiscount[i];

      e.addEventListener('click', () => {
        this.deleteDiscount(e.id)
      })
    }
    for (let i = 0; i < editDiscount.length; i++) {
      const e = editDiscount[i];

      e.addEventListener('click', () => {
        this.loadEditDiscount(e.id)
      })
    }

    const deleteCharge = document.getElementsByClassName("deleteCharge")
    const editCharge = document.getElementsByClassName("editCharge")

    for (let i = 0; i < deleteCharge.length; i++) {
      const e = deleteCharge[i];

      e.addEventListener('click', () => {
        this.deleteCharge(e.id)
      })
    }
    for (let i = 0; i < editCharge.length; i++) {
      const e = editCharge[i];

      e.addEventListener('click', () => {
        this.loadEditCharge(e.id)
      })
    }
  }

  /**
   * Se encarga de cargar los datos del pedido
   * @param order 
   */
  loadOrder(order: any) {
    this.selectedExternalPlatform = []
    let body = JSON.parse(order[0].body)
    this.orderLinesUpdate = []
    this.tableLines.ajax.url(`${environment.portalHost}api/getOrderLines/` + this.selectedOrder[0].id).load();
    // this.tableLines.ajax.url(`https://ekonnect2.krack.es/api/getOrderLines/` + this.selectedOrder[0].id).load();
    // this.tableLines.ajax.url(`http://localhost:4200/api/getOrderLines/` + this.selectedOrder[0].id).load();

    this.tableDiscounts.ajax.url(`${environment.portalHost}api/getOrderDiscounts/` + this.selectedOrder[0].id).load();
    // this.tableDiscounts.ajax.url(`https://ekonnect2.krack.es/api/getOrderDiscounts/` + this.selectedOrder[0].id).load();
    // this.tableDiscounts.ajax.url(`http://localhost:4200/api/getOrderDiscounts/` + this.selectedOrder[0].id).load();

    this.tableCharges.ajax.url(`${environment.portalHost}api/getOrderCharges/` + this.selectedOrder[0].id).load();
    // this.tableCharges.ajax.url(`https://ekonnect2.krack.es/api/getOrderCharges/` + this.selectedOrder[0].id).load();
    // this.tableCharges.ajax.url(`http://localhost:4200/api/getOrderCharges/` + this.selectedOrder[0].id).load();

    let externalPlatformData = this.externalPlatforms.find((i: { id: any; }) => i.id == order[0].externalPlatform_id)

    this.externalPlatformSelection.addSelected({id: externalPlatformData.id, text: externalPlatformData.value})

    this.orderLoaded = true

    this.externalPlatformId = order[0].externalPlatform_id

    //* DATOS DE LA CABECERA
    let externalId = body.ExternalId
    let origin = body.Origin
    let dateBody = new Date(Date.parse(body.OrderDateTime))
    let dateBodyFinal = dateBody.getFullYear() + "-" + ("0" + (dateBody.getMonth() + 1)).slice(-2) + "-" + (("0" + dateBody.getDate()).slice(-2))
    let orderDateTime = dateBodyFinal
    let shippingMode = body.ShippingMode
    let shippingService = body.ShippingService
    let shippingServiceAux = body.ShippingServiceAux ? body.shippingServiceAux : ""
    let marketId = body.MarketId
    let email = body.EMailAddress ? body.EMailAddress : ""
    // id cliente ¿?
    // cluster logístico ¿?
    // payment portal ¿?
    let externalPaymentMethod = body.ExternalPaymentMethod
    let shippingShop = body.ShippingShop
    let phone = body.Phone
    
    //* DATOS DEL ENVÍO Y FACTURACIÓN
    let shippingName = body.ShippingAddressee1
    let shippingSurname = body.ShippingAddressee2
    let shippingPhone = body.ShippingPhone
    let shippingAddress1 = body.ShippingAddress1
    let shippingAddress2 = body.ShippingAddress2
    let shippingCity = body.ShippingCity
    let shippingZipCode = body.ShippingZipCode
    let shippingProvince = body.ShippingProvince
    let shippingCountryId = body.ShippingCountryId

    //* DATOS DE LOS ARTÍCULOS
    
    //* DATOS DE INVOICE
    let invoiceName = body.InvoiceAddressee1
    let invoiceSurname = body.InvoiceAddressee2
    // let invoicePhone = body.Phone
    let invoiceAddress1 = body.InvoiceAddress1
    let invoiceAddress2 = body.InvoiceAddress2
    let invoiceCity = body.InvoiceCity
    let invoiceZipCode = body.InvoiceZipCode
    let invoiceProvince = body.InvoiceProvince
    let invoiceCountryId = body.InvoiceCountryId
    
    //* DATOS DISCOUNTS
    
    //* DATOS PARA LA CLASE
    let shippingHandler = body.ShippingHandler
    let requestedDeliveryDate = body.RequestedDeliveryDate
    let paymentRef = body.PaymentRef
    let invoiceCompanyName = body.InvoiceCompanyName
    let invoiceAddressComplement1 = body.InvoiceAddressComplement1
    let invoiceAddressComplement2 = body.InvoiceAddressComplement2
    let invoiceAddressComplement3 = body.InvoiceAddressComplement3
    let invoiceFiscalNumber = body.InvoiceFiscalNumber
    let shippingCompanyName = body.ShippingCompanyName
    let shippingAddressComplement1 = body.ShippingAddressComplement1
    let shippingAddressComplement2 = body.ShippingAddressComplement2
    let shippingAddressComplement3 = body.ShippingAddressComplement3
    let shippingNote = body.ShippingNote
    let shippingCharge = body.ShippingCharge

    let discounts: OrderLinesDiscounts[] = []
    
    this.orderLinesUpdate = []
    body.Lines.forEach((l: any) => {
      l.Discounts.forEach((d: any) => {
        discounts.push(new OrderLinesDiscounts(
          d.Amount,
          d.DiscountReasonId
        ))
      });

      this.orderLinesUpdate.push(new OrderLines(
        l.ExternalId,
        l.ItemId,
        l.SizeIndex,
        l.OriginalPrice,
        l.ActivePrice,
        discounts
      ))
      discounts = [] 
    });

    this.totalDiscounts = []
    body.TotalDiscounts.forEach((t: any) => {
      this.totalDiscounts.push(new OrderTotalDiscounts(
        t.Type,
        t.Amount,
        t.Code,
        t.DiscountReasonId
      ))
    });

    this.charges = []
    body.Charges.forEach((c: any) => {
      this.charges.push(new OrderCharges(
        c.ChargeDescription,
        c.Amount
      ))
    });

    let is_guest = body.Is_guest
    let sg_customer_mail = body.Sg_customer_mail
    let sg_customer_phone = body.Sg_customer_phone
    let sg_customer_name = body.Sg_customer_name
    let sg_customer_lastname = body.Sg_customer_lastname
    let check_privacy = body.check_privacy
    
    this.orderBody = new Order(
      externalId,
      marketId,
      origin,
      orderDateTime,
      email,
      phone,
      shippingMode,
      shippingHandler,
      shippingService,
      shippingShop,
      shippingServiceAux,
      requestedDeliveryDate,
      paymentRef,
      invoiceCompanyName,
      invoiceName,
      invoiceSurname,
      invoiceAddressComplement1,
      invoiceAddressComplement2,
      invoiceAddressComplement3,
      invoiceFiscalNumber,
      invoiceAddress1,
      invoiceAddress2,
      invoiceZipCode,
      invoiceCity,
      invoiceProvince,
      invoiceCountryId,
      shippingCompanyName,
      shippingName,
      shippingSurname,
      shippingAddress1,
      shippingAddress2,
      shippingAddressComplement1,
      shippingAddressComplement2,
      shippingAddressComplement3,
      shippingZipCode,
      shippingCity,
      shippingProvince,
      shippingCountryId,
      shippingPhone,
      shippingNote,
      externalPaymentMethod,
      shippingCharge,
      this.orderLinesUpdate,
      this.totalDiscounts,
      this.charges,
      is_guest,
      sg_customer_mail,
      sg_customer_phone,
      sg_customer_name,
      sg_customer_lastname,
      check_privacy
    )
    
    //! GENERAR PANTALLA DE CABECERA
    $("#externalId").val(externalId)
    $("#origin").val(origin)
    $("#orderDateTime").val(orderDateTime)
    $("#shippingMode").val(shippingMode)
    $("#shippingService").val(shippingService)
    $("#shippingServiceAux").val(shippingServiceAux)
    $("#marketId").val(marketId)
    $("#customerEmail").val(email)
    $("#externalPaymentMethod").val(externalPaymentMethod)
    // $("#shippingShop").val(shippingShop)
    if (shippingMode == 3) {
      $('#shippingService option[value="3"]').prop('selected', true)
      this.isDropdownDisabled = false
      let shopData = this.listShops.find((i: { id: any; }) => i.id == shippingShop)
      this.shopSelection.addSelected({id: shopData.id, text: shopData.value})
    } else {
      $('#shippingService option[value="1"]').prop('selected', true)
      
      let shopData = this.listShops.find((i: { id: any; }) => i.id == shippingShop)
      this.shopSelection.addSelected({id: shopData.id, text: shopData.value})
      this.isDropdownDisabled = true
    }
    $("#phone").val(phone)

    //! GENERAR PANTALLA DE DIRECCIÓN DE ENVÍO Y FACTURACIÓN
    $("#shippingName").val(shippingName)
    $("#shippingSurname").val(shippingSurname)
    $("#shippingPhone").val(shippingPhone)
    $("#shippingAddress1").val(shippingAddress1)
    $("#shippingAddress2").val(shippingAddress2)
    $("#shippingCity").val(shippingCity)
    $("#shippingZipCode").val(shippingZipCode)
    $("#shippingProvince").val(shippingProvince)
    $("#shippingCountryId").val(shippingCountryId)

    $("#invoiceName").val(invoiceName)
    $("#invoiceSurname").val(invoiceSurname)
    // $("#invoicePhone").val(invoicePhone)
    $("#invoiceAddress1").val(invoiceAddress1)
    $("#invoiceAddress2").val(invoiceAddress2)
    $("#invoiceCity").val(invoiceCity)
    $("#invoiceZipCode").val(invoiceZipCode)
    $("#invoiceProvince").val(invoiceProvince)
    $("#invoiceCountryId").val(invoiceCountryId)

    //! GENERAR PANTALLA DE ARTÍCULOS
    let arrLinesNumbers = body.Lines.map((function(l: { return: any; ExternalId:any; }) { return l.ExternalId.split('-').pop(); }))
    let lastLine = Math.max(...arrLinesNumbers)
    let externalIdDefault = body.Lines[0].ExternalId.split('-')[0]+"-"+body.Lines[0].ExternalId.split('-')[1]
    let externalIdNew = lastLine + 1
    $("#externalIdAdd").val(externalIdDefault+"-"+externalIdNew)
    $("#externalIdAdd").prop("disabled", true)
    
    this.timeoutId = setTimeout(() => {
      this.getArticlesExternalPlatform(order[0].externalPlatform_id)
      this.activateEditDelete()
    }, 1000);
  }

  /**
   * Vacía de contenido los inputs necesarios
   */
  cleanInputs() {
    this.tableLines.ajax.url(`${environment.portalHost}api/getOrderLines/` + 3).load();
    // this.tableLines.ajax.url(`https://ekonnect2.krack.es/api/getOrderLines/` + 3).load();
    // this.tableLines.ajax.url(`http://localhost:4200/api/getOrderLines/` + 3).load();

    this.tableLines.ajax.url(`${environment.portalHost}api/getOrderDiscounts/` + 3).load();
    // this.tableDiscounts.ajax.url(`https://ekonnect2.krack.es/api/getOrderDiscounts/` + 3).load();
    // this.tableDiscounts.ajax.url(`http://localhost:4200/api/getOrderDiscounts/` + 3).load();

    this.tableLines.ajax.url(`${environment.portalHost}api/getOrderCharges/` + 3).load();
    // this.tableCharges.ajax.url(`https://ekonnect2.krack.es/api/getOrderCharges/` + 3).load();
    // this.tableCharges.ajax.url(`http://localhost:4200/api/getOrderCharges/` + 3).load();

    this.orderLoaded = false

    this.selectedShop = []

    //! VACIAR PANTALLA DE CABECERA
    $("#externalId").val("")
    $("#origin").val("")
    $("#orderDateTime").val("")
    $("#shippingMode").val("")
    $("#shippingService").val("")
    $("#shippingServiceAux").val("")
    $("#marketId").val("")
    $("#customerEmail").val("")
    // id cliente ¿?
    // cluster logístico ¿?
    // payment portal ¿?
    $("#externalPaymentMethod").val("")
    $("#shippingShop").val("")
    $("#phone").val("")

    //! VACIAR PANTALLA DE DIRECCIÓN DE ENVÍO Y FACTURACIÓN
    $("#shippingName").val("")
    $("#shippingSurname").val("")
    $("#shippingPhone").val("")
    $("#shippingAddress1").val("")
    $("#shippingAddress2").val("")
    $("#shippingCity").val("")
    $("#shippingZipCode").val("")
    $("#shippingProvince").val("")
    $("#shippingCountryId").val("")

    $("#invoiceName").val("")
    $("#invoiceSurname").val("")
    // $("#invoicePhone").val("")
    $("#invoiceAddress1").val("")
    $("#invoiceAddress2").val("")
    $("#invoiceCity").val("")
    $("#invoiceZipCode").val("")
    $("#invoiceProvince").val("")
    $("#invoiceCountryId").val("")

    //! VACIAR PANTALLA DE AÑADIR PRODUCTOS
    this.selectedExternalPlatform = []
    this.selectedExternalPlatformFirst = []
    this.selectedOrder = []
    this.selectedExtPlatItem = []
    this.selectedItemSize = []
    $("#externalIdAdd").val("")
    $("#activePriceAdd").val("")
    $("#originalPriceAdd").val("")
    $("#discountAdd").val("")
    $("#externalIdAdd").prop("disabled", false)

    if (this.orderLoaded) {
      this.orderLoaded = false
    }

    this.nuevoPedido = true
  }

    /**
   * Da formato al desplegable de cada línea
   * @param d 
   * @param id_item 
   * @param ean 
   * @returns 
   */
  format = (d: any) => {
    let listaDiscounts = ""

    d.Discounts.forEach((e: any) => {
       listaDiscounts += '<span class="dtr-data" value="' + e.DiscountReasonId + '">' + e.Amount + '</span>'
    })

    return (
      `
      <ul class="list-group list-group-flush dtr-details">
        <li class="list-group-item"><span class="dtr-title"><b>Discounts</b></span>
          <ul>
          ` + listaDiscounts + `
          </ul>
        </li>
      </ul>
      `
    );
  }

  /**
   * Handler para RowCallBack
   * @param info 
   */
  someClickHandler(info: any): void {
    this.row = info;
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Inicia la tabla de lines
   */
  processTableLines() { 
    this. tableLines = $("#kt_datatable_orders_redo").DataTable({
      responsive: true,
      processing: true,
      language: {
        "processing": `<span>Cargando...
        </span>`,
        "lengthMenu": "Mostrar _MENU_ registros",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "search": "Buscar:",
        "loadingRecords": " ",
        "paginate": {
          "first": "Primero",
          "last": "Último",
          "next": ">",
          "previous": "<"
        },
        "aria": {
          "sortAscending": ": Activar para ordenar la columna de manera ascendente",
          "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      },
  
      ajax: {
        //! EN CASO DE HACER CAMBIOS LOCALES EN LA PARTE DE NODE, UTILIZAR LA URL COMENTADA. PARA SUBIRLO, SE DEBE UTILIZAR LA DEL environment
        url: `${environment.portalHost}api/getOrderLines/` + 3,
        // url: `https://ekonnect2.krack.es/api/getOrderLines/` + 3,
        // url: `http://localhost:4200/api/getOrderLines/` + 3,
        dataSrc: "",
      },
      columns: [
        {
          className: 'details-control border-right-0',
          orderable: false,
          data: null,
          defaultContent: '<i class="la la-angle-right"></i>',
        },
        { title: 'ExternalId', data: 'ExternalId'},
        { title: 'ItemId', data: 'ItemId'},
        { title: 'SizeIndex', data: 'SizeIndex'},
        { title: 'OriginalPrice', data: 'OriginalPrice'},
        { title: 'ActivePrice', data: 'ActivePrice'},
        {
          title: '',
          render: function (data: any, type: any, full: any) {
            return `
            <td nowrap="nowrap" class="datatable-actions-td">
              <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action editLine" data-bs-target="#kt_modal_edit" data-bs-toggle="modal" id="` + full.ExternalId + `"  title="Editar">
                <span class="svg-icon svg-icon-md"> 
                  <svg xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                        fill="#000000" fill-rule="nonzero"
                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                      <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                    </g>
                  </svg>
                </span>
              </a>
              <a class="btn btn-sm btn-clean btn-icon datatable-action deleteLine" id="` + full.ExternalId + `" title="Eliminar"> 
                <span class="svg-icon svg-icon-md"> 
                  <svg
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                      viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                          fill="#000000" fill-rule="nonzero"></path>
                        <path
                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                          fill="#000000" opacity="0.3"></path>
                    </g>
                  </svg> 
                </span> 
              </a>
            </td>`
          },
          className: 'dt-body-center border-right-0'
        },
      ],
      order: [[1, 'asc']],
      columnDefs: [
        // {
        //   targets: 1,
        //   visible: true,
        //   searchable: false
        // },
        // {
        //   targets: 1,
        //   visible: false,
        //   searchable: true
        // },
  
      ],
  
      rowCallback: (row: Node, data: any, index: number) => {
        const self = this;
        $('td', row).off('click');
        $('td', row).on('click', () => {
          self.someClickHandler(row);
        });
        $('td.details-control', row).off('click');
        $('td.details-control', row).on('click', () => {
          var tr = row;
          var row2 = this.tableLines.row(tr)
          if (row2.child.isShown()) {
  
            row2.child.hide();
            $('td.details-control').html('<i class="la la-angle-right"></i>')
            $(".dt-hasChild").find('td.details-control').html('<i class="la la-angle-down"></i>');
  
          } else {
            var start_time = new Date().getTime();
  
            var end_tiem = new Date().getTime();
            var tiempo = end_tiem - start_time;
  
            this.timeoutId = setTimeout(() => {
              row2.child(this.format(row2.data())).show();
              $(".dt-hasChild").find('td.details-control').html('<i class="la la-angle-down"></i>');
            }, tiempo / 0.001);
          }
        });
        
        $('input.editor-active', row).off('click');
  
        return row;
      },
    })
  }

  /**
   * Inicia la tabla de discounts
   */
  processTableDiscounts() {
    this. tableDiscounts = $("#kt_datatable_discounts").DataTable({
      responsive: true,
      processing: true,
      language: {
        "processing": `<span>Cargando...
        </span>`,
        "lengthMenu": "Mostrar _MENU_ registros",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "search": "Buscar:",
        "loadingRecords": " ",
        "paginate": {
          "first": "Primero",
          "last": "Último",
          "next": ">",
          "previous": "<"
        },
        "aria": {
          "sortAscending": ": Activar para ordenar la columna de manera ascendente",
          "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      },
  
      ajax: {
        //! EN CASO DE HACER CAMBIOS LOCALES EN LA PARTE DE NODE, UTILIZAR LA URL COMENTADA. PARA SUBIRLO, SE DEBE UTILIZAR LA DEL environment
        url: `${environment.portalHost}api/getOrderDiscounts/` + 3,
        // url: `https://ekonnect2.krack.es/api/getOrderDiscounts/` + 3,
        // url: `http://localhost:4200/api/getOrderDiscounts/` + 3,
        dataSrc: "",
      },
      columns: [
        { title: 'Tipo', data: 'Type'},
        { title: 'Cantidad', data: 'Amount'},
        { title: 'Código', data: 'Code'},
        { title: 'DiscountReasonId', data: 'DiscountReasonId'},
        {
          title: '',
          render: function (data: any, type: any, full: any) {
            return `
            <td nowrap="nowrap" class="datatable-actions-td">
              <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action editDiscount" data-bs-target="#kt_modal_edit_discount" data-bs-toggle="modal" id="` + full.Code + `"  title="Editar">
                <span class="svg-icon svg-icon-md"> 
                  <svg xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                        fill="#000000" fill-rule="nonzero"
                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                      <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                    </g>
                  </svg>
                </span>
              </a>
              <a class="btn btn-sm btn-clean btn-icon datatable-action deleteDiscount" id="` + full.Code + `" title="Eliminar"> 
                <span class="svg-icon svg-icon-md"> 
                  <svg
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                      viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                          fill="#000000" fill-rule="nonzero"></path>
                        <path
                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                          fill="#000000" opacity="0.3"></path>
                    </g>
                  </svg> 
                </span> 
              </a>
            </td>`
          },
          className: 'dt-body-center border-right-0'
        },
      ],
      order: [[2, 'asc']]
    })
  }

  /**
   * Inicia la tabla de charges
   */
  processTableCharges() {
    this. tableCharges = $("#kt_datatable_charges").DataTable({
      responsive: true,
      processing: true,
      language: {
        "processing": `<span>Cargando...
        </span>`,
        "lengthMenu": "Mostrar _MENU_ registros",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "search": "Buscar:",
        "loadingRecords": " ",
        "paginate": {
          "first": "Primero",
          "last": "Último",
          "next": ">",
          "previous": "<"
        },
        "aria": {
          "sortAscending": ": Activar para ordenar la columna de manera ascendente",
          "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      },
  
      ajax: {
        //! EN CASO DE HACER CAMBIOS LOCALES EN LA PARTE DE NODE, UTILIZAR LA URL COMENTADA. PARA SUBIRLO, SE DEBE UTILIZAR LA DEL environment
        url: `${environment.portalHost}api/getOrderCharges/` + 3,
        // url: `https://ekonnect2.krack.es/api/getOrderCharges/` + 3,
        // url: `http://localhost:4200/api/getOrderCharges/` + 3,
        dataSrc: "",
      },
      columns: [
        { title: 'Descripción', data: 'ChargeDescription'},
        { title: 'Cantidad', data: 'Amount'},
        {
          title: '',
          render: function (data: any, type: any, full: any) {
            return `
            <td nowrap="nowrap" class="datatable-actions-td">
              <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action editCharge" data-bs-target="#kt_modal_edit_charge" data-bs-toggle="modal" id="` + full.Amount + `"  title="Editar">
                <span class="svg-icon svg-icon-md"> 
                  <svg xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                        fill="#000000" fill-rule="nonzero"
                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                      <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                    </g>
                  </svg>
                </span>
              </a>
              <a class="btn btn-sm btn-clean btn-icon datatable-action deleteCharge" id="` + full.Amount + `" title="Eliminar"> 
                <span class="svg-icon svg-icon-md"> 
                  <svg
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                      viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                          fill="#000000" fill-rule="nonzero"></path>
                        <path
                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                          fill="#000000" opacity="0.3"></path>
                    </g>
                  </svg> 
                </span> 
              </a>
            </td>`
          },
          className: 'dt-body-center border-right-0'
        },
      ],
      order: [[0, 'asc']]
    })
  }

  /**
   * Inicia el componente
   */
  ngOnInit(): void {
    this.processTableLines()
    this.processTableDiscounts()
    this.processTableCharges()
    // this.getNumOrders()
    this.getExternalPlatforms()
    this.getAllTiendasPedidos()

    this.myForm = this.fb.group({
      article: [this.selectedExtPlatItem]
    });

    // Swal.fire('La funcionalidad de creación de pedidos está desactivada por el momento', '', 'info')

    this.dropdownOrder = {
      singleSelection: true,
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 6,
      allowSearchFilter: true,
      searchPlaceholderText: 'Ej: 404520',
    };

    this.dropdownSingle = {
      singleSelection: true,
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy() {
    clearTimeout(this.timeoutId)

    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.tableLines) {
      this.tableLines.destroy();
    }

    if (this.tableDiscounts) {
      this.tableDiscounts.destroy();
    }

    if (this.tableCharges) {
      this.tableCharges.destroy();
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN

}