import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import 'jquery';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-info-productos',
  templateUrl: './info-productos.component.html',
  styleUrls: ['./info-productos.component.scss']
})
export class InfoProductosComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  
  markets: any = [];
  selectedMarket: any[] = [];
  selectedDescriptionProduct: any[] = [];
  selectedComercial: any[] = [];
  selectedFamilia: any[] = [];
  selectedTemporada: any[] = [];

  resolverFiltrados: any = [];
  selectedMarca: any[] = [];
  id_item: string;

  marcas: any = [];
  descriptionProducts: any = [];
  comerciales: any = [];
  familiares: any = [];
  temporadas: any = [];

  dropdownSingleSettings: IDropdownSettings = {};
  dropdownSingleSettingsMulti: IDropdownSettings = {};
  dropdownSingleSettingsSeasons: IDropdownSettings = {};
  
  table: DataTables.Api

  componentDestroyed$: Subject<boolean> = new Subject()

  private unsubscribe: Subscription[] = [];
  
  constructor(private appService: AppService, private ref: ChangeDetectorRef) { }

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE

  //! GETS
  //#region GETS
  /**
   * Accede a la información de las plataformas
   */
  getPlatforms() {
    const appServiceSubscription =this.appService.getPlatforms().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.markets = res;
        this.selectedMarket = [{ "Name": this.markets[0]["Name"], "Id": this.markets[0]["Id"] }]
        this.ref.detectChanges()
        this.processTable()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  getBrands() {
    const appServiceSubscription =this.appService.getInternalBrands().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.marcas = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  getDescriptionProduct() {
    const appServiceSubscription =this.appService.getFromInternal('InternalItemGroup07').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.descriptionProducts = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }
  
  getComercial() {
    const appServiceSubscription =this.appService.getFromInternal('InternalItemGroup13').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.comerciales = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  getFamilia() {
    const appServiceSubscription =this.appService.getFromInternal('InternalItemGroup02').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.familiares = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  getInternalSeasons() {
    const appServiceSubscription =this.appService.getInternalSeasons().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.temporadas = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion GETS

  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Limpia los filtros de los selectores
   */
  limpiarFiltros() {
    this.selectedMarca = [];
    this.selectedTemporada = [];
    this.selectedComercial = [];
    this.selectedFamilia = [];
    this.selectedDescriptionProduct = [];
    this.selectedMarket = [];
    $('#item').val("");

    $('#search').click()
  }

  /**
   * Realiza la búsqueda requerida en la tabla
   */
  search() {
    // this.table.ajax.url("http://localhost:4200/api/getItemInfo/" + this.selectedMarket[0]["Id"]);
    this.table.ajax.url(environment.portalHost + 'api/getItemInfo/' + this.selectedMarket[0]["Id"])
    this.table.ajax.reload()
  }
  
  /**
   * Formatea la lista de elementos de cada línea
   * @param d 
   * @returns 
   */
  format = (d: any) => {
    return (
      `
      <ul class="list-group list-group-flush dtr-details">
        <li class="list-group-item"><span class="dtr-title"><b>Id artículo</b></span>          <span class="dtr-data">${d.id_producto}</span></li>
        <li class="list-group-item"><span class="dtr-title"><b>Marca</b></span>                <span class="dtr-data">${d.marca}</span></li>
        <li class="list-group-item"><span class="dtr-title"><b>Descripcion</b></span>          <span class="dtr-data">${d.DESCRIPCION}</span></li>
        <li class="list-group-item"><span class="dtr-title"><b>Comercial</b></span>           <span class="dtr-data">${d.comercial}</span></li>
        <li class="list-group-item"><span class="dtr-title"><b>Familia</b></span>              <span class="dtr-data">${d.familia}</span></li>
        <li class="list-group-item"><span class="dtr-title"><b>Categoria de producto</b></span><span class="dtr-data">${d.categorias + "" === null + "" ? "(Sin categoría)" : d.CATEGORIAS}</span></li>
        <li class="list-group-item"><span class="dtr-title"><b>Temporada</b></span>            <span class="dtr-data">${d.temporada}</span></li>
        <li class="list-group-item"><span class="dtr-title"><b>Color</b></span>            <span class="dtr-data">${d.color}</span></li>
        </ul>
        <div><button id="button-info-${d.id_producto}" style="margin-top: 20px;" class="btn btn-success">+Info</button><div id="info-talla-${d.id_producto}"></div></div>
      `
    );
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Se procesa la datatable
   */
  processTable() {
    this.table = $("#kt_datatable_info_products").DataTable({
      processing: true,
      responsive: true,
      language: {
        "processing": `<span>Cargando...
        </span>`,
        "lengthMenu": "Mostrar _MENU_ registros",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "search": "Buscar:",
        "loadingRecords": " ",
        "paginate": {
          "first": "Primero",
          "last": "Último",
          "next": ">",
          "previous": "<"
        },
        "aria": {
          "sortAscending": ": Activar para ordenar la columna de manera ascendente",
          "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      },

      deferRender: true,

      ajax: {
        url: environment.portalHost + 'api/getItemInfo/0',
        //url: "http://localhost:4200/api/getItemInfo/0",
        dataSrc: "",
      },

      columns: [
        {
          className: 'details-control border-right-0',
          orderable: false,
          data: null,
          defaultContent: '<i class="la la-angle-right"></i>',
        },
        { title: 'ID PRODUCTO', data: 'id_producto' },
        { title: 'MARKET', data: 'market' },
        { title: 'CREACIÓN', data: 'creacion' },
        { title: 'ACTUALIZACIÓN', data: 'actualizacion' },
        { title: 'ACTU. PRECIO', data: 'actualizacion_precio' },
        { title: 'ACTU. STOCK', data: 'actualizacion_stock' },
        { title: 'ACTU. FOTOS', data: 'actualizacion_foto' },
        { title: 'BORRADO', data: 'borrado' },
        /* 10 */  { title: 'id market', data: 'Id_externalplatform' },
        /* 11 */  { title: 'marca', data: 'marca' },
        /* 12 */  { title: 'DESCRIPCION', data: 'DESCRIPCION' },
        /* 13 */  { title: 'comercial', data: 'comercial' },
        /* 14 */  { title: 'familia', data: 'familia' },
        /* 15 */  { title: 'temporada', data: 'temporada' },
      ],
      columnDefs: [
        {
          targets: 9,
          visible: false,
          searchable: true
        },
        {
          targets: 10,
          visible: false,
          searchable: true
        },
        {
          targets: 11,
          visible: false,
          searchable: true
        },
        {
          targets: 12,
          visible: false,
          searchable: true
        },
        {
          targets: 13,
          visible: false,
          searchable: true
        },
        {
          targets: 14,
          visible: false,
          searchable: true
        },
      ],

      rowCallback: (row: Node, data: any, index: number) => {
        $('td.details-control', row).off('click');
        $('td.details-control', row).on('click', () => {
          var tr = row;
          var row2 = this.table.row(tr)
          if (row2.child.isShown()) {

            row2.child.hide();

            $('td.details-control').html('<i class="la la-angle-right"></i>')
            $(".dt-hasChild").find('td.details-control').html('<i class="la la-angle-down"></i>');

          } else {

            row2.child(this.format(row2.data())).show();
            $(".dt-hasChild").find('td.details-control').html('<i class="la la-angle-down"></i>');
          }
        });
      },

      drawCallback: () => {
        $('select[name="kt_datatable_info_products_length"]').addClass('custom-select custom-select-sm form-control form-control-sm');
        $('#kt_datatable_info_products_filter label input').addClass("form-control form-control-sm");
        $('div.dataTables_scrollBody table thead').html('');
        $('div.dataTables_scrollBody table tfoot').html('');
        $('td.details-control').html('<i class="la la-angle-right"></i>')
      },
      initComplete: () => {
        if (this.selectedMarket && this.selectedMarket.length > 0) {
          // this.table.ajax.url("http://localhost:4200/api/getItemInfo/" + this.selectedMarket[0]["Id"])
          this.table.ajax.url(environment.portalHost + 'api/getItemInfo/' + this.selectedMarket[0]["Id"]);
          this.table.ajax.reload();
        }
        
        $('select[name="kt_datatable_info_products_length"]').addClass('custom-select custom-select-sm form-control form-control-sm');
        $('#kt_datatable_info_products_filter label input').addClass("form-control form-control-sm")

        $.fn.dataTable.ext.search.push(
          (settings: any, data: any, dataIndex: any) => {
            // this.id_item = $('#item').val().trim();
            this.id_item = ""
            
            var marca: any[] = [];
            this.selectedMarca.forEach(element => {
              marca.push(element.Name)
            });

            var descriptionProduct: any[] = []
            this.selectedDescriptionProduct.forEach(element => {
              descriptionProduct.push(element.Name)
            });

            var comercial: any[] = [];
            this.selectedComercial.forEach(element => {
              comercial.push(element.Name)
            })

            var familia: any[] = [];
            this.selectedFamilia.forEach(element => {
              familia.push(element.Name)
            });
            var temporada: any[] = [];
            this.selectedTemporada.forEach(element => {
              temporada.push(element.Name)
            })


            if ((this.id_item === "" || data[1].includes(this.id_item)) &&
              (marca.includes(data[10]) || marca.length == 0) && (descriptionProduct.includes(data[11]) || descriptionProduct.length == 0) && (comercial.includes(data[12]) || comercial.length == 0) && (familia.includes(data[13]) || familia.length == 0) && (temporada.includes(data[14]) || temporada.length == 0)) {

              return true
            }
            return false
          }

        );
        $('#search').on('click', () => {
          this.resolverFiltrados = []
          this.table.draw()
        });
      }
    })
  }

  /**
   * Se inicia el componente
   */
  ngOnInit(): void {
    this.getPlatforms()
    this.getBrands()
    this.getDescriptionProduct()
    this.getComercial()
    this.getFamilia()
    this.getInternalSeasons()

    this.dropdownSingleSettings = {
      singleSelection: true,
      idField: 'Id',
      textField: 'Name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSingleSettingsMulti = {
      idField: 'Id',
      textField: 'Name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSingleSettingsSeasons = {
      idField: 'Id',
      textField: 'Name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.table) {
      this.table.destroy();
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN

}
