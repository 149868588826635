<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
<div class="card card-custom">
    <div class="card-header" class="my-5 mx-5">
        <div>
            <button class="btn btn-primary font-weight-bolder" id="anadir"
                data-bs-toggle="modal" data-bs-target="#kt_modal_nuevo"><i class="la la-plus"></i>Nuevo mapeo</button>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
    </div>

    <div class="card-body">
        <div id="kt_datatable_discounts_wrapper" class="dataTables_wrapper dt-bootstrap4">
            <div style="width: 100%; padding-left: -10px;">
                <div class="table-responsive">
                    <table id="kt_datatable_discounts"
                        class="table-head-custom table-bordered table-hover table-checkable dataTable dtr-inline collapsed"
                        role="grid" aria-describedby="kt_datatable_discounts_info" style="width: 100%;">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_nuevo">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Nueva regla</h4>
                    <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <label for="cupon" class="my-5"><b>Cupón</b></label>
                    <input id="cupon" class="form-control" />
                    
                    <label id="discount_reason_label" for="discount_reason" class="my-5"><b>Discount reason</b></label>
                    <input type="number" id="discount_reason" class="form-control" />

                    <label><b>Markets aplicables:</b></label>
                    <ng-multiselect-dropdown id="market" name="market" [placeholder]="'Sin selección'" class="multi-select"
                        [settings]="dropdownSingleSettings" [data]="markets" [(ngModel)]="selectedMarket">
                    </ng-multiselect-dropdown>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="saveMap()">Guardar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" tabindex="-1" id="kt_modal_edit">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Editar</h4>
                    <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <label for="cupon" class="my-5"><b>Cupón</b></label>
                    <input id="cuponEdit" class="form-control" />
                    <br>
                    <label id="discount_reason_label" for="discount_reason" class="my-5"><b>Discount
                            reason</b></label>
                    <input type="number" id="discount_reasonEdit" class="form-control" />
                    <label>Markets aplicables:</label>
                    <ng-multiselect-dropdown #multiEdit id="marketEdit" name="marketEdit" [placeholder]="'Sin selección'"
                        class="multi-select" [settings]="dropdownSingleSettings" [data]="markets"
                        [(ngModel)]="selectedMarketEdit">
                    </ng-multiselect-dropdown>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="editMap()">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>