import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import 'jquery';
import { AppService } from 'src/app/app.service';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tallajes',
  templateUrl: './tallajes.component.html',
  styleUrls: ['./tallajes.component.scss']
})

export class TallajesComponent implements OnInit, OnDestroy {

  public mappings:any[] = []
  public brands:any[] = []
  public externalSizes:any[] = []
  public platforms:any[] = []
  public conversions:any = []
  public domains:any[] = []
  public ruleDuplicate:any

  dropdownTallajeMarket: any[] = []

  selectedMarkets:any[] = []
  selectedItemsMarket:any[] = [];

  selectedTallaje:any[] = []
  @ViewChild('tallajeEdit') tallajeEdit:MultiSelectComponent;

  selectedMarca:any[] = []
  @ViewChild('marcaEdit') marcaEdit:MultiSelectComponent;

  selectedConversion:any[] = []
  @ViewChild('conversionEdit') conversionEdit:MultiSelectComponent;

  selectedTallajeMarket:any[] = []
  @ViewChild('tallajeMarketEdit') tallajeMarketEdit:MultiSelectComponent;

  selectedDuplicateMarket:any[] = []
  @ViewChild('duplicateMarketSelected') duplicateMarketSelected:MultiSelectComponent;

  dropdownSingle:IDropdownSettings = {};
  dropdownMultiple:IDropdownSettings = {};

  private timeoutId!: any;

  private unsubscribe: Subscription[] = [];

  table: DataTables.Api

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(private appService: AppService,private ref: ChangeDetectorRef) { }

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE

  //! GETS
  //#region GETS
  /**
   * Saca la información de los mapos de talla
   */
  getSizeMappings(){
    const appServiceSubscription = this.appService.getSizeMappings().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.mappings = res
        this.ref.detectChanges()
        // this.processTable()
      }, err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la información de las marcas
   */
  getBrands(){
    const appServiceSubscription = this.appService.getBrands().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.brands = res
        this.ref.detectChanges()
      }, 
      err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la información de las plataformas
   */
  getPlatforms(){
    const appServiceSubscription = this.appService.getPlatforms().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.platforms = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la información de las conversiones para el id requerido
   * @param id 
   */
  getConversions(id:any){
    const appServiceSubscription = this.appService.getConversions(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.conversions = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  
  /**
   * Saca la información de domains
   */
  getDomains(){
    const appServiceSubscription = this.appService.getDomains().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.domains = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la información de las tallas externas
   * @param selected 
   * @param create 
   */
  async getExternalSizes(selected:any, create: boolean = false){
    $('#extSlabel').hide();
    $('#extSMlabel').hide();
    $('#external_size').hide();
    $('#external_sizeMODAL').hide();

    if (create) {

      if(selected.length == 0) {
        selected = [{"Id":0, "Name":"Trampantojo"}]
      }

      selected.forEach((e: { Id: any; }) => {
        const appServiceSubscription = this.appService.getExternalSizes(e.Id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          this.externalSizes = res
          if(this.externalSizes)
          {
            $('#extSlabel').show();
            $('#external_size').show();
            $('#extSMlabel').show();
            $('#external_sizeMODAL').show();
          }
          this.ref.detectChanges()
        }, err => {
          console.log(err)
        })

        this.unsubscribe.push(appServiceSubscription);
      });

    } else {

      const appServiceSubscription = this.appService.getExternalSizes(selected).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.externalSizes = res
        if(this.externalSizes)
        {
          $('#extSlabel').show();
          $('#external_size').show();
          $('#extSMlabel').show();
          $('#external_sizeMODAL').show();
        }
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })

      this.unsubscribe.push(appServiceSubscription);
    }
  }

  /**
   * Puebla el modal para la edición de los mapeos
   * @param id_mapping_size 
   */
  poblarModal(id_mapping_size:number){
    this.limpiarSelected()
    this.selectedItemsMarket = []
    const appServiceSubscription = this.appService.getMappingToEdit(id_mapping_size).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.getExternalSizes(res[0].id_externalplatform)
      this.timeoutId = setTimeout(() => {
        this.getConversions(res[0].id_conversion_destino)
        this.tallajeEdit.addSelected({id: res[0].id_sizeDomain, text:res[0].sizeDomainName})
        this.marcaEdit.addSelected({id: res[0].id_brand, text:res[0].brandName})
        this.conversionEdit.addSelected({id: res[0].id_conversion_destino, text:res[0].conversionName})
        if (this.externalSizes) {
          let externalSizeGroupName = this.externalSizes.find((i: { Id: any; }) => i.Id == res[0].external_sizGroup).Name
          this.tallajeMarketEdit.addSelected({id: res[0].external_sizGroup, text: externalSizeGroupName})
        }
        $('#id_platform_edit').val(res[0].id_externalplatform)
        $('#id_mappingEDIT').val(id_mapping_size)
        this.ref.detectChanges()
      }, 500);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Con el id del mapeo saca la información
   * @param id_mapping_size 
   */
  poblarModalDuplicar(id_mapping_size:number){
    this.limpiarSelected()
    this.selectedItemsMarket = []
    const appServiceSubscription = this.appService.getMappingToEdit(id_mapping_size).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      // Se copia el mapeo en una variable global
      this.ruleDuplicate = res[0]
      // Se vuelven a activar todos los markets al poblar el modal
      this.platforms.forEach(e => {
        e.isDisabled = false
      });
      // Se busca el market seleccionado y se deshabilita en el selector
      this.platforms.find((i: { Id: any; }) => i.Id == res[0].id_externalplatform).isDisabled = true
      // Se reinicia la información que será cargada en el selector múltiple
      this.platforms = [...this.platforms]
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion GETS

  //! POSTS
  //#region POSTS
  /**
   * Guarda la información del mapeo creado
   */
  saveMarketSizeMap(){
    let marcas = this.selectedMarca;
    let domain = this.selectedTallaje[0].Id;
    let conversion = this.selectedConversion[0].Id;
    // let platform = $('#platform').val();
    let external_size: any = null;
    if (this.externalSizes) {
      external_size = this.selectedTallajeMarket[0].Id;
    }

    let group = ""
    this.selectedItemsMarket[0].Id
    this.platforms.forEach(i => {
      if (i.Id == this.selectedItemsMarket[0].Id) {
        group = i.market_group;
      }
    });

    let add = true

    this.selectedItemsMarket.forEach((e: any) => {
      this.platforms.forEach(i => {
        if (i.Id == e.Id) {
          if(group !== i.market_group){
            Swal.fire(
                    'Distintos marketplaces',
                    'No puedes seleccionar markets que no estén relacionados.',
                    'warning'
                  )
            add = false
          }
        }
      });
    });

    if (add) {
      this.selectedItemsMarket.forEach((e: any) => {
        marcas.forEach((marca: any) => {
          const appServiceSubscription = this.appService.setSizeMapping(marca.Id, domain, conversion, e.Id, external_size).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
            window.location.reload()
          }, err => {
            console.log(err)
          })

          this.unsubscribe.push(appServiceSubscription);
        });
      })
    }
  }

  /**
   * Guarda la información de la edición del mapeo
   */
  saveMappingEdit(){
    let id = $('#id_mappingEDIT').val()
    let marca = this.selectedMarca[0].Id;
    let domain = this.selectedTallaje[0].Id;
    let conversion = this.selectedConversion[0];
    let platform = $('#id_platform_edit').val();
    let external_size = this.selectedTallajeMarket[0].Id;
    
    if (this.selectedItemsMarket.length) {

    }
    else {
      const appServiceSubscription = this.appService.saveMappingEdit(id, marca, domain, conversion, platform, external_size).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          window.location.reload()
        }, err => {
          console.log(err)
        })

      this.unsubscribe.push(appServiceSubscription);
    }
  }

  /**
   * Duplica la regla de mapeo con la información de la variable this.ruleDuplicate
   */
  duplicateRule() {
    Swal.fire({
      title: '¿Duplicar mapeo?',
      text: '¿Deseas duplicar este mapeo?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Duplicar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.selectedDuplicateMarket.length > 0) {
          this.selectedDuplicateMarket.forEach(e => {
            const appServiceSubscription = this.appService.setSizeMapping(this.ruleDuplicate.id_brand, this.ruleDuplicate.id_sizeDomain, this.ruleDuplicate.id_conversion_destino, e.Id, this.ruleDuplicate.external_sizGroup).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
            }, err => {
              console.log(err)
            })

            this.unsubscribe.push(appServiceSubscription);
          });
          this.timeoutId = setTimeout(() => {
            window.location.reload()
          }, 500);
        }
        else {
          Swal.fire('No has seleccionado ningún market', '', 'error')
        }
      } else if (result.isDenied) {
        Swal.fire('No se guardaron los cambios', '', 'info')
      }
    })
  }
  //#endregion POSTS
  
  //! DELETES
  //#region DELETES
  /**
   * Elimina el mapeo seleccionado
   * @param id 
   */
  deleteSizeMapping(id:number){
    Swal.fire({
      title: '¿Eliminar mapeo?',
      text: '¿Deseas eliminar este mapeo?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const appServiceSubscription = this.appService.deleteSizeMapping(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          Swal.fire('!Eliminado!', '', 'success')
          window.location.reload()
        }, err => {
          console.log(err)
        })

        this.unsubscribe.push(appServiceSubscription);
      } else if (result.isDenied) {
        Swal.fire('No se guardaron los cambios', '', 'info')
      }
    })
  }
  //#endregion DELETES

  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Limpia todos los filtros
   */
  limpiarFiltros(){
    $('select.filtro').val("")
    $('select.filtro').trigger('change')
  }

  /**
   * Vacía de contenido los seleccionables
   */
  limpiarSelected(){
    this.selectedItemsMarket = []
    this.selectedMarca = []
    this.selectedTallaje = []
    this.selectedConversion = []
    this.selectedTallajeMarket = []
    this.selectedMarkets = []
    this.selectedDuplicateMarket = []
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Inicia la tabla de mapos
   */
  processTable(){
    var table = $('#kt_datatable_tallajes').DataTable({
      language: {
          "processing": `<span>Cargando...
          </span>`,
          "lengthMenu": "Mostrar _MENU_ registros",
          "zeroRecords": "No se encontraron resultados",
          "emptyTable": "Ningún dato disponible en esta tabla",
          "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "infoFiltered": "(filtrado de un total de _MAX_ registros)",
          "search": "Buscar:",
          "loadingRecords": " ",
          "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": ">",
              "previous": "<"
          },
          "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "decimal": ",",
          "thousands": ".",
          "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      }
  });
  var cont = 0;

  table.columns().every(function () {

    var column = this;

      var select:any;

      if(cont <= 4){

        select = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
        .appendTo($(column.footer()).empty()).on('change', () => {
            var val = $.fn.dataTable.util.escapeRegex(
              select.val()
            );
            column.search(val ? '^' + val + '$' : '', true, false).draw();
          });
          column.data().unique().sort().each(function (d: string, j: any) {
            select.append('<option value="' + d + '">' + d + '</option>')
          });
      }
      cont++
  });
    $('tfoot tr:not(#newrule)').appendTo('thead')
    $('select[name="kt_datatable_length"]').addClass('custom-select custom-select-sm form-control form-control-sm');
    $('#kt_datatable_filter label input').addClass("form-control form-control-sm")
  }

  processTableTallajes(){
    this.table = $("#kt_datatable_tallajes_new").DataTable({
      processing: true,
      responsive: true,
      searching: true,
      pageLength: 25,
      language: {
        "processing": `<span>Cargando...
        </span>`,
        "lengthMenu": "Mostrar _MENU_ registros",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "search": "Buscar:",
        "loadingRecords": " ",
        "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": ">",
            "previous": "<"
        },
        "aria": {
            "sortAscending": ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
    },

      deferRender: true,

      ajax: {
        url: environment.portalHost+'api/markets/getSizeMappings',
        // url: 'http://localhost:4200/api/markets/getSizeMappings',
        dataSrc: "",
      },

      columns: [
        { title: 'MARCA', data: 'id_brand'},
        { title: 'TALLAJE AVELON',data: 'id_sizeDomain'},
        { title: 'CONVERSIÓN',data: 'id_conversion_destino'},
        { title: 'MARKET',data: 'id_externalplatform'},
        { title: 'TALLAJE MARKET',data: 'external_sizGroup'},
        {
          title: '',
          render: function (data: any, type: any, full: any) {
            return `
            <td nowrap="nowrap" class="datatable-actions-td">
              <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action datatable-action-duplicate" data-bs-target="#kt_modal_duplicar" data-bs-toggle="modal" data-id="${full.id_mapping_size}" title="Duplicar">
                <span class="svg-icon svg-icon-md">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path 
                          d="M6,9 L6,15 C6,16.6568542 7.34314575,18 9,18 L15,18 L15,18.8181818 C15,20.2324881 14.2324881,21 12.8181818,21 L5.18181818,21 C3.76751186,21 3,20.2324881 3,18.8181818 L3,11.1818182 C3,9.76751186 3.76751186,9 5.18181818,9 L6,9 Z" fill="#000000" fill-rule="nonzero"/>
                        <path 
                          d="M10.1818182,4 L17.8181818,4 C19.2324881,4 20,4.76751186 20,6.18181818 L20,13.8181818 C20,15.2324881 19.2324881,16 17.8181818,16 L10.1818182,16 C8.76751186,16 8,15.2324881 8,13.8181818 L8,6.18181818 C8,4.76751186 8.76751186,4 10.1818182,4 Z" fill="#000000" opacity="0.3"/>¡
                    </g>
                  </svg>
                </span>
              </a>
              <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action datatable-action-edit" data-bs-target="#kt_modal_dani" data-bs-toggle="modal" data-id="${full.id_mapping_size}" title="Editar">
                <span class="svg-icon svg-icon-md"> 
                  <svg xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                        fill="#000000" fill-rule="nonzero"
                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                      <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                    </g>
                  </svg> 
                </span>
              </a>
              <a href="javascript:;" class="btn btn-sm btn-clean btn-icon datatable-action datatable-action-delete" data-id="${full.id_mapping_size}" title="Eliminar"> 
                <span class="svg-icon svg-icon-md"> 
                  <svg
                      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                      viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                          fill="#000000" fill-rule="nonzero"></path>
                        <path
                          d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                          fill="#000000" opacity="0.3"></path>
                    </g>
                  </svg> 
                </span> 
              </a>
            </td>`
          },
          className: 'dt-body-center border-right-0'
        },
      ],

      drawCallback: () => {
        $('.datatable-action-duplicate').off('click').on('click', (event) => {
          const id = $(event.currentTarget).data('id');
          console.log(id)
          this.poblarModalDuplicar(id);
        });

        $('.datatable-action-edit').off('click').on('click', (event) => {
          const id = $(event.currentTarget).data('id');
          this.poblarModal(id);
        });

        $('.datatable-action-delete').off('click').on('click', (event) => {
          const id = $(event.currentTarget).data('id');
          this.deleteSizeMapping(id);
        });
      },
      
      initComplete: () => {
        var table = $('#kt_datatable_tallajes_new').DataTable();

        // const editShop = document.getElementsByClassName("editShop")

        // for (let i = 0; i < editShop.length; i++) {
        //   const e = editShop[i];

        //   e.addEventListener('click', () => {
        //     this.checkData(e.id)
        //   })
        // }

        $('.datatable-action-duplicate').off('click').on('click', (event) => {
          const id = $(event.currentTarget).data('id');
          console.log(id)
          this.poblarModalDuplicar(id);
        });

        $('.datatable-action-edit').off('click').on('click', (event) => {
          const id = $(event.currentTarget).data('id');
          this.poblarModal(id);
        });

        $('.datatable-action-delete').off('click').on('click', (event) => {
          const id = $(event.currentTarget).data('id');
          this.deleteSizeMapping(id);
        });

        table.columns([0,3]).every( function () {
            var column = this;
            var select = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
                .appendTo( $(column.footer()).empty() )
                .on( 'change',  () => {
                    var val = $.fn.dataTable.util.escapeRegex(
                        select.val()
                    );
    
                    column
                        .search( val ? '^'+val+'$' : '', true, false )
                        .draw();
                } );
    
            column.data().unique().sort().each( function ( d: string, j: any ) {
                select.append( '<option value="'+d+'">'+d+'</option>' )
            } );
        });

        $('tfoot tr:not(#newrule)').appendTo('thead')

        $('select[name="kt_datatable_length"]').addClass('custom-select custom-select-sm form-control form-control-sm');
        $('#kt_datatable_filter label input').addClass("form-control form-control-sm")
      }
    });
  }

  /**
   * Inicia el componente
   */
  ngOnInit(): void {
    this.processTableTallajes()
    this.getBrands()
    this.getPlatforms()
    this.getDomains()
    this.getSizeMappings()

    this.dropdownSingle = {
      singleSelection: true,
      idField: 'Id',
      textField: 'Name',
      itemsShowLimit: 6,
      searchPlaceholderText: 'Buscar',
      allowSearchFilter: true,
    };

    this.dropdownMultiple = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Seleccionar todas',
      unSelectAllText: 'Deseleccionar todas',
      searchPlaceholderText: 'Buscar',
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy() {
    clearTimeout(this.timeoutId)

    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.table) {
      this.table.destroy();
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN
}
