import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// TODO: Rehacer componente, está anticuado y no se ajusta a las necesidades actuales

@Component({
  selector: 'app-reglas',
  templateUrl: './reglas.component.html',
  styleUrls: ['./reglas.component.scss']
})
export class ReglasComponent implements OnInit {
  /* Declaración de variables a utilizar en las categorías */
  public id: any;
  public id_ruleset: any;
  public marketTables: any

  public rulesOnProducts: any
  public productRules: any[] = []

  public enumRuleType: any[] = []
  public enumAttribute_type: any[] = []
  public enumOperation: any[] = []

  public columnNames: any[] = []
  public customProperties: any[] = []
  public customPropertiesValues: any[] = [];

  public itemFieldsList: any[] = [];

  public itemFieldAlias: any[] = []

  public createName: string
  
  public internalGroups: any[] = []
  
  public internalMap: any[] = []
  public internalMapString: string
  
  multipleSelect: any[] = []
  selectedItems:any[] = [];
  dropdownSettings:IDropdownSettings = {};
  dropdownSingleSettings:IDropdownSettings = {};

  @ViewChild('multiSelect') multiSelect:MultiSelectComponent;
  @ViewChild('singleSelect') singleSelect:MultiSelectComponent;
  @ViewChild('ruleEdit') ruleEdit:MultiSelectComponent;

  page: number = 1;

  componentDestroyed$: Subject<boolean> = new Subject()

  private unsubscribe: Subscription[] = [];

  constructor(private appService: AppService, private ref: ChangeDetectorRef, private route: ActivatedRoute) {
    const routeSubscription = this.route.paramMap.subscribe(params => {
      this.getProductRules();
      this.getRulesEnum();
    });
    // this.routeSubscription = this.route.paramMap.subscribe(params => {
    // });
    this.unsubscribe.push(routeSubscription);
  }

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE
  //! GETS
  /**
   * Activa toda la recogida de información que será utilizada en los mapeos
   */
  getMarketJson() {
    let market_id: any = this.route.snapshot.paramMap.get('id')
    const appServiceSubscription = this.appService.getMarketJson(market_id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketTables = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Se listan todas las rules para un market.
   */
  getProductRules() {
    let market_id: any = this.route.snapshot.paramMap.get('id')
    const appServiceSubscription = this.appService.getProductRules(market_id, 1).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.productRules = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Se listan todas las rules para una regla.
   */
  getRulesOnProducts(id_ruleset: number) {
    let market_id: any = this.route.snapshot.paramMap.get('id')
    const appServiceSubscription = this.appService.getRulesOnProducts(market_id, id_ruleset).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.rulesOnProducts = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Se filtran las opciones de los diferentes enums para utilizarlos como opciones. Se almacenan en array.
   */
  getRulesEnum() {
  const appServiceSubscription = this.appService.getRulesEnum().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.enumRuleType = ((res[0].column_type).split('\'').join('')).split(",").map(String);
      this.enumAttribute_type = ((res[1].column_type).split('\'').join('')).split(",").map(String);
      this.enumOperation = ((res[2].column_type).split('\'').join('')).split(",").map(String);
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Se listan todos los nombres de la tabla items.
   */
  getItemFieldColumnNames() {
    const appServiceSubscription = this.appService.getItemFieldColumnNames().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.columnNames = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Se listan las propiedades de la tabla customproperties
   */
  getCustomProperties() {
    const appServiceSubscription = this.appService.getCustomProperties().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.customProperties = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la lista de valores posibles para customproperties
   * @param property 
   */
  async getCustomPropertiesValues(property: string, id_rule: number = 0) {
    const appServiceSubscription = this.appService.getCustomPropertiesValues(property).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.customPropertiesValues = res
      this.ref.detectChanges()

      if (id_rule != 0) {
        let categ: any[] = this.rulesOnProducts.find((c: { id_rule: number; })=>c.id_rule == id_rule).value.slice(1, -1).replace(/(['"])/g, "").split(',')
  
        res.forEach((i:any) => {
          categ.forEach(cat => {
            if(cat == i.Id){
              this.singleSelect.addSelected({id: i.Id, text: i.value})
            }
          });
        });
      }

      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la lista de valores posibles para una columna
   * @param column 
   */
  async getItemFieldsList(column: string) {
    const appServiceSubscription = this.appService.getItemFieldsList(column).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.itemFieldsList = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la lista de valores con mapeo de tabla
   * @param column 
   * @param table 
   */
  async getItemFieldsListTable(column: string, table: string, id_rule: number = 0) {
    if (this.multiSelect) {
      this.multiSelect.selectedItems = []
    }
    this.multipleSelect = []

    const appServiceSubscription = this.appService.getItemFieldsListTable(column, table).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.itemFieldsList = res
      this.ref.detectChanges()

      if (id_rule != 0) {
        let categ: any[] = this.rulesOnProducts.find((c: { id_rule: number; })=>c.id_rule == id_rule).value.slice(1, -1).replace(/(['"])/g, "").split(',')
  
        res.forEach((i:any) => {
          categ.forEach(cat => {
            if(cat == i.Id){
              this.multiSelect.addSelected({id: i.Id, text: i.value})
              this.ref.detectChanges()
            }
          });
        });
      }
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la lista de valores con mapeo de tabla sin group_names
   * @param column 
   * @param table 
   */
  async getItemFieldsListSpecial(column: string, table: string, id_rule: number = 0) {
    if (this.multiSelect) {
      this.multiSelect.selectedItems = []
    }
    this.multipleSelect = []

    const appServiceSubscription = this.appService.getItemFieldsListSpecial(column, table).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.itemFieldsList = res
      this.ref.detectChanges()

      console.log("Aquí llega")

      if (id_rule != 0) {
        let categ: any[] = this.rulesOnProducts.find((c: { id_rule: number; })=>c.id_rule == id_rule).value.slice(1, -1).replace(/(['"])/g, "").split(',')

        res.forEach((i: any) => {
          categ.forEach(cat => {
            if(cat == i.Id){
              this.multiSelect.addSelected({id: i.Id, text: i.value})
              this.ref.detectChanges()
            }
          });
        });
      }
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca el alias a mostrar en el front
   */
  getItemFieldAlias() {
    const appServiceSubscription = this.appService.getItemFieldAlias().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      let json_data = JSON.parse(res[0].value)

      for(var i in json_data) {
        this.itemFieldAlias.push([i, json_data [i]]);
      }
    
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la lista de internalgroups
   */
  getInternalGroups() {
    const appServiceSubscription = this.appService.getInternalGroups().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.internalGroups = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }


  //! POSTS
  /** 
   * Crea una regla de producto.
   * @param name 
   * @param marketId 
   * @param externalCat 
   * @param orden 
   * @param type 
   * @returns 
   */
  createProductRule(name: string, externalCat: string, orden: number, type: number) {
    let market_id: any = this.route.snapshot.paramMap.get('id')
    if (name == '' ) {
      Swal.fire(
        'Campo vacío',
        'Tienes que dar nombre a la regla.',
        'warning'
      )
      return
    }
    const appServiceSubscription = this.appService.createProductRule(name, market_id, externalCat, orden, type).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
      window.location.reload();
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
    this.createName = ''
  }

  /**
   * Se crea una regla de categoría.
   * @param id_ruleset 
   * @param rule_type 
   * @param attribute_type 
   * @param attribute 
   * @param operation 
   * @param value 
   * @returns 
   */
  createRule(id_ruleset: number, rule_type: string, attribute_type: string, attribute: string, operation: string, value: any, name: string) {

    for (let i = 0; i < arguments.length; i++) {
      const element = arguments[i];
      if (element == '' || element == null) {
        Swal.fire(
          'Campo vacío',
          'Tienes que dar un valor al campo '+(i-1),
          'warning'
        )
        return
      } 
    }

    let finalArray = new Array
    for (let i = 0; i < value.length; i++) {
      const e = value[i];
      finalArray.push(e.Id)
    }

    let valueJson = JSON.stringify(finalArray)

    const appServiceSubscription = this.appService.createRule(id_ruleset, rule_type, attribute_type, attribute, operation, valueJson, name).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Se crea una regla de categoría con un parámetro de número
   * @param id_ruleset 
   * @param rule_type 
   * @param attribute_type 
   * @param attribute 
   * @param operation 
   * @param value 
   * @returns 
   */
  createRuleNumber(id_ruleset: number, rule_type: string, attribute_type: string, attribute: string, operation: string, value: string, name: string) {
    for (let i = 0; i < arguments.length; i++) {
      const element = arguments[i];
      if (element == '' || element == null) {
        Swal.fire(
          'Campo vacío',
          'Tienes que dar un valor al campo '+(i-1),
          'warning'
        )
        return
      } 
    }

    let checkedNumber = /^[^0-9]\d*(\.\d+)?$/.test(value)
    if (!checkedNumber) {
      let valueSend = "["+value+"]"
      const appServiceSubscription = this.appService.createRule(id_ruleset, rule_type, attribute_type, attribute, operation, valueSend, name).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.ngOnInit();
      }, err => {
        console.log(err)
      })
      this.unsubscribe.push(appServiceSubscription);
    } else {
      Swal.fire(
        'Error de entrada en VALOR',
        'Deben ser DOS decimales y separarse por PUNTO, no por COMA. 😉',
        'warning'
      )
    }
  }

  /**
   * Duplica la ruleset y sus rules
   * @param idRuleset 
   */
  duplicateRuleset(idRuleset: number) {
    let idPlatform: any = this.route.snapshot.paramMap.get('id')
    const appServiceSubscription = this.appService.duplicateRuleset(idPlatform, idRuleset).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }
  
  //! PUTS
  /**
   * Actualiza los campos de la regla de producto.
   * @param name 
   * @param externalCat 
   * @param order 
   * @param type 
   * @param id 
   */
  updateProductRule(name: string, category: string = "", id: number) {
    const appServiceSubscription = this.appService.updateProductRule(name, category, id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Se actualizan los campos de categoría con los parámetros deseados.
   * @param type 
   * @param attType 
   * @param attribute 
   * @param operacion 
   * @param valor 
   * @param id 
   */
   updateRule(type: string, attType: string, attribute: string, operacion: string, valor: any, id: number, name: string) {

    let valueJson = "";

    let finalArray = new Array;

    if (attType == "item" || attType == "price" || attType == "discount") {
      let valorFinal = valor.replace("\"", "").replace("[", "").replace("]", "")
      valueJson = "["+valorFinal+"]"
    } 
    else {
      for (let i = 0; i < valor.length; i++) {
        const e = valor[i];
        finalArray.push(e.Id)
      }

      valueJson = JSON.stringify(finalArray)
    }
    
    const appServiceSubscription = this.appService.updateRule(type, attType, attribute, operacion, valueJson, id, name).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);

    this.itemFieldsList = []
  }

  activateRule(activate: number, id: number) {
    const appServiceSubscription = this.appService.activateRule(activate, id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  //! DELETES
  /**
   * Se elimina el registro correspondiente al id_rule.
   * @param id 
   */
  deleteProductRule(id: number) {
    const appServiceSubscription = this.appService.deleteProductRule(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Se elimina el registro correspondiente al id_rule.
   * @param id 
   */
   deleteRule(id: number) {
    const appServiceSubscription = this.appService.deleteRule(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  //! OTHERS
  
  /**
   * Gestiona la selección de los atributos a mostrar en el select de la creación y actualizción de reglas
   * @param attributeType 
   * @param attribute 
   */
  attributeSelect(attributeType: string, attribute: string) {
    console.log(attributeType, attribute)
    switch (attributeType) {
      case "customproperty":
        console.log(attribute)
        this.getCustomPropertiesValues(attribute)
        break;
      case "itemfield":
        switch (true) {
          case attribute.includes("InternalColorId"):
            this.getItemFieldsListSpecial(attribute, 'color')
            break;
          case attribute.includes("InternalBrandId"):
            this.getItemFieldsListSpecial(attribute, 'brand')
            break;
          case attribute.includes("BrandId"):
            this.getItemFieldsListSpecial(attribute, 'externalbrand')
            break;
          case attribute.includes("SizeDomainId"):
            this.getItemFieldsListSpecial(attribute, 'sizedomain_conversion')
            break;
          case attribute.includes("InternalItemGroup"):
            this.getItemFieldsListTable(attribute, 'internalgroups')
            break;
          default:
            this.getItemFieldsList(attribute)
            break;
        }
        break;
      default:
        break;
    }
  }

  /**
   * Mapea el valor del campo valores para que salgan los campos visibles para el usuario
   * @param attribute 
   * @param values 
   * @returns 
   */
  async mapValues(attribute: string, values: string, id_rule: number) {
    this.selectedItems = []
    if (this.multiSelect) {
      this.multiSelect.selectedItems = []
    }
    this.multipleSelect = []

    switch (attribute) {
      case "InternalColorId":
        await this.getItemFieldsListSpecial(attribute, 'color', id_rule)
        break;
      case "InternalBrandId":
        await this.getItemFieldsListSpecial(attribute, 'brand', id_rule)
        break;
      case "BrandId":
        await this.getItemFieldsListSpecial(attribute, 'externalbrand', id_rule)
        break;
      case "SizeDomainId":
        await this.getItemFieldsListSpecial(attribute, 'sizedomain_conversion', id_rule)
        break;
      case "Fabricacion":
        await this.getCustomPropertiesValues(attribute, id_rule)
        break;
      case "Destacado":
        await this.getCustomPropertiesValues(attribute, id_rule)
        break;
      case "item":
        return;
      case "price":
        return;
      case "discount":
        return;
      default:
        await this.getItemFieldsListTable(attribute, 'internalgroups', id_rule)
        break;
    }

    // const individualValues = (this.removeBrackets(values)).split(",")

    // this.internalMap = []
    // this.selectedItems = []

    // individualValues.forEach(e => {
    //   var mappedValue = this.mapFromItemFieldsList(e)

    //   this.internalMap.push(JSON.parse('{"Id": "' + e + '", "value": "' + mappedValue + '"}'))
    //   if (attribute != "Fabricacion" && attribute != "Destacado") {
    //     this.multiSelect.removeSelected(JSON.parse('{"Id": "' + e + '", "value": "' + (this.mapFromItemFieldsList(e) ? this.mapFromItemFieldsList(e) : e) + '"}'))
    //   } else {
    //     this.singleSelect.removeSelected(JSON.parse('{"Id": "' + e + '", "value": "' + (this.mapFromItemFieldsList(e) ? this.mapFromItemFieldsList(e) : e) + '"}'))
    //   }
    // });
  }
  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
    /**
   * Traduce los atributos
   * @param param 
   * @returns 
   */
  mapFromItemFieldAlias(param: string) {
    let res = ''
    for (let i = 0; i < this.itemFieldAlias.length; i++) {
      const e = this.itemFieldAlias[i];
      if (e[0] == param) {
        res = e[1]
        break        
      } else {
        res = param
      }
    }
    return res
  }

  /**
   * Traduce los valores
   * @param param 
   * @returns 
   */
  mapFromItemFieldsList(param: string) {
    let res = ''
    if (param == 'True' || param == 'False') {
      for (let i = 0; i < this.customPropertiesValues.length; i++) {
        const e = this.customPropertiesValues[i];
        if (e.Id == param) {
          res = e.value
          break
        } else {
          res = param
        }
      }
    } else {
      for (let i = 0; i < this.itemFieldsList.length; i++) {
        const e = this.itemFieldsList[i];
        if (e.Id == param) {
          res = e.value
          break
        } else {
          res = param
        }
      }
    }
    return res
  }

  // Con idea de traducirlos todos, claro.. Jajajaja
  traductorValores(param: string, rule: any) {
    let paramArr = param.slice(1, -1).replace(/['"]+/g, '').split(",");

    let string = paramArr.join(", ")

    return string
  }

  /**
   * Conversor a int.
   * @param param 
   * @returns 
   */
  toInt(param: string) {
    return parseInt(param)
  }

  /**
   * Limpia las variables al crear la regla para que no se mantengan.
   */
  clearVariables() {
    this.createName = ''
    this.selectedItems = []
  }

  /**
   * Quita los corchetes del string para no repetirlos al actualizar el contenido
   * @param value 
   * @returns 
   */
  removeBrackets(value: string) {
    let result = value.replace(/[\[\]']+/g,'');

    let finalResult = result.replace(/['"]+/g, '')

    return finalResult;
  }

  /**
   * Activa el onChange del select
   */
  observeSelect():void {}

  /**
   * Activa el select múltiple
   * @param multi 
   */
  initMultipleSelect(multi: boolean) {
    if (multi) {
      this.dropdownSettings = {
        singleSelection: false
      };
    }
    if (!multi) {
      this.dropdownSettings = {
        singleSelection: true
      };
    }
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Se inicia el componente con las funciones requeridas.
   */
  ngOnInit(): void {
    this.getProductRules()
    this.getMarketJson()
    this.getItemFieldColumnNames()
    this.getCustomProperties()
    this.getItemFieldAlias()
    this.getInternalGroups()

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'Id',
      textField: 'value',
      selectAllText: 'Seleccionar todas',
      unSelectAllText: 'Deseleccionar todas',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSingleSettings = {
      singleSelection: true,
      idField: 'Id',
      textField: 'value',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN

}