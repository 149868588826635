<!-- Hacer a mano el select, no funciona la función de jquery -->
<div class="card ">
    <div class="card-header card-header-stretch">
        <h3 class="card-title">Mapeo de características</h3>
    </div>
    
    <div class="card-body">
        <div style="display: flex; justify-content: space-around; align-items: center;">
            <button class="btn btn-primary saveSelection" id="kt_datatable_caracteristicas_submit"
                (click)="saveMapping()">Guardar selección</button>
            <div style="display: flex;">
                <input type="text" class="form-control searchCaracteristica" placeholder="Crear una caracteristica">
                <button class="btn btn-sm btn-primary" id="create-caracteristica" (click)="createCaracteristica()">Crear</button>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <button
                    class="btn btn-primary font-weight-bolder"
                    id="editFeatures"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_features"
                >
                    Editar características
                </button>
            </div>
        </div>
        <table id="kt_datatable_caracteristicas"
            class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom"
            role="grid" aria-describedby="kt_datatable_caracteristicas_info" style="width:100%">
            <thead id="header">
                <tr role="row">
                    <th width="10"></th>
                    <th width="10"></th>
                </tr>
            </thead>
        </table>
    </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_features">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Editor de características</h4>

                <div
                    class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                >
                    <span class="svg-icon svg-icon-2x" style="font-size: 24px"
                    >&times;</span
                    >
                </div>
            </div>
            <div class="modal-body">
                <label id="op_logistico" class="my-5"
                    ><b>Característica</b>
                </label>
                <ng-multiselect-dropdown
                    [placeholder]="'Característica'"
                    [settings]="dropdownSettings"
                    [data]="caracteristicas"
                    [(ngModel)]="caracteristicaSeleccionada"
                >
                </ng-multiselect-dropdown>
                <br>
                <label id="op_logistico" class="my-5">
                    <b>Nuevo nombre</b>
                </label> 
                <input type="text" class="form-control" name="updatedFeature" id="updatedFeature">
            </div>

            <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                Cancelar
            </button>
            <button
                type="button"
                (click)="editFeature('delete')"
                class="btn btn-danger"
                data-bs-dismiss="modal"
            >
                Borrar
            </button>
            <button
                type="button"
                (click)="editFeature('update')"
                class="btn btn-primary"
                data-bs-dismiss="modal"
            >
                Actualizar
            </button>
            </div>
        </div>
    </div>
</div>