<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
<div class="card card-custom">
  <div class="card-header" class="my-5 mx-5">
    <div>
        <button class="btn btn-primary font-weight-bolder" data-bs-target="#kt_modal_nuevo" id="anadir" data-bs-toggle="modal"
            data-bs-target="#kt_modal_nuevo"><i class="la la-plus"></i>Nueva regla</button>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        <!-- <button class="btn btn-primary font-weight-bolder" id="anadir"><i class="las la-broom"></i>Limpiar
            filtros</button> -->
    </div>
</div>
<div class="card-body">
  <div style="display: none;">
    <ng-multiselect-dropdown #avelonStateUpdate
        [placeholder]="'Estado de avelon'"
        [settings]="multipleDrowpdownSettings"
        [data]="avelonStates"
        [(ngModel)]="selectedAvelonStateUpdate"
    >
    </ng-multiselect-dropdown>
  
    <ng-multiselect-dropdown #avelonLogisticStateUpdate
        [placeholder]="'Estado logístico de avelon'"
        [settings]="multipleDrowpdownSettings"
        [data]="avelonLogisticStates"
        [(ngModel)]="selectedAvelonLogisticStateUpdate"
    >
    </ng-multiselect-dropdown>

    <ng-multiselect-dropdown #marketStateUpdate
        [placeholder]="'Estado de market'"
        [settings]="singleDropdownSettings"
        [data]="marketStates"
        [(ngModel)]="selectedMarketStateUpdate"
    >
    </ng-multiselect-dropdown>
  </div>

  <div id="kt_datatable_wrapper" class="dataTables_wrapper dt-bootstrap4">
    <div style="width: 100%; padding-left: -10px;">
      <div class="table-responsive" [style.display]="loadTable ? 'block' : 'none'">
        <table id="kt_datatable_orders_rules" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_info" style="width:100%">
          <thead>
              <tr role="row">
                  <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_orders_rules" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                  <th class="sorting_disabled" tabindex="1" aria-controls="kt_datatable_orders_rules" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                  <th class="sorting_disabled" tabindex="2" aria-controls="kt_datatable_orders_rules" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                  <th class="sorting_disabled" tabindex="3" aria-controls="kt_datatable_orders_rules" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                  <th class="sorting_disabled" tabindex="4" aria-controls="kt_datatable_orders_rules" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
              </tr>
            </thead>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" id="kt_modal_edit">
  <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Editar {{reglaAEditar != '' ? reglaAEditar : "regla"}}</h4>
            <!-- <button type="button" class="btn-close" aria-label="Close"
                (click)="modal.dismiss('Cross click')"></button> -->
        </div>
  
        <div class="modal-body">
  
          <label class="my-5"><b>Estados de avelon</b></label>
          <ng-multiselect-dropdown #avelonStateUpdate
              [placeholder]="'Estado de avelon'"
              [settings]="multipleDrowpdownSettings"
              [data]="avelonStates"
              [(ngModel)]="selectedAvelonStateUpdate"
          >
          </ng-multiselect-dropdown>
  
          <label class="my-5"><b>Estados logísticos de avelon</b></label>
          <ng-multiselect-dropdown #avelonLogisticStateUpdate
              [placeholder]="'Estado logístico de avelon'"
              [settings]="multipleDrowpdownSettings"
              [data]="avelonLogisticStates"
              [(ngModel)]="selectedAvelonLogisticStateUpdate"
          >
          </ng-multiselect-dropdown>
  
          <label class="my-5"><b>Estados del market</b></label>
          <ng-multiselect-dropdown #marketStateUpdate
              [placeholder]="'Estado de market'"
              [settings]="singleDropdownSettings"
              [data]="marketStates"
              [(ngModel)]="selectedMarketStateUpdate"
          >
          </ng-multiselect-dropdown>
  
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-light"
                data-bs-dismiss="modal">Cerrar</button>
            <button type="button" class="btn btn-primary" (click)="updateStatesMapping()" data-bs-dismiss="modal">Guardar</button>
        </div>
      </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" id="kt_modal_nuevo">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
          <h4 class="modal-title">Crear regla</h4>
          <!-- <button type="button" class="btn-close" aria-label="Close"
              (click)="modal.dismiss('Cross click')"></button> -->
      </div>

      <div class="modal-body" #statesCreate>

        <label for="avelonStateCreate" class="my-5"><b>Estados de avelon</b></label>
        <ng-multiselect-dropdown #avelonStateCreate
            [placeholder]="'Estado de avelon'"
            [settings]="multipleDrowpdownSettings"
            [data]="avelonStates"
            [(ngModel)]="selectedAvelonState"
        >
        </ng-multiselect-dropdown>

        <label for="avelonLogisticStateCreate" class="my-5"><b>Estados logísticos de avelon</b></label>
        <ng-multiselect-dropdown #avelonLogisticStateCreate
            [placeholder]="'Estado logístico de avelon'"
            [settings]="multipleDrowpdownSettings"
            [data]="avelonLogisticStates"
            [(ngModel)]="selectedAvelonLogisticState"
        >
        </ng-multiselect-dropdown>

        <label for="marketStateCreate" class="my-5"><b>Estados del market</b></label>
        <ng-multiselect-dropdown #marketStateCreate
            [placeholder]="'Estado de market'"
            [settings]="singleDropdownSettings"
            [data]="marketStates"
            [(ngModel)]="selectedMarketState"
        >
        </ng-multiselect-dropdown>

      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-light"
              data-bs-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="saveMap()" data-bs-dismiss="modal">Guardar</button>
      </div>
    </div>
  </div>
</div>

</div>
