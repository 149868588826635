import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sga-configuration',
  templateUrl: './sga-configuration.component.html',
  styleUrls: ['./sga-configuration.component.scss']
})
export class SgaConfigurationComponent implements OnInit {

  dropdownMulti:IDropdownSettings = {};
  dtOptions: DataTables.Settings = {};
  
  selectedConfigShop: any[] = []
  @ViewChild('configShopSelection') configShopSelection:MultiSelectComponent;

  public configParams: any[] = []
  public configShops: any[] = []
  
  private timeoutId!: any;

  componentDestroyed$: Subject<boolean> = new Subject()

  private unsubscribe: Subscription[] = [];

  constructor(private appService: AppService, private ref: ChangeDetectorRef) { }
  
  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE

  //! GETS
  //#region GETS
  getConfigParams() {
    const appServiceSubscription = this.appService.getConfigParams().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.configParams = res
      this.ref.detectChanges()
      this.populateForm()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  getConfigShops() {
    const appServiceSubscription = this.appService.getAllConfigShops().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.configShops = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion GETS

  //! PUTS
  //#region PUTS
  /**
   * 
   */
  updateConfig() {
    let avelonUser = $("#avelonUser").val()
    let repoUser = $("#repoUser").val()
    let warehouses = ''
    let prioridad = $("#prioridad").val()

    this.selectedConfigShop.forEach(e => {
      warehouses += e.id+','
    });

    warehouses = warehouses.substring(0, warehouses.length - 1)

    let datos = {
      avelonUser: avelonUser,
      repoUser: repoUser,
      warehouses: warehouses,
      prioridad: prioridad
    }

    const appServiceSubscription = this.appService.updateConfig(datos).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      Swal.fire('Actualizado', '', 'success')
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion PUTS

  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  populateForm() {
    let avelonUser = this.configParams.find(x => x.key == "USUARIO_AVELON_ELOGISTIK")
    let repoUser = this.configParams.find((x => x.key == "USUARIO_REPO_TRASPASOS"))
    let warehouses = this.configParams.find(x => x.key == "WAREHOUSES")
    let prioridad = this.configParams.find(x => x.key == "PURCHASE_ORDER_SHOP_PRIORITY")

    $("#avelonUser").val(avelonUser.value)
    $("#repoUser").val(repoUser.value)
    $("#prioridad").val(prioridad.value)
    
    this.timeoutId = setTimeout(() => {
      warehouses.value.split(",").forEach((e: string) => {
        let shop = this.configShops.find(x => x.id == e)
        if (shop) {
          this.configShopSelection.addSelected({id: shop.id, text: shop.Name})
        }
      });
    }, 500);
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Inicia el componente
   */
  ngOnInit(): void {
    this.getConfigShops()
    this.getConfigParams()
    // this.processTableData()

    this.dropdownMulti = {
      singleSelection: false,
      idField: 'id',
      textField: 'Name',
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
    ngOnDestroy() {
      clearTimeout(this.timeoutId)
  
      this.unsubscribe.forEach((sb) => sb.unsubscribe());
  
      this.componentDestroyed$.next(true)
      this.componentDestroyed$.complete()
    }
  //#endregion INICIALIZACIÓN

}