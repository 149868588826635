export class OrderCharges {
  ChargeDescription: string;
  Amount: number;
  
    constructor(ChargeDescription: string, Amount: number) {
        {
          this.ChargeDescription = ChargeDescription;
          this.Amount = Amount;
        }
    }
  }