import { Renderer2, ChangeDetectorRef } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { error } from 'jquery';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-operadores-logisticos',
  templateUrl: './operadores-logisticos.component.html',
  styleUrls: ['./operadores-logisticos.component.scss']
})
export class OperadoresLogisticosComponent implements OnInit {

  public seurUsers: any[] = []

  componentDestroyed$: Subject<boolean> = new Subject()
  private timeoutId!: any;

  constructor(private renderer: Renderer2, private appService: AppService, private ref: ChangeDetectorRef, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getSeurUsers()

    this.timeoutId = setTimeout(() => {
      (<HTMLElement>$("#mustClick").get(0)).click()
    }, 500);
  }

  getSeurUsers() {
    // const appServiceSubscription = this.appService.getSeurUsers().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
    //   this.seurUsers = res.data
    //   console.log(res.data)
    //   this.ref.detectChanges()
    // }, err => { console.log(err) })

    this.appService.getSeurUsers().subscribe(
      data => {
        this.seurUsers = data;
        this.ref.detectChanges();
      },
      error => {
        console.error('Error:', error);
      }
    );
  }

  deleteUser(id: number) {
    // const appServiceSubscription = this.appService.deleteSeurUser(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
    //   this.getSeurUsers()
    // }, err => { console.log(err) })
    Swal.fire({
      title: '¿Eliminar usuario?',
      text: '¿Estás seguro de que deseas eliminar este usuario?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const appServiceSubscription = this.appService.deleteSeurUser(id).subscribe(res => {
          Swal.fire('!Eliminado!', '', 'success')
          const index = this.seurUsers.findIndex(item => item.shop_id === id);
    
          // Remove the item at the found index
          if (index !== -1) {
            this.seurUsers.splice(index, 1);
          }
      
          this.ref.detectChanges();
        }, err => {
          console.log(err)
        })

        // this.unsubscribe.push(appServiceSubscription);
      } else if (result.isDenied) {
        Swal.fire('No se guardaron los cambios', '', 'info')
      }
    })
  }

  poblarModalUsers(id: number){
    console.log('entra')
    this.appService.getSeurUser(id).subscribe(res => {
      console.log(res)
      let accountNumber = res[0].account_number


      $('#kt_modal_edit_seur_user #id_seurUserEdit').val(res[0].shop_id)
      $('#kt_modal_edit_seur_user #account_numberEdit').val(accountNumber)

      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
  }

  editSeurUser(){
    let id = $('#kt_modal_edit_seur_user #id_seurUserEdit').val()
    let accountNumber = $('#kt_modal_edit_seur_user #account_numberEdit').val()

    if(accountNumber == ''){
      Swal.fire({
        title: 'Ooops',
        text: 'El campo número de cuenta no puede estar vacío',
        icon: 'warning',
      })
    } else {
      this.appService.editSeurUser(id, accountNumber).subscribe(res => {
        this.getSeurUsers()
        this.modalService.dismissAll()
      }, err => {
        console.log(err)
      })
    }
  }

  saveSeurUser(){
    let id = $('#kt_modal_new_seur_user #new_shop_id').val()
    let accountNumber = $('#kt_modal_new_seur_user #new_account_number').val()

    if(accountNumber == '' || id == ''){
      Swal.fire({
        title: 'Ooops',
        text: 'Los campos no pueden estar vacíos',
        icon: 'warning',
      })
    } else {
      console.log(id, accountNumber)
      let values = `(${id}, '${accountNumber}')`
      this.appService.saveSeurUser(values).subscribe(res => {
        this.getSeurUsers()
        this.modalService.dismissAll()
      }, err => {
        console.log(err)
      })
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timeoutId)
  }
}
