import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-dynamic-mapping',
  templateUrl: './dynamic-mapping.component.html',
  styleUrls: ['./dynamic-mapping.component.scss']
})
export class DynamicMappingComponent implements OnInit {
  public marketTables: any
  public marketsActive: any
  public mappingAttributes: any
  public listDefaultData: any = []

  public attributeType = ''
  public attributeName = ''

  public internalColors: any
  public internalBrands: any
  public internalAges: any
  public internalPlantilla: any
  public internalGender: any
  public internalCategories: any
  public internalSuelas: any
  public internalSeasons: any
  public internalCorte: any
  public internalForro: any
  public internalTacon: any
  public internalSport: any
  public internalLogisticStates: any
  public internalIso: any

  public marketColors: any
  public marketBrands: any
  public marketAges: any
  public marketPlantilla: any
  public marketGender: any
  public marketCategories: any
  public marketSuelas: any
  public marketSeasons: any
  public marketCorte: any
  public marketForro: any
  public marketTacon: any
  public marketSport: any
  public marketLogisticStates: any
  public mappingTabId: any
  public marketIso: any

  public listMapped: any
  listInternalMapped: any[] = [];
  listExternalMapped: any[] = [];

  public listToAdd: any = []
  public listToUpdate: any = []
  public listToDelete: any = []

  private timeoutId!: any;

  private unsubscribe: Subscription[] = [];

  table: DataTables.Api

  componentDestroyed$: Subject<boolean> = new Subject()

  constructor(private appService: AppService, private ref: ChangeDetectorRef,private route: ActivatedRoute, private router: Router) {
    const routeSubscription = this.route.paramMap.subscribe(params => {
      this.getMarketJson();
    });
    this.unsubscribe.push(routeSubscription);
    //* Se fuerza la recarga de la página con el cambio de parámetro en la url.
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  //! ############################### METODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE
    //! ############################### MARKET INFO ###############################
  //#region MARKET INFO
  /**
   * Activa toda la recogida de información que será utilizada en los mapeos
   */
  getMarketJson() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    const appServiceSubscription = this.appService.getMarketJson(market_id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.marketTables = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Recoge los parámetros que deben estar activos en los mapeos
   */
  getMarketJsonActives() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    const appServiceSubscription = this.appService.getMarketJsonActives(market_id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.marketsActive = res
        this.mappingAttributes = JSON.parse(res[0].mapping_attributes)

        this.gestorInicio(this.mappingAttributes[0].name)

        this.checkMappingTab()
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Coge el id de tab para el mapeo correspondiente
   * @param attribute 
   * @param name 
   */
  getMappingTabId(attribute: string, name: string) {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    const appServiceSubscription = this.appService.getMappingTabId(market_id, name, attribute).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.mappingTabId = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Comprueba, si no, inserta los nuevos valores
   */
  checkMappingTab() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    const appServiceSubscription = this.appService.checkMappingTab(market_id, this.marketsActive[0].mapping_attributes).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion MARKET INFO

  //! ############################### GESTIÓN DE INICIO ###############################
  //#region GESTIÓN DE INICIO
  /**
   * Inicia los datos de la categoría que se requiera
   * @param attribute categoría del dato a iniciar
   */
  gestorInicio(attribute: string) {
    switch (attribute) {
      case 'Color':
        this.initMarketColor()
        break;
      case 'Genero':
        this.initMarketGender()
        break;
      default:
        break;
    }
  }

  /**
   * Inicia la información de color del market
   */
  initMarketColor() {
    let market_id: any = this.route.snapshot.paramMap.get('id')
   
    this.getInternalColors("color")

    let parsed = JSON.parse(this.marketTables[0].table_schema)
    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_color_data, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.marketColors = res;
        this.listDefaultData = this.marketColors
        this.attributeType = "InternalColor"
        this.attributeName = "Color"

        this.getMappingTabId(this.attributeType, this.attributeName)
      }, err => {
        console.log(err)
      })

      this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Inciia la información de género del market
   */
  initMarketGender() {
    let market_id: any = this.route.snapshot.paramMap.get('id')
   
    this.getInternalGender()

    let parsed = JSON.parse(this.marketTables[0].table_schema)
    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_genders_data, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.marketGender = res;
        this.listDefaultData = this.marketGender
        this.attributeType = "InternalItemGroup02"
        this.attributeName = "Genero"

        this.getMappingTabId(this.attributeType, this.attributeName)
      }, err => {
        console.log(err)
      })

      this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion GESTIÓN DE INICIO
  
  //! ############################### GESTIÓN DE INFORMACIÓN ###############################
  //#region GESTIÓN DE INFORMACIÓN
  /**
   * Gestor de funciones para mapeo dinámico
   * @param attribute atributo que se quiere utilizar
   * @param attType tipo de atributo
   */
  gestorFunciones(attribute: string, attType: string) {
    let market_id: any = this.route.snapshot.paramMap.get('id')
    // if (market_id == 33) {
    //   attType = "Gender"
    //   attribute = "Gender"
    // }
    this.getMappingTabId(attType, attribute)
    switch (attribute) {
      case 'Color':
        this.getMarketColors()
        break;
      case 'Edad':
        this.getMarketAges()
        break;
      case 'Genero':
        this.getMarketGenders()
        break;
      case 'Marca':
        this.getMarketBrands()
        break;
      case 'Temporada':
        this.getMarketSeasons()
        break;
      case 'Suela':
        this.getMarketSuelas()
        break;

      case 'Forro':
        switch (market_id) {
          case 6:
            this.getMarketForroSpartoo()
            break;
          default:
            this.getMarketForro()
            break;
        }
        break;
      case 'Corte':
        switch (market_id) {
          case 6:
            this.getMarketCorteSpartoo()
            break;
          default:
            this.getMarketCorte()
            break;
        }
        this.getMarketCorte()
        break;
      case 'Tacon':
        this.getMarketHeight()
        break;
      case 'Plantilla':
        this.getMarketPlantilla()
        break;
      case 'Category':
        this.getMarketCategories()
        break;
      case 'Deporte':
        this.getMarketSports()
        break;
      case 'ISO':
        this.getMarketIso()
        break;
      default:
        break;
    }
    this.timeoutId = setTimeout(() => {
      this.checkDynamicMapping(this.attributeType);
    }, 500);
  }

  //? Comprobación para mientras no se cambia el componente de Mapeos
  conditionMiravia(name: string) {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    if (market_id == 7) {
      if (name == 'Category' || name == 'Size') {
        return false
      }
    }

    return true
  }
 

  //#region COLORES
  /**
   * Extrae los datos internos de colores
   * @param tabla nombre de la tabla que se quiere utilizar para acceder a la información de colores
   */
  getInternalColors(tabla: string) {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    const appServiceSubscription = this.appService.getMarketTableData(market_id, tabla, 'Name').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.internalColors = res
      }, err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Extrae los datos de los colores del market y actualiza los datos para la datatable
   */
  getMarketColors() {
    let market_id: any = this.route.snapshot.paramMap.get('id')
   
    this.getInternalColors("color")

    let parsed = JSON.parse(this.marketTables[0].table_schema)
    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_color_data, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.marketColors = res;
        this.listDefaultData = this.marketColors
        this.attributeType = "InternalColor"
        this.attributeName = "Color"

        this.getMappingTabId(this.attributeType, this.attributeName)

        this.timeoutId = setTimeout(() => {
          this.processTable(this.internalColors)
        }, 600);
      }, err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region EDADES
  /**
   * Extrae los datos internos de edades
   */
  getInternalAges() {
    const appServiceSubscription = this.appService.getMarketTableDataGroups('InternalItemGroup02').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalAges = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  
  /**
   * Extrae los datos externos de edades
   */
  getMarketAges() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalAges()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_age_data, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketAges = res
      this.listDefaultData = this.marketAges
      this.attributeType = "InternalItemGroup03"
      this.attributeName = "Edad"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalAges)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region GÉNERO
  /**
   * Extrae los datos internos de género
   */
   getInternalGender() {
    const appServiceSubscription = this.appService.getMarketTableDataGroups('InternalItemGroup02').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalGender = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Extrae los datos externos de género
   */
  getMarketGenders() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalGender()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_genders_data, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketGender = res
      this.listDefaultData = this.marketGender
      // if (market_id == 33) {
      //   this.attributeType = "Gender"
      //   this.attributeName = "Gender"
      // }
      // else {
      //   this.attributeType = "InternalItemGroup02"
      //   this.attributeName = "Genero"
      // }

      this.attributeType = "InternalItemGroup02"
      this.attributeName = "Genero"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalGender)
      }, 700);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region MARCAS
  /**
   * Extrae los datos internos de marcas
   * @param tabla 
   */
  getInternalBrands(tabla: string) {
    let market_id: any = this.route.snapshot.paramMap.get('id')
    const appServiceSubscription = this.appService.getMarketTableData(market_id, tabla, 'Name').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalBrands = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Extrae los datos externos de marcas
   */
  getMarketBrands() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalBrands("externalbrand")

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_brand_data, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe((res: any) => {
      this.marketBrands = res
      this.listDefaultData = this.marketBrands
      this.attributeType = "BrandId"
      this.attributeName = "Marca"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalBrands)
      }, 600);

    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region CATEGORÍAS
  /**
   * Extrae los datos internos de categorías
   */
  getInternalCategories() {
    const appServiceSubscription = this.appService.getMarketTableDataGroups('InternalItemGroup04').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalCategories = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  
  /**
   * Extrae los datos externos de categorías
   */
  getMarketCategories() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalCategories()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_categories_data, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketCategories = res
      this.listDefaultData = this.marketCategories
      this.attributeType = "InternalCategory"
      this.attributeName = "Category"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalCategories)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region TEMPORADAS
  /**
   * Extrae los datos internos de temporadas
   */
   getInternalSeasons() {
    const appServiceSubscription = this.appService.getInternalSeasons().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalSeasons = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Extrae los datos externos de categorías
   */
  getMarketSeasons() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalSeasons()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_season_data, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketSeasons = res
      this.listDefaultData = this.marketSeasons
      this.attributeType = "InternalSeasonId"
      this.attributeName = "Temporada"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalSeasons)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  getInternalSuelas() {
    const appServiceSubscription = this.appService.getMarketTableDataGroups('InternalItemGroup11').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalSuelas = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  getMarketSuelas() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalSuelas()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_suela_data, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketSuelas = res
      this.listDefaultData = this.marketSuelas
      this.attributeType = "InternalItemGroup11"
      this.attributeName = "Suela"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalSuelas)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region FORRO
  /**
   * Extrae los datos internos de forro
   */
   getInternalForro() {
    const appServiceSubscription = this.appService.getMarketTableDataGroups('InternalItemGroup10').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalForro = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Extrae los datos externos de tacón
   */
  getMarketForro() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalForro()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_forro_data, "id").pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketForro = res
      this.listDefaultData = this.marketForro
      this.attributeType = "InternalItemGroup10"
      this.attributeName = "Forro"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalForro)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region CORTE
  /**
   * Extrae los datos internos de corte
   */
   getInternalCorte() {
    const appServiceSubscription = this.appService.getMarketTableDataGroups('InternalItemGroup09').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalCorte = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Extrae los datos externos de corte
   */
  getMarketCorte() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalCorte()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_corte_data, "id").pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketCorte = res
      this.listDefaultData = this.marketCorte
      this.attributeType = "InternalItemGroup09"
      this.attributeName = "Corte"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalCorte)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region TACÓN
  /**
   * Extrae los datos internos de tacón
   */
   getInternalHeight() {
    const appServiceSubscription = this.appService.getMarketTableDataGroups('InternalItemGroup05').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalTacon = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Extrae los datos externos de tacón
   */
  getMarketHeight() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalHeight()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_tacon_data, "id").pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketTacon = res

      this.listDefaultData = this.marketTacon
      this.attributeType = "InternalItemGroup05"
      this.attributeName = "Tacon"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalTacon)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region PLANTILLA
  /**
   * Extrae los datos internos de plantillas
   */
  getInternalPlantilla() {
    const appServiceSubscription = this.appService.getMarketTableDataGroups('InternalItemGroup10').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalPlantilla = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Extrae los datos externos de plantillas
   */
  getMarketPlantilla() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalPlantilla()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_plantilla_data, 'id').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketPlantilla = res
      this.listDefaultData = this.marketPlantilla
      this.attributeType = "InternalItemGroup10"
      this.attributeName = "Plantilla"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalPlantilla)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region DEPORTE
  getInternalSports() {
    let market_id: any = this.route.snapshot.paramMap.get('id')
    const appServiceSubscription = this.appService.getMiraklSportCategories(market_id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalSport = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  getMarketSports() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalSports()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_sport_data, 'id').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketSport = res
      this.listDefaultData = this.marketSport
      this.attributeType = "Sport"
      this.attributeName = "Deporte"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalSport)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion
 
  //#region ISO
  getInternalIso() {
    const appServiceSubscription = this.appService.getCountryIsos().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.internalIso = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  getMarketIso() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getInternalIso()

    let parsed = JSON.parse(this.marketTables[0].table_schema)

    const appServiceSubscription = this.appService.getMarketTableData(market_id, parsed.table_country_data, 'iso_mirakl').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketIso = res
      this.listDefaultData = this.internalIso
      this.attributeType = "Country"
      this.attributeName = "ISO"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.marketIso)
      }, 1000);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion

  //#region SPARTOO
  /**
   * Extrae los datos externos de corte
   */
   getMarketCorteSpartoo() {
    this.getInternalCorte()

    const appServiceSubscription = this.appService.getMarketTableDataCompositionSpartoo("product_composition").pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketCorte = res
      this.listDefaultData = this.marketCorte
      this.attributeType = "InternalItemGroup09"
      this.attributeName = "Corte"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalCorte)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Extrae los datos externos de forro
   */
  getMarketForroSpartoo() {
    this.getInternalForro()

    const appServiceSubscription = this.appService.getMarketTableDataCompositionSpartoo("voering_composition").pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketForro = res
      this.listDefaultData = this.marketForro
      this.attributeType = "InternalItemGroup10"
      this.attributeName = "Forro"

      this.getMappingTabId(this.attributeType, this.attributeName)

      this.timeoutId = setTimeout(() => {
        this.processTable(this.internalForro)
      }, 600);
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion
  //#endregion GESTIÓN DE INFORMACIÓN

  /**
   * Guarda, actualiza o hace las dos
   */
  saveOrUpdate() {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.getMappingTabId(this.attributeType, this.attributeName)

    var dataMarketInside = this.table.data();
    var mapInternal: any = [];
    var mapMarket: any = [];

    Object.values(dataMarketInside).forEach(element => {
      var select: any;

      if (element["Name"] != undefined) {
        mapInternal.push(element["Id"])
      } 
      else if (element[0] != undefined) {
          var dataMarket: any[] = element[0]["aoData"]

          dataMarket.forEach(element2 => {
            select = element2["nTr"]["children"][2]["children"];
            if ($(select).find("option:selected").val() != '0') {
              mapMarket.push($(select).find("option:selected").val())
            }
          });
        }
    });

    const selects = document.querySelectorAll("[recomendado]")

    for (let i = 0; i < selects.length; i++) {
      let e = selects[i]

      this.listToAdd.push({"id":  e.attributes.getNamedItem("recomendado")?.value, "value": e.id})
    }

    var valuesLine = "";
    var updateWhen = "";
    var updateIn = "";
    var valuesDelete = "";

    if (this.listToAdd.length > 0) {
      this.listToAdd.forEach((e: any) => {
        valuesLine += "(" + market_id + ",'" + e.id + "','" + e.value + "','" + this.attributeType + "', " + this.mappingTabId[0].id_mapping_tab + "),"
      });
    }

    let valuesLineFinal = valuesLine.replace(/,\s*$/, "")

    if (this.listToUpdate.length > 0) {
      this.listToUpdate.forEach((e: any) => {
        updateWhen += "WHEN internal_value = '" + e.id + "' THEN '" + e.value + "' "
        updateIn += "\"" + e.id + "\"" + ","
      });
    }

    if (this.listToDelete.length > 0) {
      this.listToDelete.forEach((e: any) => {
        valuesDelete += "\"" + e.id + "\","
      });
      valuesDelete = valuesDelete.substring(0, valuesDelete.length - 1)
    }

    if (valuesLineFinal != "") {
      const appServiceSubscription = this.appService.createDynamicMapping(valuesLineFinal, market_id, this.attributeName, this.attributeType).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        }, err => {
          console.log(err)
        })

        this.unsubscribe.push(appServiceSubscription);
    }

    if (updateWhen != "" && updateIn != "") {
      const appServiceSubscription = this.appService.updateDynamicMapping(updateWhen, updateIn.substring(0, updateIn.length - 1), market_id, this.mappingTabId[0].id_mapping_tab).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        }, err => {
          console.log(err)
        })

        this.unsubscribe.push(appServiceSubscription);
    }

    if (valuesDelete != "") {
      const appServiceSubscription = this.appService.deleteMapping(market_id, valuesDelete).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      }, err => {
        console.log(err)
      })

      this.unsubscribe.push(appServiceSubscription);
    }

    if (market_id == 33) {
      const appServiceSubscription = this.appService.updateMiraklIso(this.listToAdd[0].value, this.listToAdd[0].id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        }, err => {
          console.log(err)
        })

        this.unsubscribe.push(appServiceSubscription);
    }

    window.location.reload();
  }

  firstCheck() {
    const selects = document.getElementsByClassName("mapsToAdd")

    for (let i = 0; i < selects.length; i++) {
      let e = selects[i]

      if ( e.querySelectorAll("option:checked")[0].id != '') {
        if (this.listToAdd.some((x: { id: string; }) => x.id === e.id)) {
          let index = this.listToAdd.indexOf(e.id)
          this.listToAdd.splice(index, 1)
        }

        //? Por qué estaba esto aquí?!
        // this.listToAdd.push({"id":e.id, "value": e.querySelectorAll("option:checked")[0].id})
      }
    }
  }
  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Comprueba si está activo el mapeo y lo muestra o no
   * @param param 
   * @returns 
   */
  isItActive(param: string) {
    let res = false

    if (this.marketsActive) {
      let parsed = JSON.parse(this.marketsActive[0].mapping_attributes)

      for (let i = 0; i < parsed.length; i++) {
        const e = parsed[i];

        if ((e.name).toLowerCase() == param) {
          res = true
          break
        }
      }
    }
    return res
  }

  /**
   * Comprueba si ya existe el mapeo para mandar aviso
   * @param attType 
   */
  checkDynamicMapping(attType: string) {
    let market_id: any = this.route.snapshot.paramMap.get('id')

    this.listExternalMapped = []
    this.listInternalMapped = []

    this.mappingTabId

    if (attType != 'Country') {
      const appServiceSubscription = this.appService.checkDynamicMapping(market_id, attType, this.mappingTabId[0].id_mapping_tab).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          this.listMapped = res

          console.log(res)
  
          this.listMapped.forEach((element: any) => {
            this.listInternalMapped.push(element["internal_value"].toString())
            this.listExternalMapped.push(element["external_value"].toString())
          });
        }, err => {
          console.log(err)
        })

        this.unsubscribe.push(appServiceSubscription);
    }
    else {
      const appServiceSubscription = this.appService.getMiraklIsoCodes().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          this.listMapped = res
  
          this.listMapped.forEach((element: any) => {
            if (element["internal_value"] != '') {
              this.listInternalMapped.push(element["internal_value"].toString())
              this.listExternalMapped.push(element["external_value"].toString())
            }
          });
        }, err => {
          console.log(err)
        })

        this.unsubscribe.push(appServiceSubscription);
    }
  }

  /**
   * Limpia las listas para poder ser rellenadas de nuevo
   */
  clearLists() {
    this.timeoutId = setTimeout(() => {
      this.listToAdd = []
      this.listToUpdate = []
    }, 550);
  }
  //#endregion UTILS

  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Inicia el componente
   */
    /**
   * Procesa la información para generar la datatable
   * @param data 
   */
  processTable(data: any) {
    this.table = $("#kt_datatable_mapping").DataTable({
      columnDefs: [
        { "searchable": false, "targets": 2 }
      ],
      paging: false,
      destroy: true,
      language: {
        "processing": `<span>Cargando...
        </span>`,
        "lengthMenu": "Mostrar _MENU_ registros",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "search": "Buscar:",
        "loadingRecords": " ",
        "paginate": {
          "first": "Primero",
          "last": "Último",
          "next": ">",
          "previous": "<"
        },
        "aria": {
          "sortAscending": ": Activar para ordenar la columna de manera ascendente",
          "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      },

      data: data,
      orderCellsTop: true,

      columns: [
        {
          title: 'MAPEADO', data: 'Id', width: "6%",
          render: (d) => {
            if (this.listInternalMapped.includes(d.toString())) {
              return `<a style="font-size:30px; color: green;">&check;</a>`
            } else {
              return `<a style="font-size:30px; color: red;">&times;</a>`
            }
          }
        },

        { title: 'AVELON', data: 'Name' },
        {
          title: 'MARKET', data: null,
          render: (d) => {
            var select = `<select class="form-select mapsToAdd" aria-label="Default select example" id="` + d["Id"] + `">`

            select += `<option value="0">Selecciona una opción</option>`

            this.listDefaultData.forEach((element: any) => {
              this.listMapped.forEach((element2: any) => {
                if (element2["internal_value"] === d["Id"].toString() && element2["external_value"] === element["id"] + "") {

                  select += `<option selected id="${element["id"]}" value="${element["id"]}">${element["value"]} - ${element["id"]}</option>`
                }
              });

              if ((typeof element["value"] == 'number' ? element["value"] : element["value"].toUpperCase() ) == d["Name"] && !this.listExternalMapped.includes(element["id"] + "")) {

                select += `<option selected recomendado="${d["Id"]}" id="${element["id"]}" value="${element["id"]}">${element["value"]} - ${element["id"]} (Recomendado)</option>`

              } else 
              if (!select.includes(`<option selected id="${element["id"]}" value="${element["id"]}">${element["value"]} - ${element["id"]}</option>`) && !select.includes(`<option selected recomendado="${d["Id"]}" id="${element["id"]}" value="${element["id"]}">${element["value"]} - ${element["id"]} (Recomendado)</option>`)) {

                select += `<option id="${element["id"]}" value="${element["id"]}">${element["value"]} - ${element["id"]}</option>`
              }
            });
            return select
          }
        },
      ],
      order: [[1, 'asc']],
    });

    // Se añade evento listener al selector de opciones
    const selects = document.getElementsByClassName("mapsToAdd")

    this.firstCheck()

    for (let i = 0; i < selects.length; i++) {
      let e = selects[i];

      // Se añade un evento a cada uno de los selects de market
      e.addEventListener('change', ()=> {
        // En caso de existir previamente un mapeo, se incluye en la lista de actualización
        if (this.listInternalMapped.includes(e.id.toString())) {
          if (e.querySelectorAll("option:checked")[0].id != "") {
            // En caso de que exista ya un mapeo en la lista de actualización, se elimina para poder meter la nueva opción
            if (this.listToUpdate.some((x: { id: string; }) => x.id === e.id)) {
              let index = this.listToUpdate.indexOf(e.id)
              this.listToUpdate.splice(index, 1)
            }
  
            this.listToUpdate.push({"id":e.id, "value": e.querySelectorAll("option:checked")[0].id})
          }
          else {
            this.listToDelete.push({"id":e.id, "value": e.querySelectorAll("option:checked")[0].id})
          }
        }
        // Si no existe un mapeo, se incluye en la lista de inserts
        else {
          if (e.querySelectorAll("option:checked")[0].id != "") {
            // En caso de que exista ya un mapeo en la lista de inserts, se elimina para poder meter la nueva opción
            if (this.listToAdd.some((x: { id: string; }) => x.id === e.id)) {
              let index = this.listToAdd.indexOf(e.id)
              this.listToAdd.splice(index, 1)
            }
  
            this.listToAdd.push({"id":e.id, "value": e.querySelectorAll("option:checked")[0].id})
          }
          else {
            if (this.listToAdd.some((x: { id: string; }) => x.id === e.id)) {
              let index = this.listToAdd.indexOf(e.id)
              this.listToAdd.splice(index, 1)
            }
          }
        }
      })
    }
  }
  
  ngOnInit(): void {
    this.table;
    this.getMarketJson()
    this.getMarketJsonActives()

    this.timeoutId = setTimeout(() => {
      (<HTMLElement>$("#mustClick").get(0)).click()
    }, 700);
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy() {
    clearTimeout(this.timeoutId)

    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.table) {
      this.table.destroy();
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion 
}