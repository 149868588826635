import { OrderCharges } from "./OrderCharges.model";
import { OrderLines } from "./OrderLines.model";
import { OrderTotalDiscounts } from "./OrderTotalDiscunts.model";

export class Order {

  ExternalId: string;
  MarketId: string;
  Origin: string;
  OrderDateTime: string;
  EMailAddress: string;
  Phone: string;
  ShippingMode: number;
  ShippingHandler: string;
  ShippingService: string;
  ShippingShop: number;
  ShippingServiceAux: string;
  RequestedDeliveryDate: string;
  PaymentRef: string;
  InvoiceCompanyName: string;
  InvoiceAddressee1: string;
  InvoiceAddressee2: string;
  InvoiceAddressComplement1: string;
  InvoiceAddressComplement2: string;
  InvoiceAddressComplement3: string;
  InvoiceFiscalNumber: string;
  InvoiceAddress1: string;
  InvoiceAddress2: string;
  InvoiceZipCode: string;
  InvoiceCity: string;
  InvoiceProvince: string;
  InvoiceCountryId: string;
  ShippingCompanyName: string;
  ShippingAddressee1: string;
  ShippingAddressee2: string;
  ShippingAddress1: string;
  ShippingAddress2: string;
  ShippingAddressComplement1: string;
  ShippingAddressComplement2: string;
  ShippingAddressComplement3: string;
  ShippingZipCode: string;
  ShippingCity: string;
  ShippingProvince: string;
  ShippingCountryId: string;
  ShippingPhone: string;
  ShippingNote: string;
  ExternalPaymentMethod: string;
  ShippingCharge: number;
  Lines: OrderLines[];
  TotalDiscounts: OrderTotalDiscounts[];
  Charges: OrderCharges[];
  Is_guest: number;
  Sg_customer_mail: string;
  Sg_customer_phone: string;
  Sg_customer_name: string;
  Sg_customer_lastname:string;
  check_privacy: Number;
  
  constructor(
    ExternalId: string, 
    MarketId: string, 
    Origin: string, 
    OrderDateTime: string, 
    EMailAddress: string, 
    Phone: string, 
    ShippingMode: number, 
    ShippingHandler: string, 
    ShippingService: string, 
    ShippingShop: number, 
    ShippingServiceAux: string, 
    RequestedDeliveryDate: string, 
    PaymentRef: string, 
    InvoiceCompanyName: string, 
    InvoiceAddressee1: string, 
    InvoiceAddressee2: string, 
    InvoiceAddressComplement1: string, 
    InvoiceAddressComplement2: string,
    InvoiceAddressComplement3: string,
    InvoiceFiscalNumber: string,
    InvoiceAddress1: string,
    InvoiceAddress2: string,
    InvoiceZipCode: string,
    InvoiceCity: string,
    InvoiceProvince: string,
    InvoiceCountryId: string,
    ShippingCompanyName: string,
    ShippingAddressee1: string,
    ShippingAddressee2: string,
    ShippingAddress1: string,
    ShippingAddress2: string,
    ShippingAddressComplement1: string,
    ShippingAddressComplement2: string,
    ShippingAddressComplement3: string,
    ShippingZipCode: string,
    ShippingCity: string,
    ShippingProvince: string,
    ShippingCountryId: string,
    ShippingPhone: string,
    ShippingNote: string,
    ExternalPaymentMethod: string,
    ShippingCharge: number,
    Lines: OrderLines[],
    TotalDiscounts: OrderTotalDiscounts[],
    Charges: OrderCharges[],
    Is_guest: number,
    Sg_customer_mail: string,
    Sg_customer_phone: string,
    Sg_customer_name: string,
    Sg_customer_lastname: string,
    check_privacy: Number
    ) {
      {
        this.ExternalId = ExternalId;
        this.MarketId = MarketId;
        this.Origin = Origin;
        this.OrderDateTime = OrderDateTime;
        this.EMailAddress = EMailAddress;
        this.Phone = Phone;
        this.ShippingMode = ShippingMode;
        this.ShippingHandler = ShippingHandler;
        this.ShippingService = ShippingService;
        this.ShippingShop = ShippingShop;
        this.ShippingServiceAux = ShippingServiceAux;
        this.RequestedDeliveryDate = RequestedDeliveryDate;
        this.PaymentRef = PaymentRef;
        this.InvoiceCompanyName = InvoiceCompanyName;
        this.InvoiceAddressee1 = InvoiceAddressee1;
        this.InvoiceAddressee2 = InvoiceAddressee2;
        this.InvoiceAddressComplement1 = InvoiceAddressComplement1;
        this.InvoiceAddressComplement2 = InvoiceAddressComplement2;
        this.InvoiceAddressComplement3 = InvoiceAddressComplement3;
        this.InvoiceFiscalNumber = InvoiceFiscalNumber;
        this.InvoiceAddress1 = InvoiceAddress1;
        this.InvoiceAddress2 = InvoiceAddress2;
        this.InvoiceZipCode = InvoiceZipCode;
        this.InvoiceCity = InvoiceCity;
        this.InvoiceProvince = InvoiceProvince;
        this.InvoiceCountryId = InvoiceCountryId;
        this.ShippingCompanyName = ShippingCompanyName;
        this.ShippingAddressee1 = ShippingAddressee1;
        this.ShippingAddressee2 = ShippingAddressee2;
        this.ShippingAddress1 = ShippingAddress1;
        this.ShippingAddress2 = ShippingAddress2;
        this.ShippingAddressComplement1 = ShippingAddressComplement1;
        this.ShippingAddressComplement2 = ShippingAddressComplement2;
        this.ShippingAddressComplement3 = ShippingAddressComplement3;
        this.ShippingZipCode = ShippingZipCode;
        this.ShippingCity = ShippingCity;
        this.ShippingProvince = ShippingProvince;
        this.ShippingCountryId = ShippingCountryId;
        this.ShippingPhone = ShippingPhone;
        this.ShippingNote = ShippingNote;
        this.ExternalPaymentMethod = ExternalPaymentMethod;
        this.ShippingCharge = ShippingCharge;
        this.Lines = Lines;
        this.TotalDiscounts = TotalDiscounts;
        this.Charges = Charges;
        this.Is_guest = Is_guest;
        this.Sg_customer_mail = Sg_customer_mail;
        this.Sg_customer_phone = Sg_customer_phone;
        this.Sg_customer_name = Sg_customer_name;
        this.Sg_customer_lastname = Sg_customer_lastname;
        this.check_privacy = check_privacy
      }
  }
}