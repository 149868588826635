<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
<div class="card card-custom">
  <div class="card-body">
    <div id="kt_datatable_wrapper" class="dataTables_wrapper dt-bootstrap4">
      <div style="width: 100%; padding-left: -10px;">
        <div class="table-responsive">
          <table id="kt_datatable_shop_config" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_info" style="width:100%">
            
            <thead>
                <tr role="row">
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_shop_config" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_shop_config" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_shop_config" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_shop_config" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_shop_config" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_shop_config" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_shop_config" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                </tr>
            </thead>

            <tfoot id="footer">
              <tr role="row">
                <th width="10"></th>
                <th width="10"></th>
                <th width="10"></th>
                <th width="10"></th>
                <th width="10"></th>
                <th width="10"></th>
                <th width="10"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_edit">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
          <h5 class="modal-title">Editar tienda</h5>
          <!--begin::Close-->
          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
              <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
          </div>
          <!--end::Close-->
      </div>
      
      <div class="modal-body">
        <label for="market"><b>Market</b></label>
        <input type="text" name="market" id="marketInput" readonly style="border: none;" class="w-100">
        <input type="text" id="shopId" hidden>
        <div class="switches">
          <label for="retail"><b>Retail</b></label>
          <div class="form-check form-switch">
            <input class="form-check-input" name="retail" type="checkbox" role="switch" id="retailSwitch">
          </div>
        </div>
        <div class="switches"> 
          <label for="active"><b>Activo</b></label>
          <div class="form-check form-switch">
            <input class="form-check-input" name="active" type="checkbox" role="switch" id="activeSwitch">
          </div>
        </div>
        <div class="switches">
          <label for="active"><b>Master</b></label>
          <div class="form-check form-switch">
            <input class="form-check-input" name="master" type="checkbox" role="switch" id="masterSwitch">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
          <button type="button" (click)="editShopConfig()" class="btn btn-primary" data-bs-dismiss="modal">Guardar</button>
        </div>
      </div>
    </div>
  </div>
</div>