import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { Observable, Subject, Subscription, of, throwError } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { DynamicModalComponent } from '../shared/dynamic-modal/dynamic-modal.component';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// ? ############################### TO DO LIST ###############################
// * DE TODO
// TODO: Evitar que haga tantas llamadas a getPurchaseOrders, hace demasiadas solo para cargar la tabla
// TODO: Probar a usar NgSwitch
// TODO: Poder exportar todo a un excel en productos y simular. Opción de exportar con foto o sin foto
// TODO: Si a un artículo asignamos X tiendas para una plantilla, podríamos generar pedidos por tienda? Un pedido por tienda para una selección de artículos.
// TODO: Intentar solucionar el problema de app-advanced-search-simular
// TODO: Cuando se copia y pega una asignación, si se hace dos veces, se cambia el anterior también. Es decir, asignas uno, bien, asignas el segundo, el primero pasa a tener la plantilla del primero
// * PRODUCTOS
// TODO: Hacer un repaso general, limpieza y simplificación
// TODO: Marcar simuladas en la tabla de productos
// TODO: Seleccionar todos los artículos donde quiera añadir tiendas. AÑADIR, no cambiar, y permitir quitar también. Enchufarlo a Avelon.
// TODO: Corregir el contador de tiendas marcadas
// * PLANTILLAS
// * SIMULAR
// TODO: Corregir simulación de tabla
// TODO: Pedido - fecha tope, MaximumDeliveryDate
// TODO: columna fecha. Cuando es sobre pedido o stock que ponga cuál es.
// TODO: Filtrar por pedido con servicio a partir de una fecha. Sección filtrado por pedido. Desde y hasta fechas, mostrar en desplegable los pedidos en rango de fechas. Que abajo muestre qué líneas de artículos están dentro de esos pedidos.
// TODO: Botón "generar distribución". Marcamos los que van sobre pedidos de una forma y los que van sobre stock de otra. Opción de selector de que no va "sobre producto".
// TODO: Darle a simular, mandar a Avelon con predistribución. Cuando se lo asigno a un pedido. Importante lo de los cambios.
// TODO: Habilitar opción de mandar sobre pedido, genera predistribución. O sobre stock, que no genera predistribución. Debería generar una orden de envío lo del stock. Poner una fecha y, llegada la fecha, que se generen las órdenes según la plantilla que pusimos.
// TODO: Posibilidad de enviar datos y que Avelon genere la predistribución. Que generase un pedido por fecha.
// TODO: Puede haber líneas que no se asocian a ningún pedido, o que se asocia a stock.
// TODO: Si se puede traer el pedido que acabo de hacer, mostrarlo en el selector.
// TODO: Mostrar, en simulaciones, las no generadas primero, es decir, ordenar por generadas. En simular, solo aparecen las no generadas. Check de que no fue predistribuido
// TODO: Partidas objetivo, datos de referencia'¡ para mostrar el sumatorio de lo asignado a cada tienda, familia y uso. Buscar cómo sacar la referencia
// TODO: Simular teniendo en cuenta temporada. ¿Para qué temporada quieres simular? Artículo, temporada y tienda asignada. En simular, hacer el sumatorio dinámico de los totales
// TODO: Pedidos con fecha de servicio posterior. No cometer el error de seleccionar una línea que ya esté predistribuida.
// TODO: Identificar cuándo hacemos un cambio de asignación de tiendas o de plantilla. Líneas pendientes de simular que han cambiado. CUáles son los que cambiaron. Identificar el cambio con respecto a lo último que se hizo en la línea. Diferenciar entre nuevo y cambio, nuevo es que nunca se ha hecho nada, cambio es que se ha hecho algo y se ha cambiado.
// TODO: aviso de que necesite confirmación, simular, para ver si quieres seguir haciendo el pedido sobre stock que querías. Si llega la fecha y el stock no es suficiente para suministrar a las tiendas, que avise. Que lo haga una vez al día, por ejemplo, o por semana, que vea lo que setá pendiente para atrás. Incluso un botón para hacer la comprobación.
// TODO: Asociar líneas a pedidos. EXTRA.

// TODO: generar predist. marcar con check. Traer también el estado del pedido.
// TODO: filtro como en usuarios, para ocultar tiendas

@Component({
  selector: 'app-plantillas-predistribucion',
  templateUrl: './plantillas-predistribucion.component.html',
  styleUrls: ['./plantillas-predistribucion.component.scss']
})
export class PlantillasPredistribucionComponent implements OnInit {
  // ! ############################### VARIABLES DE LA CLASE ###############################
  //#region VARIABLES DE LA CLASE
  public stores: any[] = []
  public indexes: any[] = []
  public shopPatterns: any[] = []
  public sizeDomains: any[] = []
  public sizeLabels: any[] = []
  public patterns: any[] = []
  public templateShop: any[] = []
  public templateShopItems: any[] = []
  public templateItemShops: any[] = []
  public templates: any[] = []
  public marcas: any[] = []
  public temporadas: any[] = []
  public familias: any = []
  public comercial: any = []
  public manufacturer: any = []

  private timeoutId!: any

  totalCountEnters = 0

  confirmedSubscription: Subscription;

  row: any;

  filtersProducts: any[] = []
  filteringProduct: boolean;
  filteredProductList: number[] = [];

  filteringProductMujer: boolean;
  filteringProductHombre: boolean;
  filteringProductKids: boolean;
  filteringProductUnisex: boolean;

  filteredProductListMujer: number[] = [];
  filteredProductListHombre: number[] = [];
  filteredProductListKids: number[] = [];
  filteredProductListUnisex: number[] = [];

  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsLimit1: IDropdownSettings = {};
  dropdownSingleSettings: IDropdownSettings = {};
  dropdownTemplateSettings: IDropdownSettings = {};

  inputData: string

  unifiedProductList: string = ""

  linesForCsv: any[] = []
  linesForCsvTotals: any[] = []

  selectedPattern: any[] = [];
  selectedMarca: any[] = [];
  selectedTemporada: any[] = [];
  selectedFamilia: any[] = [];
  selectedComercial: any[] = [];
  selectedManufacturer: any[] = [];
  selectedTiendas: any[] = [];

  selectionSimulate: any[] = [];

  selectedProductRows = new Set<number>();
  selectedSimulateRows = new Set<number>();

  selectedTemplateEdit: any[] = []

  selectedTemplateCopied: any[] = []
  @ViewChild('plantillaCopied') plantillaCopied:MultiSelectComponent;
  selectedShopsCopied: any[] = []
  @ViewChild('shopsCopied') shopsCopied:MultiSelectComponent;
  selectedTemplate: any[] = []
  @ViewChild('templateSelector') templateSelector:MultiSelectComponent;
  selectedTemplateProducts: any[] = []
  @ViewChild('templateSelectorProducts') templateSelectorProducts:MultiSelectComponent;

  @ViewChild('simularSelectedModal') simularSelectedModal: any;
  @ViewChild('simularFilteredModal') simularFilteredModal: any;

  showAssignedProducts = false;

  assignatedValue: { id_item: number, id_template: number, shops: string }

  storeCheckCounts: any[] = []
  productsDataset: any[] = []
  templatesDataset: any[] = []
  templatesCounts: any[] = []
  uniqueDataMap = new Map()

  tableProductos: DataTables.Api
  tablePlantillas: DataTables.Api
  tableSimular: DataTables.Api

  tableSimular_mujer: DataTables.Api
  tableSimular_hombre: DataTables.Api
  tableSimular_kids: DataTables.Api
  tableSimular_unisex: DataTables.Api

  tableSelectionSimulate: DataTables.Api
  tableFilteredSimulate: DataTables.Api

  private unsubscribe: Subscription[] = [];
  componentDestroyed$: Subject<boolean> = new Subject()

  templateSelectorInitialized = false;

  editedLines: { itemId: number, selectValue: number, stores: string }[] = [];

  @ViewChild('dynamicModal') dynamicModal: DynamicModalComponent;

  @ViewChild('searchSimulate') searchSimulateButton: ElementRef;

  tallajesList: string[] = ['S', 'C', 'NI', 'UNI-MED']

  now = new Date();

  optionsCsv = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,

    headers: ['referencia','id_shop','index0','index1','index2','index3','index4','index5','index6','index7','index8','index9','index10','index11','index12','index13','index14','index15','index16','index17','index18','index19','index20','index21','index22','index23','index24','index25'],

    showTitle: false,
    title: 'Predistribución',
    useBom: true,
    removeNewLines: true,
    keys: ['id_item','id_shop','value0','value1','value2','value3','value4','value5','value6','value7','value8','value9','value10','value11','value12','value13','value14','value15','value16','value17','value18','value19','value20','value21','value22','value23','value24','value25'],
    filename: `predistribucion`
  };

  optionsCsvTotals = {
    fieldSeparator: ';',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,

    headers: ['referencia','supplier','sumShops','sumTallas','index0','index1','index2','index3','index4','index5','index6','index7','index8','index9','index10','index11','index12','index13','index14','index15','index16','index17','index18','index19','index20','index21','index22','index23','index24','index25'],

    showTitle: false,
    title: 'Predistribución Totals',
    useBom: true,
    removeNewLines: true,
    keys: ['id_item','SupplierId','shop_count','sumTallas','value0','value1','value2','value3','value4','value5','value6','value7','value8','value9','value10','value11','value12','value13','value14','value15','value16','value17','value18','value19','value20','value21','value22','value23','value24','value25'],
    filename: `predistribucion`
  };

  private purchaseOrdersCache = new Map<string, any>();

  //#endregion VARIABLES DE LA CLASE

  constructor(private appService: AppService, private ref: ChangeDetectorRef, private renderer: Renderer2, private modalService: NgbModal) { }

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE

  //! GETS
  //#region GETS
  /**
   * Método que se encarga de realizar las llamadas a los servicios necesarios para obtener los datos iniciales
   */
  private dataFetchOperations: { serviceMethod: () => Observable<any>, targetProperty: string }[] = [
    { serviceMethod: () => this.appService.getSizeDomains(), targetProperty: 'sizeDomains' },
    { serviceMethod: () => this.appService.getSizeLabelsPlantillas(), targetProperty: 'sizeLabels' },
    { serviceMethod: () => this.appService.getShopPatterns(), targetProperty: 'shopPatterns' },
    { serviceMethod: () => this.appService.getTemplatePatterns(), targetProperty: 'patterns' },
    { serviceMethod: () => this.appService.getTemplateShop(), targetProperty: 'templateShop' },
    { serviceMethod: () => this.appService.getTemplateItemShops(), targetProperty: 'templateItemShops' },
    { serviceMethod: () => this.appService.getTemplates(), targetProperty: 'templates' },
    { serviceMethod: () => this.appService.getInternalBrands(), targetProperty: 'marcas' },
    { serviceMethod: () => this.appService.getManufacturers(), targetProperty: 'manufacturer' },
    { serviceMethod: () => this.appService.getInternalSeasons(), targetProperty: 'temporadas' },
    { serviceMethod: () => this.appService.getFromInternal('InternalItemGroup02'), targetProperty: 'familias' },
    { serviceMethod: () => this.appService.getFromInternal('InternalItemGroup12'), targetProperty: 'comercial' },
    { serviceMethod: () => this.appService.getStockStores(), targetProperty: 'stores' },
    { serviceMethod: () => this.appService.getPatternIndexes(), targetProperty: 'indexes' },
    { serviceMethod: () => this.appService.getProductTotalCounts(), targetProperty: 'storeCheckCounts' }
  ];

  /**
   * Llama a los métodos de los servicios necesarios para obtener los datos iniciales
   * @param property Propiedad a la que se le va a asignar el valor obtenido de la llamada
   */
  // callDataFetch(property: string) {
  //   const operation = this.dataFetchOperations.find(op => op.targetProperty === property);
  //   if (operation) {
  //     operation.serviceMethod().subscribe(
  //       (res: any) => {
  //         (this as any)[operation.targetProperty] = res;
  //         this.ref.detectChanges();
  //       },
  //       (err: any) => {
  //         console.log(err);
  //       }
  //     );
  //   }
  // }

  callDataFetch(property: string): Observable<any> {
    const operation = this.dataFetchOperations.find(op => op.targetProperty === property);
    if (operation) {
      return operation.serviceMethod().pipe(
        map((res: any) => {
          (this as any)[operation.targetProperty] = res;
          this.ref.detectChanges();
          return res; // Pass the response along for further handling if needed
        }),
        catchError((err: any) => {
          console.log(err);
          return of(null); // Handle the error by returning a fallback value as an Observable
        })
      );
    } else {
      // If the operation is not found, return an Observable that emits null
      return of(null);
    }
  }

  fetchData() {
    this.dataFetchOperations.forEach(operation => {
      const appServiceSubscription = operation.serviceMethod().pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (res: any) => {
            (this as any)[operation.targetProperty] = res;
            switch (operation.targetProperty) {
              case "stores":
                this.processTableProductos()
                break;
              case "indexes":
                this.processTablePlantillas()
                break;
              case "templates":
                this.templateSelectorInitialized = false;
                this.templateSelector.addSelected({id: res[0].Id, text: res[0].Name})
                this.templateSelectorInitialized = true;
                break;
              default:
                break;
            }
            this.ref.detectChanges();
          },
          (err: any) => {
            console.log(err);
          }
        );
      this.unsubscribe.push(appServiceSubscription);
    });
  }

  getPurchaseOrders(supplierId: number, idItem: number) {
    const cacheKey = `${supplierId}-${idItem}`;
    if (this.purchaseOrdersCache.has(cacheKey)) {
      return of(this.purchaseOrdersCache.get(cacheKey));
    } else {
      return this.appService.getPurchaseOrders(supplierId).pipe(
        takeUntil(this.componentDestroyed$),
        map(res => {
          console.log(res);
          const processedResult = this.processResult(res, idItem);
          this.purchaseOrdersCache.set(cacheKey, processedResult);
          return processedResult;
        }),
        catchError(err => {
          console.log(err);
          return of("<option hidden value=null>Error loading orders</option>");
        })
      );
    }
  }

  callDataFetchTest(property: string): Observable<any> {
    const operation = this.dataFetchOperations.find(op => op.targetProperty === property);
    if (operation) {
      return operation.serviceMethod().pipe(
        map((res: any) => {
          (this as any)[operation.targetProperty] = res;
          this.ref.detectChanges();
          return res;
        }),
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      );
    } else {
      return of(null);
    }
  }

  getTemplateTotalCounts(): Observable<any> {
    const templateId = this.selectedTemplate[0].Id ? this.createFilterValue(this.selectedTemplate) : this.templates[0].Id;

    return this.appService.getTemplateTotalCounts(templateId)
    .pipe(
      map((res: any) => {
        this.templatesCounts = res;
        this.ref.detectChanges();
        return res;
      }),
      catchError((err: any) => {
        console.log(err);
        return of(null);
      })
    );
  }

  processResult(res: any, idItem: number) {
    let options = "<option hidden value=null></option>";
    let foundItemInOrder = false;
  
    res.forEach((o: { Lines: any[]; Id: number }) => {
      const itemInOrder = o.Lines.find((e: { ItemId: number; }) => e.ItemId === idItem);
      if (itemInOrder) {
        options += `<option value=${o.Id}>${o.Id}</option>`;
        foundItemInOrder = true;
      }
    });
  
    if (!foundItemInOrder) {
      options = "<option hidden value=null>No hay pedidos</option>";
    }
    else{
      options += "<option hidden value=null selected>Selecciona un pedido</option>"
    }
  
    return options;
  }
  //#endregion GETS

  //! POSTS
  //#region POSTS
  updateTemplateItemShops(id_item: number, id_template: number, shops: string = "") {
    const appServiceSubscription: Subscription = this.appService.updateTemplateItemShops(id_item, id_template, shops).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.callDataFetch("templateItemShops");
      this.tablePlantillas.draw(true);
      this.updateCounts('products')
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  private handleTemplatePattern(values: any, action: 'add' | 'save' | 'update') {
    interface ActionFunction {
      function: (values: any) => Observable<any>;
    }

    interface ActionWithMessage extends ActionFunction {
      message: string;
      title: string;
    }

    type ActionMap = {
      [key: string]: ActionFunction | ActionWithMessage;
    };


    const actionMap :ActionMap = {
      add: {
        function: this.appService.createTemplatePattern.bind(this.appService),
      },
      save: {
        function: this.appService.addTemplateShop.bind(this.appService),
        message: "La distribución de la plantilla se ha guardado correctamente",
        title: "Plantilla guardada"
      },
      update: {
        function: this.appService.addTemplateShop.bind(this.appService),
        message: "La distribución de la plantilla se ha actualizado correctamente",
        title: "Plantilla actualizada"
      }
    };

    const actionEntry = actionMap[action];

    if ('message' in actionEntry && 'title' in actionEntry) {
      this.subscribeToAction(actionEntry.function, values, actionEntry.title, actionEntry.message, action);
    } else {
      this.subscribeToAction(actionEntry.function, values, "", "", action);
    }
  }

  private subscribeToAction(actionFunction: Function, values: any, successTitle: string, successMessage: string, action: string) {
    const appServiceSubscription: Subscription = actionFunction(values)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe({
        next: (res: any) => {
          if (action !== 'add') {
            this.openDynamicModal(successTitle, successMessage, "Ok");
          }
        },
        error: (err: any) => console.log(err)
      });

    this.unsubscribe.push(appServiceSubscription);
  }

  // createPlantilla() {
  //   this.dynamicModal.openModal();
  // }

  createPlantilla() {
    let options = "<option hidden value=null>Escoge un tallaje</option>";
    this.sizeDomains.forEach(e => {
      options += `<option value='${e.Id}'>${e.Name}</option>`;
    });

    // Set the @Input properties
    this.dynamicModal.title = 'Crear plantilla';
    this.dynamicModal.confirmButtonText = 'Guardar';
    this.dynamicModal.cancelButtonText = 'Cancelar';
    this.dynamicModal.input = "text";
    this.dynamicModal.inputPlaceholder = "Escribe un nombre para la plantila";
    this.dynamicModal.htmlContent = `<select class='form-control' id="sizeDomainSelected">${options}</select>`;
    this.dynamicModal.modalType = "complex";

    // Listen to the confirmed event
    this.dynamicModal.preConfirm.subscribe((inputValue) => {
      if ($('#sizeDomainSelected').val() != 'null' && inputValue != '') {
        const appServiceSubscription: Subscription = this.appService.createTemplate(inputValue, $('#sizeDomainSelected').val())
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(
              (res: any) => this.callDataFetch("templates"),
              (err: any) => console.log(err)
            );

          this.unsubscribe.push(appServiceSubscription);
      }
      else {
        this.openDynamicModal('Rellena los campos', 'Debes escoger un tallaje y un nombre para continuar', "Ok", "warning")
      }
    });

    // Open the modal
    this.dynamicModal.openModal();
  }

  saveProductChanges() {
    // Convert this.editedLines to HTML table
    let tableHtml = '<div style="max-height: 200px; overflow-y: auto;"><table><tr><th>Referencia</th><th>Plantilla</th><th>Tiendas</th></tr>';
    for (let line of this.editedLines) {
      tableHtml += `<tr><td>${line.itemId}</td><td>${line.selectValue}</td><td>${line.stores}</td></tr>`;
    }
    tableHtml += '</table></div>';

    // Set the @Input properties
    this.dynamicModal.title = 'Confirma los cambios';
    this.dynamicModal.confirmButtonText = 'Confirmar';
    this.dynamicModal.cancelButtonText = 'Cancelar';
    this.dynamicModal.showConfirmButton = this.editedLines.length > 0;
    this.dynamicModal.htmlContent = this.editedLines.length > 0 ? tableHtml : "<p>No hay cambios para guardar</p>";
    this.dynamicModal.modalType = "complex";

    if (this.confirmedSubscription) {
      this.confirmedSubscription.unsubscribe();
    }

    // Listen to the confirmed event
    this.confirmedSubscription = this.dynamicModal.confirmed.subscribe(() => {
      let values = ""

      this.editedLines.forEach(el => {
        values += `(${el.itemId},${el.selectValue},"${el.stores}"),`
      });

      const appServiceSubscription: Subscription = this.appService.updateTemplateItemShopsValues(values.slice(0, -1))
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(
            (res: any) => {
              this.editedLines = []
              this.callDataFetch("templateItemShops");
              this.tableProductos.draw(true)
            },
            (err: any) => console.log(err)
          );

        this.unsubscribe.push(appServiceSubscription);
    });

    // Open the modal
    this.dynamicModal.openModal();
  }

  saveNotes(reference: string) {
    const notes = $(`#${reference}-notes`).val()

    const appServiceSubscription: Subscription = this.appService.saveNotes(parseInt(reference), notes)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(
      (res: any) => {
        this.openDynamicModal('Guardada', 'Nota guardada correctamente', "Ok")
        this.tableProductos.draw(true)
      },
      (err: any) => console.log(err)
    );

    this.unsubscribe.push(appServiceSubscription);
  }

  predistribute(type: string) {
    switch(type) {
      case 'new':
        if (Array.from(this.selectedSimulateRows).length > 0) {
          // this.getSelectionSimulate()
        }
        else {
          this.openDynamicModal('Sin selección', 'Debes seleccionar al menos una línea para continuar', 'Ok', 'warning')
        }
        break;
      case 'selected':
        if (Array.from(this.selectedSimulateRows).length > 0) {
          let matchingEntries: any[] = [];
          this.linesForCsvTotals = []

          this.selectedSimulateRows.forEach(rowNumber => {
            if (this.uniqueDataMap.has(rowNumber)) {
              matchingEntries.push(this.uniqueDataMap.get(rowNumber));
            }
          });

          this.linesForCsvTotals = matchingEntries

          setTimeout(() => {
            $("#angular2CsvTotals button").click()
          }, 500);
        }
        else {
          this.openDynamicModal('Sin selección', 'Debes seleccionar al menos una línea para continuar', 'Ok', 'warning')
        }
        break;
      case 'filtered':
        if (this.unifiedProductList != "") {
          this.linesForCsvTotals = []
          let matchingEntries: any[] = [];
          let filteredList = this.unifiedProductList.split(",").map(Number);

          filteredList.forEach(rowNumber => {
            if (this.uniqueDataMap.has(rowNumber)) {
              matchingEntries.push(this.uniqueDataMap.get(rowNumber));
            }
          });

          this.linesForCsvTotals = matchingEntries

          setTimeout(() => {
            $("#angular2CsvTotals button").click()
          }, 500);
        }
        else {
          this.openDynamicModal('Sin filtrados', 'Debes realizar un filtrado para continuar', 'Ok', 'warning')
        }
        break;
      case 'selectedDesglose':
        if (Array.from(this.selectedSimulateRows).length > 0) {
          this.openModal('selected')

          this.processTableSelectionSimulate('kt_datatable_selections_simulate')
        }
        else {
          this.openDynamicModal('Sin selección', 'Debes seleccionar al menos una línea para continuar', 'Ok', 'warning')
        }
        break;
      case 'filteredDesglose':
        if (this.unifiedProductList != "") {
          this.openModal('filtered')

          this.processTableSelectionSimulate('kt_datatable_filtered_simulate')
        }
        else {
          this.openDynamicModal('Sin filtrados', 'Debes realizar un filtrado para continuar', 'Ok', 'warning')
        }
        break;
      default:
        break;
    }
  }
  //#endregion POSTS

  //! PUTS
  //#region PUTS
  //#endregion PUTS

  //! DELETES
  //#region DELETES
  //#endregion DELETES

  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  isSame(row: any) {
    let isSame = true;
    let pattern: any = null;

    for (let p of this.patterns) {
      isSame = true;
      p["id_shop"] = row.id_shop;

      Object.entries(row).forEach(([key, value]) => {
        value = (value ?? 0)
        if (p && key.startsWith('value')) {
          if (p[key] != value) {
            isSame = false;
          }
        }
      });
      if (isSame) {
        pattern = p;
        return { same: isSame, pattern: p };
      }
    }

    return { same: isSame, pattern: pattern };
  }

  alreadyExists(id_shop: number, id_template: number, id_pattern: number) {
    let found = this.templateShop.find((e: { id_shop: number; id_template: number; id_template_pattern: number; }) => e.id_shop == id_shop && e.id_template == id_template && e.id_template_pattern == id_pattern);
    return found;
  }

  handleSaveTemplates(){
    const tableData = this.tablePlantillas.rows().data().toArray();

    // * Lógica coñazo, a ver si queda claro
    // Reduce el array de datos de la tabla para construir un array de datos a guardar
    const dataToSave = tableData.reduce((acc: any[], row: any) => {
      // Bandera para verificar si la fila tiene al menos un valor ingresado
      let hasInputValue = false;
      const data: any = { id_shop: row.id_shop, name: row.name };
      // Itera sobre las columnas para procesar los valores ingresados
      for (let i = 0; i <= this.indexes.length - 1; i++) {
        // Obtiene el valor del input basado en el id_shop y el índice, con un valor por defecto de 0
        const inputValue = $(`#number-${row.id_shop}-${i}`).val() ?? 0;
        // Si la fila tiene un id_template, verifica si el valor ingresado es diferente al valor existente
        if (row["id_template"] != null) {
          if (inputValue != row[`value${i}`]) {
            // Si es diferente, guarda el valor ingresado (convertido a entero) o 0 si está vacío
            data[`value${i}`] = inputValue != '' ? parseInt(inputValue) : 0;
            hasInputValue = true;
          } else {
            // Si es igual, guarda el valor ingresado (convertido a entero) o el valor existente
            data[`value${i}`] = inputValue ? parseInt(inputValue) : (row[`value${i}`] ?? 0);
          }
        }
        // Si la fila no tiene un id_template pero sí un valor ingresado, también marca que se ha encontrado un valor
        if (row["id_template"] == null && inputValue) {
          hasInputValue = true;
          // Guarda el valor ingresado (convertido a entero) o el valor existente
          data[`value${i}`] = inputValue ? parseInt(inputValue) : (row[`value${i}`] ?? 0);
        }
      }
      // Si se encontró al menos un valor ingresado en la fila
      if (hasInputValue) {
        // Verifica si los datos actuales son iguales a algún patrón existente
        const isSame = this.isSame(data);
        // Si son iguales, agrega el patrón encontrado al acumulador
        if (isSame.same) {
          acc.push(isSame.pattern)
        }
        // Si no son iguales, agrega los datos actuales al acumulador
        else {
          acc.push(data)
        }
      }
      // Retorna el acumulador para la siguiente iteración
      return acc;
    }, []);

    dataToSave.forEach(row => {
      const id_shop = row.id_shop;
      const id_template = parseInt(this.selectedTemplate[0].Id ? this.createFilterValue(this.selectedTemplate) : this.templates[0].Id);

      const isSame = this.isSame(row);

      const operation = row.name != null ? 'update' : 'save';

      if (isSame.same) {
        const id_pattern = isSame.pattern.id;

        if (!this.alreadyExists(id_shop, id_template, id_pattern)) {
          const valuesArr = [id_shop, id_template, id_pattern];

          this.handleTemplatePattern(valuesArr, operation);
        }
      }
      else {
        this.callDataFetch("patterns");

        const maxId = Math.max(...this.patterns.map(pattern => pattern.id));
        const id_new_pattern = maxId + 1;

        const maxPattern = this.patterns.find(pattern => pattern.id === maxId);

        const valuesArrPattern = [
          id_new_pattern,
          maxPattern.name.replace(`${maxId}`, `${id_new_pattern}`),
          ...Object.entries(row)
            .filter(([key]) => key.startsWith('value'))
            .map(([_, value]) => value != null ? value : 0)
        ];

        this.handleTemplatePattern(valuesArrPattern, 'add');

        const valuesArrTemplateShop = [id_shop, id_template, id_new_pattern];

        this.handleTemplatePattern(valuesArrTemplateShop, operation);
      }
    });

    setTimeout(() => {
      this.changeTemplate()
    }, 1500);
  }

  private checkPatterns(reference: number, shopId: number) {
    const found = this.templateShop.find((e: { id_shop: number; }) => e.id_shop == shopId);
    if (found) {
      $(`#${reference}-${shopId} option[value="${found.id_template}"]`).attr("selected", "selected")
    }
  }

  limpiarFiltros() {
    this.selectedMarca = [];
    this.selectedTemporada = [];
    this.selectedFamilia = [];
    this.selectedComercial = [];
    this.selectedManufacturer = [];
    this.unifiedProductList = ""
    $('#items').val("");
    $('.secondInput').val("");

    this.showAssigned(false)

    $('#search').click()
    $('#searchSimulate').click()
  }

  public prueba() {
    console.log("================== ESTO ES UNA PRUEBA ==================")
  }

  private createFilterValue(selectedItems: any[], cadena: boolean = false): string {
    if (selectedItems.length > 0) {
      const idArray = selectedItems.map(item => item.Id);
      return cadena ?  '"' + idArray.join('","') + '"' : idArray.join(',');
    }
    return '';
  }

  private findItem(itemId: number, storeId: number) {
    const foundItem = this.templateItemShops.find((e: { id_item: number; id_template: number; shops: string; }) => {
      if (e.id_item === itemId) {
        const shopsArray = JSON.parse(e.shops);
        $(`#select-product-${itemId} option[value='${e.id_template}']`).prop('selected', true);
        return shopsArray.includes(storeId);
      }
      return false;
    });

    return !!foundItem
  }

  private appendChange(itemId: number, storeId: number) {
    const checkbox = $(`#check-${itemId}-${storeId}`);

    // Remove any existing event listeners
    checkbox.off("change");

    checkbox.on("change", (event) => {
      const selectValue = parseInt($(`#select-product-${itemId}`).val());

      if (selectValue) {
        const checkbox = event.target as HTMLInputElement;
        const idParts = checkbox.id.split('-');
        const itemId = parseInt(idParts[1]);
        const storeId = parseInt(idParts[2]);

        const foundShops = this.templateItemShops.find((e: { id_item: number; }) => e.id_item === itemId);
        let stores = [];

        if (foundShops) {
          let storeList = JSON.parse(foundShops.shops);
          if (checkbox.checked) {
            if (!storeList.includes(storeId)) {
              storeList.push(storeId);
            }
          } else {
            const index = storeList.indexOf(storeId);
            if (index !== -1) {
              storeList.splice(index, 1);
            }
          }
          stores = storeList;
        }
        else {
          stores = [storeId];
        }

        let editedLine = this.editedLines.find(line => line.itemId === itemId && line.selectValue === selectValue);

        let storesFinal = stores

        if (editedLine) {
          let storesArray = JSON.parse(editedLine.stores);

          if (storesArray.includes(storeId)) {
            const index = storesArray.indexOf(storeId);
            if (index > -1) {
              storesArray.splice(index, 1);
            }
          } else {
            storesArray.push(storeId);
          }

          storesFinal = storesArray

          editedLine.stores = JSON.stringify(storesArray);
        } else {
          this.editedLines.push({ itemId, selectValue, stores: JSON.stringify(stores) });
        }

        this.updateTemplateItemShops(itemId, selectValue, JSON.stringify(storesFinal));
      }
      else {
        $(`#check-${itemId}-${storeId}`).prop('checked', false);

        this.openDynamicModal('Escoge una plantilla', 'Debes escoger una plantilla para continuar con la predistribución', "Ok", 'warning')
      }
    });
  }

  changeTemplate() {
    this.getTemplateTotalCounts().subscribe(
      res => {
        if (this.templateSelectorInitialized) {
          this.tablePlantillas.draw(true)
        }
      },
      err => console.log(err)
    )
  }

  drawTable(type: string) {
    setTimeout(() => {
      switch (type) {
        case "productos":
          this.tableProductos.draw(true)
          break;
        case "plantillas":
          this.tablePlantillas.draw(true)
          break;
        case "simular_mujer":
          this.tableSimular_mujer.draw(true)
          break;
        case "simular_hombre":
          this.tableSimular_hombre.draw(true);
          break;
        case "simular_kids":
          this.tableSimular_kids.draw(true)
          break;
        case "simular_unisex":
          this.tableSimular_unisex.draw(true)
          break;
        case "simulate":
          this.uniqueDataMap.clear();
          this.tableSimular_mujer.draw(true)
          this.tableSimular_hombre.draw(true);
          this.tableSimular_kids.draw(true)
          this.tableSimular_unisex.draw(true)
          break;
        default:
          break;
      }

      // this.limpiarFiltros();
    }, 1000);
  }

  openImageModal(event: any) {
    const imageUrl = event.target.src;
    (<HTMLImageElement>document.getElementById('modalImage')).src = imageUrl;
    (<any>$('#kt_modal_image')).modal('show');
  }

  someClickHandler(info: any): void {
    this.row = info;
  }

  triggerResizeEvent() {
    window.dispatchEvent(new Event('resize'));
  }

  handleBindShops(type: string) {
    let tiendasCheck = $('#bindShopsCheck').is(':checked')
    let plantillaCheck = $('#bindTemplateCheck').is(':checked')

    if (!tiendasCheck && !plantillaCheck) {
      this.openDynamicModal('Sin selección', 'Debes activar alguna opción, o tiendas o plantilla, o las dos, para continuar.', 'Ok', 'warning')
      return
    }
    if ((tiendasCheck && this.selectedTiendas.length === 0) || (plantillaCheck && this.selectedTemplateProducts.length === 0)) {
      this.openDynamicModal('Sin selección', 'Debes seleccionar al menos una opción para cada campo activo para continuar.', 'Ok', 'warning')
      return
    }

    let productCount = type === 'filtered' ? $('#kt_datatable_productos').DataTable().page.info().recordsDisplay : this.selectedProductRows.size;
    let templateName = plantillaCheck ? this.selectedTemplateProducts[0].Name : "";

    let getAlertText = (tiendasCheck: boolean, plantillaCheck: boolean) => {
      let alertText = ""
      if (tiendasCheck && !plantillaCheck) {
        alertText = `¿Seguro que quieres asociar <b>${productCount}</b> productos a las tiendas seleccionadas?`
      }
      else if (tiendasCheck && plantillaCheck) {
        alertText = `¿Seguro que quieres asociar <b>${productCount}</b> productos a las tiendas seleccionadas con la plantilla <b>${templateName}</b>?`
      }
      else if (!tiendasCheck && plantillaCheck) {
        alertText = `¿Seguro que quieres asociar la plantilla <b>${this.selectedTemplateProducts[0].Name}</b> a <b>${productCount}</b> productos?`
      }
      return alertText
    }

    switch (type) {
      case 'filtered':
        this.bindShops('filtered', getAlertText(tiendasCheck, plantillaCheck), plantillaCheck)
        break;
      case 'selected':
        this.bindShops('selected', getAlertText(tiendasCheck, plantillaCheck), plantillaCheck)
        break;
      default:
        break;
    }
  }

  bindShops(type: string, alertText: string, plantillaCheck: boolean) {
    const showAlert = (title: any, text: any, icon: any, showDenyButton = false, confirmButtonText = 'OK', denyButtonText = 'Cancelar') => {
      return Swal.fire({
        title: title,
        html: text,
        icon: icon,
        showDenyButton: showDenyButton,
        confirmButtonText : confirmButtonText,
        denyButtonText : denyButtonText,
      });
    };

    switch (type) {
      case "filtered":
        if (!this.filteringProduct) {
          showAlert('Sin filtrado', 'Debes aplicar algún filtro de búsqueda, de otro modo se actualizarían todos los productos a la selección de tiendas.', 'warning');
        } else {
          showAlert('Asignar', alertText, 'warning', true, 'Asignar')
            .then((result) => {
              if (result.isConfirmed) {
                let allHaveValue = true;
                let template = 0

                if (this.filteredProductList.length > 0) {
                  for (let id of this.filteredProductList) {
                    let selectValue = $(`#select-product-${id}`).val();
                    if (selectValue == "null") {
                      allHaveValue = false;
                      break;
                    }
                  }
                }

                if (!plantillaCheck || (plantillaCheck && this.selectedTemplateProducts.length === 0)) {
                  if (!allHaveValue) {
                    showAlert('Error', 'No se pueden asociar tiendas a productos que no tienen plantilla asociada.', 'error');
                    return
                  }
                } else if (plantillaCheck && this.selectedTemplateProducts.length > 0) {
                  template = this.selectedTemplateProducts[0].Id
                }

                const appServiceSubscription = this.appService.bindShops(this.filteredProductList, this.selectedTiendas, template)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(res => {
                    showAlert('¡Asignado!', '', 'success');
                    this.ngOnInit();
                  }, err => {
                    console.log(err);
                  });

                this.unsubscribe.push(appServiceSubscription);
              } else if (result.isDenied) {
                showAlert('No se guardaron los cambios', '', 'info');
              }
            });
        }
        break;
      case "selected":
        if (this.selectedProductRows.size === 0) {
          showAlert('Sin selección', 'Debes seleccionar algún artículo de la tabla.', 'warning');
        } else {
          showAlert('Asignar', alertText, 'warning', true, 'Asignar')
            .then((result) => {
              if (result.isConfirmed) {
                let allHaveValue = true;
                let template = 0

                if (this.selectedProductRows.size > 0) {
                  for (let id of this.selectedProductRows) {
                    let selectValue = $(`#select-product-${id}`).val();
                    if (selectValue == "null") {
                      allHaveValue = false;
                      break;
                    }
                  }
                }

                if (!plantillaCheck || (plantillaCheck && this.selectedTemplateProducts.length === 0)) {
                  if (!allHaveValue) {
                    showAlert('Error', 'No se pueden asociar tiendas a productos que no tienen plantilla asociada.', 'error');
                    return
                  }
                } else if (plantillaCheck && this.selectedTemplateProducts.length > 0) {
                  template = this.selectedTemplateProducts[0].Id
                }

                let productIds = [...this.selectedProductRows]
                const appServiceSubscription = this.appService.bindShops(productIds, this.selectedTiendas)
                .pipe(takeUntil(this.componentDestroyed$))
                .subscribe(res => {
                    showAlert('¡Asignado!', '', 'success');
                    this.ngOnInit();
                  }, err => {
                    console.log(err);
                  });

                this.unsubscribe.push(appServiceSubscription);
              } else if (result.isDenied) {
                showAlert('No se guardaron los cambios', '', 'info');
              }
            });
        }
        break;
      default:
        break;
    }
  }

  toggleSelection(reference: number, row: any, type: string = "product"): void {
    interface ActionClass {
      remove: string;
      add: string;
      removeRight?: string; // Optional property
      addRight?: string; // Optional property
    }

    const isSelected = (type === "product" ? this.selectedProductRows : this.selectedSimulateRows).has(reference);
    const backgroundColor = isSelected ? "white" : "#dfdfdf";
    let actionClass: ActionClass = isSelected ? { remove: 'fixedColumnProductsSelected', add: 'fixedColumnProducts' } : { remove: 'fixedColumnProducts', add: 'fixedColumnProductsSelected' };

    if (type !== "product") {
      actionClass = isSelected ? { remove: 'fixedColumnSimulateSelected', add: 'fixedColumnSimulate', removeRight: "fixedColumnSimulateRightSelected", addRight: "fixedColumnSimulateRight" } : { remove: 'fixedColumnSimulate', add: 'fixedColumnSimulateSelected', removeRight: "fixedColumnSimulateRight", addRight: "fixedColumnSimulateRightSelected" };
    }

    const toggleRowSelection = isSelected ? 'removeClass' : 'addClass';
    const selectedLine = type === "product" ? "selectedProductLine" : "selectedSimulateLine"

    $(row).css({ "background-color": backgroundColor })
          .find('.' + actionClass.remove)
          .removeClass(actionClass.remove)
          .addClass(actionClass.add)
          .end()

    if (actionClass.removeRight && actionClass.addRight) {
      $(row).find('.' + actionClass.removeRight)
            .removeClass(actionClass.removeRight)
            .addClass(actionClass.addRight);
    }

    $(row)[toggleRowSelection](selectedLine);

    if (isSelected) {
      (type === "product" ? this.selectedProductRows : this.selectedSimulateRows).delete(reference);
    } else {
      (type === "product" ? this.selectedProductRows : this.selectedSimulateRows).add(reference);
    }
  }


  handleSelected(type: string) {
    switch (type) {
      case "selectFiltered":
        // TODO: Falta darle una vuelta
        console.log("Selecciona todos los filtrados")
        break;
      case "filter":
        this.inputData = this.selectedProductRows.size > 0 ? Array.from(this.selectedProductRows).join(',') : "";

        setTimeout(() => {
          $('#search').click()
        }, 500);
        break;
      case "filterSimulate":
        this.inputData = this.selectedSimulateRows.size > 0 ? Array.from(this.selectedSimulateRows).join(',') : "";

        setTimeout(() => {
          $('#searchSimulate').click()
        }, 500);
        break;
      case "deselect":
        const rowsSelected = Array.from($('body').find('.selectedProductLine'));
        rowsSelected.forEach(row => {
          $(row).css({
            "background-color":"white"
          });
          $(row).find('.fixedColumnProductsSelected').removeClass('fixedColumnProductsSelected').addClass('fixedColumnProducts');
          $(row).removeClass('selectedProductLine');
        });
        this.selectedProductRows = new Set<number>();
        this.tableProductos.draw(true)
        break;
      case "deselectSimulate":
        const rowsSelectedSimulate = Array.from($('body').find('.selectedSimulateLine'));
        rowsSelectedSimulate.forEach(row => {
          $(row).css({
            "background-color":"white"
          });
          $(row).find('.fixedColumnSimulateSelected').removeClass('fixedColumnSimulateSelected').addClass('fixedColumnSimulate');
          $(row).find('.fixedColumnSimulateRightSelected').removeClass('fixedColumnSimulateRightSelected').addClass('fixedColumnSimulateRight');
          $(row).removeClass('selectedSimulateLine');
        });
        this.selectedSimulateRows = new Set<number>();
        this.drawTable("simulate")
        break;
      default:
        break;
    }
  }

  showAssigned(show: boolean) {
    if (show) {
      $("#items").val(this.templateItemShops.map(t => t.id_item).join(','))
      this.inputData = $("#items").val();
      this.showAssignedProducts = true;
    }
    else {
      $("#items").val("")
      this.showAssignedProducts = false;
    }
    this.tableProductos.draw(true)
  }

  handleCopiedChanges() {
    let template = this.selectedTemplateCopied[0].Id
    let shops = this.selectedShopsCopied.map(e => e.Id).join(',')

    this.assignatedValue = { id_item: 0, id_template: template, shops: `[${shops}]` }
  }

  openDynamicModal(title: string, htmlContent: string, confirmButtonText: string = 'Ok', icon: 'warning' | 'error' | 'success' | 'info' | 'question' = 'success', modalType: 'simple' | 'complex' = 'simple'): void {
    this.dynamicModal.title = title;
    this.dynamicModal.htmlContent = htmlContent;
    this.dynamicModal.confirmButtonText = confirmButtonText;
    this.dynamicModal.icon = icon;
    this.dynamicModal.modalType = modalType;

    this.dynamicModal.openModal();
  }

  openModal(type: string) {
    switch (type) {
      case 'selected':
        this.modalService.open(this.simularSelectedModal, { size: 'xl' })
        break;
      case 'filtered':
        this.modalService.open(this.simularFilteredModal, { size: 'xl' })
        break;
      default:
        break;
    }
  }

  getInfoCsv() {
    setTimeout(() => {
      $("#angular2Csv button").click()
    }, 500);
  }

  addCountsRow(type: string) {
    switch (type) {
      case "products":
        let $bodyRowProducts = $('#kt_datatable_productos tbody tr:first');

        let $existingCountsRowProducts = $('#kt_datatable_productos tbody').find('.counts-row');

        if ($existingCountsRowProducts.length > 0) {
            $existingCountsRowProducts.remove();
        }

        let $countsRowProducts = $('<tr>').addClass('counts-row fixed-counts-row');

        for (let i = 0; i < $bodyRowProducts.children('td').length - this.storeCheckCounts.length; i++) {
          if (i === $bodyRowProducts.children('td').length - this.storeCheckCounts.length - 1) {
            $countsRowProducts.append($('<td>').addClass('fixedColumnProducts noBorderRight').html('<b>Sumatorio:</b>'));
          } else {
            $countsRowProducts.append($('<td>').addClass('fixedColumnProducts noBorderRight'));
          }
        }

        this.storeCheckCounts.forEach(count => {
          $countsRowProducts.append($(`<td>${count.count}</td>`));
        });

        $('#kt_datatable_productos tbody').prepend($countsRowProducts);
        break;
      case "templates":
        if (this.templatesCounts.length > 0) {
          let $bodyRowTemplates = $('#kt_datatable_plantillas tbody tr:first');

          let $existingCountsRowTemplates = $('#kt_datatable_plantillas tbody').find('.counts-row');

          if ($existingCountsRowTemplates.length > 0) {
              $existingCountsRowTemplates.remove();
          }

          let $countsRowTemplates = $('<tr>').addClass('counts-row fixed-counts-row');

          const elementCount = Object.keys(this.templatesCounts[0]).length

          for (let i = 0; i < $bodyRowTemplates.children('td').length - elementCount; i++) {
            $countsRowTemplates.append($('<td>').addClass('fixedColumnProducts noBorderRight').html('<b>Sumatorio:</b>'));
          }

          for (let i = 0; i < elementCount; i++) {
            $countsRowTemplates.append($(`<td>${this.templatesCounts[0][`total_value${i}`]}</td>`));
          }

          $('#kt_datatable_plantillas tbody').prepend($countsRowTemplates);
        }
        break;
      default:
        break;
    }
  }

  updateCounts(type: string) {
    switch (type) {
      case "products":
        this.callDataFetch("storeCheckCounts").subscribe(() => {
          this.updateProductCounts();
        });
        break;
      case "templates":
        this.getTemplateTotalCounts().subscribe(() => {
          this.updateTemplateCounts();
        });
        break;
      default:
        break;
    }
  }

  updateProductCounts() {
    let $countsRow = $('#kt_datatable_productos tbody tr.counts-row');
    let skipCells = $countsRow.children('td').length - this.storeCheckCounts.length;
    $countsRow.children('td').each((index, element) => {
      if (index >= skipCells) {
        let $cell = $(element);
        $cell.text(this.storeCheckCounts[index - skipCells].count);
      }
    });
  }

  updateTemplateCounts() {
    let $countsRow = $('#kt_datatable_plantillas tbody tr.counts-row');
    let elementCount = Object.keys(this.templatesCounts[0]).length;
    let skipCells = $countsRow.children('td').length - elementCount;
    $countsRow.children('td').each((index, element) => {
      if (index >= skipCells) {
        let $cell = $(element);
        $cell.text(this.indexes[index - skipCells].count);
      }
    });
  }
  //#endregion UTILS

  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  formatProductos = (d: any) => {
    return (
      `
      <div style="display: flex; position: relative;">
        <div style="display: flex; position: sticky; left: 0px">
          <img class="distributionImage" src="${d.url}" alt="${d.id}">
          <ul class="list-group list-group-flush" style="display: grid !important; grid-template-columns: repeat(2, 1fr); margin-right: 20px;">
            <li class="list-group-item formatProductListElement"><span class="dtr-title"><b>Referencia</b></span><span class="dtr-data">${d.id}</span></li>
            <li class="list-group-item formatProductListElement"><span class="dtr-title"><b>Modelo</b></span><span class="dtr-data">${d.Style}</span></li>
            <li class="list-group-item formatProductListElement"><span class="dtr-title"><b>Color</b></span><span class="dtr-data">${d.colorName}</span></li>
            <li class="list-group-item formatProductListElement"><span class="dtr-title"><b>Marca</b></span><span class="dtr-data">${d.InternalBrandId}</span></li>
            <li class="list-group-item formatProductListElement"><span class="dtr-title"><b>Temporada</b></span><span class="dtr-data">${d.InternalSeasonId}</span></li>
            <li class="list-group-item formatProductListElement"><span class="dtr-title"><b>Familia</b></span><span class="dtr-data">${d.InternalItemGroup02}</span></li>
            <li class="list-group-item formatProductListElement"><span class="dtr-title"><b>Comercial</b></span><span class="dtr-data">${d.InternalItemGroup12}</span></li>
            <li class="list-group-item formatProductListElement"><span class="dtr-title"><b>Proveedor</b></span><span class="dtr-data">${d.manufacturerName}</span></li>
          </ul>
          <div style="display: flex; flex-direction: column;">
            <textarea style="width: 500px; height: 80%; resize: none" placeholder="Escribe aquí tus notas" id="${d.id}-notes">${d.productNotes != '' ? d.productNotes : ''}</textarea>
            <button class="btn btn-primary font-weight-bolder productNotes" id="${d.id}">
              <i class="la la-pencil"></i>Guardar nota
            </button>
          </div>
        </div>
      </div>
      `
    );
  }

  //#region COLUMN HANDLERS
  private productosColumnHandler() {
    let options = "<option hidden value=null></option>";
    this.templates.forEach(e => {
      options += `<option value=${e.Id}>${e.Name}</option>`;
    });

    let subColumns: any[] = [];
    subColumns.push({
      className: 'details-control border-right-0',
      orderable: false,
      data: null,
      defaultContent: '<i class="la la-angle-right"></i>',
    })

    subColumns.push({
      title: 'Artículo', class: "fixedColumnProducts fixedColumnProductsId noPaddingTopBottom",
      render: (data: any, type: any, full: any) => {
        // Dropdown menu HTML
        let dropdownMenu = `
          <div class="card-toolbar noPaddingTopBottom" style="padding: 1rem;">
            <div class="dropdown dropdown-inline" style="display: flex; align-items: center;">
            ${full.id}
              <button type="button" class="btn btn-hover-light-primary btn-icon btn-sm" data-bs-toggle="dropdown" data-bs-target="#kt_dropdown_${full.id}" aria-haspopup="true" aria-expanded="false">
                <span class="svg-icon svg-icon-muted svg-icon-2hx">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                    <rect x="5" y="3" width="2" height="2" rx="2" fill="black"/>
                    <rect x="5" y="6" width="2" height="2" rx="2" fill="black"/>
                    <rect x="5" y="9" width="2" height="2" rx="2" fill="black"/>
                  </svg>
                </span>
              </button>
              <div id="kt_dropdown_${full.id}" class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                <button class="dropdown-item">Copiar asignación</button>
                <div class="dropdown-divider"></div>
                <button class="dropdown-item">Asignar copiado</button>
              </div>
            </div>
          </div>
        `;

        return dropdownMenu;
      }
    });

    subColumns.push({
      title: 'Plantilla', class: "fixedColumnProducts noPaddingTopBottom",
      render: (data: any, type: any, full: any) => {
        let element2 = `
        <select class='form-control' id="select-product-${full.id}">
          ${options}
        </select>`;

        $(`#select-product-${full.id}`).on("change", (event) => {
          this.updateTemplateItemShops(full.id, $(`#select-product-${full.id}`).val());
        });

        return element2;
      }
    });

    subColumns.push({
      title: 'Modelo', class: "fixedColumnProducts noPaddingTopBottom",
      render: (data: any, type: any, full: any) => {
        let element = `${full.Style}`;

        return element;
      }
    });

    subColumns.push({
      title: 'Color', class: "fixedColumnProducts noPaddingTopBottom",
      render: (data: any, type: any, full: any) => {
        let element = `${full.colorName}`;

        return element;
      }
    });

    this.stores.forEach(store => {
      subColumns.push({
        title: store.Id.toString(),
        render: (data: any, type: any, full: any) => {
          const foundItem = this.findItem(full.id, store.Id)

          let element = `
          <div class="form-check form-switch form-check-custom form-check-solid">
            <input  type="checkbox" value="" id="check-${full.id}-${store.Id}" ${foundItem ? 'checked' : ''}/>
          </div>
          `;

          return element;
        }
      });
    });
    return subColumns;
  }

  private plantillasColumnHandler() {
    let subColumns: any[] = [];
    subColumns.push({
      title: 'Tiendas',
      class: "fixedColumnProducts",
      render: (data: any, type: any, full: any): string => {
        return full.id_shop;
      }
    });

    this.indexes.forEach(index => {
      let templateName = typeof this.selectedTemplate[0] === 'string' ? this.selectedTemplate[0] : this.selectedTemplate[0].Name
      let template = this.templates.find(t => t.Name === templateName);
      let indexValue = parseInt(index.index.replace('value', ''));
      let sizeLabel = this.sizeLabels.find(sizeLabel => sizeLabel.index === indexValue && sizeLabel.Id_sizeDomain === template.sizeDomain);
      let label = sizeLabel ? sizeLabel.label : null;

      let title = label ? `Talla ${label}` : `Índice ${indexValue}`;

      subColumns.push({
        title: `<span class="plantillas-header">${title}</span>`,
        render: (data: any, type: any, full: any) => {
          let element = `
          <div class="form-check form-switch form-check-custom form-check-solid">
            <input class="form-control no-spinner" type="number" min="0" max="99" value="${full[index.index] && full[index.index] > 0 ? full[index.index] : ''}" id="number-${full.id_shop}-${(index.index).replace("value", "")}"/>
          </div>
          `;

          return element;
        }
      });
    });
    return subColumns;
  }

  private simulateColumnHandler(tallaje: string) {
    let subColumns: any[] = [];
    subColumns.push({
      title: 'Artículo',
      class: "fixedColumnSimulate fixedColumnSimulateId",
      render: (data: any, type: any, full: any): string => {
        return full.id_item;
      }
    });

    subColumns.push({
      title: 'Nº de tiendas',
      class: "fixedColumnSimulate",
      render: (data: any, type: any, full: any): string => {
        return full.shop_count;
      }
    });

    subColumns.push({
      title: 'Unidades totales',
      class: "fixedColumnSimulate",
      render: (data: any, type: any, full: any): string => {
        return full.sumTallas;
      }
    });

    this.indexes.forEach(index => {
      let indexValue = parseInt(index.index.replace('value', ''));
      let sizeLabel = this.sizeLabels.find(sizeLabel => sizeLabel.index === indexValue && sizeLabel.Id_sizeDomain === tallaje);
      let label = sizeLabel ? sizeLabel.label : null;

      let title = label ? `Talla ${label}` : `Índice ${indexValue}`;

      subColumns.push({
        // title: `<span class="plantillas-header">${index.index.toString().replace("value", "Índice ")}</span>`,
        title: `<span class="plantillas-header">${title}</span>`,
        render: (data: any, type: any, full: any) => {
          let element = `
          <div class="form-check form-switch form-check-custom form-check-solid">
            <input class="form-control no-spinner" type="number" min="0" max="99" value="${full[index.index] && full[index.index] > 0 ? full[index.index] : ''}" id="number-${full.id_item}-${(index.index).replace("value", "")}"/>
          </div>
          `;

          $(`#number-${full.id_item}-${(index.index).replace("value", "")}`).on("change", (event) => { console.log("He cambiau") })
          return element;
        }
      });
    });

    subColumns.push({
      title: 'Pedido',
      class: "fixedColumnSimulateRight",
      render: (data: any, type: any, full: any): string => {
        // let supplierId = full.SupplierId;
        // let selectId = `select-simulate-${full.id_item}`;

        // // TODO: Revisar esta llamada, no tiene sentido hacerla tantas veces. REFE de ejemplo: 436051
        // // TODO: La cosa es que pasa todas las veces que se generan las datatables, por lo que se hace una vez por cada una de las 4 tablas
        // this.getPurchaseOrders(supplierId, full.id_item).subscribe(res => {
        //   this.totalCountEnters ++
        //   const element = document.getElementById(selectId);

        //   if (element) {
        //     element.innerHTML = res;
        //   }
        // }, err => {
        //   console.log(err);
        // });

        // let selector = `
        // <select id="${selectId}">
        // </select>`;

        let selector = "API de pedidos"

        return selector;
      }
    });

    return subColumns;
  }

  private predistributeColumnHandler() {
    let subColumns: any[] = [];

    subColumns.push({
      title: 'Referencia',
      class: "fixedColumnSimulate",
      render: (data: any, type: any, full: any): string => {
        return full.id_item;
      }
    });

    subColumns.push({
      title: 'Tienda',
      class: "fixedColumnSimulate",
      render: (data: any, type: any, full: any): string => {
        return full.id_shop;
      }
    });

    this.indexes.forEach(index => {
      let templateName = typeof this.selectedTemplate[0] === 'string' ? this.selectedTemplate[0] : this.selectedTemplate[0].Name
      let template = this.templates.find(t => t.Name === templateName);
      let indexValue = parseInt(index.index.replace('value', ''));
      let sizeLabel = this.sizeLabels.find(sizeLabel => sizeLabel.index === indexValue && sizeLabel.Id_sizeDomain === template.sizeDomain);
      // let label = sizeLabel ? sizeLabel.label : null;
      let label = null;

      let title = label ? `Talla ${label}` : `Índice ${indexValue}`;

      subColumns.push({
        title: `<span class="plantillas-header">${title}</span>`,
        // id: `number-${(index.index).replace("value", "")}`,
        render: (data: any, type: any, full: any) => {
          return full[index.index] && full[index.index] > 0 ? full[index.index] : '';
        }
      });
    });

    return subColumns;
  }
  //#endregion COLUMN HANDLERS

  //#region TABLES
  processTableProductos(){
    let subColumns: any[] = this.productosColumnHandler();

    this.tableProductos = $('#kt_datatable_productos').DataTable({
      processing: true,
      responsive: true,
      serverSide: true,
      destroy: true,
      paging: true,
      scrollX: true,
      scrollY: '70vh',
      pageLength: 50,
      searching:false,
      ordering: false,
      language: {
          "processing": `<span>Cargando...
          </span>`,
          "lengthMenu": "Mostrar _MENU_ productos",
          "zeroRecords": "No se encontraron resultados",
          "emptyTable": "Ningún dato disponible en esta tabla",
          "infoEmpty": "Mostrando productos del 0 al 0 de un total de 0 productos",
          "infoFiltered": "(filtrado de un total de _MAX_ productos)",
          "search": "Buscar:",
          "loadingRecords": " ",
          "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": ">",
              "previous": "<"
          },
          "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "decimal": ",",
          "thousands": ".",
          "info": "Mostrando _START_ a _END_ de _TOTAL_ productos"
      },

      ajax: {
        type: 'POST',
        //! EN CASO DE HACER CAMBIOS LOCALES EN LA PARTE DE NODE, UTILIZAR LA URL COMENTADA. PARA SUBIRLO, SE DEBE UTILIZAR LA DEL environment
        // url: `http://localhost:4200/api/getItemsForDist`,
        url: `${environment.portalHost}/api/getItemsForDist`,
        dataSrc: (response) => {
          const data = response.data;
          const draw = response.draw;
          const recordsTotal = response.recordsTotal;
          const recordsFiltered = response.recordsFiltered;

          response.draw = draw;
          response.recordsTotal = recordsTotal;
          response.recordsFiltered = recordsFiltered;

          $('#kt_datatable_productos').DataTable().page.info().recordsTotal = recordsTotal;
          $('#kt_datatable_productos').DataTable().page.info().recordsDisplay = recordsFiltered;

          this.filteringProduct = response.filtering
          this.filteredProductList = response.filteredProductList

          this.productsDataset = data

          this.callDataFetch("storeCheckCounts")

          return data;
        },
        data: (d: any) => {
          d.filters = []
          d.filters.push({ field: 'articulos', value: $("#items").val() })
          d.filters.push({ field: 'marcas', value: this.createFilterValue(this.selectedMarca) });
          d.filters.push({ field: 'temporadas', value: this.createFilterValue(this.selectedTemporada, true) });
          d.filters.push({ field: 'familias', value: this.createFilterValue(this.selectedFamilia, true) });
          d.filters.push({ field: 'comercial', value: this.createFilterValue(this.selectedComercial, true) });
          d.filters.push({ field: 'manufacturer', value: this.createFilterValue(this.selectedManufacturer, true) });

          this.filtersProducts = d.filters

          return {
            sentData: JSON.stringify(d)
          }
        },
      },
      columns: subColumns,

      rowCallback: (row: Node, data: any, index: number) => {
        const self = this;

        let id = parseInt($(row).find('.fixedColumnProductsId').text());

        if (this.selectedProductRows.has(id)) {
          $(row).css({
            "background-color":"#dfdfdf"
          });
          $(row).find('.fixedColumnProducts').removeClass('fixedColumnProducts').addClass('fixedColumnProductsSelected');
        }

        $('td', row).off('click');
        $('td', row).on('click', () => {
          self.someClickHandler(row);
        });
        $('td.details-control', row).off('click');
        $('td.details-control', row).on('click', () => {
          var tr = row;
          var row2 = this.tableProductos.row(tr)
          if (row2.child.isShown()) {

            row2.child.hide();
            $('td.details-control').html('<i class="la la-angle-right"></i>')
            $(".dt-hasChild").find('td.details-control').html('<i class="la la-angle-down"></i>');

          } else {
            var start_time = new Date().getTime();

            var end_tiem = new Date().getTime();
            var tiempo = end_tiem - start_time;

            this.timeoutId = setTimeout(() => {
              row2.child(this.formatProductos(row2.data())).show();
              $(".dt-hasChild").find('td.details-control').html('<i class="la la-angle-down"></i>');
              $('.distributionImage').on('click', (event) => {
                this.openImageModal(event);
              });
              $('.productNotes').on('click', (event) => {
                this.saveNotes(event.target.id);
              });
            }, tiempo / 0.0010000000);
          }
        });
        return row;
      },

      drawCallback: () => {
        const self = this

        this.stores.forEach(store => {
          this.tableProductos.rows().every((index, element) => {
            const rowData = JSON.parse(JSON.stringify(this.tableProductos.row(index).data()));
            self.checkPatterns(rowData.id, store.Id);

            this.findItem(rowData.id, store.Id);
            this.appendChange(rowData.id, store.Id);
          });
        });

        $('#kt_datatable_productos').off('click').on('click', 'tbody tr', (event) => {
          if ($('#lineSelectCheck').is(':checked')) {
            let row = event.currentTarget as HTMLTableRowElement;
            let id = parseInt($(row).find('.fixedColumnProductsId').text());
            this.toggleSelection(id, row);
          }
        });

        if ($('#search')) {
          $('#search').off('click').on('click', () => {
            if ($("#items").val() != "") {
              this.inputData = $("#items").val();
            }
            this.tableProductos.draw(true)
            this.productosColumnHandler()
          });
        }

        this.addCountsRow('products')
      },
    });

    // Habilita la posibiidad de abrir el dropdown de cada línea de producto
    $('body').on('show.bs.dropdown', '.dropdown', function(this: JQuery) {
      const dropdown = $(this);
      const offset = dropdown.offset();
      if (offset) {
        dropdown.find('.dropdown-menu').first().detach().appendTo('body').css('top', offset.top + dropdown.outerHeight()).css('left', offset.left).css('position', 'absolute');
      }
    }).on('hide.bs.dropdown', '.dropdown', function(this: JQuery) {
      $(this).find('.dropdown-menu').first().detach().appendTo(this);
    });

    $(document).on('click', '.dropdown-item', (event) => {
      // Check the text of the clicked item to determine the action
      const ref = event.target.parentElement ? event.target.parentElement.id.replace("kt_dropdown_", "") : ""
      if (event.target.childNodes[0].nodeValue === 'Copiar asignación') {
        const foundReference = this.templateItemShops.find(t => t.id_item === parseInt(ref))
        if (foundReference) {
          this.plantillaCopied.selectedItems = []
          this.shopsCopied.selectedItems = []

          this.assignatedValue = foundReference

          const template = this.templates.find(t => t.Id === foundReference.id_template)
          const shops = this.stores.filter(store => JSON.parse(foundReference.shops).includes(store.Id))

          this.plantillaCopied.addSelected({id: template.Id, text: template.Name})

          shops.forEach(s => {
            this.shopsCopied.addSelected({id: s.Id, text: s.Name})
          });
        }
        else {
          this.openDynamicModal('Error', 'No se encontró ninguna asignación para copiar.', 'Ok', 'error')
        }
      } else if (event.target.childNodes[0].nodeValue === 'Asignar copiado') {
        this.dynamicModal.title = 'Asignar copiado';
        this.dynamicModal.confirmButtonText = 'Aceptar';
        this.dynamicModal.cancelButtonText = 'Cancelar';
        this.dynamicModal.icon = 'warning';
        this.dynamicModal.htmlContent = `Estás a punto de asignar la plantilla <b>"${this.templates.find(t => t.Id === this.assignatedValue.id_template).Name}"</b> y las tiendas <b>${this.assignatedValue.shops}</b> a la referencia <b>${ref}</b>. ¿Quieres continuar?`;
        this.dynamicModal.modalType = 'complex'

        // Listen to the confirmed event
        this.dynamicModal.preConfirm.subscribe(() => {
          const appServiceSubscription = this.appService.bindShops(JSON.parse(`[${ref}]`), JSON.parse(this.assignatedValue.shops), this.assignatedValue.id_template, "copied")
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(res => {
            this.openDynamicModal('¡Asignado!', '', 'Ok', 'success')
            this.ngOnInit();
            }, err => {
              console.log(err);
            });

          this.unsubscribe.push(appServiceSubscription);
        });

        // Open the modal
        this.dynamicModal.openModal();
      }
    });
  }

  processTablePlantillas(){
    let subColumns: any[] = this.plantillasColumnHandler();

    this.tablePlantillas = $('#kt_datatable_plantillas').DataTable({
      processing: true,
      serverSide: true,
      pageLength: 50,
      scrollX: true,
      scrollY: '60vh',
      searching:false,
      paging: false,
      destroy: true,
      responsive: true,
      ordering: false,
      language: {
          "processing": `<span>Cargando...
          </span>`,
          "lengthMenu": "Mostrar _MENU_ registros",
          "zeroRecords": "No se encontraron resultados",
          "emptyTable": "Ningún dato disponible en esta tabla",
          "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "infoFiltered": "(filtrado de un total de _MAX_ registros)",
          "search": "Buscar:",
          "loadingRecords": " ",
          "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": ">",
              "previous": "<"
          },
          "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "decimal": ",",
          "thousands": ".",
          "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      },

      ajax: {
        type: 'POST',
        //! EN CASO DE HACER CAMBIOS LOCALES EN LA PARTE DE NODE, UTILIZAR LA URL COMENTADA. PARA SUBIRLO, SE DEBE UTILIZAR LA DEL environment
        // url: `http://localhost:4200/api/getPatternsForDist`,
        url: `${environment.portalHost}/api/getPatternsForDist`,
        dataSrc: (response) => {
          const data = response.data;
          const draw = response.draw;
          const recordsTotal = response.recordsTotal;
          const recordsFiltered = response.recordsFiltered;

          response.draw = draw;
          response.recordsTotal = recordsTotal;
          response.recordsFiltered = recordsFiltered;

          $('#kt_datatable_productos').DataTable().page.info().recordsTotal = recordsTotal;
          $('#kt_datatable_productos').DataTable().page.info().recordsDisplay = recordsFiltered;

          this.templatesDataset = data

          this.getTemplateTotalCounts().subscribe();

          return data;
        },
        data: (d: any) => {
          d.filters = []
          d.filters.push({ field: 'template', value: this.selectedTemplate[0].Id ? this.createFilterValue(this.selectedTemplate) : this.templates[0].Id})

          return {
            sentData: JSON.stringify(d)
          }
        },
      },
      columns: subColumns,
      drawCallback: () => {
        const self = this

        this.stores.forEach(store => {
          this.tableProductos.rows().every((index, element) => {
            const rowData = JSON.parse(JSON.stringify(this.tableProductos.row(index).data()));
            self.checkPatterns(rowData.id, store.Id);

            this.findItem(rowData.id, store.Id);
            this.appendChange(rowData.id, store.Id);
          });
        });

        $('#kt_datatable_productos').off('click').on('click', 'tbody tr', (event) => {
          if ($('#lineSelectCheck').is(':checked')) {
            let row = event.currentTarget as HTMLTableRowElement;
            let id = parseInt($(row).find('.fixedColumnProductsId').text());
            this.toggleSelection(id, row);
          }
        });

        if ($('#search')) {
          $('#search').off('click').on('click', () => {
            if ($("#items").val() != "") {
              this.inputData = $("#items").val();
            }
            this.tableProductos.draw(true)
            this.productosColumnHandler()
          });
        }

        this.addCountsRow('templates')
      },
    });
  }

  private tableSimularInstances: Record<string, DataTables.Api> = {};

  processSimulateTables() {
    this.processTableSimular('kt_datatable_simular_mujer', 'S');
    this.processTableSimular('kt_datatable_simular_hombre', 'C');
    this.processTableSimular('kt_datatable_simular_kids', 'NI');
    this.processTableSimular('kt_datatable_simular_unisex', 'UNI-MED');
  }

  processTableSimular(tableName: string, tallaje: string){
    let subColumns: any[] = this.simulateColumnHandler(tallaje);

    let tableSimularProperty;
    switch (tallaje) {
      case 'S':
        tableSimularProperty = 'tableSimular_mujer';
        break;
      case 'C':
        tableSimularProperty = 'tableSimular_hombre';
        break;
      case 'NI':
        tableSimularProperty = 'tableSimular_kids';
        break;
      case 'UNI-MED':
        tableSimularProperty = 'tableSimular_unisex';
        break;
      default:
        console.error('Invalid tableName');
        return;
    }

    if (($.fn.DataTable as any).isDataTable(`#${tableName}`)) {
      this.tableSimularInstances[tableSimularProperty].destroy();
    }

    this.tableSimularInstances[tableSimularProperty] = $(`#${tableName}`).DataTable({
      processing: true,
      serverSide: true,
      pageLength: 50,
      scrollX: true,
      scrollY: '60vh',
      searching:false,
      paging: false,
      destroy: true,
      responsive: true,
      ordering: false,
      language: {
          "processing": `<span>Cargando...
          </span>`,
          "lengthMenu": "Mostrar _MENU_ registros",
          "zeroRecords": "No se encontraron resultados",
          "emptyTable": "Ningún dato disponible en esta tabla",
          "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "infoFiltered": "(filtrado de un total de _MAX_ registros)",
          "search": "Buscar:",
          "loadingRecords": " ",
          "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": ">",
              "previous": "<"
          },
          "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "decimal": ",",
          "thousands": ".",
          "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      },
      ajax: {
        type: 'POST',
        //! EN CASO DE HACER CAMBIOS LOCALES EN LA PARTE DE NODE, UTILIZAR LA URL COMENTADA. PARA SUBIRLO, SE DEBE UTILIZAR LA DEL environment
        url: `http://localhost:4200/api/getItemsForSimulate`,
        // url: `${environment.portalHost}/api/getItemsForSimulate`,
        dataSrc: (response) => {
          const data = response.data;
          const draw = response.draw;
          const recordsTotal = response.recordsTotal;
          const recordsFiltered = response.recordsFiltered;

          response.draw = draw;
          response.recordsTotal = recordsTotal;
          response.recordsFiltered = recordsFiltered;

          $(`#${tableName}`).DataTable().page.info().recordsTotal = recordsTotal;
          $(`#${tableName}`).DataTable().page.info().recordsDisplay = recordsFiltered;

          switch (tallaje) {
            case 'S':
              this.filteredProductListMujer = response.filtering;
              this.filteredProductListMujer = response.filteredProductList
              break;
            case 'C':
              this.filteringProductHombre = response.filtering
              this.filteredProductListHombre = response.filteredProductList
              break;
            case 'NI':
              this.filteringProductKids = response.filtering
              this.filteredProductListKids = response.filteredProductList
              break;
            case 'UNI-MED':
              this.filteringProductUnisex = response.filtering
              this.filteredProductListUnisex = response.filteredProductList
              break;
            default:
              console.error('Tallaje no válido');
              return;
          }

          data.forEach((item: { id_item: number; }) => {
            if (!this.uniqueDataMap.has(item.id_item)) {
              this.uniqueDataMap.set(item.id_item, item);
            }
          });

          return data;
        },
        data: (d: any) => {
          d.filters = []
          d.filters.push({ field: 'articulos', value: $("#items").val() })
          d.filters.push({ field: 'marcas', value: this.createFilterValue(this.selectedMarca) });
          d.filters.push({ field: 'temporadas', value: this.createFilterValue(this.selectedTemporada, true) });
          d.filters.push({ field: 'familias', value: this.createFilterValue(this.selectedFamilia, true) });
          d.filters.push({ field: 'templates', value: this.createFilterValue(this.selectedTemplateEdit, true) })
          d.filters.push({ field: 'comercial', value: this.createFilterValue(this.selectedComercial, true) });
          d.filters.push({ field: 'manufacturer', value: this.createFilterValue(this.selectedManufacturer, true) });
          d.tallaje = tallaje

          console.log(d)

          return {
            sentData: JSON.stringify(d)
          }
        },
      },
      columns: subColumns,
      drawCallback: () => {
        if ($('#searchSimulate')) {
          $('#searchSimulate').off('click').on('click', () => {
            if ($("#items").val() != "") {
              this.inputData = $("#items").val();
            }
            this.drawTable('simulate')
            this.simulateColumnHandler(tallaje)
          });
        }

        $(`#${tableName}`).off('click').on('click', 'tbody tr', (event) => {
          if ($('#lineSelectCheckSimulate').is(':checked')) {
            let row = event.currentTarget as HTMLTableRowElement;
            let id = parseInt($(row).find('.fixedColumnSimulateId').text());
            this.toggleSelection(id, row, "simular");
          }
        });

        setTimeout(() => {
          this.triggerResizeEvent()
        }, 1500);

        this.unifiedProductList = [...this.filteredProductListMujer, ...this.filteredProductListHombre, ...this.filteredProductListKids, ...this.filteredProductListUnisex].join(',')

      },
      rowCallback: (row: Node, data: any, index: number) => {
        let id = parseInt($(row).find('.fixedColumnSimulateId').text());

        if (this.selectedSimulateRows.has(id)) {
          $(row).css({
            "background-color":"#dfdfdf"
          });
          $(row).find('.fixedColumnSimulate').removeClass('fixedColumnSimulate').addClass('fixedColumnSimulateSelected');
          $(row).find('.fixedColumnSimulateRight').removeClass('fixedColumnSimulateRight').addClass('fixedColumnSimulateRightSelected');
        }
      }
    });

    switch (tallaje) {
      case "S":
        this.tableSimular_mujer = this.tableSimularInstances[tableSimularProperty];
        break;
      case "C":
        this.tableSimular_hombre = this.tableSimularInstances[tableSimularProperty];
        break;
      case "NI":
        this.tableSimular_kids = this.tableSimularInstances[tableSimularProperty];
        break;
      case "UNI-MED":
        this.tableSimular_unisex = this.tableSimularInstances[tableSimularProperty];
        break;
      default:
        break;
    }
  }

  processTableSelectionSimulate(table: string){
    let subColumns: any[] = this.predistributeColumnHandler();

    this.tableSelectionSimulate = $(`#${table}`).DataTable({
      processing: true,
      serverSide: true,
      pageLength: 50,
      scrollX: true,
      scrollY: '60vh',
      searching:false,
      paging: false,
      destroy: true,
      responsive: true,
      ordering: false,
      language: {
          "processing": `<span>Cargando...
          </span>`,
          "lengthMenu": "Mostrar _MENU_ registros",
          "zeroRecords": "No se encontraron resultados",
          "emptyTable": "Ningún dato disponible en esta tabla",
          "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "infoFiltered": "(filtrado de un total de _MAX_ registros)",
          "search": "Buscar:",
          "loadingRecords": " ",
          "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": ">",
              "previous": "<"
          },
          "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "decimal": ",",
          "thousands": ".",
          "info": "Mostrando _TOTAL_ registros"
      },

      ajax: {
        type: 'POST',
        //! EN CASO DE HACER CAMBIOS LOCALES EN LA PARTE DE NODE, UTILIZAR LA URL COMENTADA. PARA SUBIRLO, SE DEBE UTILIZAR LA DEL environment
        // url: `http://localhost:4200/api/getSelectionSimulate`,
        url: `${environment.portalHost}/api/getSelectionSimulate`,
        dataSrc: (response) => {
          const data = response.data;
          const draw = response.draw;
          const recordsTotal = response.recordsTotal;
          const recordsFiltered = response.recordsFiltered;

          response.draw = draw;
          response.recordsTotal = recordsTotal;
          response.recordsFiltered = recordsFiltered;

          $(`#${table}`).DataTable().page.info().recordsTotal = recordsTotal;
          $(`#${table}`).DataTable().page.info().recordsDisplay = recordsFiltered;

          this.linesForCsv = data

          return data;
        },
        data: (d: any) => {
          switch (table) {
            case 'kt_datatable_selections_simulate':
              d.itemList = Array.from(this.selectedSimulateRows).join(',')
              break;
            case 'kt_datatable_filtered_simulate':
              d.itemList = this.unifiedProductList
              break;
            default:
              break;
          }

          return {
            sentData: JSON.stringify(d)
          }
        },
      },
      columns: subColumns
    });
  }
  //#endregion TABLES

  //#region NGONs
  ngOnInit(): void {
    this.dropdownSettings = {
      idField: 'Id',
      textField: 'Name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettingsLimit1 = {
      idField: 'Id',
      textField: 'Name',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.dropdownSingleSettings = {
      idField: 'Id',
      textField: 'Name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      singleSelection: true
    };

    this.dropdownTemplateSettings = {
      idField: 'Id',
      textField: 'Name',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      singleSelection: true
    };

    this.fetchData();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.tableProductos) {
      this.tableProductos.destroy();
    }

    clearTimeout(this.timeoutId)

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion NGONs

  //#endregion INICIALIZACIÓN
}