import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-advanced-search-simular',
  templateUrl: './advanced-search-simular.component.html',
  styleUrls: ['./advanced-search-simular.component.scss']
})
export class AdvancedSearchSimularComponent {
  @Input() buscarButtonId: string;

  @Input() inputData:  string;
  @Input() selectedMarca:  any[] = [];
  @Input() selectedTemporada:  any[] = [];
  @Input() selectedFamilia:  any[] = [];
  @Input() selectedComercial:  any[] = [];
  @Input() selectedManufacturer:  any[] = [];
  @Input() selectedTemplate:  any[] = [];

  @Input() marcas: any[] = [];
  @Input() temporadas: any[] = [];
  @Input() familias: any[] = [];
  @Input() comercial: any[] = [];
  @Input() manufacturer: any[] = [];
  @Input() templates: any[] = [];

  @Input() dropdownSettings: IDropdownSettings = {};

  @Output() onLimpiarFiltros: EventEmitter<void> = new EventEmitter();

  limpiar() {
    this.onLimpiarFiltros.emit();
  }
}
