import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reglas-logistica',
  templateUrl: './reglas-logistica.component.html',
  styleUrls: ['./reglas-logistica.component.scss']
})
export class ReglasLogisticaComponent implements OnInit {

  // TODO: Corregir el error de los markets desactivados. Si están desactivados, no carga el modal de edición
  
  constructor(private appService: AppService, private ref: ChangeDetectorRef) { }

  //* BEGIN::Declaración de variables.
  dtOptions: DataTables.Settings = {};
  dropdownSettings:IDropdownSettings = {};
  dropdownSettingsStockStore:IDropdownSettings = {};
  dropdownSettingsSingle:IDropdownSettings = {};

  public shippingType: any[] = [{"id": "STORE", "value":"Recogida en tienda"},{"id": "HOME", "value":"A domicilio"},{"id": "PICKUP", "value":"Pickup"}]
  public selectedShippingType: any[] = []

  public shippingCountries: any[] = []
  public selectedCountries:any[] = [];

  public shippingProvinces: any[] = []
  public selectedProvinces:any[] = [];

  public stockStores: any[] = []
  public selectedStockStores: any[] = []
  public selectedStoreDestiny: any[] = []

  public externalPlatforms: any[] = []
  public selectedExternalPlatforms: any[] = []

  public logisticOperators: any[] = []
  public selectedLogisticOperator: any[] = []
  public selectedLogisticOperatorReturn: any[] = []

  public idRule: any
  public logisticRules: any[] = []

  public prioritiesFrom: any[] = []

  public selectedRuleEdit: any

  public row: any

  public createShopNGo = false
  public updateShopNGo = false
  //* END::Declaración de variables

  //* BEGIN::ViewChilds
  @ViewChild('priority') priority: any;
  @ViewChild('instructions') instructions: any;

  @ViewChild('editPriority') editPriority: any;
  @ViewChild('editShipping') editShipping: any;
  @ViewChild('editCountry') editCountry: any;
  @ViewChild('editProvince') editProvince: any;
  @ViewChild('editStockStore') editStockStore: any;
  @ViewChild('editPlatformOrigin') editPlatformOrigin: any;
  @ViewChild('editStoreDestiny') editStoreDestiny: any;
  @ViewChild('editOperator') editOperator: any;
  @ViewChild('editOperatorReturn') editOperatorReturn: any;
  @ViewChild('editInstructions') editInstructions: any;

  @ViewChild('domicilio') domicilio: ElementRef;
  @ViewChild('tienda') tienda: any;
  @ViewChild('spain') spain: any;
  //* END::ViewChilds

  table: DataTables.Api
  private timeoutId!: any

  private unsubscribe: Subscription[] = [];

  componentDestroyed$: Subject<boolean> = new Subject()

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE
  /**
 * Obtiene las reglas logísticas
 */
  getLogisticRules() {
    const appServiceSubscription = this.appService.getLogisticRules().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.logisticRules = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Obtiene los países de envío
   */
  getShippingCountries() {
    const appServiceSubscription = this.appService.getShippingCountries().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.shippingCountries = res
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Obtiene las provincias de envío de España
   */
  getShippingProvinces() {
    const appServiceSubscription = this.appService.getShippingProvinces().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.shippingProvinces = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Obtiene las tiendas con stock
   */
  getStockStores() {
    const appServiceSubscription = this.appService.getStockStores().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.stockStores = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Obtiene las plataformas externas
   */
  getExternalPlatforms() {
    const appServiceSubscription = this.appService.getExternalPlatforms().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.externalPlatforms = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Obtiene los operadores logísticos
   */
  getLogisticOperators() {
    const appServiceSubscription = this.appService.getLogisticOperators().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.logisticOperators = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  saveLogisticRule() {
    var countryArr: any[] = []
    this.selectedCountries.forEach(e => {
      countryArr.push(e.id)
    });
    
    var provinceArr: any[] = []
    this.selectedProvinces.forEach(e => {
      provinceArr.push(e.id)
    });

    var stockShopArr: any[] = []
    this.selectedStockStores.forEach(e => {
      stockShopArr.push(e.Id)
    });

    var externalPlatformsArr: any[] = []
    let shopngo = 0
    if (!this.createShopNGo) {
      this.selectedExternalPlatforms.forEach(e => {
        externalPlatformsArr.push(e.id)
      });
    }
    else {
      shopngo = 1
      externalPlatformsArr.push(2)
    }

    var storeDestinyArr: any[] = []
    this.selectedStoreDestiny.forEach(e => {
      storeDestinyArr.push(e.Id)
    });

    let shippingType = this.selectedShippingType.length > 0 ? this.selectedShippingType[0].id : null
    let countryIso = JSON.stringify(countryArr)
    let provinceId = JSON.stringify(provinceArr)
    let stockShop = JSON.stringify(stockShopArr)
    let externalPlatforms = JSON.stringify(externalPlatformsArr)
    let storeDestiny = JSON.stringify(storeDestinyArr)
    let logisticOperator = this.selectedLogisticOperator.length > 0 ? this.selectedLogisticOperator[0].id : null
    let logisticOperatorReturn = this.selectedLogisticOperatorReturn.length > 0 ? this.selectedLogisticOperatorReturn[0].id : 0
    let priority = this.priority.nativeElement.value
    let instructions = this.instructions.nativeElement.value

    let values = "('" + shippingType + "','" + countryIso + "','" + provinceId + "','" + stockShop + "'," + logisticOperator + "," + logisticOperatorReturn + ",'" + storeDestiny + "','" + externalPlatforms + "'," + priority + ",'" + instructions + "', " + shopngo + ")"

    if (shippingType == null || logisticOperator == null) {
      Swal.fire('Es necesario rellenar todos los campos con asterisco', '', 'info')
    } else {
      this.handlePriorities(priority, "insert")

      this.timeoutId = setTimeout(() => {
        const appServiceSubscription = this.appService.saveLogisticRule(values).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
              this.clearSelected()
              setTimeout(() => {
                this.table.ajax.reload()
                this.getLogisticRules()
                setTimeout(() => {
                  this.table.ajax.reload()
                }, 700);
                // window.nm.reload()
              }, 500);
            }, err => {
              console.log(err)
            })
          this.unsubscribe.push(appServiceSubscription);
      }, 1000);
    }
  }

  editLogisticRule() {
    var countryArr: any[] = []
    this.selectedCountries.forEach(e => {
      countryArr.push(e.id)
    });
    
    var provinceArr: any[] = []
    this.selectedProvinces.forEach(e => {
      provinceArr.push(e.id)
    });

    var stockShopArr: any[] = []
    this.selectedStockStores.forEach(e => {
      stockShopArr.push(e.Id)
    });

    var externalPlatformsArr: any[] = []
    let shopngo = 0
    if (!this.updateShopNGo) {
      this.selectedExternalPlatforms.forEach(e => {
        externalPlatformsArr.push(e.id)
      });
    }
    else {
      shopngo = 1
      externalPlatformsArr.push(2)
    }

    var storeDestinyArr: any[] = []
    this.selectedStoreDestiny.forEach(e => {
      storeDestinyArr.push(e.Id)
    });
    
    let shippingType = this.selectedShippingType.length > 0 ? this.selectedShippingType[0].id : null
    let countyIso = JSON.stringify(countryArr)
    let provinceId = JSON.stringify(provinceArr)
    let stockShop = JSON.stringify(stockShopArr)
    let externalPlatforms = JSON.stringify(externalPlatformsArr)
    let storeDestiny = JSON.stringify(storeDestinyArr)
    let logisticOperator = this.selectedLogisticOperator.length > 0 ? this.selectedLogisticOperator[0].id : null
    let logisticOperatorReturn = this.selectedLogisticOperatorReturn.length > 0 ? this.selectedLogisticOperatorReturn[0].id : 0
    let priority = this.editPriority.nativeElement.value
    let instructions = this.editInstructions.nativeElement.value

    this.handlePriorities(priority, "update", this.idRule)

    this.timeoutId = setTimeout(() => {
      const appServiceSubscription = this.appService.editLogisticRule(shippingType, countyIso, provinceId, stockShop, logisticOperator, logisticOperatorReturn, storeDestiny, externalPlatforms, priority, instructions, shopngo, this.idRule).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
            this.clearSelected()
            setTimeout(() => {
              this.getLogisticRules()
              this.table.ajax.reload()
            }, 500);
          }, err => {
            console.log(err)
          })

      this.unsubscribe.push(appServiceSubscription);
    }, 1000);
  }

  deleteRule(id: any) {
    var rule = this.logisticRules.find((a: {id_logistic_rule: any})=> a.id_logistic_rule == id)

    Swal.fire({
      title: '¿Borrar?',
      text: '¿Estás seguro de que deseas borrar esta regla?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Borrar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('¡Borrado!', '', 'success'
        )

        const appServiceSubscription = this.appService.deleteLogisticRule(id, rule.priority).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          this.table.ajax.reload()
          this.getLogisticRules()
          // window.location.reload()
        }, err => {
          console.log(err)
        })

        this.unsubscribe.push(appServiceSubscription);

      } else if (result.isDenied) {
        Swal.fire('No se borrró la regla', '', 'info')
      }
    })
  }

  /**
   * Lleva a cabo las operaciones para gestionar el correcto orden de prioridades tanto en insert como en update
   * @param priority 
   * @param mode 
   * @param id 
   * @returns 
   */
  handlePriorities(priority:any, mode: string, id: number = 0) {
    switch (mode) {
      case "insert":
        if (this.logisticRules.some(e => e.priority == priority)) {
          const appServiceSubscription = this.appService.getFromPriority(priority).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
              let newPriority = parseInt(priority) + 1
              let values: any[] = []

              res.forEach((e: any) => {
                values.push("(" + e.id_logistic_rule + "," + newPriority + ")")
                newPriority++
              });

              this.unsubscribe.push(appServiceSubscription);

              this.timeoutId = setTimeout(() => {
                const appServiceSubscription = this.appService.updatePriorityCascade(values.join(",")).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
                  }, err => {
                    console.log(err)
                  })

                  this.unsubscribe.push(appServiceSubscription);
              }, 1000);
            }, err => {
              console.log(err)
            })
        } else {
          return
        }
        break;
      case "update":
        const appServiceSubscription = this.appService.getFromPriority(priority).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
            let newPriority = parseInt(priority) + 1 
            let values: any[] = []

            res.forEach((e: any) => {
              if (e.id_logistic_rule != id) {
                values.push("(" + e.id_logistic_rule + "," + newPriority + ")")
                newPriority++
              }
            });

            if (values.length > 0) {
              this.timeoutId = setTimeout(() => {
                const appServiceSubscription = this.appService.updatePriorityCascade(values.join(",")).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
                  }, err => {
                    console.log(err)
                  })
  
                  this.unsubscribe.push(appServiceSubscription);
              }, 1000);
            }

          }, err => {
            console.log(err)
          })

          this.unsubscribe.push(appServiceSubscription);
        break;
      default:
        break;
    }
  }
  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Carga todas las tablas de datos necesarios
   */
  loadInfo() {
    this.getExternalPlatforms()
    this.getShippingCountries()
    this.getShippingProvinces()
    this.getStockStores()
    this.getLogisticOperators()
    this.getLogisticRules()
  }

  /**
   * Da formato al desplegable de cada línea
   * @param d 
   * @param id_item 
   * @param ean 
   * @returns 
   */
    format = (d: any, id_item: any, ean: any) => {
      let lineProvince: string = "";
      (JSON.parse(d.shipping_province_id)).forEach((e: any) => {
        var val = this.shippingProvinces.find((i: { id: any; }) => i.id == e)
        // console.log(val)
        if (val != undefined) {
          lineProvince += val.value+", ";
        }
      });
      let countries = JSON.parse(d.shipping_country_iso)
      if (countries.some((c: { priority: string; }) => c.priority === "ES")) {
        if (lineProvince == "") {
          lineProvince = "Cualquiera."
        }
      } else {
        if (lineProvince == "") {
          lineProvince = "No aplica."
        }
      }
  
      let lineCountry: string = "";
      (JSON.parse(d.shipping_country_iso)).forEach((e: any) => {
        var val = this.shippingCountries.find((i: { id: string; }) => i.id === e.toString())
        if (val != undefined) {
          lineCountry += val.value+", ";
        }
      });
      if (d.shipping_type != "STORE") {
        if (lineCountry == "") {
          lineCountry = "Cualquiera."
        }
      } else {
        if (lineCountry == "") {
          lineCountry = "No aplica."
        }
      }
  
      let lineStock: string = "";
      (JSON.parse(d.stock_shop)).forEach((e: any) => {
        var val = this.stockStores.find((i: { Id: any; }) => i.Id == e)
        if (val != undefined) {
          lineStock += val.Name+", ";
        }
      });
      if (lineStock == "") {
        lineStock = "Cualquiera."
      }
  
      let lineDestiny: string = "";
      (JSON.parse(d.destiny_shop)).forEach((e: any) => {
        var val = this.stockStores.find((i: { Id: any; }) => i.Id == e)
        if (val != undefined) {
          lineDestiny += val.Name+", ";
        }
      });
      if (d.shipping_type != "STORE") {
        if (lineDestiny == "") {
          lineDestiny = "No aplica."
        }
      } else {
        if (lineDestiny == "") {
          lineDestiny = "Cualquiera."
        }
      }
  
      let linePlatorm: string = "";
      (JSON.parse(d.externalPlatform_id)).forEach((e: any) => {
        var val = this.externalPlatforms.find((i: { id: any; }) => i.id == e)
        if (val != undefined) {
          linePlatorm += val.value+", ";
        }
      });
      if (linePlatorm == "") {
        linePlatorm = "Cualquiera."
      }
              
      return (
        `
        <ul class="list-group list-group-flush dtr-details">
        <li class="list-group-item"><span class="dtr-title"><b>Id regla</b></span>          <span class="dtr-data">${d.id_logistic_rule}</span></li>
        <li class="list-group-item"><span class="dtr-title"><b>Prioridad</b></span>          <span class="dtr-data">${d.priority}</span></li>
          <li class="list-group-item"><span class="dtr-title"><b>Tipo de envío</b></span>          <span class="dtr-data">${d.shipping_type}</span></li>
          <li class="list-group-item"><span class="dtr-title"><b>País</b></span>          <span class="dtr-data">${lineCountry.trim().slice(0, -1)}</span></li>
          <li class="list-group-item"><span class="dtr-title"><b>Provincia</b></span>          <span class="dtr-data">${lineProvince.trim().slice(0, -1)}</span></li>
          <li class="list-group-item"><span class="dtr-title"><b>Tiendas con stock</b></span>          <span class="dtr-data">${lineStock.trim().slice(0, -1)}</span></li>
          <li class="list-group-item"><span class="dtr-title"><b>Operador logístico</b></span>          <span class="dtr-data">${d.op_logistic_value}</span></li>
          <li class="list-group-item"><span class="dtr-title"><b>Operador logístico de devolución</b></span>          <span class="dtr-data">${d.op_logistic_return_value}</span></li>
          <li class="list-group-item"><span class="dtr-title"><b>Tienda de destino</b></span>          <span class="dtr-data">${lineDestiny.trim().slice(0, -1)}</span></li>
          <li class="list-group-item"><span class="dtr-title"><b>Market</b></span>          <span class="dtr-data">${linePlatorm.trim().slice(0, -1)}</span></li>
          <li class="list-group-item"><span class="dtr-title"><b>Información</b></span>                <span class="dtr-data">${d.preparation_instruction}</span></li>
        </ul>
        `
      );
    }
  
  /**
   * Handler para RowCallBack
   * @param info 
   */
  someClickHandler(info: any): void {
    this.row = info;
  }
  
  limpiarFiltros() {
    $('select.filtro').val("")
    $('select.filtro').trigger('change')
  }

  loadPriority() {
    if (this.logisticRules.length > 0 ) {
      var maxPriority = Math.max(...this.logisticRules.map(o => o.priority))
      this.priority.nativeElement.value = maxPriority + 1
    }
    else {
      this.priority.nativeElement.value = 1
    }
  }

  checkIfSpain() {
    return this.selectedCountries.some(e => e.id === 'ES')
  }

  checkShippingHome() {
    return (this.selectedShippingType.some(e => e.id === 'HOME') || this.selectedShippingType.some(e => e.id === 'PICKUP'))
  }

  checkShippingStore() {
    return this.selectedShippingType.some(e => e.id === 'STORE')
  }

  checkChange() {
    if (this.selectedShippingType[0].id == "STORE") {
      this.tienda.nativeElement.style.display="block"
    } else {
      this.tienda.nativeElement.style.display="none"
    }
  }

  checkData(id:any) {
    this.clearSelected()
    this.domicilio.nativeElement.style.display="block"
    this.spain.nativeElement.style.display="none"
    this.tienda.nativeElement.style.display="block"

    const appServiceSubscription = this.appService.getLogisticRule(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      console.log(res)
        res.forEach((e: any) => {
          let countries = JSON.parse(e.shipping_country_iso)
          let provinces = JSON.parse(e.shipping_province_id)
          let storesStock = JSON.parse(e.stock_shop)
          let externalPlatforms = JSON.parse(e.externalPlatform_id)
          let destinyShop = JSON.parse(e.destiny_shop)

          let notActiveShops: number[] = []
          let notActiveDestinyShops: number[] = []
          let notActivePlatforms: number[] = []
          let noCountry: number[] = []

          this.selectedRuleEdit = e

          if (e.shipping_type == 'STORE') {
            this.domicilio.nativeElement.style="display:none"
            this.tienda.nativeElement.style.display="block"
          }
          
          if (e.shipping_type == 'HOME' || e.shipping_type == 'PICKUP') {
            // this.tienda.nativeElement.remove()
            this.tienda.nativeElement.style.display="none"
            if (countries.find((x: string) => x === "ES")) {
              this.spain.nativeElement.style="display:block"
            }
          }

          this.editPriority.nativeElement.value = res[0].priority

          if (e.shipping_type != '') {
            this.editShipping.selectedItems = []
            let shipType = this.shippingType.find(x => x.id == e.shipping_type)
            this.editShipping.addSelected({id: shipType.id, text: shipType.value})
          }
          
          if (countries.length > 0) {
            this.editCountry.selectedItems = []
            countries.forEach((i: any) => {
              let country = this.shippingCountries.find(x => x.id === i)

              if (country) {
                this.editCountry.addSelected({id: country.id, text: country.value})
              }
              else {
                noCountry.push(i)
              }
            });
          }

          if (provinces.length > 0) {
            this.editProvince.selectedItems = []
            provinces.forEach((i: any) => {
              let province = this.shippingProvinces.find(x => x.id == i)
              this.editProvince.addSelected({id: province.id, text: province.value})
            });
          }

          if (storesStock.length > 0) {
            this.editStockStore.selectedItems = []

            storesStock.forEach((i: any) => {
              let storeStock = this.stockStores.find(x => x.Id == i)

              if (storeStock) {
                this.editStockStore.addSelected({id: storeStock.Id, text: storeStock.Name})
              }
              else {
                notActiveShops.push(i)
              }
            });
          }

          if (externalPlatforms.length > 0) {
            if (e.is_shopngo == 1) {
              this.updateShopNGo = true
              $('#shopNGoEdit').attr('checked', 'checked') 
            }
            else {
              this.updateShopNGo = false
              $('#shopNGoEdit').removeAttr('checked')
              
              this.editPlatformOrigin.selectedItems = []
              
              externalPlatforms.forEach((i: any) => {
                console.log(i)
                console.log(this.externalPlatforms)
                let platform = this.externalPlatforms.find(x => x.id == i)
                if (platform) {
                  this.editPlatformOrigin.addSelected({id: platform.id, text: platform.value})
                }
                else {
                  notActivePlatforms.push(i)
                }
              });
            }
          }
          else {
            this.updateShopNGo = false
            $('#shopNGoEdit').removeAttr('checked')
          }

          if (destinyShop.length > 0) {
            this.editStoreDestiny.selectedItems = []
            destinyShop.forEach((i: any) => {
              let platform = this.stockStores.find(x => x.Id == i)

              if (platform) {
                this.editStoreDestiny.addSelected({id: platform.Id, text: platform.Name})
              }
              else {
                notActiveDestinyShops.push(i);
              }
            });
            console.log(notActiveDestinyShops)
          }

          if (e.op_logistic_id > 0) {
            this.editOperator.selectedItems = []
            let operator = this.logisticOperators.find(x => x.id == e.op_logistic_id)
            this.editOperator.addSelected({id: operator.id, text: operator.value})
          }

          if (e.op_logistic_return_id) {
            this.editOperatorReturn.selectedItems = []
            let operatorReturn = this.logisticOperators.find(x => x.id == e.op_logistic_return_id)
            this.editOperatorReturn.addSelected({id: operatorReturn.id, text: operatorReturn.value})
          }

          this.editInstructions.nativeElement.value = res[0].preparation_instruction

          let warningMessage = 'Atención';
          let message = 'Imposible marcar como seleccionadas ';
          
          if (notActivePlatforms.length > 0) {
              message += `las siguientes plataformas: ${notActivePlatforms.join(',')}; `;
          }
          if (notActiveShops.length > 0) {
              message += `las siguientes tiendas: ${notActiveShops.join(',')}; `;
          }
          if (noCountry.length > 0) {
              message += `los siguientes países: ${noCountry.join(',')}; `;
          }
          if (notActiveDestinyShops.length > 0) {
              message += `las siguientes tiendas de destino: ${notActiveDestinyShops.join(',')}; `;
          }
          
          if (message !== 'Imposible marcar como seleccionadas ') {
              message += '. Es posible que se hayan desactivado.';
              Swal.fire(warningMessage, message, 'warning');
          }
        });
        this.ref.detectChanges()
      }, err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }

  clearSelected() {
    this.priority.nativeElement.value = ""
    this.instructions.nativeElement.value = ""
    this.selectedShippingType = []
    this.selectedCountries = []
    this.selectedExternalPlatforms = []
    this.selectedLogisticOperator = []
    this.selectedLogisticOperatorReturn = []
    this.selectedProvinces = []
    this.selectedStockStores = []
    this.selectedStoreDestiny = []
  }

  isShopNGo(type: string) {
    switch (type) {
      case "create":
        this.createShopNGo = !this.createShopNGo;
        if (this.createShopNGo != false) {
          this.selectedExternalPlatforms = [2]
        }
        else {
          this.selectedExternalPlatforms = []
        }
        break;
      case "update":
        this.updateShopNGo = !this.updateShopNGo;
        if (this.updateShopNGo != false) {
          this.selectedExternalPlatforms = [2]
        }
        else {
          this.selectedExternalPlatforms = []
        }
        break;
      default:
        break;
    }
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Procesa la datatable
   */
  processTable() {
    $('select[name="kt_datatable_length"]').addClass('custom-select custom-select-sm form-control form-control-sm');
    $('#kt_datatable_filter label input').addClass("form-control form-control-sm")

    this. table = $("#kt_datatable_logistic_rules").DataTable({
        searching: true,
        responsive: true,
        processing: true,
        paging: false,
        language: {
          "processing": `<span>Cargando...
          </span>`,
          "lengthMenu": "Mostrar _MENU_ registros",
          "zeroRecords": "No se encontraron resultados",
          "emptyTable": "Ningún dato disponible en esta tabla",
          "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "infoFiltered": "(filtrado de un total de _MAX_ registros)",
          "search": "Buscar:",
          "loadingRecords": " ",
          "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": ">",
            "previous": "<"
          },
          "aria": {
            "sortAscending": ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "decimal": ",",
          "thousands": ".",
          "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
        },
  
        ajax: {
          //! EN CASO DE HACER CAMBIOS LOCALES EN LA PARTE DE NODE, UTILIZAR LA URL COMENTADA. PARA SUBIRLO, SE DEBE UTILIZAR LA DEL environment
          // url: `http://localhost:4200/api/getLogisticRules`,
          url: `${environment.portalHost}/api/getLogisticRules`,
          dataSrc: "",
        },
        columns: [
          {
            className: 'details-control border-right-0',
            orderable: false,
            data: null,
            defaultContent: '<i class="la la-angle-right"></i>',
          },
          { title: '#', data: 'priority', width: "3%" },
          // { title: 'ID RULE', data: 'id_logistic_rule' },
          { title: 'SHIPPING', data: 'shipping_type' },
          { title: 'PAÍS', data: 'shipping_country_iso',
            render: function (data: any, type: any, full: any) {
              let line: string = full.countryName;
              if (full.shipping_type != "STORE") {
                if (line == null) {
                  line = "Cualquiera"
                }
              } else {
                if (line == null) {
                  line = "No aplica"
                }
              }
              return `<td>
                ` + line.trim() + `
              </td>`
            }
          },
          { title: 'PROVINCIA', data: 'shipping_province_id',
            render: function (data: any, type: any, full: any) {
              let line: string = full.provinceName;
              let countries = JSON.parse(full.shipping_country_iso)
              if (countries.some((c: { priority: string; }) => c.priority === "ES")) {
                if (line == null) {
                  line = "Cualquiera"
                }
              } else {
                if (line == null) {
                  line = "No aplica"
                }
              }
              return `<td>
                ` + line.trim() + `
              </td>`
            }
          },
          { title: 'TIENDAS CON STOCK', data: 'stock_shop',
            render: function (data: any, type: any, full: any) {
              let line: string = full.stockShopName;
              if (line == null) {
                line = "Cualquiera"
              }
              return `<td>
                ` + line.trim() + `
              </td>`
            }
          },
          { title: 'OPERADOR', data: 'op_logistic_value'},
          { title: 'OPERADOR DEVOLUCIÓN', data: 'op_logistic_return_value',
            render: function (data: any, type: any, full: any) {
              let line: string = full.op_logistic_return_value;
              if (line == null) {
                line = "No definido"
              }
              return `<td>
                ` + line.trim() + `
              </td>`
            }
          },
          { title: 'TIENDA DE DESTINO', data: 'destiny_shop',
            render: function (data: any, type: any, full: any) {
              let line: string = full.destinyShopName;
              if (full.shipping_type != "STORE") {
                if (line == null) {
                  line = "No aplica"
                }
              } else {
                if (line == null) {
                  line = "Cualquiera"
                }
              }
              return `<td>
                ` + line.trim() + `
              </td>`
            }
          },
          { title: 'MARKET', data: 'externalPlatform_id',
            render: function (data: any, type: any, full: any) {
              let line: string = full.externalPlatformName;
              if (line == null) {
                line = "Cualquiera"
              }
              return `<td>
                ` + line.trim() + `
              </td>`
            }
          },
          { title: 'ShopNGO', data: 'is_shopngo',
            render: function (data: any, type: any, full: any) {
              let shopngo: number = full.is_shopngo;

              return shopngo === 0 ? `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>` : `<span style="font-size:16px; color: green;">&check;</span>`
            }
          },
          // { title: 'PAÍSES', data: 'shipping_country_iso'},
          // { title: 'INSTRUCCIONES', data: 'preparation_instruction' },
          {
            title: '',
            render: function (data: any, type: any, full: any) {
              return `
              <td nowrap="nowrap" class="datatable-actions-td">
                <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action editRule" data-bs-target="#kt_modal_edit" data-bs-toggle="modal" id="` + full.id_logistic_rule + `"  title="Editar">
                  <span class="svg-icon svg-icon-md"> 
                    <svg xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                          fill="#000000" fill-rule="nonzero"
                          transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                        <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                      </g>
                    </svg> 
                  </span>
                </a>
                <a class="btn btn-sm btn-clean btn-icon datatable-action deleteRule" id="` + full.id_logistic_rule + `" title="Eliminar"> 
                  <span class="svg-icon svg-icon-md"> 
                    <svg
                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                        viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <path
                            d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                            fill="#000000" fill-rule="nonzero"></path>
                          <path
                            d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                            fill="#000000" opacity="0.3"></path>
                      </g>
                    </svg> 
                  </span> 
                </a>
              </td>`
  
            },
            className: 'dt-body-center border-right-0'
          },
        ],
        order: [[1, 'asc']],
        columnDefs: [
          // {
          //   targets: 1,
          //   visible: false,
          //   searchable: false
          // },
          // {
          //   targets: 10,
          //   visible: false,
          //   searchable: true
          // },
  
        ],
  
        rowCallback: (row: Node, data: any, index: number) => {
          const self = this;
          $('td', row).off('click');
          $('td', row).on('click', () => {
            self.someClickHandler(row);
          });
          $('td.details-control', row).off('click');
          $('td.details-control', row).on('click', () => {
            var tr = row;
            var row2 = this.table.row(tr)
            if (row2.child.isShown()) {
  
              row2.child.hide();
              $('td.details-control').html('<i class="la la-angle-right"></i>')
              $(".dt-hasChild").find('td.details-control').html('<i class="la la-angle-down"></i>');
  
            } else {
              var start_time = new Date().getTime();
  
              var end_tiem = new Date().getTime();
              var tiempo = end_tiem - start_time;
  
              this.timeoutId = setTimeout(() => {
                row2.child(this.format(row2.data(), data["id_item"], data["ean"])).show();
                $(".dt-hasChild").find('td.details-control').html('<i class="la la-angle-down"></i>');
              }, tiempo / 0.0010000000);
            }
          });
          $('input.editor-active', row).off('click');
          // $('input.editor-active', row).on('click', () => {
          //   if (this.resolver.includes(data.id)) {
  
          //     var i = this.resolver.indexOf(data.id)
          //     this.resolver.splice(i, 1)
          //   } else {
          //     this.resolver.push(data.id)
          //   }
  
          //   this.listCheck = JSON.stringify(this.resolver).substring(1, JSON.stringify(this.resolver).length - 1)
          // })
          return row;
  
        },
  
        drawCallback: () => {
          const editRulesModal = document.getElementsByClassName("editRule")
  
          for (let i = 0; i < editRulesModal.length; i++) {
            const e = editRulesModal[i];
  
            e.addEventListener('click', () => {
              this.checkData(e.id)
              this.idRule = e.id
            })
          }
  
          const deleteRule = document.getElementsByClassName("deleteRule")
  
          for (let i = 0; i < deleteRule.length; i++) {
            const e = deleteRule[i];
  
            e.addEventListener('click', () => {
              this.deleteRule(e.id)
            })
          }
        },
  
        initComplete: () => {
          this.loadInfo()
  
          // const editRulesModal = document.getElementsByClassName("editRule")
  
          // for (let i = 0; i < editRulesModal.length; i++) {
          //   const e = editRulesModal[i];
  
          //   e.addEventListener('click', () => {
          //     this.checkData(e.id)
          //     this.idRule = e.id
          //   })
          // }
  
          // const deleteRule = document.getElementsByClassName("deleteRule")
  
          // for (let i = 0; i < deleteRule.length; i++) {
          //   const e = deleteRule[i];
  
          //   e.addEventListener('click', () => {
          //     this.deleteRule(e.id)
          //   })
          // }
  
          this.table.columns([2,6,7]).every(function () {
            var column = this;
            var select:any;
  
            select = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
            .appendTo($(column.footer()).empty()).on('change', () => {
                var val = $.fn.dataTable.util.escapeRegex(
                  select.val()
                );
                column.search(val ? '^' + val + '$' : '', true, false).draw();
              });
  
              column.data().unique().sort().each(function (d: string, j: any) {
                if (d != null) {
                  select.append('<option value="' + d + '">' + d + '</option>')
                }
              });
          });
          
          var filtersArray = ["", "", "", "", ""];
  
          var tableCountry = this.table.column(3)
          var tableProvince = this.table.column(4)
          var tableStockStores = this.table.column(5)
          var tableDestiny = this.table.column(7)
          var tableMarket = this.table.column(8)
  
          // var selectCountry = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
          // .appendTo($(tableCountry.footer()).empty()).on('change', () => {
          //     var val = $.fn.dataTable.util.escapeRegex(
          //       selectCountry.val()
          //     );
          //     console.log(val)
          //     table.search(val ?  val : '', true, false).draw();
          //   });
  
          //   tableCountry.data().unique().sort().each( (d: string, j: any) => {
          //     JSON.parse(d).forEach((e: any) => {
          //       var data = this.shippingCountries.find((i: { id: any; }) => i.id === e.toString())
          //       selectCountry.append('<option value="' + data.id + '">' + data.value + '</option>')
          //     });
          //   });
  
          // var selectCountry = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
          // .appendTo($(tableCountry.footer()).empty()).on('change', () => {
          //     var val = $.fn.dataTable.util.escapeRegex(
          //       selectCountry.val()
          //     );
  
          //     filtersArray[0] = val;
  
          //     table.search(JSON.stringify(filtersArray) ? JSON.stringify(filtersArray) : '', true, true).draw();
          //   });
            
          //   tableCountry.data().unique().sort().each( (d: any, j: any) => {
          //     console.log(d)
          //     JSON.parse(d).forEach((e: any) => {
          //       console.log(this.shippingCountries)
          //       var data = this.shippingCountries.find((i: { id: any; }) => i.id === e.toString())
          //       console.log(data)
          //       selectCountry.append('<option value="' + data.value + '">' + data.value + '</option>')
          //     });
          //   });
          
          // var selectProvince = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
          // .appendTo($(tableProvince.footer()).empty()).on('change', () => {
          //     var val = $.fn.dataTable.util.escapeRegex(
          //       selectProvince.val()
          //     );
  
          //     filtersArray[1] = val;
              
          //     table.search(JSON.stringify(filtersArray) ? JSON.stringify(filtersArray) : '', true, false).draw();
          //   });
  
          //   tableProvince.data().unique().sort().each( (d: any, j: any) => {
          //     JSON.parse(d).forEach((e: any) => {
          //       var data = this.shippingProvinces.find((i: { id: any; }) => i.id == e)
          //       selectProvince.append('<option value="' + data.value + '">' + data.value + '</option>')
          //     });
          //   });
  
          // var selectStockStore = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
          // .appendTo($(tableStockStores.footer()).empty()).on('change', () => {
          //     var val = $.fn.dataTable.util.escapeRegex(
          //       selectStockStore.val()
          //     );
  
          //     filtersArray[2] = val;
              
          //     table.search(JSON.stringify(filtersArray) ? JSON.stringify(filtersArray) : '', true, false).draw();
          //   });
  
          //   tableStockStores.data().unique().sort().each( (d: any, j: any) => {
          //     JSON.parse(d).forEach((e: any) => {
          //       var data = this.stockStores.find((i: { Id: any; }) => i.Id == e)
          //       selectStockStore.append('<option value="' + data.Name + '">' + data.Name + '</option>')
          //     });
          //   });
  
          // var selectDestiny = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
          // .appendTo($(tableDestiny.footer()).empty()).on('change', () => {
          //     var val = $.fn.dataTable.util.escapeRegex(
          //       selectDestiny.val()
          //     );
              
          //     if (val != '') {
          //       filtersArray[3] = val.toString();
          //     } else {
          //       filtersArray[3] = val
          //     }
              
          //     table.search(JSON.stringify(filtersArray) ? JSON.stringify(filtersArray) : '', true, false).draw();
          //   });
  
          //   tableDestiny.data().unique().sort().each( (d: any, j: any) => {
          //     JSON.parse(d).forEach((e: any) => {
          //       var data = this.stockStores.find((i: { Id: any; }) => i.Id == e)
          //       selectDestiny.append('<option value="' + data.Name + '">' + data.Name + '</option>')
          //     });
          //   });
          
          // var selectMarket = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
          // .appendTo($(tableMarket.footer()).empty()).on('change', () => {
          //     var val = $.fn.dataTable.util.escapeRegex(
          //       selectMarket.val()
          //     );
  
          //     if (val != '') {
          //       filtersArray[4] = val.split('-')[1].trim();
          //     } else {
          //       filtersArray[4] = val
          //     }
              
          //     table.search(JSON.stringify(filtersArray) ? JSON.stringify(filtersArray) : '', true, false).draw();
          //   });
  
          //   tableMarket.data().unique().sort().each( (d: any, j: any) => {
          //     JSON.parse(d).forEach((e: any) => {
          //       var data = this.externalPlatforms.find((i: { id: any; }) => i.id == e)
          //       selectMarket.append('<option value="' + data.value + '">' + data.value + '</option>')
          //     });
          //   });
  
          $('tfoot tr:not(#newrule)').appendTo('thead')
          $('select[name="kt_datatable_length"]').addClass('custom-select custom-select-sm form-control form-control-sm');
          $('#kt_datatable_filter label input').addClass("form-control form-control-sm")
        }
    })
  }

  /**
   * Inicia el componente
   */
  ngOnInit(): void {
    this.loadInfo()
    this.processTable()

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'value',
      selectAllText: 'Seleccionar todas',
      unSelectAllText: 'Deseleccionar todas',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.dropdownSettingsStockStore = {
      singleSelection: false,
      idField: 'Id',
      textField: 'Name',
      selectAllText: 'Seleccionar todas',
      unSelectAllText: 'Deseleccionar todas',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.dropdownSettingsSingle = {
      singleSelection: true,
      idField: 'id',
      textField: 'value',
      selectAllText: 'Seleccionar todas',
      unSelectAllText: 'Deseleccionar todas',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy(): void {
    if (this.table) {
      this.table.destroy() 
    }
    
    clearTimeout(this.timeoutId)

    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN
}
