<!-- begin::Modal 1 -->
<div style="display: flex; padding: 0 0 2rem 2rem; justify-content: right">
    <button
      type="button"
      class="btn btn-primary"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_1"
      (click)="clearVariables()"
    >
      Crear regla de producto
    </button>
  
    <div class="modal fade" tabindex="-1" id="kt_modal_1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Creación de regla de producto</h5>
  
            <!--begin::Close-->
            <div
              class="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span class="svg-icon svg-icon-2x"></span>
            </div>
            <!--end::Close-->
          </div>
  
          <div class="modal-body">
            <!--begin::Input nombre-->
            <div class="form-floating mb-7" style="display: flex">
              <input
                type="text"
                class="form-control"
                id="floatingInputValue"
                [(ngModel)]="createName"
              />
              <label for="floatingInputValue">Nombre</label>
            </div>
            <!--end::Input nombre-->
  
            <!--begin::Input orden-->
            <!-- ! Por ahora no se le da uso -->
            <!-- ? si se usa, hay que añadir en la función del (click) el campo "toInt(createOrden.value)" donde está puesto 1 -->
            <!-- <div class="form-floating mb-7" style="display: flex;">
                          <input type="number" class="form-control" id="floatingInputValue" #createOrden/>
                          <label for="floatingInputValue">Orden</label>
                      </div> -->
            <!--end::Input orden-->
          </div>
  
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">
              Cerrar
            </button>
            <button
              type="button"
              (click)="createProductRule(createName, '', 1, 1)"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Modal 1 -->
<!--begin::Accordion-->
<div class="accordion" id="accordion_1">
    <div class="accordion-item" *ngFor="let item of productRules | paginate: { itemsPerPage: 10, currentPage: page }" >
        <div>

            <!-- begin::Modal editar regla -->
            <div class="modal fade" tabindex="-1" id="kt_modal_3_{{item.id_ruleset}}">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Editar regla</h5>
            
                            <!--begin::Close-->
                            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                <span class="svg-icon svg-icon-2x"></span>
                            </div>
                            <!--end::Close-->
                        </div>
            
                        <div class="modal-body">
                            <div class="form-floating mb-7" style="display: flex;">
                                <input type="text" class="form-control" id="floatingInputValue" value="{{item.name}}" #rulesetName3/>
                                <label for="floatingInputValue">Nombre</label>
                            </div>
                        </div>
            
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                            <button type="button" (click)="updateProductRule(rulesetName3.value, '', item.id_ruleset)" class="btn btn-primary" data-bs-dismiss="modal">
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end::Modal editar regla -->

            <h2 class="accordion-header" id="accordionHeader_{{item.id_ruleset}}" style="display: flex; align-items: center;">
                <button class="accordion-button fs-4 fw-bold collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#accordionBody_'+item.id_ruleset" aria-expanded="false" [attr.aria-controls]="'accordionBody_'+item.id_ruleset" (click)="getRulesOnProducts(item.id_ruleset)">
                    <svg *ngIf="item.activo == 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                    </svg>
                    <span *ngIf="item.activo == 1" style="font-size:16px; color: green;">&check;</span>
                    {{item.name}}
                </button>
                <!-- begin::Dropdown menu 1 -->
                <div class="card-toolbar" style="padding: 1rem;">
                    <div class="dropdown dropdown-inline">
                        <button type="button" class="btn btn-hover-light-primary btn-icon btn-sm" data-bs-toggle="dropdown" data-bs-target="#kt_dropdown_1" aria-haspopup="true" aria-expanded="false">
                            <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect x="10" y="10" width="4" height="4" rx="2" fill="black"/>
                                <rect x="17" y="10" width="4" height="4" rx="2" fill="black"/>
                                <rect x="3" y="10" width="4" height="4" rx="2" fill="black"/>
                                </svg>
                            </span>
                        </button>
                        <div id="kt_dropdown_1" class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                            <a class="dropdown-item" href="#" data-bs-toggle="modal" [attr.data-bs-target]="'#kt_modal_3_'+item.id_ruleset">Editar</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="#" [swal]="{title:'¿Eliminar?', text:'¿Estás seguro de que deseas eliminar la regla?', icon:'warning',showCancelButton: true}"
                            (confirm)="deleteProductRule(item.id_ruleset)">Eliminar</a>
                            <ng-container *ngIf="item.activo == 0">
                                <div class="dropdown-divider"></div>
                                <button class="dropdown-item" (click)="activateRule(1,item.id_ruleset)">Activar</button>
                            </ng-container>
                            <ng-container *ngIf="item.activo == 1">
                                <div class="dropdown-divider"></div>
                                <button class="dropdown-item" (click)="activateRule(0,item.id_ruleset)">Desactivar</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!-- end::Dropdown menu 1 -->
            </h2>
            <div id="accordionBody_{{item.id_ruleset}}" class="accordion-collapse collapse" [attr.aria-labelledby]="'accordionHeader_'+item.id_ruleset" data-bs-parent="#accordion_1">
                <div class="accordion-body">

                    <!-- begin:: Modal de includes -->
                    <div class="modal fade" tabindex="-1" id="kt_modal_2_{{item.id_ruleset}}_1">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Creación de reglas</h5>
                                    <!--begin::Close-->
                                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                        <span class="svg-icon svg-icon-2x"></span>
                                    </div>
                                    <!--end::Close-->
                                </div>
                    
                                <div class="modal-body">

                                    <!--begin::Nombre de regla-->
                                    <div class="form-floating mb-7" style="display: flex;">
                                        <input class="form-control" type="text" #ruleName>
                                        <label for="floatingSelectType">Nombre de regla</label>
                                    </div>
                                    <!--end::Nombre de regla-->

                                    <!--begin::Select atributo-->
                                    <div class="form-floating mb-7" style="display: flex;">
                                        <select class="form-select" id="floatingSelectType" aria-label="Floating label select example" #attTypeCreate2 >
                                            <option selected></option>
                                            <option *ngFor="let attributeType of enumAttribute_type;">{{attributeType}}</option>
                                        </select>
                                        <label for="floatingSelectType">Tipo de atributo</label>
                                    </div>
                                    <!--end::Select atributo-->

                                    <!--begin::Select atributo-->
                                    <div class="form-floating mb-7" style="display: flex;">
                                        <select  class="form-select" id="floatingSelectAtt" aria-label="Floating label select example" #attributeCreate2 (change)="attributeSelect(attTypeCreate2.value, attributeCreate2.value)">
                                            <option selected></option>
                                            <ng-container *ngIf="attTypeCreate2.value == 'itemfield'">
                                                <option *ngFor="let name of itemFieldAlias;" value="{{name[0]}}">{{name[1]}}</option>
                                            </ng-container>
                                            <option *ngIf="attTypeCreate2.value == 'price'">price</option>
                                            <option *ngIf="attTypeCreate2.value == 'discount'">discount</option>
                                            <ng-container *ngIf="attTypeCreate2.value == 'customproperty'">
                                                <option *ngFor="let property of customProperties">{{property.PropertyName}}</option>
                                            </ng-container>
                                            <option *ngIf="attTypeCreate2.value == 'item'">item</option>
                                        </select>
                                        <label for="floatingSelectAtt">Atributo</label>
                                    </div>
                                    <!--end::Select atributo-->
                                    
                                    <!--begin::Select operación-->
                                    <div class="form-floating mb-7" style="display: flex;">
                                        <select class="form-select" id="floatingSelectOperation" aria-label="Floating label select example" #operacionCreate2 (change)="observeSelect()">
                                            <ng-container *ngIf="attTypeCreate2.value == 'itemfield' || attTypeCreate2.value == 'item'">
                                                <option selected></option>
                                                <ng-container *ngFor="let option of enumOperation;">
                                                    <option *ngIf="option == 'IN' || option == 'NOT IN' || option == '=' || option == '!='">{{option}}</option>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="attTypeCreate2.value == 'price' || attTypeCreate2.value == 'discount'">
                                                <option selected></option>
                                                <option *ngFor="let option of enumOperation | slice:2:8">{{option}}</option>
                                            </ng-container>
                                            <ng-container *ngIf="attTypeCreate2.value == 'customproperty'">
                                                <option></option>
                                                <ng-container *ngFor="let option of enumOperation;">
                                                    <option *ngIf="option == '=' || option == '!='">{{option}}</option>
                                                </ng-container>
                                            </ng-container>
                                            <option *ngIf="!attTypeCreate2.value" selected></option>
                                        </select>
                                        <label for="floatingSelectOperation">Operación</label>
                                    </div>
                                    <!--end::Select operación-->

                                    <!--begin::Input valor-->
                                    <!-- ITEM -->
                                    <div *ngIf="attTypeCreate2.value == 'item'">
                                        <p *ngIf="operacionCreate2.value == 'IN' || operacionCreate2.value == 'NOT IN'">Introducir la referencia del artículo de Avelon separada por comas simples</p>
                                        <p *ngIf="operacionCreate2.value == '=' || operacionCreate2.value == '!='">Introducir una única referencia de artículo de Avelon.</p>
                                        <div class="form-floating mb-7" style="display: flex;">
                                            <input class="form-control" id="floatingInputValue" type="text" #valorCreate2/>
                                            <label for="floatingInputValue">Valor</label>
                                        </div>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" (click)="createRuleNumber(item.id_ruleset, 'include', attTypeCreate2.value, attributeCreate2.value, operacionCreate2.value, valorCreate2.value, ruleName.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>

                                    <!-- ITEMFIELD -->
                                    <div *ngIf="attTypeCreate2.value == 'itemfield'">

                                        <ng-multiselect-dropdown *ngIf="operacionCreate2.value == 'IN' || operacionCreate2.value == 'NOT IN'"
                                            name="itemfield"
                                            [placeholder]="'Valor'"
                                            [settings]="dropdownSettings"
                                            [data]="itemFieldsList"
                                            [(ngModel)]="multipleSelect"
                                        >
                                        </ng-multiselect-dropdown>

                                        <ng-multiselect-dropdown *ngIf="operacionCreate2.value == '=' || operacionCreate2.value == '!='"
                                            name="itemfield"
                                            [placeholder]="'Valor'"
                                            [settings]="dropdownSingleSettings"
                                            [data]="itemFieldsList"
                                            [(ngModel)]="multipleSelect"
                                        >
                                        </ng-multiselect-dropdown>

                                        
                                        <div [style.display]="!operacionCreate2.value ? 'block' : 'none'" class="form-floating mb-7" style="display: flex;">
                                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                                <option *ngIf="!attributeCreate2.value" selected>Selecciona un atributo y una operación</option>
                                                <option *ngIf="attributeCreate2.value != ''">Selecciona una operación</option>
                                            </select>
                                            <label for="floatingInputValue">Valor</label>
                                        </div>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" (click)="createRule(item.id_ruleset, 'include', attTypeCreate2.value, attributeCreate2.value, operacionCreate2.value, (multipleSelect.length ? multipleSelect : internalMap), ruleName.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>

                                    <!-- PRICE || DISCOUNT -->
                                    <div *ngIf="attTypeCreate2.value == 'price' || attTypeCreate2.value == 'discount'">
                                        <p>Introduce el valor de la regla separando los decimales con punto (.)</p>
                                        <div class="form-floating mb-7" style="display: flex;">
                                            <input class="form-control" type="number" #valorCreate2/>
                                            <label for="floatingInputValue">Valor</label>
                                        </div>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" (click)="createRuleNumber(item.id_ruleset, 'include', attTypeCreate2.value, attributeCreate2.value, operacionCreate2.value,valorCreate2.value, ruleName.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>

                                    <!-- CUSTOMPROPERTY -->
                                    <div *ngIf="attTypeCreate2.value == 'customproperty'">
                                        <ng-multiselect-dropdown
                                            name="custom"
                                            [placeholder]="'Valor'"
                                            [settings]="dropdownSingleSettings"
                                            [data]="customPropertiesValues"
                                            [(ngModel)]="selectedItems"
                                        >
                                        </ng-multiselect-dropdown>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" (click)="createRule(item.id_ruleset, 'include', attTypeCreate2.value, attributeCreate2.value, operacionCreate2.value, (selectedItems.length ? selectedItems : internalMap), ruleName.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>

                                    <!-- TRAMPANTOJO -->
                                    <div [style.display]="!attTypeCreate2.value ? 'block' : 'none'" class="form-floating mb-7" style="display: flex;">
                                        <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                            <option selected></option>
                                        </select>
                                        <label for="floatingInputValue">Valor</label>
                                    </div>
                                    <!--end::Input valor-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end::Modal de includes -->

                    <!-- begin:: Modal de excludes -->
                    <div class="modal fade" tabindex="-1" id="kt_modal_2_{{item.id_ruleset}}_2">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Creación de reglas</h5>
                                    <!--begin::Close-->
                                    <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                        <span class="svg-icon svg-icon-2x"></span>
                                    </div>
                                    <!--end::Close-->
                                </div>
                    
                                <div class="modal-body">

                                    <!--begin::Nombre de regla-->
                                    <div class="form-floating mb-7" style="display: flex;">
                                        <input class="form-control" type="text" #ruleNameEx>
                                        <label for="floatingSelectType">Nombre de regla</label>
                                    </div>
                                    <!--end::Nombre de regla-->

                                    <!--begin::Select tipo de atributo-->
                                    <div class="form-floating mb-7" style="display: flex;">
                                        <select class="form-select" id="floatingSelect" aria-label="Floating label select example" #attTypeCreate4 (change)="observeSelect()">
                                            <option selected></option>
                                            <option *ngFor="let attributeType of enumAttribute_type;">{{attributeType}}</option>
                                        </select>
                                        <label for="floatingSelect">Tipo de atributo</label>
                                    </div>
                                    <!--end::Select tipo de atributo-->

                                    <!--begin::Select atributo-->
                                    <div class="form-floating mb-7" style="display: flex;">
                                        <select  class="form-select" id="floatingSelectAtt" aria-label="Floating label select example" #attributeCreate4 (change)="attributeSelect(attTypeCreate4.value, attributeCreate4.value)">
                                            <option selected></option>
                                            <ng-container *ngIf="attTypeCreate4.value == 'itemfield'">
                                                <option *ngFor="let name of itemFieldAlias;" value="{{name[0]}}">{{name[1]}}</option>
                                            </ng-container>
                                            <option *ngIf="attTypeCreate4.value == 'price'">price</option>
                                            <option *ngIf="attTypeCreate4.value == 'discount'">discount</option>
                                            <ng-container *ngIf="attTypeCreate4.value == 'customproperty'">
                                                <option *ngFor="let property of customProperties">{{property.PropertyName}}</option>
                                            </ng-container>
                                            <option *ngIf="attTypeCreate4.value == 'item'">item</option>
                                        </select>
                                        <label for="floatingSelectAtt">Atributo</label>
                                    </div>
                                    <!--end::Select atributo-->
                                    
                                    <!--begin::Select operación-->
                                    <div class="form-floating mb-7" style="display: flex;">
                                        <select class="form-select" id="floatingSelectOperation" aria-label="Floating label select example" #operacionCreate4 (change)="observeSelect()">
                                            <ng-container *ngIf="attTypeCreate4.value == 'itemfield' || attTypeCreate4.value == 'item'">
                                                <option selected></option>
                                                <ng-container *ngFor="let option of enumOperation;">
                                                    <option *ngIf="option == 'IN' || option == 'NOT IN' || option == '=' || option == '!='">{{option}}</option>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="attTypeCreate4.value == 'price' || attTypeCreate4.value == 'discount'">
                                                <option selected></option>
                                                <option *ngFor="let option of enumOperation | slice:2:8">{{option}}</option>
                                            </ng-container>
                                            <ng-container *ngIf="attTypeCreate4.value == 'customproperty'">
                                                <option></option>
                                                <ng-container *ngFor="let option of enumOperation;">
                                                    <option *ngIf="option == '=' || option == '!='">{{option}}</option>
                                                </ng-container>
                                            </ng-container>
                                            <option *ngIf="!attTypeCreate4.value" selected></option>
                                        </select>
                                        <label for="floatingSelectOperation">Operación</label>
                                    </div>
                                    <!--end::Select operación-->

                                    <!--begin::Input valor-->
                                    <!-- ITEM -->
                                    <div *ngIf="attTypeCreate4.value == 'item'">
                                        <p *ngIf="operacionCreate4.value == 'IN' || operacionCreate4.value == 'NOT IN'">Introducir la referencia del artículo de Avelon separada por comas simples</p>
                                        <p *ngIf="operacionCreate4.value == '=' || operacionCreate4.value == '!='">Introducir una única referencia de artículo de Avelon.</p>
                                        <div class="form-floating mb-7" style="display: flex;">
                                            <input class="form-control" id="floatingInputValue" type="text" #valorCreate4/>
                                            <label for="floatingInputValue">Valor</label>
                                        </div>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" (click)="createRuleNumber(item.id_ruleset, 'exclude', attTypeCreate4.value, attributeCreate4.value, operacionCreate4.value, valorCreate4.value, ruleNameEx.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>

                                    <!-- ITEMFIELD -->
                                    <div *ngIf="attTypeCreate4.value == 'itemfield'">

                                        <ng-multiselect-dropdown *ngIf="operacionCreate4.value == 'IN' || operacionCreate4.value == 'NOT IN'"
                                            name="itemfield"
                                            [placeholder]="'Valor'"
                                            [settings]="dropdownSettings"
                                            [data]="itemFieldsList"
                                            [(ngModel)]="multipleSelect"
                                        >
                                        </ng-multiselect-dropdown>

                                        <ng-multiselect-dropdown *ngIf="operacionCreate4.value == '=' || operacionCreate4.value == '!='"
                                            name="itemfield"
                                            [placeholder]="'Valor'"
                                            [settings]="dropdownSingleSettings"
                                            [data]="itemFieldsList"
                                            [(ngModel)]="multipleSelect"
                                        >
                                        </ng-multiselect-dropdown>

                                        <div [style.display]="!operacionCreate4.value ? 'block' : 'none'" class="form-floating mb-7" style="display: flex;">
                                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                                <option *ngIf="!attributeCreate4.value" selected>Selecciona un atributo y una operación</option>
                                                <option *ngIf="attributeCreate4.value != ''">Selecciona una operación</option>
                                            </select>
                                            <label for="floatingInputValue">Valor</label>
                                        </div>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" (click)="createRule(item.id_ruleset, 'exclude', attTypeCreate4.value, attributeCreate4.value, operacionCreate4.value, (multipleSelect.length ? multipleSelect : internalMap), ruleNameEx.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>

                                    <!-- PRICE || DISCOUNT -->
                                    <div *ngIf="attTypeCreate4.value == 'price' || attTypeCreate4.value == 'discount'">
                                        <p>Introduce el valor de la regla separando los decimales con punto (.)</p>
                                        <div class="form-floating mb-7" style="display: flex;">
                                            <input class="form-control" type="number" #valorCreate4/>
                                            <label for="floatingInputValue">Valor</label>
                                        </div>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" (click)="createRuleNumber(item.id_ruleset, 'exclude', attTypeCreate4.value, attributeCreate4.value, operacionCreate4.value,valorCreate4.value, ruleNameEx.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>

                                    <!-- CUSTOMPROPERTY -->
                                    <div *ngIf="attTypeCreate4.value == 'customproperty'">
                                        <ng-multiselect-dropdown
                                            name="custom"
                                            [placeholder]="'Valor'"
                                            [settings]="dropdownSingleSettings"
                                            [data]="customPropertiesValues"
                                            [(ngModel)]="selectedItems"
                                        >
                                        </ng-multiselect-dropdown>

                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                            <button type="button" (click)="createRule(item.id_ruleset, 'exclude', attTypeCreate4.value, attributeCreate4.value, operacionCreate4.value, (selectedItems.length ? selectedItems : internalMap), ruleNameEx.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                Guardar
                                            </button>
                                        </div>
                                    </div>

                                    <!-- TRAMPANTOJO -->
                                    <div [style.display]="!attTypeCreate4.value ? 'block' : 'none'" class="form-floating mb-7" style="display: flex;">
                                        <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                            <option selected></option>
                                        </select>
                                        <label for="floatingInputValue">Valor</label>
                                    </div>
                                    <!--end::Input valor-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end::Modal de excludes -->
                    
                    <!-- begin::Includes -->
                    <div style="display: none;">
                        <div style="display: flex; align-items: center;">
                            <h3>Incluidos</h3>

                            <!-- begin::Dropdown menu 2 -->
                            <div class="card-toolbar" style="padding: 1rem;">
                                <div class="dropdown dropdown-inline">
                                    <button type="button" class="btn btn-hover-light-primary btn-icon btn-sm" data-bs-toggle="modal" [attr.data-bs-target]="'#kt_modal_2_'+item.id_ruleset+'_1'" aria-haspopup="true" aria-expanded="false" (click)="multipleSelect = [] || selectedItems = []">

                                        <!--begin::Svg Icon | path: assets/media/icons/duotune/general/gen041.svg-->
                                        <span class="svg-icon svg-icon-muted svg-icon-2hx">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->

                                    </button>
                                </div>
                            </div>
                            <!-- end::Dropdown menu 2 -->

                        </div>

                        <!--begin::Datatable-->
                        <table id="kt_datatable_example_1" class="table align-middle table-row-dashed fs-6 gy-5">
                            <thead>
                                <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                    <th style="width: 5%;">Nombre</th>
                                    <th style="width: 15%;">Campo</th>
                                    <th style="width: 10%;">Operación</th>
                                    <th style="width: 55%;">Valores</th>
                                    <th style="width: 10%;">Acciones</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let cat of rulesOnProducts" class="text-gray-600 fw-bold">
                                <!-- begin::Modal edición includes -->
                                <div class="modal fade" tabindex="-1" id="kt_modal_4_{{cat.id_rule}}_{{item.id_ruleset}}">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Edición de incluido con ID: {{cat.id_rule}}</h5>

                                                <!--begin::Close-->
                                                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                                    <span class="svg-icon svg-icon-2x"></span>
                                                </div>
                                                <!--end::Close-->

                                            </div>
                                            <div class="modal-body">

                                                <!--begin::Nombre de regla-->
                                                <div class="form-floating mb-7" style="display: flex;">
                                                    <input class="form-control" type="text" #ruleNameEdit value="{{cat.name}}">
                                                    <label for="floatingSelectType">Nombre de regla</label>
                                                </div>
                                                <!--end::nombre de regla-->

                                                <!--begin::Select atributo-->
                                                <div class="form-floating mb-7" style="display: flex;">
                                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example" #attTypeCreate5 (change)="observeSelect()">
                                                        <option *ngFor="let attributeType of enumAttribute_type;" [attr.selected]="cat.attribute_type == attributeType ? true : null">{{attributeType}}</option>
                                                    </select>
                                                    <label for="floatingSelect">Tipo de atributo</label>
                                                </div>
                                                <!--end::Select atributo-->

                                                <!--begin::Select atributo-->
                                                <div class="form-floating mb-7" style="display: flex;">
                                                    <select  class="form-select" id="floatingSelectAtt" aria-label="Floating label select example" #attributeCreate5 (change)="attributeSelect(attTypeCreate5.value, attributeCreate5.value)">
                                                        <option value="{{cat.attribute}}">{{mapFromItemFieldAlias(cat.attribute)}}</option>
                                                        <ng-container *ngIf="attTypeCreate5.value == 'itemfield'">
                                                            <option *ngFor="let name of itemFieldAlias;" value="{{name[0]}}">{{name[1]}}</option>
                                                        </ng-container>
                                                        <option *ngIf="attTypeCreate5.value == 'price'">price</option>
                                                        <option *ngIf="attTypeCreate5.value == 'discount'">discount</option>
                                                        <ng-container *ngIf="attTypeCreate5.value == 'customproperty'">
                                                            <option *ngFor="let property of customProperties">{{property.PropertyName}}</option>
                                                        </ng-container>
                                                        <option *ngIf="attTypeCreate5.value == 'item'">item</option>
                                                    </select>
                                                    <label for="floatingSelectAtt">Atributo</label>
                                                </div>
                                                <!--end::Select atributo-->
                                                
                                                <!--begin::Select operación-->
                                                <div class="form-floating mb-7" style="display: flex;">
                                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example" #operacionCreate5 (change)="operacionCreate5.value == 'IN' ? initMultipleSelect(true) : (operacionCreate5.value == 'NOT IN' ? initMultipleSelect(true) : initMultipleSelect(false))">
                                                        <ng-container *ngIf="attTypeCreate5.value == 'itemfield' || attTypeCreate5.value == 'item'">
                                                            <ng-container *ngFor="let option of enumOperation;">
                                                                <option *ngIf="option == 'IN' || option == 'NOT IN' || option == '=' || option == '!='" [attr.selected]="cat.operation == option ? true : null">{{option}}</option>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="attTypeCreate5.value == 'price' || attTypeCreate5.value == 'discount'">
                                                            <option *ngFor="let option of enumOperation | slice:2:8" [attr.selected]="cat.operation == option ? true : null">{{option}}</option>
                                                        </ng-container>
                                                        <ng-container *ngIf="attTypeCreate5.value == 'customproperty'">
                                                            <ng-container *ngFor="let option of enumOperation;">
                                                                <option *ngIf="option == '=' || option == '!='" [attr.selected]="cat.operation == option ? true : null">{{option}}</option>
                                                            </ng-container>
                                                        </ng-container>
                                                    </select>
                                                    <label for="floatingSelect">Operación</label>
                                                </div>
                                                <!--end::Select operación-->

                                                <!--begin::Input valor-->
                                                <!-- ITEM -->
                                                <div *ngIf="attTypeCreate5.value == 'item'">
                                                    <p *ngIf="operacionCreate5.value == 'IN' || operacionCreate5.value == 'NOT IN'">Introducir la referencia del artículo de Avelon separada por comas simples</p>
                                                    <p *ngIf="operacionCreate5.value == '=' || operacionCreate5.value == '!='">Introducir una única referencia de artículo de Avelon.</p>
                                                    <div class="form-floating mb-7" style="display: flex;">
                                                        <input class="form-control" id="floatingInputValue" type="text" #valorCreate5 value="{{removeBrackets(cat.value)}}"/>
                                                        <label for="floatingInputValue">Valor</label>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                                        <button type="button" (click)="updateRule('include', attTypeCreate5.value, attributeCreate5.value, operacionCreate5.value, valorCreate5.value, cat.id_rule, ruleNameEdit.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>

                                                <!-- ITEMFIELD -->
                                                <div *ngIf="attTypeCreate5.value == 'itemfield'">
                                                    <ng-multiselect-dropdown #multiSelect
                                                        [placeholder]="'Valor'"
                                                        [settings]="dropdownSettings"
                                                        [data]="itemFieldsList"
                                                        [(ngModel)]="multipleSelect"
                                                    >
                                                    </ng-multiselect-dropdown>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                                        <button type="button" (click)="updateRule('include', attTypeCreate5.value, attributeCreate5.value, operacionCreate5.value, (multipleSelect.length ? multipleSelect : internalMap), cat.id_rule, ruleNameEdit.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>

                                                <!-- PRICE || DISCOUNT -->
                                                <div *ngIf="attTypeCreate5.value == 'price' || attTypeCreate5.value == 'discount'">
                                                    <p>Introduce el valor de la regla separando los decimales con punto (.)</p>
                                                    <div class="form-floating mb-7" style="display: flex;">
                                                        <input class="form-control" type="number" #valorCreate5 value="{{removeBrackets(cat.value)}}"/>
                                                        <label for="floatingInputValue">Valor</label>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                                        <button type="button" (click)="updateRule('include', attTypeCreate5.value, attributeCreate5.value, operacionCreate5.value, valorCreate5.value, cat.id_rule, ruleNameEdit.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>

                                                <!-- CUSTOMPROPERTY -->
                                                <div *ngIf="attTypeCreate5.value == 'customproperty'">
                                                    <ng-multiselect-dropdown #singleSelect
                                                        [placeholder]="'Valor'"
                                                        [settings]="dropdownSingleSettings"
                                                        [data]="customPropertiesValues"
                                                        [(ngModel)]="selectedItems"
                                                    >
                                                    </ng-multiselect-dropdown>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                                        <button type="button" (click)="updateRule('include', attTypeCreate5.value, attributeCreate5.value, operacionCreate5.value, (selectedItems.length ? selectedItems : internalMap), cat.id_rule, ruleNameEdit.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>


                                                <!-- TRAMPANTOJO -->
                                                <div [style.display]="!attTypeCreate5.value ? 'block' : 'none'" class="form-floating mb-7" style="display: flex;">
                                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                                        <option selected></option>
                                                    </select>
                                                    <label for="floatingInputValue">Valor</label>
                                                </div>
                                                <!--end::Input valor-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end::Modal edición includes -->

                                <tr *ngIf="cat.rule_type == 'include'">
                                    <th>{{cat.name}}</th>
                                    <th>{{mapFromItemFieldAlias(cat.attribute)}}</th>
                                    <th>{{cat.operation}}</th>
                                    <th class="scrollReglas">{{traductorValores(cat.value, cat)}}</th>
                                    <th>
                                        <div class="dropdown dropdown-inline">
                                            <button type="button" class="btn btn-hover-light-primary btn-icon btn-sm" data-bs-toggle="dropdown" data-bs-target="#kt_dropdown_4" aria-haspopup="true" aria-expanded="false">
                                                <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <rect x="10" y="10" width="4" height="4" rx="2" fill="black"/>
                                                    <rect x="17" y="10" width="4" height="4" rx="2" fill="black"/>
                                                    <rect x="3" y="10" width="4" height="4" rx="2" fill="black"/>
                                                    </svg>
                                                </span>
                                            </button>
                                            <div id="kt_dropdown_4" class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                <a class="dropdown-item" href="#" data-bs-toggle="modal" [attr.data-bs-target]="'#kt_modal_4_'+cat.id_rule+'_'+item.id_ruleset" (click)="mapValues(cat.attribute, cat.value, cat.id_rule)">Editar</a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" href="#" [swal]="{title:'¿Eliminar?', text:'¿Estás seguro de que deseas eliminar la regla?', icon:'warning',showCancelButton: true}"
                                                (confirm)="deleteRule(cat.id_rule)">Eliminar</a>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        <!--end::Datatable-->

                    </div>
                    <!-- end::Includes -->

                    <!-- begin::Excludes -->
                    <div>
                        <!-- begin:: Título y opción de añadir -->
                        <div style="display: flex; align-items: center;">
                            <h3>Excluidos</h3>

                            <!-- begin::Dropdown menu 3 -->
                            <div class="card-toolbar" style="padding: 1rem;">
                                <div class="dropdown dropdown-inline">
                                    <button type="button" class="btn btn-hover-light-primary btn-icon btn-sm" data-bs-toggle="modal" [attr.data-bs-target]="'#kt_modal_2_'+item.id_ruleset+'_2'" aria-haspopup="true" aria-expanded="false" (click)="multipleSelect = []">

                                        <!--begin::Svg Icon | path: assets/media/icons/duotune/general/gen041.svg-->
                                        <span class="svg-icon svg-icon-muted svg-icon-2hx">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"/>
                                                <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"/>
                                            </svg>
                                        </span>
                                        <!--end::Svg Icon-->

                                    </button>
                                </div>
                            </div>
                            <!-- end::Dropdown menu 3 -->

                        </div>
                        <!-- end:: Título y opción añadir -->

                        <!--begin::Datatable-->
                        <table id="kt_datatable_example_1" class="table align-middle table-row-dashed fs-6 gy-5">
                            <thead>
                                <tr class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                    <th style="width: 5%;">Nombre</th>
                                    <th style="width: 15%;">Campo</th>
                                    <th style="width: 10%;">Operación</th>
                                    <th style="width: 55%;">Valores</th>
                                    <th style="width: 10%;">Acciones</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let cat of rulesOnProducts" class="text-gray-600 fw-bold">
                                <!-- begin::Modal edición excludes -->
                                <div class="modal fade" tabindex="-1" id="kt_modal_5_{{cat.id_rule}}_{{item.id_ruleset}}">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Edición de excluido con ID: {{cat.id_rule}}</h5>

                                                <!--begin::Close-->
                                                <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                                    <span class="svg-icon svg-icon-2x"></span>
                                                </div>
                                                <!--end::Close-->

                                            </div>
                                            <div class="modal-body">

                                                <!--begin::Nombre de regla-->
                                                <div class="form-floating mb-7" style="display: flex;">
                                                    <input class="form-control" type="text" #ruleNameEdit value="{{cat.name}}">
                                                    <label for="floatingSelectType">Nombre de regla</label>
                                                </div>
                                                <!--end::nombre de regla-->

                                                <!--begin::Select atributo-->
                                                <div class="form-floating mb-7" style="display: flex;">
                                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example" #attTypeCreate5 (change)="observeSelect()">
                                                        <option>{{cat.attribute_type}}</option>
                                                        <option *ngFor="let attributeType of enumAttribute_type;">{{attributeType}}</option>
                                                    </select>
                                                    <label for="floatingSelect">Tipo de atributo</label>
                                                </div>
                                                <!--end::Select atributo-->

                                                <!--begin::Select atributo-->
                                                <div class="form-floating mb-7" style="display: flex;">
                                                    <select  class="form-select" id="floatingSelectAtt" aria-label="Floating label select example" #attributeCreate5 (change)="attributeSelect(attTypeCreate5.value, attributeCreate5.value)">
                                                        <option value="{{cat.attribute}}">{{mapFromItemFieldAlias(cat.attribute)}}</option>
                                                        <ng-container *ngIf="attTypeCreate5.value == 'itemfield'">
                                                            <option *ngFor="let name of itemFieldAlias;" value="{{name[0]}}">{{name[1]}}</option>
                                                        </ng-container>
                                                        <option *ngIf="attTypeCreate5.value == 'price'">price</option>
                                                        <option *ngIf="attTypeCreate5.value == 'discount'">discount</option>
                                                        <ng-container *ngIf="attTypeCreate5.value == 'customproperty'">
                                                            <option *ngFor="let property of customProperties">{{property.PropertyName}}</option>
                                                        </ng-container>
                                                        <option *ngIf="attTypeCreate5.value == 'item'">item</option>
                                                    </select>
                                                    <label for="floatingSelectAtt">Atributo</label>
                                                </div>
                                                <!--end::Select atributo-->
                                                
                                                <!--begin::Select operación-->
                                                <div class="form-floating mb-7" style="display: flex;">
                                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example" #operacionCreate5 (change)="operacionCreate5.value == 'IN' ? initMultipleSelect(true) : (operacionCreate5.value == 'NOT IN' ? initMultipleSelect(true) : initMultipleSelect(false))">
                                                        <ng-container *ngIf="attTypeCreate5.value == 'itemfield' || attTypeCreate5.value == 'item'">
                                                            <ng-container *ngFor="let option of enumOperation;">
                                                                <option *ngIf="option == 'IN' || option == 'NOT IN' || option == '=' || option == '!='" [attr.selected]="cat.operation == option ? true : null">{{option}}</option>
                                                            </ng-container>
                                                        </ng-container>
                                                        <ng-container *ngIf="attTypeCreate5.value == 'price' || attTypeCreate5.value == 'discount'">
                                                            <option *ngFor="let option of enumOperation | slice:2:8" [attr.selected]="cat.operation == option ? true : null">{{option}}</option>
                                                        </ng-container>
                                                        <ng-container *ngIf="attTypeCreate5.value == 'customproperty'">
                                                            <ng-container *ngFor="let option of enumOperation;">
                                                                <option *ngIf="option == '=' || option == '!='" [attr.selected]="cat.operation == option ? true : null">{{option}}</option>
                                                            </ng-container>
                                                        </ng-container>
                                                    </select>
                                                    <label for="floatingSelect">Operación</label>
                                                </div>
                                                <!--end::Select operación-->

                                                <!--begin::Input valor-->
                                                 <!-- ITEM -->
                                                <div *ngIf="attTypeCreate5.value == 'item'">
                                                    <p *ngIf="operacionCreate5.value == 'IN' || operacionCreate5.value == 'NOT IN'">Introducir la referencia del artículo de Avelon separada por comas simples</p>
                                                    <p *ngIf="operacionCreate5.value == '=' || operacionCreate5.value == '!='">Introducir una única referencia de artículo de Avelon.</p>
                                                    <div class="form-floating mb-7" style="display: flex;">
                                                        <input class="form-control" id="floatingInputValue" type="text" #valorCreate5 value="{{removeBrackets(cat.value)}}"/>
                                                        <label for="floatingInputValue">Valor</label>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                                        <button type="button" (click)="updateRule('exclude', attTypeCreate5.value, attributeCreate5.value, operacionCreate5.value, valorCreate5.value, cat.id_rule, ruleNameEdit.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>

                                                <!-- ITEMFIELD -->
                                                <div *ngIf="attTypeCreate5.value == 'itemfield'">
                                                    <ng-multiselect-dropdown #multiSelect
                                                        [placeholder]="'Nuevo valor'"
                                                        [settings]="dropdownSettings"
                                                        [data]="itemFieldsList"
                                                        [(ngModel)]="multipleSelect"
                                                    >
                                                    </ng-multiselect-dropdown>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                                        <button type="button" (click)="updateRule('exclude', attTypeCreate5.value, cat.attribute, operacionCreate5.value, (multipleSelect.length ? multipleSelect : internalMap), cat.id_rule, ruleNameEdit.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>

                                                <!-- PRICE || DISCOUNT -->
                                                <div *ngIf="attTypeCreate5.value == 'price' || attTypeCreate5.value == 'discount'">
                                                    <p>Introduce el valor de la regla separando los decimales con punto (.)</p>
                                                    <div class="form-floating mb-7" style="display: flex;">
                                                        <input class="form-control" type="number" #valorCreate5 value="{{removeBrackets(cat.value)}}"/>
                                                        <label for="floatingInputValue">Valor</label>
                                                    </div>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                                        <button type="button" (click)="updateRule('exclude', attTypeCreate5.value, attributeCreate5.value, operacionCreate5.value, valorCreate5.value, cat.id_rule, ruleNameEdit.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>

                                                <!-- CUSTOMPROPERTY -->
                                                <div *ngIf="attTypeCreate5.value == 'customproperty'">
                                                    <ng-multiselect-dropdown #singleSelect
                                                        [placeholder]="'Nuevo valor'"
                                                        [settings]="dropdownSingleSettings"
                                                        [data]="customPropertiesValues"
                                                        [(ngModel)]="selectedItems"
                                                    >
                                                    </ng-multiselect-dropdown>

                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cerrar</button>
                                                        <button type="button" (click)="updateRule('exclude', attTypeCreate5.value, attributeCreate5.value, operacionCreate5.value, (selectedItems.length ? selectedItems : internalMap), cat.id_rule, ruleNameEdit.value)" class="btn btn-primary" data-bs-dismiss="modal">
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </div>


                                                <!-- TRAMPANTOJO -->
                                                <div [style.display]="!attTypeCreate5.value ? 'block' : 'none'" class="form-floating mb-7" style="display: flex;">
                                                    <select class="form-select" id="floatingSelect" aria-label="Floating label select example">
                                                        <option selected></option>
                                                    </select>
                                                    <label for="floatingInputValue">Valor</label>
                                                </div>
                                                <!--end::Input valor-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end::Modal edición excludes -->

                                <tr *ngIf="cat.rule_type == 'exclude'">
                                    <th>{{cat.name}}</th>
                                    <th>{{mapFromItemFieldAlias(cat.attribute)}}</th>
                                    <th>{{cat.operation}}</th>
                                    <th class="scrollReglas">{{traductorValores(cat.value, cat)}}</th>
                                    <th>
                                        <div class="card-toolbar">
                                            <div class="dropdown dropdown-inline">
                                                <button type="button" class="btn btn-hover-light-primary btn-icon btn-sm" data-bs-toggle="dropdown" data-bs-target="#kt_dropdown_5" aria-haspopup="true" aria-expanded="false">
                                                    <span class="svg-icon svg-icon-muted svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="10" y="10" width="4" height="4" rx="2" fill="black"/>
                                                        <rect x="17" y="10" width="4" height="4" rx="2" fill="black"/>
                                                        <rect x="3" y="10" width="4" height="4" rx="2" fill="black"/>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div id="kt_dropdown_5" class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                                    <a class="dropdown-item" href="#" data-bs-toggle="modal" [attr.data-bs-target]="'#kt_modal_5_'+cat.id_rule+'_'+item.id_ruleset" (click)="mapValues(cat.attribute, cat.value, cat.id_rule)">Editar</a>
                                                    <div class="dropdown-divider"></div>
                                                    <a class="dropdown-item" href="#" [swal]="{title:'¿Eliminar?', text:'¿Estás seguro de que deseas eliminar la regla?', icon:'warning',showCancelButton: true}"
                                                    (confirm)="deleteRule(cat.id_rule)">Eliminar</a>
                                                </div>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                        <!--end::Datatable-->

                    </div>
                    <!-- end::Excludes -->
                
                </div>
            </div>
        </div>
    </div>
    <pagination-controls class="my-pagination" (pageChange)="page = $event" [responsive]="true" [autoHide]="true" previousLabel="" nextLabel=""></pagination-controls>
</div>
<!--end::Accordion-->
