import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import 'jquery';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AuthService } from 'src/app/modules/auth';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-panel-usuarios',
  templateUrl: './panel-usuarios.component.html',
  styleUrls: ['./panel-usuarios.component.scss']
})
export class PanelUsuariosComponent implements OnInit {

  public users: any[] = [];
  public permisos: any[] = [];
  public rolesWithNames: any[] = [];
  public roles: any[] = [];
  @ViewChild('multi') multi:MultiSelectComponent;
  @ViewChild('single') single:MultiSelectComponent;

  selectedItems:any[] = [];
  selectedRole:any[] = [];
  dropdownSettings:IDropdownSettings = {};
  dropdownSettingsRoles:IDropdownSettings = {};

  roleSelected = false

  componentDestroyed$: Subject<boolean> = new Subject()

  table: DataTables.Api
  tablePermisos: DataTables.Api

  private currentUser = this.authService.currentUserValue;

  dev = false

  private unsubscribe: Subscription[] = [];

  constructor(private appService: AppService, private ref: ChangeDetectorRef, private authService: AuthService) {}

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE

  //! GETS
  //#region GETS
  /**
   * Accede a la información de los permisos de los usuarios
   */
  getPermisos(){
    const appServiceSubscription = this.appService.getPermisos().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.permisos = res
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a la información de los roles de usuario
   */
  getRoles(){
    const appServiceSubscription = this.appService.getRoles().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.roles = res
      this.isDev()
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a la información de los nombres de los roles
   */
  getRolesWithNames(){
    const appServiceSubscription = this.appService.getRolesWithNames().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.rolesWithNames = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Accede a la información de los usuarios
   */
  getUsers() {
    const appServiceSubscription = this.appService.getAllUsers().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.users = res
      this.ref.detectChanges()
      this.processTable()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion GETS

  //! POSTS
  //#region POSTS
  /**
   * Guarda la información del nuevo usuario
   */
  saveNewUser(){
    let nombre = $('#nombreNewUser').val()
    let apellidos = $('#apellidoNewUser').val();
    let email = $('#emailNewUser').val();
    let password = $('#passwordNewUser').val();
    let passwordCheck = $('#passwordNewCheck').val();
    let role = $('#roleNewUser').val();
    if (password == passwordCheck) {
      const appServiceSubscription = this.appService.saveNewUser(nombre, apellidos, email, password, role).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          window.location.reload()
        }, err => {
          Swal.fire({
            title: 'Email incorrecto',
            text: 'El email ya existe en la base de datos',
            icon: 'warning',
          })
          console.log(err)
        })
      this.unsubscribe.push(appServiceSubscription);
    } else {
      Swal.fire({
        title: 'La contraseña no es válida',
        text: 'Las contraseñas que has introducido son distintas, asegúrate de escibirlas correctamente',
        icon: 'warning',
      })
    }
  }

  /**
   * Guarda la información del nuevo rol
   */
  saveNewRole(){
    let name = $('#nameNewRole').val()
    let permisos = ''
    this.selectedItems.forEach(i => {
      permisos += i.id+","
    });
    const appServiceSubscription = this.appService.saveNewRole(name, permisos.slice(0, -1)).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        window.location.reload()
      }, err => {
        Swal.fire({
          title: 'Nombre repetido',
          text: 'El nombre ya existe entre los roles',
          icon: 'warning',
        })
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion POSTS

  //! PUTS
  //#region PUTS
  /**
   * Guarda la edición del usuario
   */
  saveUserEdit(){
    let id = $('#idUserEdit').val();
    let nombre = $('#nombreUserEdit').val()
    let apellidos = $('#apellidoUserEdit').val();
    let email = $('#emailUserEdit').val();
    let password = $('#passwordUserEdit').val();
    let passwordCheck = $('#passwordUserCheck').val();
    let role = $('#roleUserEdit').val();

    if (password == passwordCheck) {
      const appServiceSubscription = this.appService.saveUserEdit(id, nombre, apellidos, email, password, role).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          window.location.reload()
        }, err => {
          console.log(err)
        })
      this.unsubscribe.push(appServiceSubscription);
    } else {
      Swal.fire({
        title: 'La contraseña no es válida',
        text: 'Las contraseñas que has introducido son distintas, asegúrate de escibirlas correctamente',
        icon: 'warning',
      })
    }
  }

  /**
   * Guarda la edición del rol
   */
  saveRoleEdit(){
    let id = $('#idRoleEdit').val()
    let name = $('#nombreRoleEdit').val()
    let permisos = '';
    this.selectedItems.forEach(i => {
      permisos += i.id+","
    });
    const appServiceSubscription = this.appService.saveRoleEdit(id, name, permisos).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        window.location.reload()
      }, err => {
        console.log(err)
      })
    this.unsubscribe.push(appServiceSubscription);
  }

  updatePermisos(active: number, permisoId: number, rolId: number) {
    const rol = this.roles.find(r => r.id == rolId)

    let permisosArray = rol.permisos.split(',').map(Number);

    if (active === 0) {
      permisosArray = permisosArray.filter((id: number) => id !== permisoId);
    } else if (active === 1) {
      permisosArray.push(permisoId);
    }

    const updatedPermisos = permisosArray.join(',');

    const appServiceSubscription = this.appService.saveRoleEdit(rolId, rol.name, updatedPermisos).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.getRoles()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion PUTS
  
  //! DELETES
  //#region DELETES
  /**
   * Elimina la información del usuario requerido
   * @param id 
   */
  deleteUser(id: number){
    Swal.fire({
      title: '¿Eiminar usuario?',
      text: '¿Estás seguro de que deseas eliminar este usuario?.',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('!Eliminado!', '', 'success')
        const appServiceSubscription = this.appService.deleteUser(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          window.location.reload()
        }, err => {
          console.log(err)
        })
        this.unsubscribe.push(appServiceSubscription);
      } else if (result.isDenied) {
        Swal.fire('No se guardaron los cambios', '', 'info')
      }
    })
  }

  /**
   * Elimina la información del rol requerido
   * @param id 
   */
  deleteRole(id: number){
    Swal.fire({
      title: '¿Eiminar rol?',
      text: '¿Estás seguro de que deseas eliminar este rol? Si alguno de los usuarios tienen este rol asignado, se les asignará un valor nulo.',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        const appServiceSubscription = this.appService.deleteRole(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          window.location.reload()
          Swal.fire('!Eliminado!', '', 'success')
        }, err => {
          console.log(err)
        })
        this.unsubscribe.push(appServiceSubscription);
      } else if (result.isDenied) {
        Swal.fire('No se guardaron los cambios', '', 'info')
      }
    })
  }
  //#endregion DELETES

  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Resetea la información de los filtros
   */
  limpiarFiltros(){
    $('select.filtro').val("")
    $('select.filtro').trigger('change')
  }

  /**
   * Se encarga de poblar el modal de usuarios
   * @param email 
   */
  poblarModalUsers(email:string){
    const appServiceSubscription = this.appService.getUserByEmail(email).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      $('#kt_modal_user #nombreUserEdit').val(res[0]["username"])
      $('#kt_modal_user #apellidoUserEdit').val(res[0]["lastname"])
      $('#kt_modal_user #emailUserEdit').val(res[0]["email"])
      $('#kt_modal_user #roleUserEdit').val(res[0]["rol"])
      $('#kt_modal_user #idUserEdit').val(res[0]["id"])

      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Se encarga de poblar el modal de roles
   * @param id 
   */
  poblarModalRoles(id:number){
    this.selectedItems = []
    const appServiceSubscription = this.appService.getRole(id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      $('#kt_modal_role_edit #idRoleEdit').val(res[0]["id"])
      $('#kt_modal_role_edit #nombreRoleEdit').val(res[0]["name"])
      let per: any[] = res[0]["permisos"].split(',')
      per.forEach(i => {
        this.permisos.forEach(permiso => {
          if(permiso.id == i){
            this.multi.addSelected({id: permiso.id, text:permiso.name})
            console.log(this.selectedItems)
          }
        });
      });
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  activePermiso(permisoId: number, rolId: number) {
    const rol = this.roles.find(r => r.id == rolId)
    const permisosArray = rol.permisos.split(',').map(Number);
    
    const rolExistsInPermisos = permisosArray.includes(permisoId);
    if(rolExistsInPermisos) {
      return true
    }
    return false
  }

  roleSelect(selected: boolean) {
    if (selected == false && this.selectedRole.length > 0) {
      selected = !selected;
    }
    this.roleSelected = selected;
    this.ref.detectChanges()
  }

  isDev() {
    const rolId = this.roles.find(r => r.name === "dev").id

    if (this.currentUser?.rol == rolId) {
      this.dev = true
    }
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Procesa la tabla de usuarios
   */
  processTable(){
    this.table = $('#kt_datatable_users').DataTable({
      language: {
          "processing": `<span>Cargando...
          </span>`,
          "lengthMenu": "Mostrar _MENU_ registros",
          "zeroRecords": "No se encontraron resultados",
          "emptyTable": "Ningún dato disponible en esta tabla",
          "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "infoFiltered": "(filtrado de un total de _MAX_ registros)",
          "search": "Buscar:",
          "loadingRecords": " ",
          "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": ">",
              "previous": "<"
          },
          "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "decimal": ",",
          "thousands": ".",
          "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      }
  });
    this.table.columns().every( function () {
        var column = this;
        var select = $('<select class="form-control form-control-sm form-filter datatable-input datatable-filter-select filtro"><option value="">TODOS</option></select>')
            .appendTo( $(column.footer()).empty() )
            .on( 'change',  () => {
                var val = $.fn.dataTable.util.escapeRegex(
                    select.val()
                );

                column
                    .search( val ? '^'+val+'$' : '', true, false )
                    .draw();
            } );

        column.data().unique().sort().each( function ( d: string, j: any ) {
            select.append( '<option value="'+d+'">'+d+'</option>' )
        } );
    });
    $('tfoot tr:not(#newrule)').appendTo('#kt_datatable_users thead')
    $('select[name="kt_datatable_users_length"]').addClass('custom-select custom-select-sm form-control form-control-sm');
    $('#kt_datatable_users_filter label input').addClass("form-control form-control-sm")
  }

  /**
   * Incia el componente
   */
  ngOnInit(): void {
    this.getRoles()
    this.getUsers()
    this.getPermisos()
    this.getRolesWithNames()
    
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleccionar todas',
      unSelectAllText: 'Deseleccionar todas',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };

    this.dropdownSettingsRoles = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleccionar todas',
      unSelectAllText: 'Deseleccionar todas',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      limitSelection: 4
    };
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.table) {
      this.table.destroy();
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN
}
