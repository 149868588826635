<link
  href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css"
  rel="stylesheet"
/>
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>



<div class="card card-custom">
  <div class="card-header card-header-stretch mx-5 my-5">
    <!-- <h3 class="card-title">Configuración</h3> -->
    <div class="card-toolbar">
      
      <!-- Navegador -->
      <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
        <li class="nav-item">
          <a class="nav-link" id="mustClick" data-bs-toggle="tab" href="#tab_descuentos">SEUR</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="card-body">
    <div class="tab-content" id="myTabContent">
        <!--* BEGIN::Tabla de descuentos -->
        <div class="tab-pane fade" id="tab_descuentos" role="tabpanel">
          <div class="card-header" class="my-5 mx-5">
            <div>
              <button
                class="btn btn-primary font-weight-bolder"
                id="anadir"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_new_seur_user"
                >
                <!-- (click)="clearSelected()" -->
                <i class="la la-plus"></i>Nuevo usuario
              </button>
              <!-- <button class="btn btn-primary font-weight-bolder" (click)="consoleo()"><i class="la la-plus"></i>Console</button> -->
              <!-- <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            <button class="btn btn-primary font-weight-bolder" id="editar" data-bs-toggle="modal"
                                data-bs-target="#kt_modal_edit_discount" (click)="clearSelected()"><i class="la la-plus"></i>Edición de reglas</button>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
              <!-- <button class="btn btn-primary font-weight-bolder" id="anadir" (click)="limpiarFiltros()"><i class="las la-broom"></i>Limpiar filtros</button> -->
            </div>
          </div>
          <div
            id="kt_datatable_shopGo_wrapper"
            class="dataTables_wrapper dt-bootstrap4"
          >
            <div style="width: 100%; padding-left: -10px">
              <div class="table-responsive">
                <table
                  id="kt_datatable_shopGo"
                  class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom"
                  role="grid"
                  aria-describedby="kt_datatable_shopGo_info"
                  style="width: 100%"
                >
                  <thead>
                    <tr role="row">
                      <th
                        class="sorting sorting_asc"
                        tabindex="0"
                        aria-controls="kt_datatable_shopGo"
                        rowspan="1"
                        colspan="1"
                        aria-label="Record ID: activate to sort column descending"
                      >
                        Tienda
                      </th>
                      <th
                        class=""
                        tabindex="0"
                        aria-controls="kt_datatable_shopGo"
                        rowspan="1"
                        colspan="1"
                        aria-label="Record ID: activate to sort column descending"
                      >
                        Número de cuenta
                      </th>
                    </tr>
                  </thead>
                  <tr id="filters"></tr>
                  <tbody>
                    <tr *ngFor="let item of seurUsers">
                      <td>{{ item["shop_id"] }}</td>
                      <td>{{ item["account_number"] }}</td>
                      <td nowrap="nowrap" class="datatable-actions-td">
                        <a
                          class="btn btn-sm btn-clean btn-icon mr-2 datatable-action"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_edit_seur_user"
                          (click)="
                            poblarModalUsers(item['shop_id'])
                          "
                          title="Editar"
                        >
                          <span class="svg-icon svg-icon-md">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                ></path>
                                <rect
                                  fill="#000000"
                                  opacity="0.3"
                                  x="5"
                                  y="20"
                                  width="15"
                                  height="2"
                                  rx="1"
                                ></rect>
                              </g>
                            </svg>
                          </span>
                        </a> 
                        <a
                          href="javascript:;"
                          class="btn btn-sm btn-clean btn-icon datatable-action"
                          (click)="deleteUser(item['shop_id'])"
                          title="Eliminar"
                        > 
                          <span class="svg-icon svg-icon-md">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                ></path>
                              </g>
                            </svg>
                          </span>
                        </a> 
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!--* END::Tabla de descuentos -->
        <!--* BEGIN::Modal de edición de descuentos -->
        <!--* BEGIN::Modal de creación de descuentos -->
      <div class="modal fade" tabindex="-1" id="kt_modal_new_seur_user">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Nuevo usuario</h5>

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span class="svg-icon svg-icon-2x" style="font-size: 24px"
                  >&times;</span
                >
              </div>
              <!--End::Close-->
            </div>

            <div class="modal-body">
              <!-- <ng-multiselect-dropdown
                id="select_discount_reason"
                class="form-floating"
                [placeholder]="'Reason ID'"
                [settings]="dropdownSettingsDiscounts"
                [data]="reasonIds"
                [(ngModel)]="selectedReasonIds"
              >
              </ng-multiselect-dropdown> -->
              <form style="margin-bottom: 20px" class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  name="shop_id"
                  id="new_shop_id"
                />
                <label for="shop_id"><b>Tienda</b></label>
              </form>
              <br />
              <form style="margin-bottom: 20px" class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  name="account_number"
                  id="new_account_number"
                />
                <label for="account_number"><b>Número de cuenta</b></label>
              </form>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                Cancelar
              </button>
              <button
                type="button"
                (click)="saveSeurUser()"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--* END::Modal de creación de descuentos -->
      <div class="modal fade" tabindex="-1" id="kt_modal_edit_seur_user">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Editar usuario</h5>

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span class="svg-icon svg-icon-2x" style="font-size: 24px"
                  >&times;</span
                >
              </div>
              <!--end::Close-->
            </div>

            <div class="modal-body">
              <input type="number" hidden id="id_seurUserEdit" />
              <br />
              <form style="margin-bottom: 20px" class="form-floating">
                <input
                  class="form-control"
                  type="text"
                  name="account_number"
                  id="account_numberEdit"
                />
                <label for="amountEdit"><b>Número de cuenta</b></label>
              </form>
              <br />
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                Cancelar
              </button>
              <button type="button" class="btn btn-primary" (click)="editSeurUser()" data-bs-dismiss="modal">Guardar</button>
            </div>
          </div>
        </div>
      </div>
      <!--* END::Modal de edición de descuentos -->
    </div>
  </div>
</div>
