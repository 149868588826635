import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings, MultiSelectComponent } from 'ng-multiselect-dropdown';
import { Subject, Subscription } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { ListItem } from './ReglasPedidos.model';
import 'jquery';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-reglas-pedidos',
  templateUrl: './reglas-pedidos.component.html',
  styleUrls: ['./reglas-pedidos.component.scss']
})
export class ReglasPedidosComponent implements OnInit {
  public market_id: any = this.route.snapshot.paramMap.get('id')

  public mappingOrderStates: any
  public marketTables: any
  public avelonStates: any
  public avelonLogisticStates: any
  public marketStates: any
  public marketOrderStatesTable : string
  idMapping: number

  tableName: string
  reglaAEditar: string = ''
  loadTable: boolean = false

  singleDropdownSettings:IDropdownSettings = {};
  multipleDrowpdownSettings:IDropdownSettings = {};

  selectedAvelonState:any[] = [];
  selectedAvelonLogisticState:any[] = [];
  selectedMarketState:any[] = [];

  selectedAvelonStateUpdate:any[] = [];
  selectedAvelonLogisticStateUpdate:any[] = [];
  selectedMarketStateUpdate:any[] = [];

  avelonOrderStatesTable: any = []
  avelonOrderLogisticsStatesTable: any = []

  private timeoutId!: any

  componentDestroyed$: Subject<boolean> = new Subject()

  @ViewChild('avelonStateUpdate') avelonStateUpdate:MultiSelectComponent;
  @ViewChild('avelonLogisticStateUpdate') avelonLogisticStateUpdate:MultiSelectComponent;
  @ViewChild('marketStateUpdate') marketStateUpdate:MultiSelectComponent;

  closeResult: string;
  @ViewChild('content') myModal: any;

  closeResult2: string;
  @ViewChild('nuevaRegla') nuevaRegla: any;

  table: DataTables.Api

  private unsubscribe: Subscription[] = [];
  
  constructor(private appService: AppService, private ref: ChangeDetectorRef, private route: ActivatedRoute, private renderer: Renderer2, private modalService: NgbModal) { 
    const routeSubscription = this.route.paramMap.subscribe(params => {
      this.market_id = this.route.snapshot.paramMap.get('id')
      this.getMarketJson()
    });

    this.unsubscribe.push(routeSubscription);
  }
  
  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE
  //! GETS
  //#region GETS
  /**
   * Activa toda la recogida de información que será utilizada en los mapeos
   */
  getMarketJson() {
    const appServiceSubscription = this.appService.getMarketJson(this.market_id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketTables = res

      this.getMarketMappingTable()
      this.getMarketStates()
      this.getAvelonStates()
      this.getAvelonLogisticStates()
      this.getMappingOrderStates()
      this.ref.detectChanges()

      this.table.ajax.url(environment.portalHost + '/api/getMappingOrderStates/' + this.market_id)
      this.table.ajax.reload()
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }

  getAllFromAvelonTables() {
    const appServiceSubscription1 = this.appService.getAllFromTable("avelon_order_states").pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.avelonOrderStatesTable = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription1);
    const appServiceSubscription2 = this.appService.getAllFromTable("avelon_order_logistics_states").pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.avelonOrderLogisticsStatesTable = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription2);
  }

  /**
   * Accede a la información
   */
  getMarketMappingTable() {
    let parsed = JSON.parse(this.marketTables[0].table_schema)

    this.marketOrderStatesTable = parsed.table_order_states_data

    this.loadTable = true
  }

  /**
   * Extrae los datos externos de categorías
   */
  getMarketStates() {
    const appServiceSubscription = this.appService.getMarketTableData(this.market_id, this.marketOrderStatesTable, 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.marketStates = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la información de los estados de avelon
   */
  getAvelonStates() {
    const appServiceSubscription = this.appService.getMarketTableData(this.market_id, 'avelon_order_states', 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.avelonStates = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  /**
   * Saca la información de los estados logísticos de avelon
   */
  getAvelonLogisticStates() {
    const appServiceSubscription = this.appService.getMarketTableData(this.market_id, 'avelon_order_logistics_states', 'value').pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.avelonLogisticStates = res
      this.ref.detectChanges()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }

  getMappingOrderStates() {
    const appServiceSubscription = this.appService.getMappingOrderStates(this.market_id).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.mappingOrderStates = res
      this.table.ajax.url(environment.portalHost + '/api/getMappingOrderStates/' + this.market_id)
      this.table.ajax.reload()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion GETS

  //! POSTS
  //#region POSTS
  /**
   * Guarda el mapeo
   */
  saveMap() {
    let avelon = this.selectedAvelonState
    let avelonLogistic = this.selectedAvelonLogisticState
    let market = this.selectedMarketState[0].id

    var avelonStateList: any[] = []
    var avelonLogisticStateList: any[] = []

    var avelonstateListLang: any[] = []
    var avelonLogisticStateListLang: any[] = []

    avelon.forEach(e => {
      avelonStateList.push(e.id)
      avelonstateListLang.push(e.value)
    });

    avelonLogistic.forEach(e => {
      avelonLogisticStateList.push(e.id)
      avelonLogisticStateListLang.push(e.value)
    })

    const appServiceSubscription = this.appService.createOrderStateMap(this.market_id, JSON.stringify(avelonStateList), JSON.stringify(avelonLogisticStateList), market, JSON.stringify(avelonstateListLang), JSON.stringify(avelonLogisticStateListLang)).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
      this.table.ajax.reload()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion POSTS

  //! PUTS
  //#region PUTS
  /**
   * Actualiza el mapeo
   */
  updateStatesMapping() {
    let avelon = this.selectedAvelonStateUpdate
    let avelonLogistic = this.selectedAvelonLogisticStateUpdate
    let market = this.selectedMarketStateUpdate[0].id

    var avelonStateList: any[] = []
    var avelonLogisticStateList: any[] = []

    var avelonstateListLang: any[] = []
    var avelonLogisticStateListLang: any[] = []

    avelon.forEach(e => {
      avelonStateList.push(e.id)
      avelonstateListLang.push(e.value)
    });

    avelonLogistic.forEach(e => {
      avelonLogisticStateList.push(e.id)
      avelonLogisticStateListLang.push(e.value)
    })

    const appServiceSubscription = this.appService.updateStatesMapping(this.market_id, JSON.stringify(avelonStateList), JSON.stringify(avelonLogisticStateList), market, JSON.stringify(avelonstateListLang), JSON.stringify(avelonLogisticStateListLang), this.idMapping).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.ngOnInit();
      this.table.ajax.reload()
    }, err => {
      console.log(err)
    })
    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion PUTS

  //! DELETES
  //#region DELETES
  /**
   * Elimina el mapeo requerido
   * @param rule 
   */
  deleteMapping(rule: any) {
    Swal.fire({
      title: '¿Borrar?',
      text: '¿Está seguro de que desea eliminar este mapeo?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Eliminar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Borrado!', '', 'success')
       
        const appServiceSubscription = this.appService.deleteOrderStateMap(this.market_id, rule).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
          this.ngOnInit();
          this.table.ajax.reload()
        }, err => {
          console.log(err)
        })
        this.unsubscribe.push(appServiceSubscription);

      } else if (result.isDenied) {
        Swal.fire('No se guardaron los cambios', '', 'info')
      }
    })
  }
  //#endregion DELETES

  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  /**
   * Selector de reglas en edición
   */
  ruleSelect(idMapping: any) {
    this.idMapping = idMapping
    this.clearSelected()

    var marketStateId = 0
    var marketStateValue = ''

    var listAvelonLogistics: any = []
    var listAvelon: any = []

    this.mappingOrderStates.forEach((e:any) => {
      if (e.id == idMapping) {
        marketStateId = e.market_state
        marketStateValue = e.market_state_lang

        JSON.parse(e.avelon_order_logistic_state).forEach((aol: any) => {
          let val = this.avelonOrderLogisticsStatesTable.find((e: any) => e.id === aol)
          listAvelonLogistics.push(new ListItem(aol, val.value))
        })

        JSON.parse(e.avelon_order_state).forEach((ao: any) => {
          let val = this.avelonOrderStatesTable.find((e: any) => e.id === ao)
          listAvelon.push(new ListItem(ao, val.value))
        });
      }
    })

    this.reglaAEditar = marketStateValue

    this.avelonStateUpdate.selectedItems = []
    this.avelonLogisticStateUpdate.selectedItems = []
    this.marketStateUpdate.selectedItems = []

    listAvelon.forEach((e: ListItem) => {
      this.avelonStateUpdate.addSelected(e)
    });
    listAvelonLogistics.forEach((e: ListItem) => {
      this.avelonLogisticStateUpdate.addSelected(e)
    });

    this.marketStateUpdate.addSelected({id: marketStateId, text: marketStateValue})
  }

  /**
   * Vacía de contenido los selectores
   */
  clearSelected () {
    this.selectedAvelonState = []
    this.selectedAvelonLogisticState = []
    this.selectedMarketState = []

    this.selectedAvelonStateUpdate = []
    this.selectedAvelonLogisticStateUpdate = []
    this.selectedMarketStateUpdate = []
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  /**
   * Procesa la datatable
   */
  processTable(){
    this.getAllFromAvelonTables()

    this.table = $('#kt_datatable_orders_rules').DataTable({
        retrieve: true,
        processing: true,
        responsive: true,
        destroy: true,
        language: {
          processing: `<span>Cargando...
          </span>`,
          lengthMenu: 'Mostrar _MENU_ registros',
          zeroRecords: 'No se encontraron resultados',
          emptyTable: 'Ningún dato disponible en esta tabla',
          infoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
          infoFiltered: '(filtrado de un total de _MAX_ registros)',
          search: 'Buscar:',
          loadingRecords: ' ',
          paginate: {
            first: 'Primero',
            last: 'Último',
            next: '>',
            previous: '<',
          },
          aria: {
            sortAscending:
              ': Activar para ordenar la columna de manera ascendente',
            sortDescending:
              ': Activar para ordenar la columna de manera descendente',
          },
          decimal: ',',
          thousands: '.',
          info: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
        },

        ajax: {
          url: environment.portalHost + '/api/getMappingOrderStates/' + this.market_id,
          // url: 'http://localhost:3333/api/getMappingOrderStates/' + this.market_id,
          dataSrc: '',
        },

        columns: [
          { title: 'Posición', className: 'reorder', data: 'order' },
          { title: 'ESTADO DE AVELON', data: 'avelon_order_state',
            render: function (data: any, type: any, full: any) {
              var stateValues = JSON.parse(full.avelon_order_state_lang)
              var res = ""
              stateValues.forEach((lv: any) => {
                res += lv+", "
              });
              return `<p>` + res.slice(0,-2) + `</p>`
            }
          }, 
          { title: 'ESTADO LOGÍSTICO DE AVELON', data: 'avelon_order_logistic_state',
            render: function (data: any, type: any, full: any) {
              var logisticsValues = JSON.parse(full.avelon_order_logistic_state_lang)
              var res = ""
              logisticsValues.forEach((lv: any) => {
                res += lv+", "
              });
              return `<p>` + res.slice(0,-2) + `</p>`
            }
          },
          { title: 'ESTADO DE MARKET', data: 'market_state_lang' },
          {
            title: '',
            data: '',
            render: function (data: any, type: any, full: any) {
              return `<i id="botonId" value=` + full.id + ` type="button" class="la la-edit editRule" edit=` + full.id + ` data-bs-target="#kt_modal_edit" data-bs-toggle="modal" title="` + full.id + `"></i>
              <i type="button" drop=${full.id} class="la la-trash deleteRule"></i>`
            },
            className: 'dt-body-center border-right-0'
          },
        ],
        columnDefs: [
          { orderable: true, className: 'reorder', targets: 0 },
          { orderable: false, targets: '_all' }
        ],

        drawCallback: () => {
          const editRule = document.getElementsByClassName('editRule');

          for (let i = 0; i < editRule.length; i++) {
            const e = editRule[i];
            let idMapping = e.getAttribute("edit")
  
            e.addEventListener('click', () => {
              this.ruleSelect(idMapping)
            });
          }

          const deleteRule = document.getElementsByClassName('deleteRule');

          for (let i = 0; i < deleteRule.length; i++) {
            const e = deleteRule[i];
            let idMapping = e.getAttribute("drop")
  
            e.addEventListener('click', () => {
              this.deleteMapping(idMapping)
            });
          }
        },
  
        initComplete: () => {
          this.getAllFromAvelonTables()
          const editRule = document.getElementsByClassName('editRule');
  
          for (let i = 0; i < editRule.length; i++) {
            const e = editRule[i];
            let idMapping = e.getAttribute("edit")

            e.addEventListener('click', () => {
              this.ruleSelect(idMapping)
            });
          }

          const deleteRule = document.getElementsByClassName('deleteRule');

          for (let i = 0; i < deleteRule.length; i++) {
            const e = deleteRule[i];
            let idMapping = e.getAttribute("drop")
  
            e.addEventListener('click', () => {
              this.deleteMapping(idMapping)
            });
          }
        },
    })
  }
  
  /**
   * Inicia el componente
   */
  ngOnInit(): void {
    this.market_id = this.route.snapshot.paramMap.get('id')

    this.getMarketJson()
    this.processTable()

    this.singleDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.multipleDrowpdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'value',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  /**
   * Finaliza el componente y todas las suscripciones
   */
  ngOnDestroy(): void {
    $.fn.dataTable.ext.search = []

    clearTimeout(this.timeoutId)

    this.unsubscribe.forEach((sb) => sb.unsubscribe());

    if (this.table) {
      this.table.destroy();
    }

    this.componentDestroyed$.next(true)
    this.componentDestroyed$.complete()
  }
  //#endregion INICIALIZACIÓN
}
