import { Component, EventEmitter, Input, Output } from '@angular/core';
import Swal from 'sweetalert2';

// TODO: Implementar componente de modal dinámico, está en ello
@Component({
  selector: 'app-dynamic-modal',
  templateUrl: './dynamic-modal.component.html',
  styleUrls: ['./dynamic-modal.component.scss']
})
export class DynamicModalComponent {
  @Input() componentDestroyed$: any;
  @Input() title: string;
  @Input() confirmButtonText: string;
  @Input() showConfirmButton: boolean = true;
  @Input() cancelButtonText: string;
  @Input() input?: 'text' | 'email' | 'password' | 'number' | 'tel' | 'range' | 'textarea' | 'select' | 'radio' | 'checkbox' | 'file' | 'url';
  @Input() inputPlaceholder: string;
  @Input() htmlContent: string;
  @Input() icon?: 'warning' | 'error' | 'success' | 'info' | 'question';
  @Input() modalType: 'simple' | 'complex' = 'simple';
  @Output() preConfirm = new EventEmitter<string>();
  @Output() confirmed = new EventEmitter<void>();

  resetModal() {
    this.title = '';
    this.confirmButtonText = '';
    this.cancelButtonText = '';
    this.htmlContent = '';
    this.showConfirmButton = true;
    this.icon = undefined;
  }

  openModal() {
    // Swal.fire({
    //   title: this.title,
    //   input: this.input,
    //   inputPlaceholder: this.inputPlaceholder,
    //   html: this.htmlContent,
    //   confirmButtonText: this.confirmButtonText,
    //   cancelButtonText: this.cancelButtonText,
    //   showCancelButton: true,
    //   confirmButtonColor: '#009EF7',
    //   showLoaderOnConfirm: true,
    //   showConfirmButton: this.showConfirmButton,
    //   icon: this.icon,
    //   preConfirm: (inputValue) => {
    //     this.preConfirm.emit(inputValue);
    //   },
    //   allowOutsideClick: () => !Swal.isLoading()
    // }).then((result: any) => {
    //   if (result.isConfirmed) {
    //     this.confirmed.emit();
    //   }
    // });

    // const swalOptions: any = {
    //   title: this.title,
    //   html: this.htmlContent,
    //   confirmButtonText: this.confirmButtonText,
    //   cancelButtonText: this.cancelButtonText,
    //   showCancelButton: true,
    //   confirmButtonColor: '#009EF7',
    //   icon: this.icon,
    //   allowOutsideClick: () => !Swal.isLoading()
    // };

    // // Conditionally add input fields if needed
    // if (this.input) {
    //   swalOptions.input = this.input;
    //   swalOptions.inputPlaceholder = this.inputPlaceholder;
    // } else {
    //   // Explicitly ensure no input field is added
    //   delete swalOptions.input;
    //   delete swalOptions.inputPlaceholder;
    // }

    // // Conditionally add preConfirm if it's provided
    // if (this.preConfirm) {
    //   swalOptions.preConfirm = (inputValue: string | undefined) => {
    //     this.preConfirm.emit(inputValue);
    //   };
    //   swalOptions.showLoaderOnConfirm = true;
    // }

    // Swal.fire(swalOptions).then((result: any) => {
    //   if (result.isConfirmed) {
    //     this.confirmed.emit();
    //   }
    // });


    const swalOptions: any = {
      title: this.title,
      icon: this.icon,
      showCancelButton: true,
      cancelButtonText: this.cancelButtonText || 'Close', // Provide a default
      allowOutsideClick: () => !Swal.isLoading()
    };

    // Adjust options based on modal type
    if (this.modalType === 'complex') {
      swalOptions.html = this.htmlContent;
      swalOptions.confirmButtonText = this.confirmButtonText;
      swalOptions.confirmButtonColor = '#009EF7';

      // Conditionally add input fields if needed
      if (this.input) {
        swalOptions.input = this.input;
        swalOptions.inputPlaceholder = this.inputPlaceholder;
      }

      // Conditionally add preConfirm if it's provided
      if (this.preConfirm) {
        swalOptions.preConfirm = (inputValue: string | undefined) => {
          this.preConfirm.emit(inputValue);
        };
        swalOptions.showLoaderOnConfirm = true;
      }
    } else {
      swalOptions.text = this.htmlContent; 
      swalOptions.showConfirmButton = true;
      swalOptions.showCancelButton = false;
      if (this.cancelButtonText != "") {
        swalOptions.showCancelButton = true;
      }
    }

    // TODO: seguir puliendo esto

    Swal.fire(swalOptions).then((result: any) => {
      if (result.isConfirmed) {
        this.confirmed.emit();
      }
    });

    this.resetModal();
  }
}