<div>
  <div class="card">
    <div class="card-body">
      <div class="tab-content" id="myTabContent">
        <button id="btnTraspasos" style="width: 45%; margin-right: 3%;" class="btn btn-primary float-right ms-auto" (click)="type ='itemMovementDocuments'; itemMovementDocuments()">
            Actualizar traspasos
        </button>
        <button id="btnB2C" style="width: 45%" class="btn btn-primary float-right ms-auto" (click)="type ='refreshB2C'; refreshB2C()">
            Actualizar pedidos B2C
        </button>
        <br>
        <br>
        <br>
        <br>
        <button id="btnPrices" style="width: 45%; margin-right: 3%;" class="btn btn-primary float-right ms-auto" (click)="type ='priceCheck'; forceCheck('priceCheck')">
          Forzar precios
        </button>
        <button id="btnTariff" style="width: 45%" class="btn btn-primary float-right ms-auto" (click)="type ='tariffCheck'; forceCheck('tariffCheck')">
          Forzar tarifas
        </button>¡
        <div style="width: 45%; margin-right: 3%;">
          <label for="priceRefs">REFERENCIAS PARA FORZAR PRECIOS</label>
          <input id="priceRefs" type="text" class="form-control" placeholder="Deben ir separadas por coma">
        </div>
      </div>
      <br>
      <div *ngIf="loading" class="spinner-border text-info" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div *ngIf="success" role="status">
        <div id="success"></div>
      </div>
      <div *ngIf="error" role="status">
        <div id="error"></div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <div class="card">
    <div class="card-body">
      <div class="tab-content" id="myTabContent">
        <label for="externalIds">REFERENCIAS</label>
        <input id="externalIds" type="text" class="form-control" placeholder="Deben ir separadas por coma">
        <label for="operadores">OPERADORES</label>
        <ng-multiselect-dropdown
          #operatorSelector
          [placeholder]="'Operador'"
          [settings]="dropdownSettings"
          [data]="operadores"
          [(ngModel)]="selectedOperator"
          (onSelect)="check('expedition')"
        >
        </ng-multiselect-dropdown>
        <br>
        <div>
          <div *ngIf="expeditionChecked">
            <input type="checkbox" id="expedition" (click)="check('expeditionNum')">
            <label for="expedition" id="labelCheckExpedition" style="margin: 0 5px;"><b>Forzar nueva expedición</b></label>
            <input *ngIf="expeditionNumChecked" type="number" name="expeditionNum" id="expeditionNum" value="1">
          </div>
          <br>
          <input type="checkbox" id="return" (click)="check('return')">
          <label for="return" id="labelCheckReturn" style="margin: 0 5px;"><b>Enviar devolución</b></label>
        </div>
        <br>
        <button id="btnShippings" style="width: 45%; margin-right: 3%;" class="btn btn-primary float-right ms-auto" (click)="type ='shippings'; handleShipping()">
            Cambiar transportista
        </button>
        </div>
        <br>
        <div *ngIf="loadingTransport" class="spinner-border text-info" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div *ngIf="successTransport" role="status">
          <div id="successTransport"></div>
        </div>
        <div *ngIf="errorTransport" role="status">
          <div id="errorTransport"></div>
        </div>
    </div>
  </div>
</div>