import { OrderLinesDiscounts } from "./OrderLinesDiscounts.model.";

export class OrderLines {
    ExternalId: string;
    ItemId: number;
    SizeIndex: number;
    OriginalPrice: number;
    ActivePrice: number;
    Discounts: OrderLinesDiscounts[];
  
    constructor(ExternalId: string, ItemId: number, SizeIndex: number, OriginalPrice: number, ActivePrice: number, Discounts: OrderLinesDiscounts[]) {
        {
          this.ExternalId = ExternalId;
          this.ItemId = ItemId;
          this.SizeIndex = SizeIndex;
          this.OriginalPrice = OriginalPrice;
          this.ActivePrice = ActivePrice;
          this.Discounts = Discounts;
        }
    }
  }