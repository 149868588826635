<link
  href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css"
  rel="stylesheet"
/>
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
<script
  type="text/javascript"
  charset="utf8"
  src="https://cdn.datatables.net/1.10.18/js/jquery.dataTables.js"
></script>

<div class="card card-custom">
  <div class="card-header card-header-stretch mx-5 my-5">
    <h3 class="card-title">Configuración</h3>
    <div class="card-toolbar">
      <!-- Navegador -->
        <ul
        class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0"
       >
        <li class="nav-item">
          <a class="nav-link" id="mustClick" href="#tab_no_mapeados" data-bs-toggle="tab">No Mapeados</a>
        </li>
        
  
        <li class="nav-item">
          <a class="nav-link" href="#tab_mapeados" data-bs-toggle="tab">Mapeados</a>
        </li>
        </ul>
      
      
    </div>
  </div>
  
  <div class="tab-content">

    <!--*INICIO TABLA DE NO MAPEADOS-->
    <div class="tab-pane fade" id="tab_no_mapeados" role="tabpanel">
      <div class="card-header" class="my-5 mx-5">
        <div>
          <button
            class="btn btn-primary font-weight-bolder"
            (click)="clearSelected()"
            id="anadir"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_nuevo"
          >
            <i class="la la-plus"></i>Nuevo mapeo
          </button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
      </div>
     
      <div class="card-body">
       
        <div id="kt_datatable_logistic_states_wrapper" class="dataTables_wrapper dt-bootstrap4">
          <div style="width: 100%; padding-left: -10px">
            <div class="table-responsive">
              <button id="fakeClick" hidden></button>
              <table
                id="kt_datatable_logistic_states"
                role="grid"
                aria-describedby="kt_datatable_logistic_states_info"
                style="width: 100%"
                class="table-head-custom table-bordered table-hover table-checkable dataTable dtr-inline collapsed"
              >
                <thead>
                  <tr role="row">
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_logistic_states" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_logistic_states" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_logistic_states" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_logistic_states" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        
      </div>
  </div>

    <!--*FINAL TABLA DE NO MAPEADOS-->

    <!--*INICIO TABLA DE MAPEADOS-->
  
    <div class="tab-pane fade" id="tab_mapeados" role="tabpanel">
      <div class="card-header" class="my-5 mx-5">
        <div>
          <button
            class="btn btn-primary font-weight-bolder"
            (click)="clearSelected()"
            id="anadir"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_nuevo"
          >
            <i class="la la-plus"></i>Nuevo mapeo
          </button>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        </div>
        <br>
        <div>
          <ul class="nav nav-tabs nav-tabs-line">
            <li class="nav-item">
              <button class="nav-link" (click)="getMappingOpLogisticStatesSpecific(1)" data-toggle="tab">SEUR/DPD</button>
          </li>
          <li class="nav-item">
            <button class="nav-link" (click)="getMappingOpLogisticStatesSpecific(4)" data-toggle="tab">DHL</button>
        </li>
        <li class="nav-item">
          <button class="nav-link" (click)="getMappingOpLogisticStatesSpecific(3)" data-toggle="tab">CORREOS EXPRESS</button>
      </li>
      <li class="nav-item">
        <button class="nav-link" href="tab_mapeados" (click)="getMappingOpLogisticStatesSpecific(5)" data-toggle="tab">AL</button>
    </li>
    <li class="nav-item">
      <button class="nav-link" href="tab_mapeados" (click)="getMappingOpLogisticStatesSpecific(6)" data-toggle="tab">CORREOS POSTAL</button>
  </li>
          </ul>
        </div>
      </div>
      <div class="card-body">
        <div id="kt_datatable_logistic_states_wrapper" class="dataTables_wrapper dt-bootstrap4">
          <div style="width: 100%; padding-left: -10px;">
              <div class="table-responsive">
                  <table id="kt_datatable_logistic_states_mapeados"
                      class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom"
                      role="grid" aria-describedby="kt_datatable_logistic_states_info" style="width:100%">
                      <thead>
                          <tr role="row">
                            <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                            <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                          </tr>
                      </thead>      
                     
                      
                      <!-- <tfoot id="footer">
                        <tr>
                        <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                        <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                        <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                        <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                        <th tabindex="0" aria-controls="kt_datatable_logistic_states_mapeados" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                      </tr>
                      </tfoot>         -->
                  </table>
              </div>
          </div>
      </div>
  </div>
      </div>
     
    <!--*FINAL TABLA DE MAPEADOS-->
  </div>
 
  <!--*Modal de Nuevo Mapeo -->
  <div class="modal fade" tabindex="-1" id="kt_modal_nuevo">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Nuevo mapeo</h4>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x" style="font-size: 24px"
              >&times;</span
            >
          </div>
        </div>
        <div class="modal-body">
          <label id="op_logistico" class="my-5"
            ><b>OPERADOR LOGISTICO*</b></label
          >
          <ng-multiselect-dropdown
            [placeholder]="'Operador logistico'"
            [settings]="dropdownSettings"
            [data]="logisticList"
            [(ngModel)]="operadorSeleccionado"
            (onSelect)="getOpLogisticStateNoMapFiltro()"
          >
          </ng-multiselect-dropdown>
          <label id="estado_logistico" class="my-5"
            ><b>ESTADO LOGISTICO*</b></label
          >
          <ng-multiselect-dropdown
            [placeholder]="'Estado Logistico'"
            [settings]="dropdownSettings2"
            [data]="logisticStateListNoMap"
            [(ngModel)]="estadoSeleccionado"
          >
          </ng-multiselect-dropdown>

          <label id="estado_avelon" class="my-5"
            ><b>ESTADO LOGISTICO AVELON</b></label
          >
          <ng-multiselect-dropdown
            [placeholder]="'Estado Logistico Avelon'"
            [settings]="dropdownSettings"
            [data]="avelonStateList"
            [(ngModel)]="estadoAvelonSeleccionado"
          >
          </ng-multiselect-dropdown>
          <br>
          <div>
            <div style="float: left; width: 30%;" class="form-check form-switch form-check-custom form-check-solid">
              <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="is_incidence_save"
            />
            <label class="form-check-label" for="is_incidence_save"><b>INCIDENCIA</b></label>
            </div>
            <div style="float: left;" class="form-check form-switch form-check-custom form-check-solid">
              <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="is_final_save"
            />
            <label class="form-check-label" for="is_final_save"><b>FINAL</b></label>
            </div>
          </div>
          </div>
      
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Cancelar
          </button>
          <button
            type="button"
            (click)="saveLogisticState()"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- <ng-template #content let-modal> -->
  <div class="modal fade" tabindex="-1" id="kt_modal_edit">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Editar</h4>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x" style="font-size: 24px"
              >&times;</span
            >
          </div>
        </div>
        <div class="modal-body">
          <label><b>OPERADOR LOGISTICO</b></label>
          <ng-multiselect-dropdown
            #operador_seleccionado
            [placeholder]="'Operador logistico'"
            [settings]="dropdownSettings"
            [data]="logisticList"
            [(ngModel)]="operadorSeleccionado"
          >
          </ng-multiselect-dropdown>

          <label class="my-5"><b>ESTADO LOGISTICO</b></label>
          <ng-multiselect-dropdown
            #estado_seleccionado
            [placeholder]="'Estado Logistico'"
            [settings]="dropdownSettings"
            [data]="logisticStateList"
            [(ngModel)]="estadoSeleccionado"
          >
          </ng-multiselect-dropdown>

          <label class="my-5"><b>ESTADO LOGISTICO AVELON</b></label>
          <ng-multiselect-dropdown
            #estado_avelon_seleccionado
            [placeholder]="'Sin selección'"
            [settings]="dropdownSettings"
            [data]="avelonStateList"
            [(ngModel)]="estadoAvelonSeleccionado"
          >
          </ng-multiselect-dropdown>
          <br />
          <div>
            <div style="float: left; width: 30%;" class="form-check form-switch form-check-custom form-check-solid">
              <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="is_incidence"
            />
            <label style="float: left;" class="form-check-label" for="is_incidence"><b>INCIDENCIA</b></label>
            </div>
            <div class="form-check form-switch form-check-custom form-check-solid">
              <br />
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="is_final"
              />
              <label class="form-check-label" for="is_final"><b>FINAL</b></label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Cancelar
          </button>
          <button
            type="button"
            (click)="updateLogisticState()"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>


  <!--MODAL MAPEAR NO MAPEADO-->
  <div class="modal fade" tabindex="-1" id="kt_modal_mapping">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Mapear</h4>

          <div
            class="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="svg-icon svg-icon-2x" style="font-size: 24px"
              >&times;</span
            >
          </div>
        </div>
        <div class="modal-body">
          <label><b>OPERADOR LOGISTICO</b></label>
          <ng-multiselect-dropdown
            #operador_seleccionado
            [placeholder]="'Operador logistico'"
            [settings]="dropdownSettings"
            [data]="logisticList"
            [(ngModel)]="operadorSeleccionado"
          >
          </ng-multiselect-dropdown>

          <label class="my-5"><b>ESTADO LOGISTICO</b></label>
          <ng-multiselect-dropdown
            #estado_seleccionado
            [placeholder]="'Estado Logistico'"
            [settings]="dropdownSettings"
            [data]="logisticStateListNoMap"
            [(ngModel)]="estadoSeleccionado"
          >
          </ng-multiselect-dropdown>

          <label class="my-5"><b>ESTADO LOGISTICO AVELON</b></label>
          <ng-multiselect-dropdown
            #estado_avelon_seleccionado
            [placeholder]="'Sin selección'"
            [settings]="dropdownSettings"
            [data]="avelonStateList"
            [(ngModel)]="estadoAvelonSeleccionado"
          >
          </ng-multiselect-dropdown>
          <br />
          <div>
            <div style="float: left; width: 30%;" class="form-check form-switch form-check-custom form-check-solid">
              <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="is_incidence_map"
            />
            <label style="float: left;" class="form-check-label" for="is_incidence"><b>INCIDENCIA</b></label>
            </div>
            <div class="form-check form-switch form-check-custom form-check-solid">
              <br />
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="is_final_map"
              />
              <label class="form-check-label" for="is_final"><b>FINAL</b></label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">
            Cancelar
          </button>
          <button
            type="button"
            (click)="saveLogisticStateMapeable()"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Mapear
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- </ng-template> -->
</div>





