import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-caracteristicas',
  templateUrl: './caracteristicas.component.html',
  styleUrls: ['./caracteristicas.component.scss']
})
export class CaracteristicasComponent implements OnInit {
  tableCaracteristicas: DataTables.Api;
  caracteristicas: any[] = [];
  listMapped: any
  listToAdd: any[] = []
  listToUpdate: any[] = []
  listToDelete: any[] = []
  listInternalMapped: any
  listExternalMapped: any

  caracteristicaSeleccionada: any[] = [];

  componentDestroyed$: Subject<boolean> = new Subject()

  dropdownSettings: IDropdownSettings = {};

  private unsubscribe: Subscription[] = [];

  constructor(private appService: AppService, private route: ActivatedRoute) { }

  //! ############################### MÉTODOS DE LA CLASE ###############################
  //#region MÉTODOS DE LA CLASE
  //! GETS
  //#region GETS
  checkMapping() {
    this.listExternalMapped = []
    this.listInternalMapped = []

    const appServiceSubscription = this.appService.checkMappingCaracteristicas().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
        this.listMapped = res

        this.listMapped.forEach((element: any) => {
          this.listInternalMapped.push(element["id_internal_feature"].toString())
          this.listExternalMapped.push(element["id_external_feature"].toString())
        });
      }, err => {
        console.log(err)
      })

    this.unsubscribe.push(appServiceSubscription);
  }

  getCaracteristicas() {
    const appServiceSubscription = this.appService.getCaracteristicas().pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      this.caracteristicas = res
    }, err => {
      console.log(err)
    })

    this.unsubscribe.push(appServiceSubscription);
  }
  //#endregion GETS

  //! POSTS
  //#region POSTS
  editFeature(type: string) {
    const idFeature = this.caracteristicaSeleccionada[0].id
    switch (type) {
      case 'delete':
        if (this.caracteristicaSeleccionada.length === 0) {
          Swal.fire({
            title: 'Característica no seleccionada',
            text: 'Por favor, selecciona una característica para poder actualizar.',
            icon: 'warning',
          })
        }
        else {
          this.appService.deleteFeature(idFeature)
            .pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
              Swal.fire({
                title: 'Característica eliminada',
                text: 'La característica ha sido eliminada exitosamente.',
                icon: 'success',
              })
            }, err => {
              console.log(err);
            });
        }
        break;
      case 'update':
        const input = document.querySelector('#updatedFeature') as HTMLInputElement;
        const updatedCaracteristica = input.value.trim();

        if (this.caracteristicaSeleccionada.length === 0) {
          Swal.fire({
            title: 'Característica no seleccionada',
            text: 'Por favor, selecciona una característica para poder actualizar.',
            icon: 'warning',
          })
        }
        else if (updatedCaracteristica === '') {
          Swal.fire({
            title: 'Característica vacía',
            text: 'Por favor, escribe una característica para poder actualizar.',
            icon: 'warning',
          });
        }
        else {
          this.appService.updateFeature(idFeature, updatedCaracteristica)
            .pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
              Swal.fire({
                title: 'Característica actualizada',
                text: 'La característica ha sido actualizada exitosamente.',
                icon: 'success',
              })
            }, err => {
              console.log(err);
            });
        }
        break;
      default:
        break;
    }

    this.getCaracteristicas();
    this.tableCaracteristicas.ajax.reload();
    this.caracteristicaSeleccionada = []
    $("#updatedFeature").val('')
  }
  //#endregion POSTS

  //! PUTS
  //#region PUTS
  createCaracteristica() {
    const input = document.querySelector('.searchCaracteristica') as HTMLInputElement;
    const newCaracteristica = input.value.trim().toLowerCase();
  
    const isDuplicate = this.caracteristicas.some(
      (c) => c.caracteristica.toLowerCase() === newCaracteristica
    );
  
    if (isDuplicate) {
      Swal.fire({
        title: 'Característica duplicada',
        text: 'Asegúrate de que la característica que intentas agregar no exista previamente.',
        icon: 'warning',
      });
    } else if (newCaracteristica === '') {
      Swal.fire({
        title: 'Característica vacía',
        text: 'Por favor, escribe una característica para poder agregarla.',
        icon: 'warning',
      });
    }
     else {
      this.appService.createCaracteristica(newCaracteristica)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(
          (res) => {
            this.getCaracteristicas();
            this.tableCaracteristicas.ajax.reload();
            Swal.fire({
              title: 'Característica creada',
              text: 'La característica ha sido creada exitosamente.',
              icon: 'success',
            });
          },
          (err) => {
            Swal.fire({
              title: 'Error al crear característica',
              text: 'Ocurrió un error al intentar crear la característica. Por favor, intenta de nuevo.',
              icon: 'error',
            });
          }
        );
    }
  }
  //#endregion PUTS
  
  //! DELETES
  //#region DELETES
  //#endregion DELETES
  //#endregion MÉTODOS DE LA CLASE

  //! ############################### UTILS ###############################
  //#region UTILS
  hasCommonWord(name: string, caracteristica: string): boolean {
    const nameWords = name.toLowerCase().split(/\s+/);
    const caracteristicaWords = caracteristica.toLowerCase().split(/\s+/);
    return nameWords.some(word => caracteristicaWords.includes(word));
  }

  firstCheck(selects: any) {
    for (let i = 0; i < selects.length; i++) {
      let e = selects[i]

      if ( e.querySelectorAll("option:checked")[0].id != '') {
        if (this.listToAdd.some((x: { id: string; }) => x.id === e.id)) {
          let index = this.listToAdd.indexOf(e.id)
          this.listToAdd.splice(index, 1)
        }
      }
    }
  }

  saveMapping() {
    let market_id: any = this.route.snapshot.paramMap.get('id')
    const selects = document.querySelectorAll("[recomendado]")

    for (let i = 0; i < selects.length; i++) {
      let e = selects[i]

      this.listToAdd.push({"id":  e.attributes.getNamedItem("recomendado")?.value, "value": e.id})
    }

    var valuesLine = "";
    var valuesUpdate = "";
    var valuesDelete = "";

    if (this.listToAdd.length > 0) {
      this.listToAdd.forEach((e: any) => {
        valuesLine += "(" + market_id + ",'" + e.id + "'," + e.value + "),"
      });
    }

    let valuesLineFinal = valuesLine.replace(/,\s*$/, "")

    if (this.listToUpdate.length > 0) {
      this.listToUpdate.forEach((e: any) => {
        valuesUpdate += "(" + market_id + ",'" + e.id + "'," + e.value + "),"
      });
    }

    let valuesLineFinalUpdate = valuesUpdate.replace(/,\s*$/, "")

    if (this.listToDelete.length > 0) {
      this.listToDelete.forEach((e: any) => {
        valuesDelete += "\"" + e.id + "\","
      });
      valuesDelete = valuesDelete.substring(0, valuesDelete.length - 1)
    }

    console.log(this.listToAdd, this.listToUpdate, this.listToDelete)

    const observables = [];

    if (valuesLineFinal != "") {
      observables.push(this.appService.createFeatureMapping(valuesLineFinal).pipe(takeUntil(this.componentDestroyed$)));
    }
  
    if (valuesLineFinalUpdate != "") {
      observables.push(this.appService.createFeatureMapping(valuesLineFinalUpdate).pipe(takeUntil(this.componentDestroyed$)));
    }
  
    if (valuesDelete != "") {
      observables.push(this.appService.deleteFeaturesMapping(market_id, valuesDelete).pipe(takeUntil(this.componentDestroyed$)));
    }

    forkJoin(observables).subscribe(
      res => {
        this.listToAdd = [];
        this.listToUpdate = [];
        this.listToDelete = [];
        this.checkMapping();
        this.getCaracteristicas();
        this.tableCaracteristicas.ajax.reload();
      },
      err => {
        console.log(err);
      }
    );
  }
  //#endregion UTILS
  
  //! ############################### INICIALIZACIÓN ###############################
  //#region INICIALIZACIÓN
  processTableSimular(){
    this.tableCaracteristicas = $('#kt_datatable_caracteristicas').DataTable({
      processing: true,
      responsive: true,
      ordering: false,
      language: {
          "processing": `<span>Cargando...
          </span>`,
          "lengthMenu": "Mostrar _MENU_ registros",
          "zeroRecords": "No se encontraron resultados",
          "emptyTable": "Ningún dato disponible en esta tabla",
          "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
          "infoFiltered": "(filtrado de un total de _MAX_ registros)",
          "search": "Buscar:",
          "loadingRecords": " ",
          "paginate": {
              "first": "Primero",
              "last": "Último",
              "next": ">",
              "previous": "<"
          },
          "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
          },
          "decimal": ",",
          "thousands": ".",
          "info": "Mostrando _START_ a _END_ de _TOTAL_ registros"
      },
      
      ajax: {
        //! EN CASO DE HACER CAMBIOS LOCALES EN LA PARTE DE NODE, UTILIZAR LA URL COMENTADA. PARA SUBIRLO, SE DEBE UTILIZAR LA DEL environment
        url: `${environment.portalHost}/api/getMappingCaracteristicas`,
        // url: `http://localhost:4200/api/getMappingCaracteristicas`,
        dataSrc: '',
      },
      columns: [
        {
          title: 'AVELON',
          render: function (data: any, type: any, full: any) {
            return `<td>` + full.Name + `</td>`;
          },
        },
        {
          title: 'MARKET',
          data: null,
          render: (d: any, type: any, full: any) => {
            var select = `<select class="form-select mapsToAdd" aria-label="Default select example" id="` + full.Id + `">`

            select += `<option value="0">Selecciona una característica</option>`

            this.caracteristicas.forEach((element: any) => {
              this.listMapped.forEach((element2: any) => {
                if (element2["id_internal_feature"] === d["Id"].toString() && element2["id_external_feature"] === element["id"]) {

                  select += `<option selected id="${element["id"]}" value="${element["id"]}">${element["caracteristica"]}</option>`
                }
              });

              if (this.hasCommonWord(d["Name"], element["caracteristica"])  && !this.listInternalMapped.includes(d["Id"])) {

                select += `<option selected recomendado="${d["Id"]}" id="${element["id"]}" value="${element["id"]}">${element["caracteristica"]} (Recomendado)</option>`

              } else 
              if (!select.includes(`<option selected id="${element["id"]}" value="${element["id"]}">${element["caracteristica"]}</option>`) && !select.includes(`<option selected recomendado="${d["Id"]}" id="${element["id"]}" value="${element["id"]}">${element["caracteristica"]} (Recomendado)</option>`)) {

                select += `<option id="${element["id"]}" value="${element["id"]}">${element["caracteristica"]}</option>`
              }
            });
            return select
          }
        }
      ],
      drawCallback: () => {
        const selects = Array.from(document.getElementsByClassName("mapsToAdd"))
    
        this.firstCheck(selects)
    
        for (let i = 0; i < selects.length; i++) {
          let e = selects[i];
    
          // Se añade un evento a cada uno de los selects de market
          e.addEventListener('change', ()=> {
            console.log("Hola")
            // En caso de existir previamente un mapeo, se incluye en la lista de actualización
            if (this.listInternalMapped.includes(e.id.toString())) {
              if (e.querySelectorAll("option:checked")[0].id != "") {
                // En caso de que exista ya un mapeo en la lista de actualización, se elimina para poder meter la nueva opción
                if (this.listToUpdate.some((x: { id: string; }) => x.id === e.id)) {
                  let index = this.listToUpdate.indexOf(e.id)
                  this.listToUpdate.splice(index, 1)
                }
      
                this.listToUpdate.push({"id":e.id, "value": e.querySelectorAll("option:checked")[0].id})
              }
              else {
                this.listToDelete.push({"id":e.id, "value": e.querySelectorAll("option:checked")[0].id})
              }
            }
            // Si no existe un mapeo, se incluye en la lista de inserts
            else {
              if (e.querySelectorAll("option:checked")[0].id != "") {
                // En caso de que exista ya un mapeo en la lista de inserts, se elimina para poder meter la nueva opción
                if (this.listToAdd.some((x: { id: string; }) => x.id === e.id)) {
                  let index = this.listToAdd.indexOf(e.id)
                  this.listToAdd.splice(index, 1)
                }
      
                this.listToAdd.push({"id":e.id, "value": e.querySelectorAll("option:checked")[0].id})
              }
              else {
                if (this.listToAdd.some((x: { id: string; }) => x.id === e.id)) {
                  let index = this.listToAdd.indexOf(e.id)
                  this.listToAdd.splice(index, 1)
                }
              }
            }
          })
        }
      },
      initComplete: () => {
      }
    });
  }

  ngOnInit(): void {
    this.checkMapping()
    this.getCaracteristicas()
    this.processTableSimular()

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'caracteristica',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }
  //#endregion INICIALIZACIÓN
}
