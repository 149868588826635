<link href="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/css/select2.min.css" rel="stylesheet" />
<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>

<div class="card ">
    <div class="card-header card-header-stretch">
        <h3 class="card-title">Control de usuarios</h3>
        <div class="card-toolbar">

            <!-- Navegador -->
            <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                <ng-container>
                    <li class="nav-item">
                        <a class="nav-link active" data-bs-toggle="tab" href="#tab_users">Usuarios</a>
                    </li>
                </ng-container>
                <ng-container *ngIf="dev">
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#tab_roles">Roles</a>
                    </li>
                </ng-container>
                <ng-container>
                    <li class="nav-item">
                        <a class="nav-link" data-bs-toggle="tab" href="#tab_permisos">Permisos</a>
                    </li>
                </ng-container>
            </ul>

        </div>
    </div>
    <div class="card-body">
        <div class="tab-content" id="myTabContent">
            
            <ng-container>
                <!-- Tabla de usuarios -->
                <div class="tab-pane fade active show" id="tab_users" role="tabpanel">
                  <div class="card-header" class="my-5 mx-5">
                    <div>
                      <button class="btn btn-primary font-weight-bolder" id="anadir" data-bs-toggle="modal" data-bs-target="#kt_modal_nuevo_user"><i class="la la-plus"></i>Nuevo usuario</button>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <button class="btn btn-primary font-weight-bolder" id="anadir" (click)="limpiarFiltros()"><i class="las la-broom"></i>Limpiar filtros</button>
                   </div>
                  </div>
                  <div id="kt_datatable_users_wrapper" class="dataTables_wrapper dt-bootstrap4">
                    <div style="width: 100%; padding-left: -10px;">
                      <div class="table-responsive">
                        <table id="kt_datatable_users" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom"  role="grid" aria-describedby="kt_datatable_users_info" style="width:100%">
                            <thead>
                                <tr role="row">
                                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_users" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Nombre</th>
                                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_users" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Apellido</th>
                                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_users" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Email</th>
                                    <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_users" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Rol</th>
                                    <th class="sorting_disabled" tabindex="0" aria-controls="kt_datatable_users" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th>
                                </tr>
                            </thead>
                            <tr id="filters">
              
                            </tr>
                            <tbody>
                                <tr *ngFor="let item of users">
                                  <td>{{item["username"]}}</td>
                                  <td>{{item["lastname"]}}</td>
                                  <td>{{item["email"]}}</td>
                                  <td>{{item["name"]}}</td>
                                  <td *ngIf='item["is_default"]==0' style="font-size:16px; color: red;">&times;</td>
                                  <td *ngIf='item["is_default"]==1' style="font-size:16px; color: green;">&check;</td>
                                  <td nowrap="nowrap" class="datatable-actions-td">
                                    <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action" data-bs-toggle="modal" data-bs-target="#kt_modal_user" (click)="poblarModalUsers(item['email'])" title="Editar">
                                      <span class="svg-icon svg-icon-md"> 
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                          xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"></rect>
                                            <path
                                              d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                              fill="#000000" fill-rule="nonzero"
                                              transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                                            <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                                          </g>
                                        </svg> 
                                      </span>
                                    </a>
                                    <a href="javascript:;" class="btn btn-sm btn-clean btn-icon datatable-action" (click)="deleteUser(item['id'])" title="Eliminar"> 
                                      <span class="svg-icon svg-icon-md"> 
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                            viewBox="0 0 24 24" version="1.1">
                                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                              <rect x="0" y="0" width="24" height="24"></rect>
                                              <path
                                                d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                                fill="#000000" fill-rule="nonzero"></path>
                                              <path
                                                d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                                fill="#000000" opacity="0.3"></path>
                                          </g>
                                        </svg> 
                                      </span> 
                                    </a>
                                  </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Apellido</th>
                                    <th>Email</th>
                                    <th>Rol</th>
                                </tr>
                            </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
            </ng-container>
            <ng-container>
              <!-- Tabla de roles -->
              <div class="tab-pane fade" id="tab_roles" role="tabpanel">
                <div class="card-header" class="my-5 mx-5">
                  <div>
                    <button class="btn btn-primary font-weight-bolder" id="anadir" data-bs-toggle="modal" data-bs-target="#kt_modal_nuevo_role"><i class="la la-plus"></i>Nuevo rol</button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <!-- <button class="btn btn-primary font-weight-bolder" id="anadir" (click)="limpiarFiltros()"><i class="las la-broom"></i>Limpiar filtros</button> -->
                 </div>
                </div>
                <div id="kt_datatable_role_wrapper" class="dataTables_wrapper dt-bootstrap4">
                  <div style="width: 100%; padding-left: -10px;">
                    <div class="table-responsive">
                      <table id="kt_datatable_role" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_role_info" style="width:100%">
                          <thead>
                              <tr role="row">
                                  <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_role" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Nombre</th>
                                  <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_role" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Permisos</th>
                                  <!-- <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_role" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending"></th> -->
                              </tr>
                          </thead>
                          <tr id="filters">
            
                          </tr>
                          <tbody>
                              <tr *ngFor="let item of rolesWithNames">
                                <td>{{item["name"]}}</td>
                                <td>{{item["lista"] != null ? item["lista"] : 'Sin permisos'}}</td>
                                <td *ngIf='item["is_default"]==0' style="font-size:16px; color: red;">&times;</td>
                                <td *ngIf='item["is_default"]==1' style="font-size:16px; color: green;">&check;</td>
                                <td nowrap="nowrap" class="datatable-actions-td">
                                  <a class="btn btn-sm btn-clean btn-icon mr-2 datatable-action" data-bs-toggle="modal" data-bs-target="#kt_modal_role_edit" (click)="poblarModalRoles(item['id'])" title="Editar">
                                    <span class="svg-icon svg-icon-md"> 
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                          <rect x="0" y="0" width="24" height="24"></rect>
                                          <path
                                            d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                            fill="#000000" fill-rule="nonzero"
                                            transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                                          <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                                        </g>
                                      </svg> 
                                    </span>
                                  </a>
                                  <a href="javascript:;" class="btn btn-sm btn-clean btn-icon datatable-action" (click)="deleteRole(item['id'])" title="Eliminar"> 
                                    <span class="svg-icon svg-icon-md"> 
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
                                          viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <rect x="0" y="0" width="24" height="24"></rect>
                                            <path
                                              d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                                              fill="#000000" fill-rule="nonzero"></path>
                                            <path
                                              d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                                              fill="#000000" opacity="0.3"></path>
                                        </g>
                                      </svg> 
                                    </span> 
                                  </a>
                                </td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
          </ng-container>
          <ng-container>
            <!-- Tabla de prueba  -->
            <div class="tab-pane fade" id="tab_permisos" role="tabpanel">
              <div class="card-header" class="my-5 mx-5">
                <div>
                  <button class="btn btn-primary font-weight-bolder" id="anadir" data-bs-toggle="modal" data-bs-target="#kt_modal_nuevo_role"><i class="la la-plus"></i>Nuevo rol</button>
                  <br><br>
                  <label for="roleSelector" class="my-5"><b>FILTRO</b></label>
                  <ng-multiselect-dropdown #single
                    id="roleSelector"
                    [placeholder]="'Roles'"
                    [settings]="dropdownSettingsRoles"
                    [data]="roles"
                    [(ngModel)]="selectedRole"
                    (onSelect)="roleSelect(true)"
                    (onDeSelect)="roleSelect(false)"
                  >
                  </ng-multiselect-dropdown>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <!-- <button class="btn btn-primary font-weight-bolder" id="anadir" (click)="limpiarFiltros()">routin<i class="las la-broom"></i>Limpiar filtros</button> -->
                </div>
              </div>
              <div id="kt_datatable_prueba_wrapper" class="dataTables_wrapper dt-bootstrap4">
                <div style="width: 100%; padding-left: -10px;">
                  <div class="table-responsive">
                    <table id="kt_datatable_prueba" class="table-bordered table-hover table-checkable dataTable dtr-inline collapsed table-head-custom" role="grid" aria-describedby="kt_datatable_prueba_info" style="width:100%">
                      <thead>
                          <tr role="row">
                              <th class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_prueba" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">Permisos / Nombre</th>
                              <ng-container *ngIf="roleSelected">
                                <th *ngFor="let rol of selectedRole" class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_prueba" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">{{rol.name}}</th>
                              </ng-container>
                              <ng-container *ngIf="!roleSelected">
                                <th *ngFor="let rol of roles" class="sorting sorting_asc" tabindex="0" aria-controls="kt_datatable_prueba" rowspan="1" colspan="1" aria-label="Record ID: activate to sort column descending">{{rol.name}}</th>
                              </ng-container>
                          </tr>
                      </thead>
                      <tr id="filters">
        
                      </tr>
                      <tbody>
                        <!-- <tr *ngFor="let permiso of permisos">
                          <td>{{permiso.name}}</td>
                          <ng-container *ngFor="let user of users">
                            <td>
                              <div class="form-check form-switch form-check-custom form-check-solid" *ngIf="activePermiso(user.rol, permiso.id)">
                                <input class="form-check-input" type="checkbox" value="" id="flexSwitchChecked" checked="checked" (click)="updatePermisos(0, user.id, permiso.id)" />
                              </div>
                              <div class="form-check form-switch form-check-custom form-check-solid" *ngIf="!activePermiso(user.rol, permiso.id)">
                                <input class="form-check-input" type="checkbox" value="" id="flexSwitchChecked" (click)="updatePermisos(1, user.id, permiso.id)"/>
                              </div>
                            </td>
                          </ng-container>
                        </tr> -->
                        <tr *ngFor="let permiso of permisos">
                          <td>{{permiso.name}}</td>
                          <ng-container *ngIf="roleSelected">
                            <td *ngFor="let rol of selectedRole">
                              <div class="form-check form-switch form-check-custom form-check-solid" *ngIf="activePermiso(permiso.id, rol.id)">
                                <input class="form-check-input" type="checkbox" value="" id="flexSwitchChecked" checked="checked" (click)="updatePermisos(0, permiso.id, rol.id)" />
                              </div>
                              <div class="form-check form-switch form-check-custom form-check-solid" *ngIf="!activePermiso(permiso.id, rol.id)">
                                <input class="form-check-input" type="checkbox" value="" id="flexSwitchChecked" (click)="updatePermisos(1, permiso.id, rol.id)"/>
                              </div>
                            </td>
                          </ng-container>
                          <ng-container *ngIf="!roleSelected">
                            <td *ngFor="let rol of roles">
                              <div class="form-check form-switch form-check-custom form-check-solid" *ngIf="activePermiso(permiso.id, rol.id)">
                                <input class="form-check-input" type="checkbox" value="" id="flexSwitchChecked" checked="checked" (click)="updatePermisos(0, permiso.id, rol.id)" />
                              </div>
                              <div class="form-check form-switch form-check-custom form-check-solid" *ngIf="!activePermiso(permiso.id, rol.id)">
                                <input class="form-check-input" type="checkbox" value="" id="flexSwitchChecked" (click)="updatePermisos(1, permiso.id, rol.id)"/>
                              </div>
                            </td>
                          </ng-container>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
<div class="modal fade" tabindex="-1" id="kt_modal_user">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Editar usuario</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
              <label for="nombreUserEdit" class="my-5"><b>Nombre</b></label>
              <input id="nombreUserEdit" class="form-control" type="text"/>
              <label for="apellidoUserEdit" class="my-5"><b>Apellido</b></label>
              <input id="apellidoUserEdit" class="form-control" type="text"/>
              <label for="emailUserEdit" class="my-5"><b>Email</b></label>
              <input id="emailUserEdit" class="form-control" type="email"/>
              <label for="passwordUserEdit" class="my-5"><b>Contraseña</b></label>
              <input id="passwordUserEdit" class="form-control" type="password"/>
              <label for="passwordUserCheck" class="my-5"><b>Repite la contraseña</b></label>
              <input id="passwordUserCheck" class="form-control" type="password"/>
              <label for="roleUserEdit" class="my-5"><b>Rol</b></label>
              <select id="roleUserEdit" class="form-control form-control-sm form-filter datatable-input form-select">
                <option *ngFor="let role of roles" value="{{role['id']}}">{{role["name"]}}</option>
              </select>
              <br/>
              <input type="hidden" id="idUserEdit"/>
          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="saveUserEdit()" class="btn btn-primary">Guardar</button>
          </div>
      </div>
  </div>
</div>
<div class="modal fade" tabindex="-1" id="kt_modal_nuevo_user">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Nuevo usuario</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <label for="nombreNewUser" class="my-5"><b>Nombre</b></label>
            <input id="nombreNewUser" class="form-control" type="text"/>
            <label for="apellidoNewUser" class="my-5"><b>Apellido</b></label>
            <input id="apellidoNewUser" class="form-control" type="text"/>
            <label for="emailNewUser" class="my-5"><b>Email</b></label>
            <input id="emailNewUser" class="form-control" type="email"/>
            <label for="passwordNewUser" class="my-5"><b>Contraseña</b></label>
            <input id="passwordNewUser" class="form-control" type="password"/>
            <label for="passwordNewCheck" class="my-5"><b>Repite la contraseña</b></label>
            <input id="passwordNewCheck" class="form-control" type="password"/>
            <label for="rolesNewUser" class="my-5"><b>Roles</b></label>
            <select id="roleNewUser" class="form-control form-control-sm form-filter datatable-input form-select">
              <option *ngFor="let role of roles" value="{{role['id']}}">{{role["name"]}}</option>
            </select>
            <br/>
            <input type="hidden" id="idNewUser"/>
        </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="saveNewUser()" class="btn btn-primary">Guardar</button>
          </div>
      </div>
  </div>
</div>


<!-- Modales roles -->
<div class="modal fade" tabindex="-1" id="kt_modal_role_edit">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Editar Rol</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
              <label for="nombreRoleEdit" class="my-5"><b>Nombre</b></label>
              <input id="nombreRoleEdit" class="form-control" type="text"/>
              <label for="permisosRoleEdit" class="my-5"><b>Permisos</b></label>
              <ng-multiselect-dropdown #multi
                id="permisosRoleEdit"
                [placeholder]="'Permisos'"
                [settings]="dropdownSettings"
                [data]="permisos"
                [(ngModel)]="selectedItems"
              >
              </ng-multiselect-dropdown>
              <br/>
              <input type="hidden" id="idRoleEdit"/>
          </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="saveRoleEdit()" class="btn btn-primary">Guardar</button>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" tabindex="-1" id="kt_modal_nuevo_role">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Nuevo Rol</h5>

              <!--begin::Close-->
              <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                  <span class="svg-icon svg-icon-2x" style="font-size: 24px;">&times;</span>
              </div>
              <!--end::Close-->
          </div>

          <div class="modal-body">
            <label for="nameNewRole" class="my-5"><b>Nombre</b></label>
            <input id="nameNewRole" class="form-control" type="text"/>
            <label for="permisos" class="my-5"><b>Permisos</b></label>
            <ng-multiselect-dropdown
              id="permisos"
              [placeholder]="'Permisos'"
              [settings]="dropdownSettings"
              [data]="permisos"
              [(ngModel)]="selectedItems"
            >
            </ng-multiselect-dropdown>
            <br/>
        </div>

          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancelar</button>
              <button type="button" (click)="saveNewRole()" class="btn btn-primary">Guardar</button>
          </div>
      </div>
  </div>
</div>